import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import InvestmentPoolConfigDetail from './Detail/InvestmentPoolConfigDetail';

const InvestmentPoolConfigCreate = () => {
  return (
    <DefaultLayout>
      <InvestmentPoolConfigDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(InvestmentPoolConfigCreate);
