import { Control, DeepMap, FieldError } from 'react-hook-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioGroup } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { NETWORK_AVAILABLE } from '../../../../../constants';
import { Pool } from '../../types';
import { KycRequired } from './KycRequired';

interface DetailProps {
  detail?: Pool;
  control?: Control<any>;
  watch?: any;
  setValue?: any;
  errors?: DeepMap<any, FieldError>;
  isShowEth?: boolean;
}

export function Detail({ control, detail, errors, isShowEth = false }: DetailProps) {
  const isTBA = control?.watchInternal('networkAvailable') === NETWORK_AVAILABLE.TBA;
  const options = isShowEth
    ? [
        {
          value: NETWORK_AVAILABLE.POLYGON,
          label: 'Polygon',
        },
        {
          value: NETWORK_AVAILABLE.SOL,
          label: 'Solana',
        },
        {
          value: NETWORK_AVAILABLE.ETH,
          label: 'Ethereum',
        },
        {
          value: NETWORK_AVAILABLE.BSC,
          label: 'BSC',
        },
        {
          value: NETWORK_AVAILABLE.TBA,
          label: 'TBA',
        },
      ]
    : [
        {
          value: NETWORK_AVAILABLE.POLYGON,
          label: 'Polygon',
        },
        {
          value: NETWORK_AVAILABLE.SOL,
          label: 'Solana',
        },
        // {
        //   value: NETWORK_AVAILABLE.ETH,
        //   label: 'Ethereum',
        // },
        {
          value: NETWORK_AVAILABLE.BSC,
          label: 'BSC',
        },
        {
          value: NETWORK_AVAILABLE.TBA,
          label: 'TBA',
        },
      ];

  return (
    <Paper>
      <PaperTitle>Project Details</PaperTitle>
      <RadioGroup
        name="networkAvailable"
        label="Network Available"
        errorMessage={errors?.networkAvailable?.message}
        control={control}
        radioData={options}
        onRenderRadio={(data) => (
          <FormControlLabel
            key={data.value}
            value={data.value}
            label={data.label}
            control={<Radio size="small" />}
            disabled={!!detail?.isDeploy}
          />
        )}
      />
      <RadioGroup
        name="acceptCurrency"
        label="Accept Currency"
        errorMessage={errors?.acceptCurrency?.message}
        control={control}
        radioData={[
          {
            value: 'usdt',
            label: 'USDT',
          },
          {
            value: 'usdc',
            label: 'USDC',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel
            key={data.value}
            value={data.value}
            label={data.label}
            control={<Radio size="small" />}
            disabled={!!detail?.isDeploy || isTBA}
          />
        )}
      />
      <KycRequired errors={errors} control={control} />
    </Paper>
  );
}
