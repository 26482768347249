import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Button } from '../../../../components/Base/Form';
import { adminRoute } from '../../../../utils';
import { FileTypeLabel, UploadFileTypeLabel } from '../../constants';
import { Media } from '../../constants/type';
import styles from './styles.module.scss';

interface SlideProps {
  data: Media;
  onDelete: (id: number | string) => void;
}

function DataRow(props: any) {
  return (
    <Grid className={styles.data} container>
      <Grid item>{props.firstChild}</Grid>
      <Grid item>{props.secondChild}</Grid>
    </Grid>
  );
}

export function Slide({ data, onDelete }: SlideProps) {
  const { firstSlide, secondSlide, thumbnail, id } = data;
  const history = useHistory();

  return (
    <Grid container className={styles.slideContainer}>
      <Grid xs={11} item>
        <h3>Project Name: {data.projectName}</h3>
        <DataRow
          firstChild={`1st slide: ${FileTypeLabel[firstSlide.type]}`}
          secondChild={`${FileTypeLabel[firstSlide.type] ?? ''} type: ${
            UploadFileTypeLabel[firstSlide.uploadType] ?? ''
          }`}
        />
        <DataRow
          firstChild={`2nd slide: ${FileTypeLabel[secondSlide.type] ?? ''}`}
          secondChild={`${FileTypeLabel[secondSlide.type] ?? ''} type: ${
            UploadFileTypeLabel[secondSlide.uploadType] ?? ''
          }`}
        />
        <DataRow
          firstChild={`Thumbnail image: ${FileTypeLabel[thumbnail.type]}`}
          secondChild={`${FileTypeLabel[thumbnail.type] ?? ''} type: ${
            UploadFileTypeLabel[thumbnail.uploadType] ?? ''
          }`}
        />
      </Grid>
      <Grid
        className={styles.actions}
        alignContent="flex-end"
        justifyContent="space-around"
        xs={1}
        item
        container
        direction="column"
      >
        <Button onClick={() => history.push(adminRoute(`/sliders/${id}/update`))}>Edit</Button>
        <Button onClick={() => onDelete(id)} className={styles.cancel}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
}
