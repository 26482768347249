import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: 'white',
        boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
        borderRadius: 10,
        padding: '20px 25px 30px 25px',
        marginTop: 20
    },
    title: {
        fontSize: 16,
        fontFamily: 'OpenSans-SemiBold',
        color: '#001F4D',
        margin: 0,
        padding: 0
    }
}))

export default useStyles
