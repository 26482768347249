import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import ConfirmDialog from '../../../../../components/Base/ConfirmDialog';
import { Button, TextInput } from '../../../../../components/Base/Form';
import { Paper as FormPaper, PaperTitle } from '../../../../../components/Base/Paper';
import { DATETIME_FORMAT } from '../../../../../constants';
import useStyles from './style';
import { ClaimConfig, Pool } from '../../types';
import CreateEditClaimConfigForm from './CreateEditClaimConfigForm';
import useFormStyles from '../useFormStyles';
import { Checkbox } from '../../../../../components/Base/Form/Checkbox/Checkbox';

const useStylesTable = makeStyles({
  tableContainer: {
    marginTop: 19,
  },
  table: {
    minWidth: 650,
  },
});

const DEFAULT_CLAIM_CONFIG: ClaimConfig = {
  id: -1,
  maxPercentClaim: 1,
  startTime: null,
};

interface ClaimConfigTableProps {
  register: any;
  setValue: any;
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  poolDetail?: Pool;
  hasTBA?: boolean;
}

export function ClaimConfigTable({ register, setValue, control, errors, poolDetail, hasTBA }: ClaimConfigTableProps) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const classesTable = useStylesTable();
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [indexDeleted, setIndexDeleted] = useState(0);
  const [editRow, setEditRow] = useState(0);
  const [isEdit, setIsEdit] = useState(true);
  const [rows, setRows] = useState<ClaimConfig[]>([]);
  const [editData, setEditData] = useState<ClaimConfig>({
    ...DEFAULT_CLAIM_CONFIG,
  });

  useEffect(() => {
    if (poolDetail && poolDetail.campaignClaimConfig && Array.isArray(poolDetail.campaignClaimConfig)) {
      setRows(poolDetail.campaignClaimConfig);
    }
  }, [poolDetail]);

  const openPopupEdit = (row: any, index: number) => {
    setEditData({ ...row });
    setEditRow(index);
    setIsEdit(true);
    setIsOpenEditPopup(true);
  };

  const openPopupCreate = () => {
    setEditData({ ...DEFAULT_CLAIM_CONFIG });
    setEditRow(-1);
    setIsEdit(false);
    setIsOpenEditPopup(true);
  };

  const handleSubmitData = (responseData: ClaimConfig) => {
    if (isEdit && editRow !== -1) {
      rows[editRow] = { ...responseData };
    } else {
      rows.push({ ...responseData });
    }

    setValue('campaignClaimConfig', JSON.stringify(rows));
    setIsOpenEditPopup(false);
  };

  const openDeleteConfirm = (index: number) => {
    // eslint-disable-next-line no-restricted-globals
    setIsOpenDeleteConfirm(true);
    setIndexDeleted(index);
  };

  const deleteRecord = () => {
    const newRows = [...rows];
    if (indexDeleted > -1) {
      newRows.splice(indexDeleted, 1);
    }

    setRows(newRows);
    setValue('campaignClaimConfig', JSON.stringify(newRows));
    setIsOpenDeleteConfirm(false);
  };

  const isTBA: boolean = hasTBA && (control?.watchInternal('claimTBA') as any);

  return (
    <FormPaper>
      {isOpenEditPopup && (
        <CreateEditClaimConfigForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          data={editData}
          isEdit={isEdit}
          handleCreateUpdateData={handleSubmitData}
        />
      )}
      <PaperTitle>Claim Configuration</PaperTitle>
      {hasTBA && <Checkbox control={control} disabled={!!poolDetail?.isDeploy} name="claimTBA" label="TBA" />}
      <TextInput
        label="Claim Policy"
        name="claimPolicy"
        control={control}
        errorMessage={errors?.claimPolicy?.message}
        inputProps={{ maxLength: 255 }}
        disabled={isTBA}
      />
      <div className={classes.formControl}>
        <Button type="button" disabled={isTBA} onClick={openPopupCreate}>
          Create
        </Button>
      </div>
      <TableContainer className={classesTable.tableContainer} component={Paper}>
        <Table className={classesTable.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Start Time</b>
              </TableCell>
              <TableCell align="right">
                <b>Max Claim (%)</b>
              </TableCell>
              <TableCell align="right">
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows.map((row: ClaimConfig, index: number) => {
                const startTime = row.startTime ? moment(row.startTime).format(DATETIME_FORMAT) : '--';
                const maxBuy = new BigNumber(row.maxPercentClaim || '0').toFixed();

                return (
                  <TableRow key={index}>
                    <TableCell>{startTime}</TableCell>
                    <TableCell align="right">{maxBuy} %</TableCell>
                    <TableCell align="right">
                      <Button onClick={() => openPopupEdit(row, index)}>Edit</Button>
                      <Button
                        className={classes.deleteClaimButton}
                        onClick={() => openDeleteConfirm(index)}
                        style={{ marginLeft: 10, marginTop: 0 }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <input
        type="hidden"
        name="campaignClaimConfig"
        value={JSON.stringify(rows)}
        ref={register({
          // required: true
        })}
      />

      <ConfirmDialog
        open={isOpenDeleteConfirm}
        confirmLoading={false}
        onConfirm={deleteRecord}
        onCancel={() => {
          setIsOpenDeleteConfirm(false);
        }}
        buttonContent="Yes"
        className={classes.modalDeleteClaim}
      >
        <p className={classes.titleDelete}>Are you sure to delete a claim?</p>
      </ConfirmDialog>
    </FormPaper>
  );
}
