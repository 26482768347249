import Pagination from '@material-ui/lab/Pagination';
import React, { useState, useEffect } from 'react';
import Button from '../../components/Base/ButtonLink';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import TableCommon from '../../components/TableCommon';
import { getYieldBoxProjects } from '../../request/completed';
import { adminRoute } from '../../utils';
import useStyles from './style';
import YielBoxProjectRow, { YieldBoxProjectType } from './YielBoxProjectRow/YielBoxProjectRow';

const tableHeaders = ['NAME', 'TOKEN', 'NETWORK', 'PRIORITY', 'ACTION'];

const YielBoxProject: React.FC<any> = (props: any) => {
  const limit = 8;
  const classes = useStyles();
  const [list, setList] = useState<YieldBoxProjectType[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [failure, setFailure] = useState<boolean>(false);
  const [currentOpen, setCurrentOpen] = useState('');
  const [lastPage, setLastPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(page);
  const [query,] = useState('');

  const getList = async (currentPage: any, query: any) => {
    const queryParams = {
      page: currentPage,
      limit: limit,
    };

    try {
      setLoading(true);
      const resObject = await getYieldBoxProjects(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setList(data);
        setPage(page);
        setLastPage(lastPage);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getList(currentPage, query);
  }, [currentPage, query]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderLandingProject = (landingProject: YieldBoxProjectType) => {
    return (
      <YielBoxProjectRow
        key={landingProject.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        landingProject={landingProject}
        onReload={() => {
          if (currentPage === 1) {
            getList(1, query);
          } else {
            setCurrentPage(1);
          }
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      list &&
      lastPage > 1 && (
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/project-yieldbox/create')} text={'Create new yield box project'} />
        </div>
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={list}
        onRenderContent={onRenderLandingProject}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default YielBoxProject;
