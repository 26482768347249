import { boolean, mixed, number, object, string } from 'yup';
import { NETWORK_AVAILABLE, POLYGON_NETWORK_ACCEPT_CHAINS } from '../../../constants';
import { isValidAddress } from '../../../services/web3';
import configureStore from '../../../store/configureStore';
import { validateSolAddress } from '../../../utils/solana';
import { getTokenInfo } from '../../../utils/token';
import { UploadType } from '../../SliderManage/constants/type';
import { BSC_NETWORK_ACCEPT_CHAINS, ETH_NETWORK_ACCEPT_CHAINS, NETWORK_AVAILABLE_CHAINID } from './../../../constants';
import { SOLANA_CHAIN_ID } from './../../../constants/network';

function validateFileType() {
  return function (this: any, file: File) {
    if (!file) {
      return true;
    }

    const chunks = file.name.split('.');
    const fileExtension = chunks[chunks.length - 1];
    const acceptFileType = ['png', 'gif', 'jpg', 'jpeg', 'JPEG', 'svg'];
    if (!acceptFileType.includes(fileExtension.toLowerCase())) {
      return this.createError({
        type: 'file-type',
        message: `Invalid image type.`,
        path: this.path,
      });
    }

    const acceptFileSize = 8 * 1024 * 1024;

    if (acceptFileSize < file.size) {
      return this.createError({
        type: 'file-size',
        message: `Image size must less than 8MB`,
        path: this.path,
      });
    }

    return true;
  };
}

export const BASE_VALIDATION_SCHEMA = {
  name: string().required('This field is required'),
  banner: object().shape({
    link: string().when('uploadType', {
      is: (uploadType: UploadType) => uploadType === UploadType.LINK,
      then: string().nullable().required('This field is required'),
    }),
    file: mixed()
      .when('uploadType', {
        is: (uploadType: UploadType) => uploadType === UploadType.FILE,
        then: mixed().required('This field is required'),
      })
      .test('check-type', 'File must be image', validateFileType()),
  }),
  website: string().required('This field is required'),
  networkAvailable: string()
    .required('This field is required')
    .test(
      'accept-network',
      "Your selected network can't deploy. Please switch network or change this Option.",
      function (value: string | undefined) {
        if (!this.parent.needValidate) {
          return true;
        }

        if (value === NETWORK_AVAILABLE.TBA) {
          return this.createError({
            message: 'The TBA field is required to change. Please submit the proper info to deploy the project.',
            path: 'networkAvailable',
          });
        }

        const appChainId = configureStore().store.getState().appNetwork.data.appChainID;

        let acceptNet = false;
        switch (value) {
          case NETWORK_AVAILABLE.POLYGON:
            acceptNet = !!POLYGON_NETWORK_ACCEPT_CHAINS[appChainId];
            break;
          case NETWORK_AVAILABLE.ETH:
            acceptNet = !!ETH_NETWORK_ACCEPT_CHAINS[appChainId];
            break;
          case NETWORK_AVAILABLE.BSC:
            acceptNet = !!BSC_NETWORK_ACCEPT_CHAINS[appChainId];
            break;
          case NETWORK_AVAILABLE.SOL:
            acceptNet = appChainId === SOLANA_CHAIN_ID;
            break;
          default:
            break;
        }

        return acceptNet;
      }
    ),
  acceptCurrency: string().required('This field is required'),
  priority: number()
    .transform((value) => (!value ? null : value))
    .nullable()
    .typeError('This field must be a number')
    .moreThan(0, 'This field must be greater than 0'),
};

export const TOKEN_INFO_DEPLOY_VALIDATION = {
  token: string()
    .required('This field is required')
    .test('address-format', 'The address is not valid', async function (value: string | undefined) {
      if (!value) {
        return false;
      }

      let choosenNetwork = this.parent.networkAvailable;

      if (!choosenNetwork) {
        choosenNetwork = (this as any).from?.[1]?.value?.networkAvailable;
      }

      if (choosenNetwork === NETWORK_AVAILABLE.SOL) {
        return validateSolAddress(value);
      }

      const validMetamaskAddress = isValidAddress(value);
      if (!validMetamaskAddress) {
        return false;
      }

      const token = await getTokenInfo(value, NETWORK_AVAILABLE_CHAINID[choosenNetwork]);
      if (!token) {
        return this.createError({
          message: 'Could not find the specified address.',
          path: 'tokenInfo.token',
        });
      }

      return true;
    }),
  symbol: string().required('This field is required'),
};

export const TOKEN_INFO_TBA_VALIDATION = {
  totalSoldCoin: number()
    .required('This field is required')
    .typeError('This field must be number')
    .moreThan(0, 'This field must be greater than 0'),
  addressReceiver: string().required('This field is required'),
  tokenImages: object().shape({
    link: string().when('uploadType', {
      is: (uploadType: UploadType) => uploadType === UploadType.LINK,
      then: string().nullable().required('This field is required'),
    }),
    file: mixed()
      .when('uploadType', {
        is: (uploadType: UploadType) => uploadType === UploadType.FILE,
        then: mixed().required('This field is required'),
      })
      .test('check-type', 'File must be image', validateFileType()),
  }),
};

export const TBA_VALIDATION = {
  tokenRateTBA: boolean().test(
    'token-rate-tba',
    'The TBA field is required to change. Please submit the proper info to deploy the project.',
    function (value: boolean | undefined) {
      return !(this.parent.needValidate && value);
    }
  ),
  tokenInfoTBA: boolean().test(
    'token-info-tba',
    'The TBA field is required to change. Please submit the proper info to deploy the project.',
    function (value: boolean | undefined) {
      return !(this.parent.needValidate && value);
    }
  ),
  buyTimeTBA: boolean().test(
    'buy-time-tba',
    'The TBA field is required to change. Please submit the proper info to deploy the project.',
    function (value: boolean | undefined) {
      return !(this.parent.needValidate && value);
    }
  ),
  tokenRate: number().when('tokenRateTBA', {
    is: (tokenInfoTBA: boolean) => !tokenInfoTBA,
    then: number().typeError('This field must be number').moreThan(0, 'This field must be greater than 0'),
  }),
  tokenInfo: object().when('tokenInfoTBA', {
    is: (tokenInfoTBA: boolean) => !tokenInfoTBA,
    then: object().shape({
      ...TOKEN_INFO_TBA_VALIDATION,
      ...TOKEN_INFO_DEPLOY_VALIDATION,
    }),
  }),
  startTime: mixed().when(['needValidate', 'buyTimeTBA'], {
    is: (needValidate: boolean, buyTimeTBA: boolean) => needValidate && !buyTimeTBA,
    then: mixed()
      .transform((data) => (!data ? null : data))
      .required('This field is required'),
  }),
  finishTime: mixed().when(['needValidate', 'buyTimeTBA'], {
    is: (needValidate: boolean, buyTimeTBA: boolean) => needValidate && !buyTimeTBA,
    then: mixed()
      .transform((data) => (!data ? null : data))
      .required('This field is required'),
  }),
};
