import { Control, DeepMap, FieldError } from 'react-hook-form';
import { NumberInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { Pool } from '../../types';
import DisplayPoolSwitch from './DisplayProgressBarSwitch';

interface ProgressBarSettingProps {
  detail?: Pool;
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  register: any;
  setValue: any;
}

export function ProgressBarSetting({ detail, control, errors, register, setValue }: ProgressBarSettingProps) {
  return (
    <Paper>
      <PaperTitle>Progress Bar Setting</PaperTitle>
      <DisplayPoolSwitch
        poolDetail={detail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
      />
      <NumberInput
        name="progressDisplay"
        control={control}
        label="Progress Bar Percentage (%)"
        errorMessage={errors?.progressDisplay?.message}
        isRequired={false}
      />
    </Paper>
  );
}
