import { FormControl, FormControlLabel, Radio } from '@material-ui/core';
import { Col, Row } from 'antd';
import { get } from 'lodash';
import { useMemo } from 'react';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { NumberInput, RadioGroup, TextInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { UploadFile } from '../../../../../components/Base/UploadFile';
import { NETWORK_AVAILABLE, NETWORK_AVAILABLE_CHAINID } from '../../../../../constants';
import { SOLANA_CHAIN_ID } from '../../../../../constants/network';
import TokenAccount from '../GeneralDetail/PoolHash';
import NewExchangeRate from '../NewExchangeRate';
import { NewStartDateAndEndDate } from '../NewStartDateAndEndDate';
import { TokenRewardDistribution } from '../TokenRewardDistribution';
import NewEnableButtonClaim from './NewEnableButtonClaim';
import { TokenAddress } from './TokenAddress';

interface TokenRewardProps {
  poolDetail?: any;
  token: any;
  setToken: any;
  formMethods: UseFormMethods<any>;
  errors?: DeepMap<any, FieldError>;
  index?: number;
  register: any;
  isEdit: boolean;
  originPool: any;
  // connection: any;
  getValues: any;
}

export function NewTokenReward({
  poolDetail,
  token,
  setToken,
  formMethods,
  index,
  register,
  isEdit,
  originPool,
  // connection,
  getValues,
}: TokenRewardProps) {
  const { watch, control, errors, setValue } = formMethods;

  const nameNetwork = `network`;
  const nameToken = `token_name`;
  const tokenAddress = `token_address`;
  const totalReward = `total_reward`;
  const rewardDuration = `reward_duration`;
  const currency = `currency`;
  const exchangeRate = `exchange_rate`;
  const is_claim = `is_claim`;
  const claim_policy = `claim_policy`;
  const ido_pool_id = `ido_pool_id`;
  const reward_percent_normal = `reward_percent_normal`;
  const rw_token_account = `rw_token_account`;
  const reward_percent_ido = `reward_percent_ido`;

  const is_direct_claim = `is_direct_claim`;

  const min_spent = `min_spent`;

  const isDisable = useMemo(() => {
    // if (isEdit && tokenByIndex?.start_time) {
    //   const now = moment();
    //   const disable = now.isAfter(tokenByIndex?.start_time);
    //   return disable;
    // }
    return false;
  }, []);

  const network = watch<string, string>(`${nameNetwork}`) as string;
  const choosenNetwork = NETWORK_AVAILABLE_CHAINID[network];

  return (
    <Paper>
      <PaperTitle>Token Reward</PaperTitle>
      <RadioGroup
        name={nameNetwork}
        label="Network Available"
        errorMessage={(errors?.[`${nameNetwork}`] as any)?.message}
        control={control}
        radioData={[
          {
            value: NETWORK_AVAILABLE.POLYGON,
            label: 'Polygon',
          },
          // {
          //   value: NETWORK_AVAILABLE.SOL,
          //   label: 'Solana',
          // },
          {
            value: NETWORK_AVAILABLE.ETH,
            label: 'Ethereum',
          },
          {
            value: NETWORK_AVAILABLE.BSC,
            label: 'BSC',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel
            key={data.value}
            value={data.value}
            label={data.label}
            control={<Radio size="small" />}
            disabled={isDisable}
          />
        )}
      />
      <FormControl>
        <RadioGroup
          name={currency}
          label="Accept Currency"
          control={control}
          radioData={[
            {
              value: 'usdt',
              label: 'USDT',
            },
            {
              value: 'usdc',
              label: 'USDC',
            },
          ]}
          onRenderRadio={(data) => (
            <FormControlLabel
              key={data.value}
              disabled={isDisable}
              value={data.value}
              label={data.label}
              control={<Radio size="small" />}
            />
          )}
        />
      </FormControl>
      <TextInput
        name={nameToken}
        control={control}
        label="Token Reward Name"
        errorMessage={get(errors, nameToken)?.message}
        isRequired={true}
        disabled={false}
      />
      <UploadFile
        control={control}
        watch={control?.watchInternal}
        data={poolDetail?.banner}
        formName="banner"
        errors={errors?.banner}
        label="Token Avatar"
        tooltip={
          <>
            Recommended ratio: 16:6 <br /> Minimum resolution: 800x300 pixel
          </>
        }
      />
      <TokenAddress
        forName={tokenAddress}
        poolDetail={poolDetail}
        token={token}
        setToken={setToken}
        disabled={isDisable}
        formMethods={formMethods}
        register={register}
        nameNetwork={nameNetwork}
        originPool={originPool}
        // connection={connection}
      />
      {choosenNetwork === SOLANA_CHAIN_ID && (
        <TokenAccount
          rw_token_account={rw_token_account}
          token={token}
          poolDetail={poolDetail}
          tokenAddress={tokenAddress}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          index={index as number}
        />
      )}
      <NumberInput
        name={totalReward}
        control={control}
        label="Total Reward Amount"
        errorMessage={get(errors, totalReward)?.message}
        isRequired={true}
        disabled={isDisable}
      />
      <TokenRewardDistribution
        isDisable={isDisable}
        errors={errors}
        control={control}
        reward_percent_normal={reward_percent_normal}
        reward_percent_ido={reward_percent_ido}
      />
      <NewExchangeRate
        poolDetail={poolDetail}
        register={register}
        token={token}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        nameCurrency={currency}
        exchangeRate={exchangeRate}
        isDisable={false}
      />
      <NumberInput
        name={rewardDuration}
        control={control}
        label="Reward Duration"
        errorMessage={get(errors, rewardDuration)?.message}
        isRequired={true}
        // disabled={isDisable}
      />
      <NewStartDateAndEndDate
        control={control}
        disabled={isDisable}
        errors={errors}
        poolDetail={poolDetail}
        formMethods={formMethods}
      />
      <TextInput
        name={ido_pool_id}
        control={control}
        label="IDO Pool ID"
        errorMessage={get(errors, ido_pool_id)?.message}
        disabled={false}
      />
      <Row>
        <Col span={5}>
          <NewEnableButtonClaim control={control} name={is_claim} />
        </Col>
        <NewEnableButtonClaim control={control} label={'Enable direct claim'} name={is_direct_claim} />
      </Row>

      <TextInput
        name={claim_policy}
        control={control}
        label="Claim Policy"
        errorMessage={get(errors, claim_policy)?.message}
        disabled={false}
      />
      <NumberInput
        name={min_spent}
        control={control}
        label="Min Spent"
        errorMessage={get(errors, min_spent)?.message}
      />
    </Paper>
  );
}
