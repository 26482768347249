import { CircularProgress, Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../components/Base/Form';
import { useForm } from 'react-hook-form';
import { Media } from '../../constants/type';
import { Detail } from './Detail';
import { GeneralDetail } from './GeneralDetail';
import { SlideConfirmModal } from '../ConfirmModal';
import { SocialSetting } from './SocialSetting/SocialSetting';
import styles from './styles.module.scss';
import { UploadFile } from './UploadFile';
import { getMediaValidationSchema } from '../../constants';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { Priority } from './Priority';

interface SlideManageFormProps {
  onSubmit: (data: Media) => Promise<boolean>;
  data: Media;
}

export const SlideManageForm = ({ onSubmit, data }: SlideManageFormProps) => {
  const history = useHistory();
  const validationSchema = getMediaValidationSchema(data);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const { errors, control, reset, watch, handleSubmit, getValues } = useForm<Media>({
    mode: 'onChange',
    defaultValues: JSON.parse(JSON.stringify(data)),
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({ ...data });
  }, [data, reset]);

  function openPopup() {
    setOpenConfirm(true);
  }

  function submitForm() {
    setOpenConfirm(false);
    setLoading(true);
    onSubmit(getValues()).then((success) => {
      if (success) {
        history.push('/dashboard/sliders');
        return;
      }

      setLoading(false);
    });
  }

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Slider Media Upload</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <GeneralDetail control={control} errors={errors} />
          <SocialSetting control={control} errors={errors} />
          <Priority control={control} errors={errors} />
        </Grid>
        <Grid item xs={6}>
          <Detail control={control} errors={errors} />
          <UploadFile
            control={control}
            required={true}
            formName="firstSlide"
            title="1st slide"
            watch={watch}
            errors={errors}
            hasTypeSection={true}
            data={data.firstSlide}
          />
          <UploadFile
            control={control}
            required={false}
            formName="secondSlide"
            title="2nd slide"
            watch={watch}
            errors={errors}
            hasTypeSection={false}
            data={data.secondSlide}
          />
          <UploadFile
            control={control}
            required={true}
            formName="thumbnail"
            title="Thumbnail image"
            watch={watch}
            errors={errors}
            hasTypeSection={false}
            data={data.thumbnail}
          />
        </Grid>
      </Grid>
      <Grid className={styles.action} container justifyContent="space-around">
        <Button onClick={handleSubmit(openPopup)}>
          {loading ? <CircularProgress size={25} /> : data.id !== '-1' ? 'Update' : 'Create'}
        </Button>
        <Button className={styles.cancel} onClick={() => history.push('/dashboard/sliders')}>
          {loading ? <CircularProgress size={25} /> : 'Cancel'}
        </Button>
      </Grid>
      <SlideConfirmModal
        open={openConfirm}
        content={data.id !== '-1' ? 'Are you sure to update the new slide?' : 'Are you sure to create the new slide?'}
        onCancel={() => setOpenConfirm(false)}
        onConfirm={() => submitForm()}
      />
    </section>
  );
};
