/* eslint-disable react-hooks/exhaustive-deps */

import { cloneDeep, get } from 'lodash';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { Control, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { DateTimePicker } from '../../../../../components/Base/Form';
import { default as useBuyTimeStyles } from './style';

interface StartDateProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  poolDetail?: any;
  index?: number;
  formMethods: UseFormMethods<any>;
  disabled: boolean;
}

export function NewStartDateAndEndDate({ control, errors, formMethods, disabled }: StartDateProps) {
  const buyTimeClasses = useBuyTimeStyles();
  const { watch, setValue } = formMethods;
  const startTime = `start_time`;
  const endTime = `end_time`;

  const duration = watch(`reward_duration`);
  const valueStart = watch(`${startTime}`);

  useMemo(() => {
    if (Number(duration) > 0 && valueStart && (valueStart as Moment).isValid()) {
      const cloneStart = cloneDeep(valueStart) as Moment;
      cloneStart.add(Number(duration), 'days');
      // cloneStart.add(Number(duration) * 30, 'minutes');
      setValue(endTime, cloneStart);
      return cloneStart;
    }
  }, [duration, valueStart, endTime]);

  return (
    <div className={buyTimeClasses.cardContainer}>
      <DateTimePicker
        name={startTime}
        label={
          <>
            Start Date <span style={{ color: 'red' }}>(*)</span>
          </>
        }
        control={control}
        errorMessage={get(errors, startTime)?.message}
        format="YYYY-MM-DD HH:mm:ss"
        showTime={{
          defaultValue: moment('00:00:00', 'HH:mm:ss'),
          format: 'HH:mm',
        }}
        minuteStep={1}
        className={buyTimeClasses['time-control']}
        disabled={disabled}
        // disabledDate={(currentDate: moment.Moment) => {
        //   const disablePast = currentDate.isBefore(moment());
        //   if (disablePast) {
        //     return true;
        //   }
        //   return false;
        // }}
      />
      <DateTimePicker
        name={endTime}
        label={
          <>
            End Date <span style={{ color: 'red' }}>(*)</span>
          </>
        }
        control={control}
        errorMessage={get(errors, endTime)?.message}
        format="YYYY-MM-DD"
        showTime={{
          defaultValue: moment('23:59:00', 'HH:mm:ss'),
          format: 'YYYY-MM-DD',
        }}
        minuteStep={1}
        disabled={true}
      />
    </div>
  );
}
