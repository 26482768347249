export const ERROR_CODE = {
    USER_REJECT: 4001
}

export enum SolanaNetwork {
    MAINNET_BETA = 'mainnet-beta',
    TESTNET = 'testnet',
    DEVNET = 'devnet',
    LOCALNET = 'localnet',
}

export const SOLANA_LAMPORT = 1000000000;