import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import FAndQDetail from './Detail/FQDetail';

const FAndQCreate = () => {
  return (
    <DefaultLayout>
      <FAndQDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(FAndQCreate);
