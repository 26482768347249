import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    leftLayout: {
      minWidth: '320px',
      minHeight: '100vh',
      background: `url('/images/admin-menu-bg.svg')`,
      color: '#8D8D8D',
      padding: '20px 14px',
      position: 'relative',
    },
    smallLeft: {
      minWidth: '100px',
      transition: 'min-width 0.5s',
    },
    headLeft: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 24,
    },
    BoxInfoUser: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: 48,
      height: 48,
      border: '2px solid #FFCC00',
      borderRadius: '50%',
      marginRight: 10,
      objectFit: 'cover'
    },
    infoUser: {
      minWidth: 180,

      '& .name': {
        overflowWrap: 'anywhere',
        fontSize: 16,
        lineHeight: '26px',
        color: '#FFFFFF',
        whiteWpace: 'nowrap',
      },
      '& .status': {
        display: 'flex',
        alignItems: 'center',
        letterSpacing: 0.4,
        color: '#9A9A9A',
        whiteWpace: 'nowrap',

        '& .icon': {
          marginLeft: 7,
        }
      }
    },
    btnSmallBig: {
      marginLeft: 'auto',
      padding: 0,
      minWidth: 40,
      width: 40,
      height: 40,
      maskImage: `url('/images/icon-shrink.svg')`,
      backgroundColor: '#FFFFFF',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',

      '&:hover': {
        backgroundColor: '#FFCC00',
      },
      '&.btnSmall' : {
        transform: 'rotate(180deg)',
      }
    },
    navLeft: {
      width: '100%',
    },
    itemNavLeft: {
      width: '100%',
      marginBottom: 5,
    },
    linkItemNavLeft: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 15,
      padding: '13px 26px',
      fontSize: 16,
      lineHeight: '20px',
      color: '#8D8D8D',
      transition: '0.3s',
      fontFamily: 'OpenSans-SemiBold',
      '&.active': {
        background: 'linear-gradient(90deg, #9B0BF3 0%, rgba(2, 211, 224, 0) 100%)',
        filter: 'drop-shadow(0px 2px 4px rgba(138, 146, 166, 0.3))',
        color: '#FFFFFF',

        '& .icon': {
          backgroundColor: '#FFFFFF',
        }
      },

      '&:hover': {
        background: 'linear-gradient(90deg, #9B0BF3 0%, rgba(2, 211, 224, 0) 100%)',
        filter: 'drop-shadow(0px 2px 4px rgba(138, 146, 166, 0.3))',
        color: '#FFFFFF',
        transition: '0.3s',
        '& .icon': {
          backgroundColor: '#FFFFFF',
        }
      },

      '& .icon': {
        width: 30,
        height: 30,
        maskImage: `url('/images/icon_nav_left/icon-home.svg')`,
        backgroundColor: '#9A9A9A',
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        marginRight: 19,

        '&.icon-small': {
          margin: 'auto',
        },

        '&.icon-project': {
          maskImage: `url('/images/icon_nav_left/ticket-star.svg')`,
        },
        
        '&.icon-participants': {
          maskImage: `url('/images/icon_nav_left/icon-participants.svg')`,
        },

        '&.icon-reward-staking': {
          maskImage: `url('/images/icon_nav_left/reward-staking.svg')`,
        },

        '&.icon-list-user': {
          maskImage: `url('/images/icon_nav_left/list-user-icon.svg')`,
        },

        '&.icon-list-admin': {
          maskImage: `url('/images/icon_nav_left/list-admin-icon.svg')`,
        },

        '&.icon-nft-contract': {
          maskImage: `url('/images/icon_nav_left/nft-contract.svg')`,
        },

        '&.icon-tier': {
          maskImage: `url('/images/icon_nav_left/tier-icon.svg')`,
        },
        '&.icon-setting': {
          maskImage: `url('/images/icon_nav_left/icon-setting.svg')`,
        },
        '&.icon-list-slide': {
          maskImage: `url('/images/icon_nav_left/crown-icon.svg')`,
        },
        '&.icon-partners': {
          maskImage: `url('/images/icon_nav_left/icon-partners.svg')`,
        },
        '&.icon-completed': {
          maskImage: `url('/images/icon_nav_left/icon-completed.svg')`,
        }
      }
    },
    Copyright: {
      fontSize: 12,
      lineHeight: '16px',
      textAlign: 'center',
      letterSpacing: 0.4,
      color: '#8D8D8D',
      position: 'absolute',
      bottom: 16,
      left: 0,
      width: '100%',
    },
    modalLogout: {
      '& .MuiDialogActions-root': {
        width: '70%',
        margin: 'auto',
      },
      '& button': {
        maxWidth: '100px',
      },
    },
    titleLogout: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '20px',
    },
  };
});

export default useStyles;
