import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import TableCommon from "../../components/TableCommon";
import { getUserList, reloadCachedUserList } from "../../request/user";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import SearchForm from "./SearchForm";
import useStyles from "./style";
import UserRow from "./UserRow";

const tableHeaders = ["WALLET", "POINT", "UPDATED AT"];

const UserList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const perPage = 10;
  const [users, setUsers] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [queryWallet, setQueryWallet] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [addressCopied, setAddressCopied] = useState("");

  const getUserListInfo = async (queryWallet: any) => {
    let queryParams = {};
    if (queryWallet) {
      queryParams = {
        ...queryParams,
        wallet_address: queryWallet,
      };
    } else {
      queryParams = {
        ...queryParams,
        page: currentPage,
        limit: perPage,
      };
    }

    try {
      setLoading(true);
      const resObject = await getUserList(queryParams);
      if (resObject.status === 200) {
        setUsers(resObject.data.data);
        const total = resObject?.data?.lastPage || 0;
        setLastPage(total);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: any) => {
    setQueryWallet(event.target.value);
  };

  useEffect(() => {
    getUserListInfo(queryWallet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryWallet, currentPage]);

  const handleReloadCached = async () => {
    try {
      await reloadCachedUserList();
      dispatch(alertSuccess("Reload completed"));
    } catch (err: any) {
      dispatch(alertFailure("Reload failed!"));
    }
    setIsOpenDialog(false);
  };

  const handleOpen = () => {
    setIsOpenDialog(true);
  };

  const handleClose = () => {
    setIsOpenDialog(false);
  };

  const onRenderUser = (user: any) => {
    return (
      <UserRow
        key={user.id}
        user={user}
        getUserListInfo={getUserListInfo}
        callBackCopy={() => {
          setAddressCopied(user.wallet_address);
        }}
        address={addressCopied}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      users &&
      lastPage > 1 && (
        <Pagination
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <button
            className={classes.button}
            style={{ color: "#000", marginLeft: "10px" }}
            onClick={handleOpen}
          >
            Reload All
          </button>
        </div>
        <SearchForm seachValue={queryWallet} handleSearch={handleSearch} />
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={users}
        onRenderContent={onRenderUser}
        onRenderPagination={onRenderPagination}
      />
      <Dialog
        className={classes.root}
        open={isOpenDialog}
        onClose={handleClose}
      >
        <DialogTitle>
          <span>This is a heavy task. <br/> Are you sure to continue?</span>
        </DialogTitle>
        <DialogActions className={classes.buttonConfirmField}>
          <Button onClick={handleReloadCached} className={classes.buttonYes}>
            Yes
          </Button>
          <Button onClick={handleClose} className={classes.buttonCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </DefaultLayout>
  );
};

export default UserList;
