import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Paper } from '../../../../../../components/Base/Paper';
import { NumberInput } from '../../../../../../components/Base/Form';
import { CommunityPool } from '../../types/pool';

interface LockScheduleProps {
  poolDetail?: CommunityPool;
  control?: Control<CommunityPool>;
  errors?: DeepMap<CommunityPool, FieldError>;
}

export function Tax({ control, errors, poolDetail }: LockScheduleProps) {
  return (
    <Paper>
      <NumberInput
        label="Max Allocation"
        name="maxAllocation"
        control={control}
        errorMessage={errors?.maxAllocation?.message}
        disabled={!!poolDetail?.isDeploy}
        isRequired
      />
      <NumberInput
        label="Tax Rate (%)"
        disabled={!!poolDetail?.isDeploy}
        name="tax"
        control={control}
        errorMessage={errors?.tax?.message}
        isRequired
      />
    </Paper>
  );
}
