import { WS_RPC_CONNECTION, SOLANA_PRESALE_FACTORY_ADDRESS } from './../constants/network';
import { Program, Provider } from '@project-serum/anchor';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import idl from '../abi/PreSaleFactoryOnSonala.json';
import { JSON_RPC_CONNECTION } from '../constants/network';

let workspace: any = null;
const preflightCommitment = 'confirmed';
const commitment = 'confirmed';

export const useWorkspace = () => workspace;

export const initWorkspace = (data?: { idl: any; publicKey: string }) => {
  //eslint-disable-next-line  react-hooks/rules-of-hooks
  const wallet = useAnchorWallet();
  const connection = new Connection(JSON_RPC_CONNECTION, {
    wsEndpoint: WS_RPC_CONNECTION,
    commitment: 'confirmed', // use confirmed is use for query on solana
  });

  const provider = new Provider(connection, wallet as any, {
    preflightCommitment,
    commitment,
  });

  // console.log({data})

  const program = new Program(data?.idl ?? idl, data?.publicKey ?? SOLANA_PRESALE_FACTORY_ADDRESS, provider);

  workspace = {
    wallet,
    connection,
    provider,
    program,
  };

  return {
    wallet,
    connection,
    provider,
    program,
  };
};
