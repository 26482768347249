import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import FeatureConfigDetail from './Detail/FeatureConfigDetail';

const FeatureConfigCreate = () => {
  return (
    <DefaultLayout>
      <FeatureConfigDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(FeatureConfigCreate);
