import {
  FormControl,
  TextareaAutosizeProps,
  TextareaAutosize,
} from "@material-ui/core";
import { ReactNode, useRef } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { HelpText } from "../RepresentData/HelpText";
import { FormLabel } from "../RepresentData/FormLabel";
import useStyles from "../styles";

type TextInputProps = {
  control?: Control<any>;
  label?: string;
  errorMessage?: string | ReactNode;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
} & TextareaAutosizeProps;

export function Textarea({
  control,
  label,
  errorMessage,
  rules,
  className,
  onChange: userOnChange,
  value: inputValue,
  ...textAreaProps
}: TextInputProps) {
  const inputRef = useRef<any>();
  const styles = useStyles();
  const classes = [styles["form-control"], className].filter(Boolean).join(" ");

  return (
    <Controller
      render={({ value, onChange, onBlur }, { invalid }) => {
        const onChangeFn = userOnChange ? userOnChange : onChange;

        return (
          <FormControl error={invalid} fullWidth className={classes}>
            {label && <FormLabel htmlFor={textAreaProps.id}>{label}</FormLabel>}
            <TextareaAutosize
              {...textAreaProps}
              value={value ?? inputValue ?? ""}
              onChange={onChangeFn}
              onBlur={onBlur}
              ref={inputRef}
              className={styles["text-area"]}
            />
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      rules={rules}
      name={textAreaProps.name ?? "text-field"}
      control={control}
    />
  );
}
