import { FormControl, InputBase, InputProps, withStyles } from '@material-ui/core';
import { ReactNode, useRef } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { HelpText } from '../RepresentData/HelpText';
import { FormLabel } from '../RepresentData/FormLabel';
import useStyles from '../styles';
import RequireSymbol from '../../RequireSymbol';

export type TextInputProps = {
  control?: Control<any>;
  label?: string;
  errorMessage?: string | ReactNode;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  isRequired?: boolean;
  postFix?: any;
} & InputProps;

export const CustomInput = withStyles(() => ({
  root: {
    width: '100%',
    fontFamily: 'inherit',
  },
  input: {
    width: '100%',
    border: '1px solid #DFDFDF',
    display: 'block',
    padding: '13px',
    height: '22px',
    marginTop: '5px',
    transition: '.1s all ease-in',
    borderRadius: '5px',
    backgroundColor: 'white',
    '&:disabled': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
    },
    '&:readonly': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
    },
    '&:focus': {
      outline: 'none',
      borderColor: '#FFCC00',
    },
  },
  
}))(InputBase);

export function TextInput({
  control,
  label,
  errorMessage,
  rules,
  className,
  postFix,
  onChange: userOnChange,
  value: inputValue,
  isRequired,
  ...textFieldProps
}: TextInputProps) {
  const inputRef = useRef<any>();
  const styles = useStyles();
  const classes = [styles['form-control'], className].filter(Boolean).join(' ');

  
  return (
    <Controller
      render={({ value, onChange, onBlur }, { invalid }) => {
        const onChangeFn = userOnChange ? userOnChange : onChange;

        return (
          <FormControl error={invalid} fullWidth className={classes}>
            {label && (
              <div style={{ display: 'flex', gap: 3 }}>
                <FormLabel htmlFor={textFieldProps.id}>{label}</FormLabel>
                {isRequired && <RequireSymbol />}
              </div>
            )}
            <CustomInput
              {...textFieldProps}
              value={value ?? inputValue ?? ''}
              onChange={onChangeFn}
              onBlur={onBlur}
              inputRef={inputRef}
            />
            {postFix && <>{postFix}</>}
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      defaultValue={textFieldProps.defaultValue ?? ''}
      rules={rules}
      name={textFieldProps.name ?? 'text-field'}
      control={control}
    />
  );
}
