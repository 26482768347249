import { SOLANA_PRESALE_FACTORY_ADDRESS_TAX } from '../../../../constants/network';
import * as anchor from '@project-serum/anchor';
import { web3 } from '@project-serum/anchor';
import * as spl from '@solana/spl-token';
import { PublicKey, SystemProgram } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { MAPPING_CURRENCY_ADDRESS, NATIVE_TOKEN_ADDRESS } from '../../../../constants';
import { initWorkspace } from '../../../../hooks/useWorkspace';
import { alertActions } from '../../../../store/constants/alert';
import {
  OFFERED_CURRENCY_SEED,
  PRESALE_POOL_SEED,
  PRESALE_POOL_TOKEN_ACCOUNT_SEED,
} from '../../../../utils/solana/sollet/deployPool';
import { updateDeploySuccess } from '../../../../request/pool';

interface DeployData {
  token: string;
  acceptCurrency: string;
  networkAvailable: string;
  wallet: any;
  tax: string;
  id: string;
  campaignHash: string;
  exchangeRate: number;
  decimalsToken: number;
  decimalsCurrentcy: number;
  receiverAddress: string;
}

export function useSolanaDeploy(idl: any) {
  const dispatch = useDispatch();

  const { program, wallet, connection, provider } = initWorkspace({
    idl: idl,
    publicKey: SOLANA_PRESALE_FACTORY_ADDRESS_TAX,
  });

  const deployer = cloneDeep(wallet);

  async function deploy(dataDeploy: DeployData) {
    if (!deployer) {
      throw new Error('Failed to load wallet');
    }

    const { acceptCurrency, networkAvailable, tax, decimalsCurrentcy, decimalsToken, exchangeRate } = dataDeploy;
    // data argument pass
    const randomCampaignHash = web3.Keypair.generate().publicKey;
    const campaign_hash = dataDeploy.campaignHash ? new PublicKey(dataDeploy.campaignHash) : randomCampaignHash;
    const poolId = campaign_hash;
    const token = new PublicKey(dataDeploy.token);

    const maxTax = new BigNumber(10).pow(9);
    const convertTax = maxTax.multipliedBy(tax).div(100).toNumber();

    let paidTokenAddress = MAPPING_CURRENCY_ADDRESS[networkAvailable]?.[acceptCurrency];
    if (!paidTokenAddress) {
      paidTokenAddress = NATIVE_TOKEN_ADDRESS;
    }

    const offeredCurrency = new PublicKey(paidTokenAddress);
    const DECIMAL_SOLANA = Math.pow(10, 9);
    const tokenRate = exchangeRate;
    const ratioTokenWithCurrentcy = new BigNumber(Math.pow(10, decimalsToken)).dividedBy(
      Math.pow(10, decimalsCurrentcy)
    );
    const actualRate = new BigNumber(DECIMAL_SOLANA)
      .multipliedBy(new BigNumber(1).div(tokenRate))
      .multipliedBy(ratioTokenWithCurrentcy);

    localStorage.setItem('rate', actualRate.toString());

    // upgrade 10^9
    const offeredRate = new anchor.BN(actualRate.toNumber());
    const taxRate = new anchor.BN(convertTax);

    const signature = dataDeploy?.wallet?.wallet_address;
    const signer = new PublicKey(signature);
    const args = {
      poolId,
      token,
      offeredCurrency,
      offeredRate,
      taxRate: taxRate,
      decimalsCurrentcy : 9,
      wallet: new PublicKey(dataDeploy.receiverAddress),
      signer,
    };

    const [presalePoolAccount] = await anchor.web3.PublicKey.findProgramAddress(
      [PRESALE_POOL_SEED, poolId.toBuffer()],
      program.programId
    );

    const [presalePoolTokenAccount] = await anchor.web3.PublicKey.findProgramAddress(
      [PRESALE_POOL_TOKEN_ACCOUNT_SEED, presalePoolAccount.toBuffer()],
      program.programId
    );

    const [offeredCurrencyAccount] = await anchor.web3.PublicKey.findProgramAddress(
      [OFFERED_CURRENCY_SEED, presalePoolAccount.toBuffer(), offeredCurrency.toBuffer()],
      program.programId
    );

    console.log('DATA DEPLOY ON SOLANA', {
      args,
      accounts: {
        sender: deployer!.publicKey,
        presalePoolAccount,
        presalePoolTokenAccount,
        offeredCurrencyAccount,
        mint: token,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
      },
    });
    console.log('=======================================================================');

    const transaction = await program.rpc.initPresalePool(...Object.values(args), {
      accounts: {
        sender: deployer!.publicKey,
        presalePoolAccount,
        presalePoolTokenAccount,
        offeredCurrencyAccount,
        mint: token,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
      },
    });
    console.log('=======================================================================');
    console.log({ transaction });
    dispatch({
      type: alertActions.SUCCESS_MESSAGE,
      payload: 'Deployed Successfully!',
    });

    const existCampaignHash = dataDeploy.campaignHash ? dataDeploy.campaignHash : web3.Keypair.generate().publicKey;
    const updateData = {
      token_decimals: decimalsToken,
      campaign_hash: existCampaignHash,
      transaction_hash: transaction,
    };

    await updateDeploySuccess(updateData, dataDeploy.id);
    window.location.reload();
  }

  return {
    deploy,
    program,
    wallet,
    connection,
    provider,
  };
}
