import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import { getYieldBoxProject } from '../../../request/completed';
import ProjectYieldBoxConfigDetail from './Detail/ProjectYieldBoxConfigDetail';

interface Params {
  id: string;
}

const ProjectYieldBoxConfigEdit: React.FC<RouteComponentProps<Params>> = (props: RouteComponentProps<Params>) => {
  const { match } = props;
  const id = match.params.id;
  const [completed, setCompleted] = useState({});

  const GetDetail = async () => {
    try {
      const resObject = await getYieldBoxProject(id);
      setCompleted(resObject.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      GetDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <DefaultLayout>
      <ProjectYieldBoxConfigDetail completed={completed} isCreate={false} />
    </DefaultLayout>
  );
};

export default withRouter(ProjectYieldBoxConfigEdit);
