import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button, TextInput } from '../../../../components/Base/Form';
import { Dropdown } from '../../../../components/Base/Form/Dropdown/Dropdown';
import { NETWORK_AVAILABLE } from '../../../../constants';
import { adminRoute } from '../../../../utils';
import { NFTContract } from '../../types';
import { NETWORK_ICON, VALIDATION_SCHEMA } from '../contants';
import useStyles from '../styles';

interface NFTContractFormProps {
  onSubmit: (data: NFTContract) => Promise<any>;
  data: NFTContract;
  isCreate?: boolean;
}

export const NFTContractForm = ({ onSubmit, data, isCreate }: NFTContractFormProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors, reset, getValues } = useForm<NFTContract>({
    mode: 'onChange',
    defaultValues: {
      ...data,
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  useEffect(() => {
    reset({
      ...data,
    });
  }, [data, reset]);

  const submitForm = () => {
    setOpenPopup(false);
    setLoading(true);
    onSubmit(getValues()).then(() => {
      setLoading(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput name="name" control={control} label="Project Name" errorMessage={errors?.name?.message} isRequired />
      <Dropdown
        control={control}
        name="network"
        errorMessage={errors?.network?.message}
        label="Network"
        className={classes.iconSelect}
        disabled={!isCreate}
        options={[
          { key: '-1', value: 'Choose a network' },
          { key: NETWORK_AVAILABLE.POLYGON, value: 'Polygon' },
          {
            key: NETWORK_AVAILABLE.BSC,
            value: 'BSC',
          },
          {
            key: NETWORK_AVAILABLE.SOL,
            value: 'Solana',
          },
          {
            key: NETWORK_AVAILABLE.ETH,
            value: 'Ethereum',
          },
        ]}
        onRenderOptions={(item) => (
          <MenuItem value={item.key}>
            {NETWORK_ICON[item.key as string] && (
              <ListItemIcon>
                <img alt="icon" src={NETWORK_ICON[item.key as string]} />
              </ListItemIcon>
            )}
            <ListItemText primary={item.value} />
          </MenuItem>
        )}
        isRequired
      />
      <TextInput
        name="address"
        control={control}
        label="NFT contract address"
        errorMessage={errors?.address?.message}
        disabled={!isCreate}
        isRequired
      />
      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={handleSubmit(() => setOpenPopup(true))}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Add' : 'Edit'}
        </Button>
        <Button
          disabled={loading}
          className={classes.buttonCancel}
          onClick={() => history.push(adminRoute('/nft-contracts'))}
        >
          Cancel
        </Button>
      </div>
      <ConfirmDialog
        open={openPopup}
        confirmLoading={false}
        onConfirm={submitForm}
        onCancel={() => {
          setOpenPopup(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to {isCreate ? 'add this contract?' : 'edit the contract?'}</p>
      </ConfirmDialog>
    </form>
  );
};
