import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  headerRight: {
    display: 'flex',
  },
  tableContainer: {
    padding: '30px 20px',
    borderRadius: 10,
    boxShadow: 'none',
    marginTop: 25
  },
  table: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: 'white',
  },
  tableHeader: {
    padding: '0px 0px 25px 30px',
    color: '#001F4D',
    // fontWeight: 600
  },
  tableBody: {
    '& > .MuiTableRow-root:last-child': {
      borderBottom: '1px solid #E5E5E5'
    },
    '& > .MuiTableRow-root:nth-child(odd)': {
      backgroundColor: 'white'
    },
  },
  pagination: {
    marginTop: 30,
    marginBottom:20,
    fontSize: 12,
    fontWeight: 400,
    '& .MuiPagination-ul': {
      justifyContent: 'center',
      '& li':{
        color:'#001F4D',
        '& .MuiPaginationItem-page':{
          border:'1px solid #001F4D',
          borderRadius:6
        }
      }
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#001F4D',
      color: '#fff'
    }
  },
  skeleton: {
    padding: '25px 0px',
    marginTop: 10
  },
  exportBtn: {
    padding: '13px 20px',
    backgroundColor: '#FFCC00',
    border: 'none',
    borderRadius: 10,
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',

    '&:focus': {
      outline: 'none'
    }
  },
  button: {
    background: 'linear-gradient(90deg, #61009D 0%, rgba(90, 231, 240, 0.64) 100%)',
    boxShadow: '0px 2px 4px rgba(138, 146, 166, 0.3)',
    border: 'none',
    borderRadius: 10,
    display: 'inline-block',
    fontFamily: 'OpenSans-SemiBold',
    cursor: 'pointer',
    transition: '.2s all ease-in',
    padding: '8px 24px',
    color: "white !important",
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
      transform: 'translateY(-7px)'
    },
    "& a": {
      color: "white"
    }
  },
  noDataMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15
  },
  errorMessage: {
    fontWeight: 500,
    marginTop: 30,
    textAlign: 'center',
    fontSize: 15,
    color: 'red'
  },
  refreshCampaigns: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    justifyContent: 'space-between',
  },
  refreshCampaignsContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',

    '&:hover .refreshCampaignsIcon': {
      transform: 'rotateZ(180deg)',
      fill: '#FFCC00'
    }
  },
  refreshCampaignsText: {
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 15,
    transition: '.2s all ease-in',
    fontFamily: 'Roboto-Medium'
  },
  refreshCampaignsIcon: {
    transition: '.2s all ease-in',
  },
  root: {
    textAlign: 'center',
    '& .MuiPaper-root': {
        padding: '12px 32px',
        borderRadius: '20px'
    },
    '& .MuiTypography-root': {
        fontFamily: 'OpenSans-SemiBold'
    }
  },
  buttonCancel: {
    background: '#C4C4C4',
    textTransform: 'none',
    color: '#001F4D',
    borderRadius: 10,
    padding: '6px 24px',
  },
  buttonConfirmField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12
  },
  buttonYes: {
    background: 'linear-gradient(89.95deg, #61009D 8.13%, #02ACD3 100%)',
    padding: '6px 36px',
    fontSize: '14px',
    fontFamily: 'OpenSans-Regular',
    textTransform: 'none',
    color: 'white',
    borderRadius: '10px'
  },
}))

export default useStyles;
