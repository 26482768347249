import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { TextInput } from '../../../../../components/Base/Form';

interface ScocialSettingProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
}

export function SocialSetting({ control, errors }: ScocialSettingProps) {
  return (
    <Paper>
      <PaperTitle>Social setting</PaperTitle>
      <TextInput name="twitter" control={control} errorMessage={errors?.twitter?.message} label="Twitter Link" />
      <TextInput name="telegram" control={control} errorMessage={errors?.telegram?.message} label="Telegram Link" />
      <TextInput name="discord" control={control} errorMessage={errors?.discord?.message} label="Discord Link" />
      <TextInput name="trailer" control={control} errorMessage={errors?.trailer?.message} label="Trailer" />
      <TextInput name="whitepaper" control={control} errorMessage={errors?.whitepaper?.message} label="Whitepaper" />
      <TextInput name="website" control={control} errorMessage={errors?.website?.message} label="Website" />
    </Paper>
  );
}
