import BigNumber from 'bignumber.js';
import { trim } from 'lodash';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import erc20ABI from '../../abi/Erc20.json';
import presaleFactoryABI from '../../abi/PreSaleFactory.json';
import campaignABI from '../../abi/Swap/Campaign.json';
import campaignFactoryABI from '../../abi/Swap/CampaignFactory.json';
import { ColumnOrder } from '../../components/TableCommon';
import { MAPPING_CURRENCY_ADDRESS, NATIVE_TOKEN_ADDRESS, NETWORK_AVAILABLE } from '../../constants';
import { updateDeploySuccess } from '../../request/pool';
import { BaseRequest } from '../../request/Request';
import { getContractInstance } from '../../services/web3';
import { adminRoute } from '../../utils';
import { convertDateTimeToUnix } from '../../utils/convertDate';
import { getDigitsAfterDecimals } from '../../utils/formatNumber';
import { getTokenInfo, TokenType } from '../../utils/token';
import { alertActions } from '../constants/alert';
import { campaignActions } from '../constants/campaign';

const queryString = require('query-string');
const ETH_LINK_DEFAULT_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_ETHLINK_ADDRESS || '';

type campaignCreateProps = {
  title: string;
  token: string;
  startTime: Date;
  finishTime: Date;
  releaseTime: Date;
  addressReceiver: string;
  affiliate: string;
  tokenByETH: string;
  tokenInfo: TokenType | null;
};

const LIMIT = 10;

export const getCampaigns = (
  currentPage: number = 1,
  query: string = '',
  filter: boolean = false,
  status?: string,
  columnsOrder?: ColumnOrder[]
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    const baseRequest = new BaseRequest();
    const loginUser = getState().user.data.wallet_address;

    dispatch({ type: campaignActions.CAMPAIGNS_REQUEST });

    let url = `/campaigns`; //page=${currentPage}&name=${query}&start_time=${startTime}&finish_time=${finishTime}
    let queryParams: any;
    if (status) {
      queryParams = {
        page: currentPage,
        limit: LIMIT,
        name: query,
        registed_by: null,
        status,
      };
    } else {
      queryParams = {
        page: currentPage,
        limit: LIMIT,
        name: query,
        registed_by: null,
      };
    }

    if (filter) {
      queryParams.registed_by = loginUser;
    }
    url += '?' + queryString.stringify(queryParams);

    if (columnsOrder && columnsOrder?.length > 0) {
      const queryOrder = columnsOrder.map((item) => `${item.label.toLowerCase()}=${item.direction}`).join('');
      url += '&' + queryOrder;
    }

    try {
      const response = (await baseRequest.get(url)) as any;
      const resObject = await response.json();

      if (resObject.status === 200) {
        const { total, page, lastPage, data } = resObject.data;

        dispatch({
          type: campaignActions.CAMPAIGNS_SUCCESS,
          payload: {
            total,
            page,
            lastPage,
            data,
          },
        });
      }
    } catch (err: any) {
      dispatch({
        type: campaignActions.CAMPAIGNS_FAIL,
        payload: err.message,
      });
    }
  };
};

export const getCampaignDetailHttp = (transactionHash: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const baseRequest = new BaseRequest();

    dispatch({ type: campaignActions.CAMPAIGN_DETAIL_HTTP_REQUEST });

    let url = `/projects/${transactionHash}`;

    try {
      const response = (await baseRequest.get(url)) as any;
      const resObject = await response.json();

      if (resObject.status === 200) {
        const { is_pause: isProcessing } = resObject.data;

        dispatch({
          type: campaignActions.CAMPAIGN_DETAIL_HTTP_SUCCESS,
          payload: {
            isProcessing,
            ...resObject.data,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: campaignActions.CAMPAIGN_DETAIL_HTTP_FAIL,
        payload: false,
      });
    }
  };
};

export const getLatestCampaign = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const baseRequest = new BaseRequest();

    dispatch({ type: campaignActions.CAMPAIGN_LATEST_GET_REQUEST });

    try {
      const response = (await baseRequest.get('/campaign-new')) as any;
      const resObject = await response.json();

      if (resObject.status === 200) {
        const { campaign_hash, decimals } = resObject.data;
        // Init ERC20 Contract By Token Address get from Campaign Contract
        const campaignContract = getContractInstance(campaignABI, campaign_hash);

        if (campaignContract) {
          let tokenSold = campaignContract.methods.tokenSold().call();
          const token = campaignContract.methods.token().call();
          const tokenClaimedPromise = campaignContract.methods.tokenClaimed().call();

          const campaignDetail = await Promise.all([token, tokenSold, tokenClaimedPromise]);

          const erc20Contract = getContractInstance(erc20ABI, campaignDetail[0]);

          tokenSold = new BigNumber(campaignDetail[1]).dividedBy(Math.pow(10, decimals)).toFixed();
          const balance = await erc20Contract?.methods.balanceOf(campaign_hash).call();
          const tokenLeft = new BigNumber(balance).dividedBy(Math.pow(10, decimals)).minus(tokenSold).toFixed();
          const tokenClaimed = new BigNumber(campaignDetail[2]).dividedBy(Math.pow(10, decimals)).toFixed();

          dispatch({
            type: campaignActions.CAMPAIGN_LATEST_GET_SUCCESS,
            payload: {
              ...resObject.data,
              tokenLeft,
              tokenClaimed,
              totalTokens: new BigNumber(tokenLeft).plus(tokenSold).toFixed(),
              tokenSold,
            },
          });
        }
      }
    } catch (err: any) {
      console.log(err.message);
      dispatch({
        type: campaignActions.CAMPAIGN_LATEST_GET_FAIL,
        payload: err.message,
      });
    }
  };
};

export const getLatestActiveCampaign = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const baseRequest = new BaseRequest();

    dispatch({ type: campaignActions.CAMPAIGN_LATEST_ACTIVE_GET_REQUEST });

    try {
      const response = (await baseRequest.get('/campaign-latest-active', true)) as any;
      const resObject = await response.json();

      if (resObject.status === 200) {
        const { campaign_hash, decimals } = resObject.data;
        // Init ERC20 Contract By Token Address get from Campaign Contract
        const campaignContract = getContractInstance(campaignABI, campaign_hash);

        if (campaignContract) {
          let tokenSold = campaignContract.methods.tokenSold().call();
          const token = campaignContract.methods.token().call();

          const campaignDetail = await Promise.all([token, tokenSold]);

          const erc20Contract = getContractInstance(erc20ABI, campaignDetail[0]);

          const balance = await erc20Contract?.methods.balanceOf(campaign_hash).call();
          const tokenLeft = new BigNumber(balance).dividedBy(Math.pow(10, decimals)).toFixed();

          tokenSold = new BigNumber(campaignDetail[1]).dividedBy(Math.pow(10, decimals)).toFixed();

          dispatch({
            type: campaignActions.CAMPAIGN_LATEST_ACTIVE_GET_SUCCESS,
            payload: {
              ...resObject.data,
              tokenLeft,
              totalTokens: new BigNumber(tokenLeft).plus(tokenSold).toFixed(),
              tokenSold,
            },
          });
        }
      }
    } catch (err: any) {
      console.log(err.message);
      dispatch({
        type: campaignActions.CAMPAIGN_LATEST_ACTIVE_GET_FAIL,
        payload: err.message,
      });
    }
  };
};

export const createCampaign = (campaign: campaignCreateProps, history: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    try {
      dispatch({ type: campaignActions.MY_CAMPAIGN_CREATE_REQUEST });

      const baseRequest = new BaseRequest();
      const factorySmartContract = getContractInstance(
        campaignFactoryABI,
        process.env.REACT_APP_SMART_CONTRACT_FACTORY_ADDRESS || ''
      );

      const { title, affiliate, startTime, finishTime, releaseTime, token, addressReceiver, tokenByETH, tokenInfo } =
        campaign;
      const releaseTimeUnix = +convertDateTimeToUnix(releaseTime);
      const startTimeUnix = +convertDateTimeToUnix(startTime);
      const finishTimeUnix = +convertDateTimeToUnix(finishTime);

      const durationTime = finishTimeUnix - startTimeUnix;

      const tokenByETHActualRate = new BigNumber(tokenByETH)
        .multipliedBy(Math.pow(10, tokenInfo?.decimals || 0))
        .dividedBy(Math.pow(10, 18));
      const tokenByEthDecimals = getDigitsAfterDecimals(tokenByETHActualRate.toString());
      const tokenByEthSendToBlock = tokenByETHActualRate.multipliedBy(Math.pow(10, tokenByEthDecimals)).toString();

      if (factorySmartContract) {
        let createdCampaign;

        if (affiliate === 'yes') {
          createdCampaign = factorySmartContract.methods
            .registerCampaignWithEthLink(
              title,
              token,
              durationTime,
              startTimeUnix,
              tokenByEthSendToBlock,
              tokenByEthDecimals,
              addressReceiver,
              ETH_LINK_DEFAULT_ADDRESS
            )
            .send({
              from: getState().user.data.wallet_address,
            });
        } else {
          createdCampaign = factorySmartContract.methods
            .registerCampaign(
              title,
              token,
              durationTime,
              startTimeUnix,
              releaseTimeUnix,
              tokenByEthSendToBlock,
              tokenByEthDecimals,
              addressReceiver
            )
            .send({
              from: getState().user.data.wallet_address,
            });
        }

        if (createdCampaign) {
          await createdCampaign.on('transactionHash', async (transactionHash: string) => {
            const loginUser = getState().user.data.wallet_address;

            const isAffiliate = affiliate === 'yes' ? 1 : 0;

            if (tokenInfo) {
              const { name, symbol, decimals } = tokenInfo;
              await baseRequest.post('/campaign-create', {
                title,
                affiliate: isAffiliate,
                start_time: startTimeUnix,
                finish_time: finishTimeUnix,
                addressReceiver,
                tokenByETH,
                owner: loginUser,
                token,
                name,
                symbol,
                decimals,
                transactionHash,
              });
            }

            dispatch({ type: campaignActions.MY_CAMPAIGN_CREATE_SUCCESS });

            history.push(adminRoute('/projects'));

            dispatch({
              type: alertActions.SUCCESS_MESSAGE,
              payload: 'Create Campaign Successful!',
            });
          });
        }
      }
    } catch (err: any) {
      dispatch({
        type: campaignActions.MY_CAMPAIGN_CREATE_FAIL,
        payload: err.message,
      });

      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
    }
  };
};

function getChainId(network: string) {
  switch (network) {
    case NETWORK_AVAILABLE.BSC:
      return process.env.REACT_APP_BSC_NETWORK_ID;
    case NETWORK_AVAILABLE.POLYGON:
      return process.env.REACT_APP_POLYGON_NETWORK_ID;
    case NETWORK_AVAILABLE.ETH:
      return process.env.REACT_APP_NETWORK_ID;
    default:
      throw new Error('Network are not supported');
  }
}

export const deployPool = (campaign: any, history: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => any) => {
    // try {
    dispatch({ type: campaignActions.MY_CAMPAIGN_CREATE_REQUEST });
    const { token, address_receiver, exchange_rate, tokenInfo, accept_currency, network_available } = campaign;
    // native token
    let paidTokenAddress = MAPPING_CURRENCY_ADDRESS[network_available]?.[accept_currency];
    if (!paidTokenAddress) {
      paidTokenAddress = NATIVE_TOKEN_ADDRESS;
    }
    let reversedRate = new BigNumber(1).dividedBy(exchange_rate).toFixed();
    let factorySmartContract: any;
    switch (network_available) {
      case NETWORK_AVAILABLE.POLYGON:
        const poolPolygonAddress = process.env.REACT_APP_SMART_CONTRACT_POLYGON_PRESALE_FACTORY_ADDRESS;
        factorySmartContract = getContractInstance(presaleFactoryABI, poolPolygonAddress || '', false);
        break;
      case NETWORK_AVAILABLE.ETH:
        const poolEtherAddress = process.env.REACT_APP_SMART_CONTRACT_ETH_PRESALE_FACTORY_ADDRESS;
        factorySmartContract = getContractInstance(presaleFactoryABI, poolEtherAddress || '', false);
        break;
      case NETWORK_AVAILABLE.BSC:
        const poolBscAddress = process.env.REACT_APP_SMART_CONTRACT_BSC_PRESALE_FACTORY_ADDRESS;
        factorySmartContract = getContractInstance(presaleFactoryABI, poolBscAddress || '', false);
        break;
      default:
      // default is init value above
    }
    if (factorySmartContract) {
      let createdCampaign;
      const userWalletAddress = getState().user.data.wallet_address;
      const signerWallet = campaign.wallet.wallet_address;
      const DECIMAL_EVM = Math.pow(10, 18);
      const info = await getTokenInfo(paidTokenAddress, getChainId(network_available) as string);

      const ratioTokenWithCurrentcy = new BigNumber(Math.pow(10, tokenInfo?.decimals)).dividedBy(
        Math.pow(10, info?.decimals as any)
      );

      const offeredRate = new BigNumber(DECIMAL_EVM)
        .multipliedBy(new BigNumber(1).div(exchange_rate))
        .multipliedBy(ratioTokenWithCurrentcy)
        .toFixed(0,0);
      console.log('Data deploy :', {
        token,
        paidTokenAddress,
        reversedRate,
        offeredRate,
        address_receiver,
        signerWallet,
        userWalletAddress,
        decimals: tokenInfo?.decimals,
      });
      createdCampaign = await factorySmartContract.methods
        .registerPool(token, trim(paidTokenAddress), '18', offeredRate, trim(address_receiver), trim(signerWallet))
        .send({
          from: userWalletAddress,
        });

      console.log('Deploy Response: ', createdCampaign);
      localStorage.setItem('createdCampaign', JSON.stringify(createdCampaign));
      if (createdCampaign) {
        dispatch({
          type: alertActions.SUCCESS_MESSAGE,
          payload: 'Deployed Successfully!',
        });

        let campaignHash = '';
        if (createdCampaign?.events && createdCampaign?.events && createdCampaign?.events[0]) {
          campaignHash = createdCampaign?.events[0].address;
        }
        const updateData = {
          campaign_hash: campaignHash,
          token_symbol: tokenInfo.symbol,
          token_name: tokenInfo.name,
          token_decimals: tokenInfo.decimals,
          token_address: tokenInfo.address,
          transaction_hash: createdCampaign.transactionHash,
        };

        localStorage.setItem(campaign.id, JSON.stringify(updateData));

        await updateDeploySuccess(updateData, campaign.id);
        window.location.reload();
      }
    }
  };
};
