import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme:any) => ({
  tableRow: {
    backgroundColor: '#f3f3f3',
    borderRadius: 5,
    cursor: 'pointer',
  },
  tableCell: {
    color: '#000000',
    fontSize: 14,
    borderBottom: 'none',
    fontFamily: 'OpenSans-Regular',
  },
  tableCellTitle: {
    fontSize: 14,
    color: theme.custom.colors.blueDark,
    borderBottom: 'none',
    fontWeight: 500,
    overflow:'hidden',
    textOverflow:'ellipsis',
    display:'-webkit-box',
    "-webkitLineClamp": 1,
    "-webkitBoxOrient":'vertical',
  },
  titleDialog : {
    textAlign:'center',
    fontSize:16,
    fontWeight:600
  }
}))

export default useStyles;
