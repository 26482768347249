const OPEN_FIRST = 'OPEN_FIRST';
const OPEN_SECOND = 'OPEN_SECOND';
const OPEN_THIRD = 'OPEN_THIRD';
const OPEN_FOUR = 'OPEN_FOUR';
const OPEN_FIVE = 'OPEN_FIVE';

export const openAction = {
  OPEN_FIRST,
  OPEN_SECOND,
  OPEN_THIRD,
  OPEN_FOUR,
  OPEN_FIVE
};
