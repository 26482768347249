import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../../components/Base/ConfirmDialog';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { deleteOurService } from '../../../request/admin';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { adminRoute } from '../../../utils';
import useStyles from './style';

export interface OurServiceProps {
  id: number;
  service_name: string;
  priority: string;
  description: string;
  created_at: null;
  updated_at: null;
}

type OurServiceRowProps = {
  ourService: OurServiceProps;
  currentOpen: string;
  setCurrentOpen: (id: string) => void;
  onReload?: any;
};

const OurServiceRecord: React.FC<OurServiceRowProps> = (props: OurServiceRowProps) => {
  const { ourService, currentOpen, setCurrentOpen, onReload } = props;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { ref, setIsVisible } = useComponentVisible();
  const history = useHistory();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);


  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ourService]);

  useEffect(() => {
    setIsVisible(ourService.id === Number(currentOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const OurServiceDetail = () => {
    history.push(adminRoute(`/list-our-service/${ourService.id}`));
  };

  const handleDelete = () => {
    setIsOpenModal(false);
    deleteOurService(ourService.id, ourService).then((res) => {
      if (res.status === 200) {
        dispatch(alertSuccess('Delete successfully!'));
        if (onReload) {
          onReload();
        }
      } else {
        dispatch(alertFailure(res.message || 'Something went wrong'));
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow ref={ref} className={classes.tableRow} key={ourService.id}>
      <TableCell className={classes.tableCell} component="td" scope="row" onClick={OurServiceDetail}>
        <span className={`${classes.wordBreak} ${classes.userName}`}>{ourService.service_name}</span>
      </TableCell>
      <TableCell className={classes.tableCell} component="td" scope="row" onClick={OurServiceDetail}>
        <span className={`${classes.wordBreak} ${classes.walletAddress}`}>{ourService.description}</span>
      </TableCell>
      <TableCell align='left' className={classes.tableCell} component="td" scope="row" onClick={OurServiceDetail}>
        <span className={`${classes.wordBreak} ${classes.lastname}`}>{ourService.priority}</span>
      </TableCell>
      <TableCell align="center" className={classes.tableCell} component="td" scope="row">
        <IconButton
          aria-controls={open ? 'fade-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            padding: '6px 12px',
          }}
        >
          <img src="/images/icon_dot.svg" alt="delete" />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setIsOpenModal(true);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={handleDelete}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
      >
        <p className={classes.titleDialog}>Are you sure to delete this admin ?</p>
      </ConfirmDialog>
    </TableRow>
  );
};

export default OurServiceRecord;
