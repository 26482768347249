import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  deleteClaimButton: {
    backgroundColor: '#001F4C',
    background: 'none',
    padding: '8px 24px',

    '&:hover': {
      backgroundColor: '#f50057',
      boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
      transform: 'translateY(-7px)',
    },

    '&:focus': {
      backgroundColor: '#f50057',
      outline: 'none',
    },
  },
  titleDelete: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '20px',
  },
  modalDeleteClaim: {
    '& .MuiDialogActions-root': {
      width: '70%',
      margin: 'auto',
    },
    '& button': {
      maxWidth: '100px',
    },
  },
}));

export default useStyles;
