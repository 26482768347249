import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { CircularProgress, Grid } from '@material-ui/core';
import * as anchor from '@project-serum/anchor';
import { TokenInstructions } from '@project-serum/serum';
import * as spl from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import _, { get, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PoolType } from '../..';
import idl from '../../../../abi/ExecutivePreSaleFactorySolana.json';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button, Textarea } from '../../../../components/Base/Form';
import { Paper, PaperTitle } from '../../../../components/Base/Paper';
import { NETWORK_AVAILABLE, NETWORK_AVAILABLE_CHAINID, USERS } from '../../../../constants';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { alertActions } from '../../../../store/constants/alert';
import { campaignActions } from '../../../../store/constants/campaign';
import { adminRoute } from '../../../../utils';
import { unixTime } from '../../../../utils/convertDate';
import { uploadFile } from '../../../../utils/file';
import { getTokenInfo, TokenType } from '../../../../utils/token';
import { UploadType } from '../../../SliderManage/constants/type';
import { BuyTime } from '../components/BuyTime';
import { ClaimConfigTable } from '../components/ClaimConfig';
import { Detail } from '../components/Detail';
import { ExchangeRate } from '../components/ExchangeRate';
import { GeneralDetail } from '../components/GeneralDetail';
import { LockSchedule } from '../components/LockSchedule';
import { Priority } from '../components/Priority';
import { ProgressBarSetting } from '../components/ProgressBarSetting';
import { SocialSetting } from '../components/SocialSetting';
import { ExtendSocialSetting } from '../components/SocialSetting/ExtendSocialSetting';
import { TokenInfo } from '../components/TokenInfo';
import { useSolanaDeploy } from '../hooks/useSolanaDeploy';
import { ClaimConfig } from '../types';
import { Tax } from './components/Tax';
import { DEFAULT_POOL, VALIDATION_SCHEMA } from './constants';
import { useDeploy } from './hooks/useDeploy';
import useStyles from './style';
import { ExcutivePool } from './types/pool';

interface PoolFormProps {
  isEdit: boolean;
  poolDetail?: ExcutivePool;
  onSubmit: (data: any) => Promise<any>;
}

export function PoolForm({ isEdit, poolDetail, onSubmit }: PoolFormProps) {
  const { deploy } = useDeploy();
  const { deploy: solanaDeploy, program, wallet, provider } = useSolanaDeploy(idl);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: loginUser } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingDeploy, setLoadingDeploy] = useState(false);
  const [deployed, setDeployed] = useState(false);
  const [token, setToken] = useState<TokenType | null>(null);
  const { user } = useSelector((state: any) => state);
  const role = useMemo(() => {
    return get(user, 'data.role', 1);
  }, [user]);

  const [isOpenConfirmDeploy, setOpenConfirmDeploy] = useState<boolean>(false);

  const isSuperAdmin = role === USERS.SUPER_ADMIN;

  const isDeploy =
    typeof poolDetail?.isDeploy === 'boolean' ? poolDetail?.isDeploy : new BigNumber(poolDetail?.isDeploy || 0).gt(0);

  const form = useForm<ExcutivePool>({
    mode: 'onChange',
    defaultValues: { ...DEFAULT_POOL, needValidate: false },
    reValidateMode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const { register, setValue, reset, errors, handleSubmit, control, watch, getValues, setError: setFormError } = form;

  useEffect(() => {
    const resetValue = poolDetail ?? DEFAULT_POOL;

    reset({
      ..._.cloneDeep(resetValue),
    });
  }, [poolDetail, reset]);

  function getClaimConfig(data: ExcutivePool) {
    const campaignClaimConfigJson = data.campaignClaimConfig?.toString() || '[]';
    const campaignClaimConfig: ClaimConfig[] = JSON.parse(campaignClaimConfigJson);
    return campaignClaimConfig.map((item: ClaimConfig) => {
      item.startTime = item.startTime ? unixTime(item.startTime) : null;
      item.endTime = item.endTime ? unixTime(item.endTime) : null;

      const claimModel: Record<string, string | number | null | undefined> = {
        start_time: item.startTime,
        end_time: item.endTime,
        min_percent_claim: item.minPercentClaim ?? 0,
        max_percent_claim: item.maxPercentClaim,
      };

      if (item.id && item.id !== -1) {
        claimModel.id = item.id;
      }

      return claimModel;
    });
  }

  const convertData = async (data: ExcutivePool, handleSubmit?: (data: any) => Promise<any>) => {
    // Format Claim Config
    const claimConfigData = data.claimTBA ? [] : getClaimConfig(data);

    let banner = data.banner.uploadedUrl;

    if (data.banner.uploadType === UploadType.FILE && !!data.banner.file) {
      banner = await uploadFile(data.banner.file);
    }

    if (data.banner.uploadType === UploadType.LINK) {
      banner = data.banner.link ?? '';
    }

    let tokenImage = data.tokenInfo.tokenImages.uploadedUrl;
    if (
      data.tokenInfo.tokenImages.uploadType === UploadType.FILE &&
      !!data.tokenInfo.tokenImages.file &&
      !data.tokenInfoTBA
    ) {
      tokenImage = await uploadFile(data.tokenInfo.tokenImages.file);
    }

    if (data.tokenInfo.tokenImages.uploadType === UploadType.LINK) {
      tokenImage = data.tokenInfo.tokenImages.link ?? '';
    }

    return {
      name: data.name,
      banner: banner,
      website: data.website,
      project_label: data.label,
      medium_link: data.mediumLink,
      twitter_link: data.twitterLink,
      telegram_link: data.telegramLink,
      facebook_link: data.facebookLink,
      linkedin_link: data.linkedinLink,
      reddit_link: data.redditLink,
      discord_link: data.discordLink,
      network_available: data.networkAvailable,
      accept_currency: data.networkAvailable === NETWORK_AVAILABLE.TBA ? 'TBA' : data.acceptCurrency,
      kyc_bypass: !!data.kycBypass,
      token: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.token,
      symbol: data.tokenInfoTBA ? 'TBA' : data?.tokenInfo.symbol,
      address_receiver: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.addressReceiver,
      total_sold_coin: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.totalSoldCoin,
      token_images: data.tokenInfoTBA ? 'TBA' : tokenImage,
      token_conversion_rate: data.tokenRateTBA ? 'TBA' : data.tokenRate,
      start_time: data.buyTimeTBA ? 'TBA' : data.startTime ? unixTime(data.startTime) : null,
      finish_time: data.buyTimeTBA ? 'TBA' : data.finishTime ? unixTime(data.finishTime) : null,
      description: data.description,
      lock_schedule: data.lockSchedule,
      claim_policy: data.claimTBA ? 'TBA' : data.claimPolicy,
      registed_by: loginUser?.wallet_address,
      claim_configuration: data.claimTBA ? [] : claimConfigData,
      is_qualified_user_display: data?.isQualifiedUserDisplay || 0,
      max_allocation: data.nft.maxAllocation,
      tax: data.nft.tax,
      is_display: data.isDisplay,
      is_display_live: data.isDisplayLive,
      priority: data.priority,
      is_private: PoolType.EXECUTIVE,
      is_tba:
        data.tokenRateTBA || data.buyTimeTBA || data.tokenInfoTBA || data.networkAvailable === NETWORK_AVAILABLE.TBA,
      progress_display: data?.progressDisplay,
      is_progress_display: data?.isProgressDisplay,
    };
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    try {
      const requestData = await convertData(data);
      const response: any = await onSubmit(requestData);
      if (response) {
        if (response?.status === 200) {
          if (isEdit) {
            dispatch(alertSuccess('Updated successfully!'));
          } else {
            dispatch(alertSuccess('Created successfully!'));
          }
          history.push(adminRoute('/projects'));
        } else {
          if (isEdit) {
            dispatch(alertFailure('Update failed'));
          } else {
            dispatch(alertFailure('Create failed'));
          }
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message));
    } finally {
      setLoading(false);
    }
  };

  async function handleUpdateAfterDeploy(data: ExcutivePool) {
    try {
      const requestData = await convertData(data);
      const updateData = {
        ...requestData,
        network_available: poolDetail?.networkAvailable,
        accept_currency: poolDetail?.acceptCurrency,
        token: poolDetail?.tokenInfo.token,
        token_conversion_rate: poolDetail?.tokenRate,
        start_time: unixTime(poolDetail?.startTime),
        finish_time: unixTime(poolDetail?.finishTime),
        address_receiver: poolDetail?.tokenInfo.addressReceiver,
        max_allocation: poolDetail?.nft.maxAllocation,
        tax: poolDetail?.nft.tax,
      };

      const res = await onSubmit(updateData);

      if (res.status !== 200) {
        dispatch(alertFailure('Fail!'));
        return;
      }

      history.push(adminRoute('/projects'));
      dispatch(alertSuccess('Updated successfully!'));
    } catch (e: any) {
      dispatch(alertFailure(e.message));
    } finally {
      setLoading(false);
    }
  }

  // Create / Update Pool (Before Pool Deployed to Smart Contract)
  const handleCampaignCreateUpdate = () => {
    setValue('needValidate', false);
    if (poolDetail?.isDeploy) {
      handleSubmit(handleUpdateAfterDeploy)();
    } else {
      handleSubmit(handleFormSubmit)();
    }
  };

  const getTokenDetail = async (token: string) => {
    const choosenNetwork = watch('networkAvailable');
    const chainId = NETWORK_AVAILABLE_CHAINID[choosenNetwork];

    if (!chainId) {
      return;
    }

    const erc20Token = await getTokenInfo(token, chainId);
    let tokenInfo: any = {};
    if (erc20Token) {
      const { name, symbol, decimals, address } = erc20Token;
      tokenInfo = { name, symbol, decimals, address };
    }
    return tokenInfo;
  };

  const onCancelDeploy = () => {
    setOpenConfirmDeploy(false);
    setValue('needValidate', false);
    return false;
  };

  const openPopupConfirmDeploy = () => {
    if (poolDetail?.isDeploy || deployed) {
      dispatch(alertFailure('Pool is deployed!'));
      return false;
    }
    setOpenConfirmDeploy(true);
  };

  const tokenInfo = watch('tokenInfo.token');
  const networkAvailable = watch('networkAvailable');
  const currentcy = watch('acceptCurrency');

  const [decimalsToken, setDecimalsToken] = useState<number>(9);
  const [decimalsCurrentcy, setDecimalsCurrentcy] = useState<number>(9);

  useEffect(() => {
    if (!errors.tokenInfo?.token && networkAvailable === NETWORK_AVAILABLE.SOL && !isEmpty(tokenInfo)) {
      Promise.all([getDecimalTokenOnSolana(false), getDecimalTokenOnSolana(true)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, provider, program, tokenInfo, networkAvailable, currentcy, errors.tokenInfo?.token]);

  const getDecimalTokenOnSolana = async (isToken: boolean) => {
    try {
      const TOKEN_PROGRAM_ID = new anchor.web3.PublicKey(TokenInstructions.TOKEN_PROGRAM_ID.toString());
      const mint = new spl.Token(
        provider?.connection,
        isToken
          ? new PublicKey(tokenInfo as any)
          : new PublicKey(
              (currentcy === 'usdt'
                ? process.env.REACT_APP_SMART_CONTRACT_SOLANA_USDT_ADDRESS
                : process.env.REACT_APP_SMART_CONTRACT_SOLANA_USDC_ADDRESS) as any
            ),
        TOKEN_PROGRAM_ID,
        provider?.wallet as any
      );
      const info = await mint.getMintInfo();
      const decimals = info?.decimals;
      if (isToken) {
        setDecimalsToken(decimals);
      } else {
        setDecimalsCurrentcy(decimals);
      }
      console.log({ info, isToken });
    } catch (error) {
      setFormError('tokenInfo.token', { type: 'custom', message: 'The address is not valid' });
    }
  };

  // Deploy Pool And Update
  const deployPool = async (data: ExcutivePool) => {
    setLoadingDeploy(true);
    const tokenInfo = await getTokenDetail(data.tokenInfo.token);
    try {
      // Save data before deploy
      const requestData = await convertData(data);
      const response = await onSubmit(requestData);

      if (!response) {
        const error = new Error('Token Address Error');
        (error as any).code = 1001;
        throw error;
      }

      if (response.status !== 200) {
        throw new Error('Update failed');
      }

      if (data.networkAvailable === NETWORK_AVAILABLE.SOL) {
        const dataDeploy: any = {
          token: data.tokenInfo.token,
          acceptCurrency: data.acceptCurrency,
          networkAvailable: data.networkAvailable,
          wallet: poolDetail?.wallet,
          tax: data?.nft?.tax,
          id: poolDetail?.id,
          campaignHash: poolDetail?.campaignHash || '',
          exchangeRate: data.tokenRate,
          decimalsToken,
          decimalsCurrentcy,
          receiverAddress: poolDetail?.tokenInfo.addressReceiver || '',
        };

        await solanaDeploy(dataDeploy);
      } else {
        const dataDeploy: any = {
          token: data.tokenInfo.token,
          startTime: data.startTime ? unixTime(data.startTime) : null,
          finishTime: data.finishTime ? unixTime(data.finishTime) : null,
          acceptCurrency: data.acceptCurrency,
          tokenInfo,
          tokenRate: data.tokenRate,
          networkAvailable: data.networkAvailable,
          addressReceiver: data.tokenInfo.addressReceiver,
          wallet: poolDetail?.wallet,
          tax: data?.nft?.tax,
          id: poolDetail?.id,
        };

        await deploy(dataDeploy);
      }
    } catch (err: any) {
      setDeployed(false);
      setLoadingDeploy(false);
      if (err?.code !== 1001) {
        dispatch({
          type: campaignActions.MY_CAMPAIGN_CREATE_FAIL,
          payload: err.message,
        });
      }

      dispatch({
        type: alertActions.ERROR_MESSAGE,
        payload: err.message,
      });
    }
  };

  const handlerDeploy = async () => {
    setOpenConfirmDeploy(false);

    setTimeout(() => {
      deployPool(getValues());
    }, 100);
  };

  const checkHandlerDeploy = () => {
    setValue('needValidate', true);

    handleSubmit(openPopupConfirmDeploy, () => {
      setValue('needValidate', false);
    })();
  };

  return (
    <>
      <input type="checkbox" name="needValidate" ref={register} hidden />
      <div className="contentPage">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GeneralDetail
              detail={poolDetail}
              control={control}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <SocialSetting errors={errors} control={control}>
              <ExtendSocialSetting errors={errors} control={control} />
            </SocialSetting>
            <ClaimConfigTable
              poolDetail={poolDetail}
              setValue={setValue}
              register={register}
              errors={errors}
              control={control}
              hasTBA
            />
            <LockSchedule errors={errors} control={control} />
            {isEdit && isDeploy && (
              <ProgressBarSetting
                detail={poolDetail}
                control={control}
                errors={errors}
                setValue={setValue}
                register={register}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Detail control={control} detail={poolDetail} setValue={setValue} watch={watch} errors={errors} isShowEth/>
            <TokenInfo poolDetail={poolDetail} token={token} setToken={setToken} formMethods={form} />
            <ExchangeRate
              poolDetail={poolDetail}
              register={register}
              token={token}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              hasTBA
            />
            <BuyTime control={control} errors={errors} poolDetail={poolDetail} hasTBA />
            <Tax poolDetail={poolDetail} control={control} errors={errors} />
            <Priority control={control} errors={errors} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <PaperTitle>About The Project</PaperTitle>
            <Textarea
              name="description"
              control={control}
              errorMessage={errors?.description?.message}
              className={classes['description']}
              minRows={10}
            />
          </Paper>
        </Grid>
        <Grid container justifyContent={isEdit && isSuperAdmin ? 'space-between' : 'center'}>
          {isEdit && isSuperAdmin && (
            <Button
              disabled={
                !poolDetail?.id || !!poolDetail?.isDeploy || loading || loadingDeploy || deployed || !isSuperAdmin
              }
              className={
                !isEdit || poolDetail?.isDeploy || deployed ? classes.formButtonDeployed : classes.formButtonDeploy
              }
              onClick={checkHandlerDeploy}
              hidden={!isSuperAdmin}
            >
              {loadingDeploy && <CircularProgress size={25} />}
              {!loadingDeploy && 'Deploy The Project'}
            </Button>
          )}

          <Button
            disabled={loading || loadingDeploy}
            className={classes.formButtonUpdatePool}
            onClick={handleCampaignCreateUpdate}
            // style={!isSuperAdmin ? { width: '100%' } : {}}
          >
            {loading || loadingDeploy ? (
              <CircularProgress size={25} />
            ) : isEdit ? (
              'Update The Project'
            ) : (
              'Create The Project'
            )}
          </Button>
        </Grid>
        <ConfirmDialog
          open={isOpenConfirmDeploy}
          confirmLoading={false}
          onConfirm={handlerDeploy}
          onCancel={onCancelDeploy}
          buttonContent="Yes"
          className={classes.modalConfirm}
        >
          <div className={classes.contentDeploy}>
            <p>The system will store the latest pool information.</p>
            <p>Are you sure you want to deploy?</p>
          </div>
        </ConfirmDialog>
      </div>
    </>
  );
}
