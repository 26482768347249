import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, Tooltip, Menu, MenuItem, IconButton } from '@material-ui/core';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { useHistory } from 'react-router-dom';

import useStyles from './style';
import { adminRoute } from '../../../utils';
import { convertUnixTimeToDateTime } from '../../../utils/convertDate';
import { FORMAT_UTC } from '../../../utils/constant';
import { PoolType } from '..';
import ConfirmDialog from '../../../components/Base/ConfirmDialog';
import { deletePool } from '../../../request/pool';
import { useDispatch } from 'react-redux';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

type CampaignProps = {
  id: string;
  name: string;
  token: string;
  start_time: any;
  finish_time: any;
  affiliate: number;
  tokenGet: number;
  ethFor: number;
  campaign_hash: string;
  symbol: string;
  is_pause: number;
  blockchain_status: number;
  is_deploy: number;
  is_private: number;
  is_display: number;
  priority: string;
};

type CampaignsRecordProps = {
  campaign: CampaignProps;
  currentOpen: string;
  setCurrentOpen: (id: string) => void;
  onReload?: any;
};

const CampaignsRecord: React.FC<CampaignsRecordProps> = (props: CampaignsRecordProps) => {
  const { campaign, currentOpen, setCurrentOpen, onReload } = props;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { ref, setIsVisible } = useComponentVisible();
  const { data } = useTypedSelector((data) => data.user);
  const isDeployed = Number(campaign?.is_deploy) > 0;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  useEffect(() => {
    setIsVisible(campaign.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const getCampaignStatus = (campaign: CampaignProps) => {
    if (campaign.is_display === 0) {
      return 'Drafted';
    }

    switch (campaign.is_deploy) {
      case 0:
        return 'Created';
      case 1:
        return 'Deployed';
      case 2:
        return 'Something else';
    }
    return '';
  };

  const ProjectDetail = () => {
    switch (campaign.is_private) {
      case PoolType.EXECUTIVE:
        return history.push(adminRoute(`/projects/executive-lounge/${campaign.id}`));
      case PoolType.COMMUNITY:
        return history.push(adminRoute(`/projects/community/${campaign.id}`));
      default:
        return history.push(adminRoute(`/projects/${campaign.id}`));
    }
  };

  function getPoolType() {
    switch (campaign.is_private) {
      case PoolType.EXECUTIVE:
        return 'Executive lounge';
      case PoolType.COMMUNITY:
        return 'Community';
      default:
        return 'Xborg';
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderAction = () => {
    if (!isDeployed && data?.role?.toString() === '3') {
      return (
        <TableCell align="center" className={classes.tableCell} component="td" scope="row">
          <IconButton
            aria-controls={open ? 'fade-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              padding: '6px 12px',
            }}
          >
            <img src="/images/icon_dot.svg" alt="delete" />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setIsOpenModal(true);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      );
    }
    return (
      <TableCell align="center" className={classes.tableCell} component="td" scope="row">
        <Tooltip title="">
          <img onClick={() => {}} src="/images/icon_dot_gray.svg" alt="delete" />
        </Tooltip>
      </TableCell>
    );
  };

  const handleDelete = () => {
    setIsOpenModal(false);
    deletePool(campaign.id, campaign).then((res) => {
      if (res.status === 200) {
        dispatch(alertSuccess('Delete successfully!'));
        if (onReload) {
          onReload();
        }
      } else {
        dispatch(alertFailure(res.message || 'Something went wrong'));
      }
    });
  };

  return (
    <TableRow ref={ref} className={classes.tableRow} key={campaign.id}>
      <TableCell className={classes.tableCellTitle} component="td" scope="row" onClick={ProjectDetail}>
        <Tooltip title={<p style={{ fontSize: 15 }}>{campaign.name}</p>}>
          <span className={classes.wordBreak}>{campaign.name}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="left" onClick={ProjectDetail}>
        {campaign.start_time === 'TBA' ? 'TBA' : convertUnixTimeToDateTime(campaign.start_time, FORMAT_UTC)}
      </TableCell>
      <TableCell className={classes.tableCell} align="left" onClick={ProjectDetail}>
        {campaign.finish_time === 'TBA' ? 'TBA' : convertUnixTimeToDateTime(campaign.finish_time, FORMAT_UTC)}
      </TableCell>
      <TableCell className={classes.tableCell} align="left" onClick={ProjectDetail}>
        {getPoolType()}
      </TableCell>
      <TableCell className={classes.tableCell} align="left" onClick={ProjectDetail}>
        <Tooltip title={<p style={{ fontSize: 15 }}>{campaign.symbol}</p>}>
          <span className={classes.wordBreak} style={{ width: 100, textAlign: 'center' }}>
            {campaign.symbol}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} align="center" onClick={ProjectDetail}>
        {getCampaignStatus(campaign)}
      </TableCell>
      <TableCell className={classes.tableCell} align="center" onClick={ProjectDetail}>
        {campaign?.priority}
      </TableCell>
      {renderAction()}
      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={handleDelete}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
      >
        <p className={classes.titleDialog}>Are you sure to delete this pool ?</p>
      </ConfirmDialog>
    </TableRow>
  );
};

export default CampaignsRecord;
