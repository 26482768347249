import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formButtonDeployed: {
    cursor: 'not-allowed',
    background: '#C4C4C4',
    borderRadius: 10,
    width: '49%',
    color: 'white',
    marginTop: 60,
    marginBottom: 60,
    '&:hover': {
      backgroundColor: '#C4C4C4',
    },
  },
  formButtonDeploy: {
    backgroundColor: '#001F4D',
    background: 'initial',
    borderRadius: 10,
    width: '49%',
    color: 'white',
    marginTop: 60,
    marginBottom: 60,

    '&:hover': {
      backgroundColor: '#001F4D',
    },
    '&:disabled': {
      backgroundColor: '#C4C4C4',
    },
  },
  formButtonUpdatePool: {
    width: '49%',
    color: 'white',
    marginTop: 60,
    marginBottom: 60,
  },
  formErrorMessage: {
    marginTop: 7,
    color: 'red',
  },
  formErrorMessageAbsolute: {
    position: 'absolute',
    bottom: '-20px',
  },
  modalConfirm: {
    '& .MuiDialog-paperWidthSm': {
      width: '500px',
    },
    '& .MuiDialogActions-root': {
      width: '50%',
      margin: 'auto',
    },
    '& button': {
      maxWidth: '100px',
    },
  },
  contentDeploy: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '20px',
    '&>p': {
      marginBottom: 5,
    },
  },
}));

export default useStyles;
