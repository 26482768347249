import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  'form-control': {
    marginTop: 30,
    color: '#001F4D',
    "& input[type='file']": {
      opacity: 0,
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      outline: "none",
      borderColor: "#FFCC00"
    }
  },
  'form-control-label': {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.25,
    color: '#001F4D',
  },
  'help-text': {
    fontFamily: 'inherit',
    fontSize: 14,
  },
  'number-input': {
    width: '100%',
    maxWidth: 'none',
    padding: '13px',
    border: '1px solid #DFDFDF',
    fontSize: 14,
    borderRadius: 5,
    'border-top-right-radius': 0,
    'border-bottom-right-radius': 0,

    '&:focus': {
      borderColor: '#FFCC00',
      outline: 'none',
    },
    '&:disabled': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: 'rgba(0, 0, 0, 0.38)'
    },
  },
  'text-area': {
    width: '100%',
    border: '1px solid #DFDFDF',
    display: 'block',
    padding: '13px',
    height: '22px',
    marginTop: '5px',
    transition: '.1s all ease-in',
    borderRadius: '5px',
    backgroundColor: 'white',
    '&:focus': {
      outline: 'none',
      borderColor: '#FFCC00',
    },
  },
  'no-border': {
    '& input.MuiInputBase-input': {
      border: 'none',
      paddingLeft: 0,
    },
  },
  tooltip: {
    backgroundColor: '#D9D9D9',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    color: '#000000',
    fontSize: 14,
    border: '1px solid #000000',
    lineHeight: '20px',
  },
  arrow: {
    color: '#D9D9D9',
    left: '10% !important',
    position: 'absolute',
    '&:before': {
      border: '1px solid #000000',
    },
  },
  fileInput: {
    width: '100%',
    display: 'block',
    marginTop: 5,
    fontSize: 14,
    '& > button': {
      padding: '2px 6px',
      marginRight: 5,
    },
  },
}));

export default useStyles;
