import { openAction } from "../constants/open"

export const setOpenFirst = (value: boolean) => {
  return {
    type: openAction.OPEN_FIRST,
    payload: value
  }
}

export const setOpenSecond = (value: boolean) => {
  return {
    type: openAction.OPEN_SECOND,
    payload: value
  }
}

export const setOpenThird = (value: boolean) => {
  return {
    type: openAction.OPEN_THIRD,
    payload: value
  }
}

export const setOpenFour = (value: boolean) => {
  return {
    type: openAction.OPEN_FOUR,
    payload: value
  }
}

export const setOpenFive = (value: boolean) => {
  return {
    type: openAction.OPEN_FIVE,
    payload: value
  }
}