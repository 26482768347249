import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultLayout from '../../../../components/Layout/DefaultLayout';
import { PoolForm } from './PoolForm';
import { getPoolDetail, updatePool } from '../../../../request/pool';
import { useDispatch } from 'react-redux';
import { alertFailure } from '../../../../store/actions/alert';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import { adminRoute } from '../../../../utils';
import { ExcutivePool } from './types/pool';
import { DEFAULT_POOL } from './constants';
import { converUnixToLocal } from '../../../../utils/convertDate';
import { ClaimConfig } from '../types';
import { NETWORK_AVAILABLE } from '../../../../constants';
import { UploadType } from '../../../SliderManage/constants/type';

function isTokenTBA(data: any) {
  return (
    data.token === 'TBA' &&
    data.total_sold_coin === 'TBA' &&
    data.token_images === 'TBA' &&
    data.symbol === 'TBA' &&
    data.address_receiver === 'TBA'
  );
}

const PoolEdit: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = true;
  const { match } = props;
  const dispatch = useDispatch();
  const [poolDetail, setPoolDetail] = useState<ExcutivePool>({ ...DEFAULT_POOL });

  // @ts-ignore
  const id = match.params?.id;

  async function getPool() {
    try {
      const res = await getPoolDetail(id);

      if (res.status !== 200) {
        dispatch(alertFailure('Server Error: ' + (res.message || 'Load pool fail !!!')));
        return false;
      }

      const data = res.data;

      const pool: ExcutivePool = {
        id: data.id,
        name: data.name,
        banner: {
          uploadType: UploadType.LINK,
          uploadedUrl: data.banner,
          link: data.banner,
        },
        website: data.website,
        label: data.project_label,
        mediumLink: data.socialNetworkSetting?.medium_link ?? '',
        twitterLink: data.socialNetworkSetting?.twitter_link ?? '',
        telegramLink: data.socialNetworkSetting?.telegram_link ?? '',
        facebookLink: data.socialNetworkSetting?.facebook_link ?? '',
        redditLink: data.socialNetworkSetting?.reddit_link ?? '',
        linkedinLink: data.socialNetworkSetting?.linkedin_link ?? '',
        discordLink: data.socialNetworkSetting?.discord_link ?? '',
        networkAvailable: data.network_available,
        acceptCurrency: data.network_available === NETWORK_AVAILABLE.TBA ? 'usdt' : data.accept_currency,
        kycBypass: data.kyc_bypass,
        tokenInfo: {
          token: data.token ?? '',
          totalSoldCoin: data.total_sold_coin && data.total_sold_coin !== 'TBA' ? Number(data.total_sold_coin) : 0,
          tokenImages: {
            uploadType: UploadType.LINK,
            uploadedUrl: data.token_images ?? '',
            link: data.token_images ?? '',
          },
          symbol: data.symbol ?? '',
          addressReceiver: data.address_receiver ?? '',
        },
        tokenRate: data.token_conversion_rate === 'TBA' ? 0 : data.token_conversion_rate,
        startTime: !!data.start_time && data.start_time !== 'TBA' ? converUnixToLocal(data.start_time) : '',
        finishTime: !!data.finish_time && data.finish_time !== 'TBA' ? converUnixToLocal(data.finish_time) : '',
        whitelistUsers: data?.whitelistUsers,
        description: data.description ?? '',
        lockSchedule: data.lock_schedule ?? '',
        claimPolicy: data.claim_policy ?? '',
        campaignClaimConfig: data.campaignClaimConfig?.map(
          (config: any): ClaimConfig => ({
            id: config.id,
            maxPercentClaim: config.max_percent_claim,
            startTime: config.start_time ? converUnixToLocal(config?.start_time) : null,
            minPercentClaim: config.min_percent_claim,
            endTime: config.end_time ? converUnixToLocal(config?.end_time) : null,
            campaignId: config.campaign_id,
          })
        ),
        isDeploy: data.is_deploy,
        isDisplay: data.is_display,
        isDisplayLive:data.is_display_live,
        wallet: data?.wallet,
        campaignHash: data?.campaign_hash,
        isQualifiedUserDisplay: data?.is_qualified_user_display || 0,
        priority: data.priority ?? '',
        nft: {
          maxAllocation: data.max_allocation,
          tax: data.tax,
        },
        tokenInfoTBA: isTokenTBA(data),
        tokenRateTBA: data.token_conversion_rate === 'TBA',
        buyTimeTBA: data.start_time === 'TBA' && data.finish_time === 'TBA',
        claimTBA: data.campaignClaimConfig.length === 0 && data.claim_policy === 'TBA',
        progressDisplay: data.progress_display,
        isProgressDisplay: data.is_progress_display,
      };

      setPoolDetail(pool);
      return res.data;
    } catch (e) {
      console.log('Error: ', e);
      dispatch(alertFailure('Pool load fail !!!'));
    }
  }

  useEffect(() => {
    getPool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editPool = async (data: any): Promise<any> => {
    const res = await updatePool(data, poolDetail?.id);

    if (res.status !== 200) {
      return res;
    }

    await getPool();

    return res;
  };

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/projects')} />
      <PoolForm isEdit={isEdit} poolDetail={poolDetail} onSubmit={editPool} />
    </DefaultLayout>
  );
};

export default withRouter(PoolEdit);
