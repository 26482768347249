import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createOurService, updateOurService } from '../../../../request/admin';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { useCommonStyle } from '../../../../styles';
import { adminRoute } from '../../../../utils';
import useStyles from '../styles';
import { OurServiceProps } from '../../OurServiceRow/OurServiceRow';

const FormOurService = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();
  const { history, isCreate } = props;

  const { ourService } = props;
  let { id, service_name, description, priority } = ourService as OurServiceProps;
  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      service_name,
      description,
      priority,
    },
  });

  const onSubmit = (values: any) => {
    const trimmedValues = {
      ...values,
      service_name: values.service_name.trim(),
      description: values.description.trim(),
      priority: values.priority,
    };
    if (isCreate) {
      createOurService(trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/list-our-service'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    } else {
      updateOurService(id, trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/list-our-service'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Service Name&nbsp;<span className={commonStyle.required}>(*)</span>
          </label>
          <input
            className={styles.inputG}
            name="service_name"
            placeholder="Service name"
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.service_name && <span className={commonStyle.error}>This field is required</span>}
        </div>
        <div className="clearfix"></div>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Description</label>
          <input className={styles.inputG} name="description" placeholder="Description" ref={register({})} />
          {errors.description && <span className={commonStyle.error}>This field is required</span>}
        </div>
        <div className="clearfix"></div>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Priority</label>
          <input ref={register({})} className={styles.inputG} name="priority" placeholder="" />
        </div>
        <div className="clearfix"></div>
        
        <div className={styles.listBtn}>
          <Button type="submit" className={styles.btnSubmit}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(FormOurService);
