import { TableCell, TableRow } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { adminRoute } from '../../../utils';
import useStyles from './style';

export interface FAndQType {
  id: number;
  question: string;
  answer: string;
  category_id: string;
  created_at: null;
  updated_at: null;
}

const FAndQRow = (props: any) => {
  const {
    landingProject,
    currentOpen,
    setCurrentOpen,
    categories,
  }: { landingProject: FAndQType; currentOpen: any; setCurrentOpen: any; onReload: any; categories } = props;
  const classes = useStyles();
  const history = useHistory();
  const { ref, setIsVisible } = useComponentVisible();

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingProject]);

  useEffect(() => {
    setIsVisible(landingProject?.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const goToUpdate = () => {
    history.push(adminRoute(`/fa-config/update/${landingProject.id}`));
  };

  const cateName = useMemo(() => {
    const cate = categories?.find((item) => Number(item.id) === Number(landingProject.category_id));
    if(cate) {
      return cate.name || ''
    }
  }, [categories, landingProject]);

  return (
    <>
      {landingProject?.id && (
        <>
          <TableRow ref={ref} className={classes.tableRow} key={landingProject.id}>
            <TableCell onClick={goToUpdate} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{landingProject.question}</span>
            </TableCell>
            <TableCell onClick={goToUpdate} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{landingProject.answer}</span>
            </TableCell>
            <TableCell align="left" onClick={goToUpdate} className={classes.tableCell} component="td" scope="row">
              <span className={classes.tableCellTitle}>{cateName}</span>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default FAndQRow;
