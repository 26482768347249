import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import FeatureProjectDetail from './Detail/FeatureProjectDetail';

const FeatureProjectCreate = () => {
  return (
    <DefaultLayout>
      <FeatureProjectDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(FeatureProjectCreate);
