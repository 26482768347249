import { Cluster, clusterApiUrl, PublicKey } from '@solana/web3.js';
import { ENV as ChainID } from '@solana/spl-token-registry';

import { SolletExtensionAdapter } from './wallet-adapter';
import { ISolletChain } from './interface';
import { SolanaNetwork } from '../../../constants/solana';

export const SOLLET_ENV = (process.env.REACT_APP_SOLANA_NETWORK_CLUSTER as Cluster) || 'testnest';
const SOLANA_NETWORK_RPC = process.env.REACT_APP_SONALA_NETWORK || '';
const WS_RPC_URL = process.env.REACT_APP_SOLANA_WS_URL || '';

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
);
const clockSysvarAccount: PublicKey = new PublicKey('SysvarC1ock11111111111111111111111111111111');

const SOLLET_CHAINS: ISolletChain[] = [
  {
    name: SolanaNetwork.MAINNET_BETA,
    endpoint: SOLANA_NETWORK_RPC || 'https://solana-api.projectserum.com/',
    chainID: ChainID.MainnetBeta,
    wsUrl: WS_RPC_URL,
  },
  {
    name: SolanaNetwork.TESTNET,
    endpoint: clusterApiUrl('testnet'),
    chainID: ChainID.Testnet,
  },
  {
    name: SolanaNetwork.DEVNET,
    endpoint: SOLANA_NETWORK_RPC || clusterApiUrl('devnet'),
    chainID: ChainID.Devnet,
    wsUrl: WS_RPC_URL,
  },
  {
    name: SolanaNetwork.LOCALNET,
    endpoint: 'http://127.0.0.1:8899',
    chainID: ChainID.Devnet,
  },
];

export const SOLLET_CONFIG = {
  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
  SOLLET_CHAINS,
  clockSysvarAccount,
};

const ASSETS_URL = 'https://raw.githubusercontent.com/solana-labs/oyster/main/assets/wallets/';

export const WALLET_PROVIDERS = [
  {
    name: 'Sollet Extension',
    url: 'https://www.sollet.io/extension',
    icon: `${ASSETS_URL}/sollet.svg`,
    adapter: SolletExtensionAdapter as any,
  },
];
