import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      '& .MuiPaper-root': {
        background: '#FFFFFF',
        padding: "21px 38px 43px",
        maxWidth: 900,
        borderRadius: 20
      },
      '& .dialog__connect': {
        marginTop: 28,
        marginBottom: 19,
        fontFamily: "OpenSans-ExtraBold",
        padding: "8px 21px",
        '& > .MuiButton-label': {
          fontWeight: 800,
          fontSize: 30,
        }
      }
    },
    dialogContentTypo: {
      color: '#001F4D',
      fontSize: '20px',
      marginTop: 30,
      fontFamily: 'OpenSans-Bold',
      '&:first-child': {
        marginTop: 0
      }
    },
    dialogContentBlock: {
      marginTop: 25,
    },
    dialogTitle: {
      background: 'transparent !important',
      '& .MuiTypography-h6': {
        fontWeight: '800',
        fontSize: '30px',
        color: '#001F4D',
        borderBottom: '1px solid rgba(255, 255, 255 ,0.1)',
        fontFamily: 'OpenSans-ExtraBold'
      },
    },
    dialogPrivacy: {
      display: 'flex',
      alignItems: 'center'
    },
    dialogPrivacyText: {
      fontFamily: 'Helvetica',
      fontSize: 14,
      lineHeight: '24px',
      color: '#001F4D',
    },
    dialogPrivacyHighlight: {
      color: '#03dbfc',
      fontWeight: 'normal',

      '&:hover': {
        color: '#4767af',
        textDecoration: 'underline',
      }
    },
    dialogCheckbox: {
      padding: 0,
      marginRight: 8,
    },
    dialogNetworks: {
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      dialog: {
        '& .MuiPaper-root': {
          background: '#020616',
          padding: '20px',
          margin: '20px',
          maxWidth: '100%',
          width: '100%',
        },
      },
      dialogContentBlock: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '20px',
        '& .MuiPaper-root': {
          padding: '20px 0'
        }
      },
      dialogPrivacy: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 0,
      }
    },
    error: {
      color: "#FF0000",
      fontSize: 14,
      "& > a": {
        color: "#0abde3"
      }
    }
  };
});

export default useStyles;
