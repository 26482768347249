/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from './store/constants/user';
import { logout } from './store/actions/user';
import { useTypedSelector } from './hooks/useTypedSelector';
import { withRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { NetworkUpdateType, settingAppNetwork } from './store/actions/appNetwork';
import { validateSolAddress } from './utils/solana';

BigNumber.config({ EXPONENTIAL_AT: 50 });
BigNumber.set({ DECIMAL_PLACES: 18 });

const AppContainer = (props: any) => {
  const dispatch = useDispatch();
  const { publicKey } = useWallet();
  const { data: loginUser } = useTypedSelector((state) => state.user);
  const { data: connectedUser } = useTypedSelector((state) => state.userConnect);

  const isSolanaAuthen = loginUser.wallet_address === publicKey?.toString() || validateSolAddress(connectedUser);

  useEffect(() => {
    if (isSolanaAuthen) {
      return;
    }

    const windowObj = window as any;
    const { ethereum } = windowObj;

    if (!ethereum) {
      return;
    }

    function handleAccountChange() {
      dispatch(logout());
    }

    ethereum.on('accountsChanged', handleAccountChange);

    function handleChainChanged(newNetworkId: string) {
      const newAppNetworkId = String(Number(newNetworkId));
      localStorage.setItem('NETWORK_ID', newAppNetworkId);
      dispatch(settingAppNetwork(NetworkUpdateType.App, newAppNetworkId));

      dispatch({
        type: userActions.USER_WALLET_CHANGED,
        payload: newAppNetworkId,
      });
    }

    ethereum.on('chainChanged', handleChainChanged);

    return () => {
      ethereum.removeListener('accountsChanged', handleAccountChange);
      ethereum.removeListener('chainChanged', handleChainChanged);
    };
  }, [loginUser, isSolanaAuthen]);

  return <>{props.children}</>;
};

export default withRouter(AppContainer);
