import { Control, DeepMap, FieldError } from "react-hook-form";
import { Paper, PaperTitle } from "../../../../../components/Base/Paper";
import { TextInput } from "../../../../../components/Base/Form";

interface LockScheduleProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
}

export function LockSchedule({ control, errors }: LockScheduleProps) {
  return (
    <Paper>
      <PaperTitle>Lock Schedule Setting</PaperTitle>
      <TextInput
        label="Lock Schedule"
        name="lockSchedule"
        control={control}
        errorMessage={errors?.lockSchedule?.message}
      />
    </Paper>
  );
}
