import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import { getNewSlider } from '../../../request/completed';
import LandingProjectDetail from './Detail/NewSliderDetail';

interface Params {
  id: string;
}

const ProjectMatrixEdit: React.FC<RouteComponentProps<Params>> = (props: RouteComponentProps<Params>) => {
  const { match } = props;
  const id = match.params.id;
  const [completed, setCompleted] = useState({});

  const getCompletedInfo = async () => {
    try {
      const resObject = await getNewSlider(id);
      setCompleted(resObject.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getCompletedInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <DefaultLayout>
      <LandingProjectDetail completed={completed} isCreate={false} />
    </DefaultLayout>
  );
};

export default withRouter(ProjectMatrixEdit);
