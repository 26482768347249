import React from 'react';
import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import CompletedDetail from './Detail/CompletedDetail';

const CreateCompleted = () => {
  return (
    <DefaultLayout>
      <CompletedDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(CreateCompleted);
