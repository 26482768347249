import useStyles from './style';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from 'react-hook-form';
import { renderErrorCreatePool } from '../../../../../utils/validate';
import { Switch } from 'antd';
import { changeDisplayStatus, changeDisplayStatusLive } from '../../../../../request/pool';
//import {alertSuccess} from "../../../store/actions/alert";
import { withRouter } from 'react-router';
import useFormStyles from '../useFormStyles';
//import {useDispatch} from "react-redux";

function DisplayPoolSwitch(props: any) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { errors, control, poolDetail } = props;
  const renderError = renderErrorCreatePool;

  const changeDisplay = async (value: any) => {
    await changeDisplayStatus({
      poolId: poolDetail.id,
      isDisplay: value,
    });
    return value;
  };

  const changeDisplayLive = async (value: any) => {
    await changeDisplayStatusLive({
      poolId: poolDetail.id,
      isDisplayLive: value,
    });
    return value;
  };

  return (
    <div className={classes.boxToggle}>
      <label className={classes.formControlLabel}>Display project</label>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="isDisplay"
          render={(field) => {
            const { value, onChange } = field;

            return (
              <Switch
                onChange={async (switchValue) => {
                  try {
                    if (poolDetail?.id) {
                      await changeDisplay(switchValue);
                    }

                    await onChange(switchValue);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                checked={value}
                checkedChildren="Display"
                unCheckedChildren="Hidden"
              />
            );
          }}
        />

        <p className={classes.formErrorMessage}>{renderError(errors, 'isDisplay')}</p>
      </FormControl>

      <label className={classes.formControlLabel}>Display project in Live section</label>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="isDisplayLive"
          render={(field) => {
            const { value, onChange } = field;
            return (
              <Switch
                onChange={async (switchValue) => {
                  try {
                    if (poolDetail?.id) {
                      await changeDisplayLive(switchValue);
                    }

                    await onChange(switchValue);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                checked={value}
                checkedChildren="Display"
                unCheckedChildren="Hidden"
              />
            );
          }}
        />

        <p className={classes.formErrorMessage}>{renderError(errors, 'isDisplayLive')}</p>
      </FormControl>
      <br />
    </div>
  );
}

export default withRouter(DisplayPoolSwitch);
