import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    "time-control": {
        marginTop: "30px",
        width: "100%",
        '& > label': {
            display: "block",
            color: '#001F4D',
            fontSize: "14px",
            letterSpacing: "0.25px",
            marginBottom: "5px"
        },
        '& > .ant-picker': {
            padding: "13px",
            width: "100%",
            borderRadius: "5px",
        },
        '& > .ant-picker:hover': {
            borderColor: "#d9d9d9"
        },
        '& > .ant-picker.ant-picker-focused': {
            outline: "none",
            borderColor: "#FFCC00",
            boxShadow: "none",
        },
    }
}))

export default useStyles
