import { BigNumber } from 'bignumber.js';
import { boolean, number, object, string } from 'yup';
import { UploadType } from '../../../../SliderManage/constants/type';
import { BASE_VALIDATION_SCHEMA, TBA_VALIDATION } from '../../contants';
import { XBorgPool } from './pool';

export const DEFAULT_POOL: XBorgPool = {
  name: '',
  banner: {
    uploadType: UploadType.LINK,
    uploadedUrl: '',
  },
  website: '',
  isPrivate: '0',
  networkAvailable: 'polygon',
  acceptCurrency: 'usdt',
  kycBypass: false,
  tokenInfoTBA: false,
  tokenInfo: {
    token: '',
    totalSoldCoin: '',
    tokenImages: {
      uploadType: UploadType.LINK,
      uploadedUrl: '',
    },
    symbol: '',
    addressReceiver: '',
  },
  tokenRateTBA: false,
  tokenRate: 0,
  buyTimeTBA: false,
  isQualifiedUserDisplay: 0,
  startTime: '',
  finishTime: '',
  mediumLink: '',
  twitterLink: '',
  telegramLink: '',
  facebookLink: '',
  linkedinLink: '',
  redditLink: '',
  description: '',
  lockSchedule: '',
  claimPolicy: '',
  claimTBA: false,
  registerTimeTBA: false,
  campaignClaimConfig: [],
  startPublicSellTime: '',
  endPublicSellTime: '',
  maxBonus: '',
  campaignHash: '',
  isDisplay: 0,
  priority: '',
};

export const VALIDATION_SCHEMA_FOR_XBORG_POOL = object({
  ...BASE_VALIDATION_SCHEMA,
  ...TBA_VALIDATION,
  registerTimeTBA: boolean().test(
    'register-rate-tba',
    'The TBA field is required to change. Please submit the proper info to deploy the project.',
    function (value: boolean | undefined) {
      return !(this.parent.needValidate && value);
    }
  ),
  maxBonus: string()
    .notRequired()
    .when('serverMaxBonus', {
      is: (serverMaxBonus) => serverMaxBonus !== undefined && serverMaxBonus !== null && serverMaxBonus > 0,
      then: string().test('less-than-server', function (value) {
        const serverMaxBonus = this.parent.serverMaxBonus;
        if (!serverMaxBonus && serverMaxBonus <= 0) {
          return true;
        }

        if (value === null || value === undefined || value === '') {
          return true;
        }

        if (isNaN(Number(value))) {
          return this.createError({
            message: 'This amount must be number',
            path: 'maxBonus',
          });
        }

        const maxBonus = Number(value);
        if (maxBonus <= 0) {
          return this.createError({
            message: 'This amount must be greater than 0',
            path: 'maxBonus',
          });
        }

        const serverMaxBonusValue = new BigNumber(serverMaxBonus).toFixed(5);
        if (Number(maxBonus) > Number(serverMaxBonusValue)) {
          return this.createError({
            message: 'The amount must be less than ' + serverMaxBonus,
            path: 'maxBonus',
          });
        }

        return true;
      }),
      otherwise: string().nullable().notRequired(),
    }),
  progressDisplay: number()
    .transform((value) => (!value ? null : value))
    .nullable()
    .typeError('This field must be a number')
    .moreThan(0, 'This field must be greater than 0')
    .max(100, 'This field must less than 100'),
});
