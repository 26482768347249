import { BaseRequest } from '../request/Request';

export async function uploadFile(file: File) {
  const form = new FormData();
  form.append('media', file);
  const res = await new BaseRequest().postImage('/admin/upload-image', form);

  const uploadResult = await res.json();
  return uploadResult.data;
}
