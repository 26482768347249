import React from 'react';
import NavLeft from './NavLeft';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Link } from 'react-router-dom';
import { adminRoute } from '../../../utils';
import { useDispatch } from 'react-redux';
import { collapseSidebar } from '../../../store/actions/global';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const LeftDefaultLayout = (props: any) => {
  const styles = useStyles();
  const { data: loginUser } = useTypedSelector((state) => state.user);
  const { collapsed } = useTypedSelector((state) => state.global);
  const dispatch = useDispatch();

  return (
    <div className={styles.leftLayout + ' ' + (collapsed && styles.smallLeft)}>
      <div className={styles.headLeft}>
        <div className={styles.BoxInfoUser}>
          <Link to={adminRoute('/profile')}>
            <img
              className={styles.avatar}
              src={loginUser.avatar ? `${API_BASE_URL}/image/${loginUser.avatar}` : '/images/avatar.svg'}
              alt=""
            />
          </Link>
          {!collapsed && (
            <div className={styles.infoUser}>
              <div className="name">{loginUser?.username}</div>
              {!!loginUser.is_active && (
                <div className="status">
                  Verified Profile
                  <img className="icon" src={'/images/icon-verified.svg'} alt="" />
                </div>
              )}
            </div>
          )}
        </div>
        <Button
          className={styles.btnSmallBig + ' ' + (collapsed && 'btnSmall')}
          onClick={() => dispatch(collapseSidebar(!collapsed))}
        ></Button>
      </div>
      <NavLeft smallLeft={collapsed} />
    </div>
  );
};

export default LeftDefaultLayout;
