import moment from 'moment';
import { useEffect } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { DateTimePicker, NumberInput } from '../../../../../../components/Base/Form';
import { PaperTitle } from '../../../../../../components/Base/Paper';
import { BaseRequest } from '../../../../../../request/Request';
import { formatCMR8Rule } from '../../../../../../utils/formatNumber';
import useStyles from './style';
import { XBorgPool } from '../../constants/pool';

interface PublicSellSettingProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  poolDetail?: XBorgPool;
  isEdit: boolean;
  serverBonus: number;
}

const PublicSellSetting = ({ control, errors, poolDetail, isEdit, serverBonus }: PublicSellSettingProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, finishTime } = poolDetail ?? { id: undefined };
  const inPublicSell = finishTime && moment().isAfter(finishTime);

  useEffect(() => {
    async function getMaxBonus() {
      if (!isEdit || !id) {
        return;
      }

      try {
        if (!inPublicSell) {
          throw new Error('Not in public sell time');
        }

        const request = new BaseRequest();
        const res = await request.get(`/admin/get-max-bonus/${id}`);

        if (res.status !== 200) {
          throw new Error('Fail to get public sell max bonus');
        }

        const resData = await res.json();

        if (resData.status !== 200) {
          throw new Error(resData.message);
        }

        control?.setValue('serverMaxBonus', resData.data);
      } catch (e: any) {
        // dispatch(alertFailure(e.message));
        control?.setValue('serverMaxBonus', 0);
      }
    }

    getMaxBonus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit, dispatch]);

  return (
    <div className={classes.exchangeRate}>
      <PaperTitle>Public Sell Setting</PaperTitle>
      <input name="serverMaxBonus" ref={control?.register} hidden />
      <div className={classes.containerTime}>
        <DateTimePicker
          name="startPublicSellTime"
          label="Start Public Sell Time"
          control={control}
          errorMessage={errors?.startPublicSellTime?.message}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={1}
        />
        <DateTimePicker
          name="endPublicSellTime"
          label="End Public Sell Time"
          control={control}
          errorMessage={errors?.endPublicSellTime?.message}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={1}
        />
      </div>
      <NumberInput
        name="maxBonus"
        control={control}
        label={`Max Allocation ${inPublicSell && !!serverBonus ? `(${formatCMR8Rule(serverBonus)})` : ''}`}
        errorMessage={errors?.maxBonus?.message}
        disabled={!inPublicSell || !isEdit || !serverBonus || isNaN(serverBonus)}
        decimalsLimit={5}
      />
    </div>
  );
};

export default PublicSellSetting;
