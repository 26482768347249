import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme:any) => ({
    header: {
        marginBottom:20,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    pagination : {
        marginTop:30,
       '& .MuiPagination-ul': {
        justifyContent:'center',
        '& .Mui-selected':{
            background: theme.custom.colors.blueDark,
            color:theme.custom.colors.white,
        },
        '& .MuiPaginationItem-outlined':{
            border:`1px solid ${theme.custom.colors.blueDark} !important`
        }
       }
    },
    boxSearch: {
        display:'flex',
    },
    inputSearch: {
        color:' black',
        width: '230px',
        border: 'none',
        height: '40px',
        outline: 'none',
        padding: '10px 15px',
        fontSize: '1rem',
        maxWidth: '100%',
        background: '#FFFFFF',
        lineHeight:' 20px',
        paddingLeft:' 40px',
        borderRadius: '10px',
        letterSpacing: '0.25px',
    },
    iconSearch:{
        position:'relative',
        left:'12%',
        width:24,
    },
}))

export default useStyles;