import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getListPoolStake } from '../../request/admin';
import { alertFailure } from '../../store/actions/alert';
import RewardStakingCreate from './Forms/Reward/RewardStakingCreate';
import RewardStakingEdit from './Forms/Reward/RewardStakingEdit';

const RewardStaking = () => {
  const dispatch = useDispatch();
  const [latestPool, setLatestPool] = useState<any>({});

  useEffect(() => {
    getListPool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListPool = async () => {
    try {
      const res = await getListPoolStake();
      if (res?.status === 200) {
        if (res?.data?.data && res?.data?.data?.length > 0) {
          const listPool = res?.data?.data;
          const latest = listPool[0];
          setLatestPool(latest);
        }
      } else {
        dispatch(alertFailure('Fetch pool detail failed'));
      }
    } catch (error: any) {
      dispatch(alertFailure(error?.message));
    }
  };

  return <>{Number(latestPool?.id) > 0 ? <RewardStakingEdit id={latestPool?.id} /> : <RewardStakingCreate />}</>;
};

export default RewardStaking;
