import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme:any) => {
  return {
    dialog: {
      '& .MuiDialog-paperWidthSm': {
        width: 360,
        borderRadius: '15px'
      }
    },
    dialogTitle: {
      '& .MuiTypography-root': {
        fontFamily: 'OpenSans-Bold',
        color: '#001F4D'
      }
    },
    dialogContent: {
      padding: '8px 24px',
      overflowY: 'initial',
      '&:nth-child(0)': {
        marginTop: '0px'
      }
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: 12,
      marginTop: 15,

      '& > *:not(:last-child)': {
        marginRight: 5 
      }
    },
    dialogInput: {
      borderRadius: 5,
      border: '1px solid black',
      padding: '10px',
      transition: '.1s all ease-in',

      '&:focus': {
        borderColor: '#FFCC00',
        outline: 'none'
      }
    },
    dialogLabel: {
      marginRight: 10,
      color: '#363636'
    },
    dialogButton: {
      textTransform: 'inherit',
      background: 'linear-gradient(97.39deg, #61009D 7.12%, #02ACD3 88.52%)',
      color: 'white',
      flexBasis: 132,
      borderRadius: 10
    },
    dialogButtonCancel: {
      background: '#C4C4C4',
      position: 'relative',
      color: '#001F4D',
      '&:hover': {
        // backgroundColor: '#a0033b'
      }
    }
  }
});

export default useStyles;

