import { Control, DeepMap, FieldError } from 'react-hook-form';
import { NumberInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import DisplayPoolSwitch from './DisplayProgressBarSwitch';

interface StakedXborgSettingProps {
  detail?: any;
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  register: any;
  setValue: any;
}

export function StakedXborgSetting({ detail, control, errors, register, setValue }: StakedXborgSettingProps) {
  return (
    <Paper>
      <PaperTitle>Display Staked NFTs Setting</PaperTitle>
      <DisplayPoolSwitch
        poolDetail={detail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
      />
      <NumberInput
        name="total_nft_stake"
        control={control}
        label="Number Staked NFTs"
        errorMessage={errors?.total_nft_stake?.message}
        isRequired={false}
      />
    </Paper>
  );
}
