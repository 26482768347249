import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControlFlex: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlBlurLabel: {
    color: '#9A9A9A !important',
  },
  formControlIcon: {
    display: 'inline-block',
    marginTop: 10,
  },
  formControlFlexBlock: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  exchangeRateTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#363636',
  },
  formControlRate: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: 5,
    '&>input:disabled': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9 !important',
    },
  },
  exchangeRateInput: {
    width: '45%',
  },
  formInputBoxEther: {
    border: '1px solid #000000 !important',
    width: '100%',
  },
  rateInput: {
    marginTop: 0,
  },
  formInputBoxBS: {
    backgroundColor: '#DFDFFF',
    color: '#3A39BB',
    width: '100%',
  },
  box: {
    right: 0,
    top: 0,
    width: 55,
    height: 50,
    backgroundColor: '#000000',
    fontSize: 14,
    color: 'white',
    border: 'none',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    'border-top-right-radius': 5,
    'border-bottom-right-radius': 5,
    padding: 5,
  },
  boxEther: {
    backgroundColor: '#3A39BB',
  },
}));

export default useStyles;
