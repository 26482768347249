import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import DefaultLayout from '../../../../components/Layout/DefaultLayout';
import { getStakeRewardDetail } from '../../../../request/admin';
import { alertFailure } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import { UploadType } from '../../../SliderManage/constants/type';
import { EditRewardForm } from './EditRewardForm';

const EditReward: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = true;
  const dispatch = useDispatch();
  const [originPool, setOriginPool] = useState();
  const [poolDetail, setPoolDetail] = useState();

  const { id } = useParams<any>();

  useEffect(() => {
    if (id) {
      getRewardDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDetail = (data: any) => {
    const result = {
      ...data,
      start_time: moment(Number(data.start_time) * 1000),
      end_time: moment(Number(data.end_time) * 1000),
      is_claim: Number(data.is_claim),
      is_direct_claim: Number(data.is_direct_claim),
      banner: {
        uploadType: UploadType.LINK,
        uploadedUrl: data.token_image,
        link: data.token_image,
      },
    };
    return result;
  };

  const getRewardDetail = async () => {
    try {
      const res = await getStakeRewardDetail(id);
      if (res?.status === 200) {
        if (res.data) {
          setOriginPool(res.data);
          setPoolDetail(handleDetail(res.data));
        }
      } else {
        dispatch(alertFailure('Fetch pool detail failed'));
      }
    } catch (error: any) {
      dispatch(alertFailure(error?.message));
    }
  };

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/reward')} />
      <EditRewardForm isEdit={isEdit} originPool={originPool} poolDetail={poolDetail} />
    </DefaultLayout>
  );
};

export default withRouter(EditReward);
