import { CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { RadioGroup, TextInput } from '../../../../components/Base/Form';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { NETWORK_AVAILABLE, STATUS_LANDING_PROJECT_AVAILABLE } from '../../../../constants';
import { createLandingPage, updateLandingPage } from '../../../../request/completed';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { LandingProject } from '../../LandingProjectRow/LandingProjectRow';

interface Completed extends Omit<LandingProject, 'banner'> {
  banner: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

const LandingProjectForm = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: Completed; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, errors, watch, getValues } = useForm<Completed>({
    mode: 'onChange',
    defaultValues: {
      banner: {
        uploadType: UploadType.LINK,
        link: '',
      },
      description: '',
      trailer_link: '',
      project_name: '',
      project_status: STATUS_LANDING_PROJECT_AVAILABLE.COMPLETED,
      project_network: NETWORK_AVAILABLE.POLYGON,
      priority: '',
      pool_id: '',
    },
    reValidateMode: 'onChange',
    // resolver: yupResolver(),
  });

  useEffect(() => {
    if (completed) {
      reset({
        banner: {
          link: completed.banner,
          uploadedUrl: completed.banner,
          uploadType: UploadType.LINK,
        },
        description: completed.description,
        trailer_link: completed.trailer_link,
        project_name: completed.project_name,
        project_status: completed.project_status,
        project_network: completed.project_network,
        priority: completed.priority,
        pool_id: completed.pool_id,
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: Completed) => {
    setIsOpenModal(false);
    let banner = values.banner.link;
    setLoading(true);
    try {
      if (values.banner.uploadType === UploadType.FILE && !!values.banner.file) {
        banner = await uploadFile(values.banner.file);
      }

      if (values.banner.uploadType === UploadType.LINK) {
        banner = values.banner.link ?? '';
      }

      const trimmedValue = {
        banner,
        description: values.description,
        trailer_link: values.trailer_link,
        project_name: values.project_name,
        project_status: values.project_status,
        project_network: values.project_network,
        priority: values.priority,
        pool_id: values.pool_id,
      };

      if (isCreate) {
        const res = await createLandingPage(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/landing-projects'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateLandingPage(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/landing-projects'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/completed')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <label>Landing Project Information</label>
            <TextInput name="project_name" control={control} label="Project Name" isRequired={true} />
            {/* <TextInput name="project_status" control={control} label="Project Status" isRequired={true} /> */}
            <TextInput name="description" control={control} label="Description" isRequired={true} />
            <TextInput name="pool_id" control={control} label="Pool Id" isRequired={false} />
            <TextInput name="trailer_link" control={control} label="Trailer Link" isRequired={false} />
            <TextInput name="priority" control={control} label="Priority" isRequired={false} />
          </Paper>
        </Grid>

        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Network Available"
                  control={control}
                  name="project_network"
                  radioData={[
                    {
                      value: NETWORK_AVAILABLE.POLYGON,
                      label: 'Polygon',
                    },
                    {
                      value: NETWORK_AVAILABLE.SOL,
                      label: 'Solana',
                    },
                    {
                      value: NETWORK_AVAILABLE.ETH,
                      label: 'Ethereum',
                    },
                    {
                      value: NETWORK_AVAILABLE.BSC,
                      label: 'BSC',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel
                      key={data.value}
                      value={data.value}
                      label={data.label}
                      control={<Radio size="small" />}
                    />
                  )}
                />
              </div>
            </>
          </Paper>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Project Status"
                  control={control}
                  name="project_status"
                  radioData={[
                    {
                      value: STATUS_LANDING_PROJECT_AVAILABLE.COMPLETED,
                      label: 'Completed',
                    },
                    {
                      value: STATUS_LANDING_PROJECT_AVAILABLE.IN_PROGRESS,
                      label: 'In Progress',
                    },
                    {
                      value: STATUS_LANDING_PROJECT_AVAILABLE.COMING_SOON,
                      label: 'Coming soon',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel
                      key={data.value}
                      value={data.value}
                      label={data.label}
                      control={<Radio size="small" />}
                    />
                  )}
                />
              </div>
            </>
          </Paper>

          <Paper>
            <>
              <label>Project Banner</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={
                  {
                    link: completed?.banner || '',
                    uploadedUrl: completed?.banner || '',
                    uploadType: UploadType.LINK,
                  } as any
                }
                formName="banner"
                errors={errors?.banner}
                selectTooltip={
                  <>
                    Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                  </>
                }
              />
            </>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the project' : 'Update the project'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>
          Are you sure to {isCreate ? 'create this project?' : 'update the project?'}
        </p>
      </ConfirmDialog>
    </>
  );
};

export default LandingProjectForm;
