import _ from 'lodash';
import { object, string, mixed, number } from 'yup';
import { FileType, Media, UploadType } from './type';

export const FileTypeLabel = {
  [FileType.IMAGE]: 'Image',
  [FileType.VIDEO]: 'Video',
};

export const UploadFileTypeLabel = {
  [UploadType.LINK]: 'Link',
  [UploadType.FILE]: 'File',
};

export enum ProjectType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  COMMUNITY = 'community',
}

export enum NetworkType {
  SOLANA = 'solana',
  POLYGON = 'polygon',
  ETHEREUM = 'ethereum',
  TBD = 'tbd',
}

export const defaultMedia: Media = {
  id: '-1',
  projectName: '',
  projectType: ProjectType.PUBLIC,
  networkAvailable: NetworkType.TBD,
  whitepaper: '',
  website: '',
  trailer: '',
  telegram: '',
  twitter: '',
  discord: '',
  description: '',
  priority: '',
  firstSlide: {
    type: FileType.VIDEO,
    uploadType: UploadType.LINK,
    file: null,
  },
  secondSlide: {
    type: FileType.IMAGE,
    uploadType: UploadType.LINK,
    file: null,
  },
  thumbnail: {
    type: FileType.IMAGE,
    uploadType: UploadType.LINK,
    file: null,
  },
};

const ACCEPT_TYPE = {
  [FileType.IMAGE]: ['png', 'gif', 'jpg', 'jpeg', 'JPEG', 'svg'],
  [FileType.VIDEO]: ['mp4', 'mov', 'mkv'],
};

const ACCEPT_SIZE = {
  [FileType.IMAGE]: 8,
  [FileType.VIDEO]: 50,
};

function validateFileType(type?: FileType) {
  return function (this: any, file: File) {
    if (!file) {
      return true;
    }

    const uploadFileType = file.type;
    const acceptType: FileType = type ? type : this.parent.type;

    if (!uploadFileType.includes(acceptType)) {
      return this.createError({
        type: 'file-type',
        message: `File must be ${acceptType}`,
        path: this.path,
      });
    }

    const chunks = file.name.split('.');
    const fileExtension = chunks[chunks.length - 1];
    const acceptFileType = ACCEPT_TYPE[acceptType];
    if (!acceptFileType.includes(fileExtension.toLowerCase())) {
      return this.createError({
        type: 'file-type',
        message: `Invalid ${acceptType} type.`,
        path: this.path,
      });
    }

    const acceptFileSize = (ACCEPT_SIZE[acceptType] ?? 0) * 1024 * 1024;

    if (acceptFileSize < file.size) {
      return this.createError({
        type: 'file-size',
        message: `${_.capitalize(acceptType)} size must less than ${ACCEPT_SIZE[acceptType] ?? 0}MB`,
        path: this.path,
      });
    }

    return true;
  };
}

const URL_REGEX = /(^$|^(?:(http|ftp|https)?:\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]))$/;

export const getMediaValidationSchema = (data: Media) => {
  const { firstSlide, thumbnail } = data;

  return object().shape({
    projectName: string().required('This field is required'),
    description: string().required('This field is required'),
    projectType: string().required('This field is required'),
    twitter: string().notRequired().nullable().matches(URL_REGEX, 'Please update the input field with a URL.'),
    telegram: string().notRequired().nullable().matches(URL_REGEX, 'Please update the input field with a URL.'),
    discord: string().notRequired().nullable().matches(URL_REGEX, 'Please update the input field with a URL.'),
    trailer: string().notRequired().nullable().matches(URL_REGEX, 'Please update the input field with a URL.'),
    whitepaper: string().notRequired().nullable().matches(URL_REGEX, 'Please update the input field with a URL.'),
    website: string().notRequired().nullable().matches(URL_REGEX, 'Please update the input field with a URL.'),
    priority: number()
      .transform((value) => (!value ? 0 : value))
      .notRequired()
      .typeError('This field must be a number')
      .integer('This field must be integer')
      .min(0, 'This field must be positive'),
    firstSlide: object().shape({
      link: string().when(['uploadType', 'type'], {
        is: (uploadType: UploadType) => uploadType === UploadType.LINK,
        then: string().nullable().required('This field is required'),
      }),
      file: mixed()
        .when(['uploadType', 'type'], {
          is: (uploadType: UploadType, type: FileType) =>
            uploadType === UploadType.FILE &&
            (!firstSlide.uploadedUrl || firstSlide.type !== type || firstSlide.uploadType !== uploadType),
          then: mixed().required('This field is required'),
        })
        .test('check-type', 'File must be image', validateFileType()),
    }),
    secondSlide: object().shape({
      file: mixed().test('check-type', 'File must be image', validateFileType(FileType.IMAGE)),
    }),
    thumbnail: object().shape({
      link: string().when('uploadType', {
        is: (uploadType: UploadType) => uploadType === UploadType.LINK,
        then: string().nullable().required('This field is required'),
      }),
      file: mixed()
        .when('uploadType', {
          is: (uploadType: UploadType) => uploadType === UploadType.FILE && uploadType !== thumbnail.uploadType,
          then: mixed().required('This field is required'),
        })
        .test('check-type', 'File must be image', validateFileType(FileType.IMAGE)),
    }),
  });
};
