import { IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../../components/Base/ConfirmDialog';
import { USERS } from '../../../constants';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { deleteIDO } from '../../../request/admin';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import useStyles from './style';

type AdminProps = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  wallet_address: string;
  role: number;
  status: number;
  ido_pool_id: string;
};

type AdminRowProps = {
  admin: AdminProps;
  currentOpen: string;
  setCurrentOpen: (id: string) => void;
  onReload?: any;
};

const AdminRecord: React.FC<AdminRowProps> = (props: AdminRowProps) => {
  const { admin, currentOpen, setCurrentOpen, onReload } = props;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { user } = useSelector((state: any) => state);
  const role = useMemo(() => {
    return get(user, 'data.role', 1);
  }, [user]);
  const isSuperAdmin = role === USERS.SUPER_ADMIN;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();
  const { ref, setIsVisible } = useComponentVisible();
  // const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  useEffect(() => {
    setIsVisible(admin.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  // const AdminDetail = () => {
  //   history.push(adminRoute(`/list-admin/${admin.id}`));
  // };

  const handleDelete = () => {
    setIsOpenModal(false);
    deleteIDO(admin.id, admin).then((res) => {
      if (res.status === 200) {
        dispatch(alertSuccess('Delete successfully!'));
        if (onReload) {
          onReload();
        }
      } else {
        dispatch(alertFailure(res.message || 'Something went wrong'));
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderAction = () => {
    if (isSuperAdmin) {
      return (
        <TableCell align="center" className={classes.tableCell} component="td" scope="row">
          <IconButton
            aria-controls={open ? 'fade-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              padding: '6px 12px',
            }}
          >
            <img src="/images/icon_dot.svg" alt="delete" />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setIsOpenModal(true);
                handleClose();
              }}
            >
              Remove
            </MenuItem>
          </Menu>
        </TableCell>
      );
    }
    return (
      <TableCell align="center" className={classes.tableCell} component="td" scope="row">
        <Tooltip title="">
          <img onClick={() => {}} src="/images/icon_dot_gray.svg" alt="delete" />
        </Tooltip>
      </TableCell>
    );
  };

  return (
    <TableRow ref={ref} className={classes.tableRow} key={admin.id}>
      {/* <TableCell className={classes.tableCell} component="td" scope="row" onClick={AdminDetail}>
        <span className={`${classes.wordBreak} ${classes.userName}`}>{admin.username}</span>
      </TableCell>
      <TableCell className={classes.tableCell} component="td" scope="row" onClick={AdminDetail}>
        <span className={`${classes.wordBreak} ${classes.lastname}`}>{admin.firstname}</span>
      </TableCell>
      <TableCell className={classes.tableCell} component="td" scope="row" onClick={AdminDetail}>
        <span className={`${classes.wordBreak} ${classes.email}`}>{admin.email}</span>
      </TableCell> */}
      <TableCell className={classes.tableCell} align="left" >
        <Tooltip title={<p style={{ fontSize: 15 }}>{admin.wallet_address}</p>}>
          <span className={`${classes.wordBreak} ${classes.walletAddress}`}>{admin.wallet_address}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.tableCell} component="td" scope="row" >
        <span className={classes.wordBreak}>{admin.ido_pool_id}</span>
      </TableCell>
      {/* <TableCell className={classes.tableCell} align="center" onClick={AdminDetail}>
        {getActiveStatus(admin)}
      </TableCell> */}
      {renderAction()}
      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={handleDelete}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
      >
        <p className={classes.titleDialog}>Are you sure to remove this wallet address?</p>
      </ConfirmDialog>
    </TableRow>
  );
};

export default AdminRecord;
