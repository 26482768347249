import { BaseRequest } from './Request';
import { apiRoute } from '../utils';
const queryString = require('query-string');

export const getAdminList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins`);
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getOurServiceList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/get-list-our-service`);
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getAboutInvestmentPool = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/get-list-invest-pool`);
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getIdoList = async (queryParams?: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/list-ido`);
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getAdminDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getOurServiceDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-our-service/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getAboutInvestmentDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/invest-pool/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateAdmin = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateOurService = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-our-service/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateAboutInvestmentPool = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/invest-pool/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createOurService = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-our-service`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createAboutInvestmentPool = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-invest-pool`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};


export const createAdmin = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createIdo = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/ido-participant/create`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteAdmin = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/delete-admin/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteOurService = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-our-service/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteAboutInvestmentPool = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/invest-pool/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteIDO = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/delete-ido-participant/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export interface StakePool {
  pool_name: string;
  is_display: number;
  lock_period: string;
  common_score: number;
  rare_score: number;
  elite_score: number;
  special_score: number;
  anarkey_lock_period: string;
  // reward_percent_normal: number;
  // reward_percent_ido: number;
  apr: number;
  total_nft_stake: string | number;
  is_display_nft_stake: string | number;
  total_nft_stake_anarkey: string | number;
  is_display_nft_stake_anarkey: string | number;
  boost_collections: any[];
  min_spent: string | number;
  token_rewards?: [
    {
      token_name: string;
      token_image: string;
      token_symbol: string;
      token_decimal: number;
      token_address: string;
      network: string;
      total_reward: number;
      reward_duration: number;
      start_time: number;
      end_time: number;
    }
  ];
}

export const createStakePool = async (data: StakePool) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/stake/create`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createRewardPool = async (data: any, poolId: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/token-reward/${poolId}`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateRewardPool = async (data: any, poolId: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/token-reward/${poolId}`);

  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateStakePool = async (id: number | string, data: StakePool) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/stake/update/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getListPoolStake = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/stakes`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getPoolStakeDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/stake/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getStakeRewardDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/token-reward/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const changeDisplayStatusStakePool = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/stake/${data.poolId}/change-display`);

  const response = (await baseRequest.put(url, {
    is_display: data.isDisplay,
  })) as any;

  const resObject = await response.json();
  return resObject;
};
