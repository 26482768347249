import { get } from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { USERS } from '../../../constants';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { adminRoute } from '../../../utils';
import { LoadingLayout } from '../../Layout/LoadingLayout';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

const RoutePermission = (props: PrivateRouteProps) => {
  const { user } = useSelector((state: any) => state);
  const { component: Component, ...rest } = props;
  const { data: loginUser } = useTypedSelector((state) => state.user);
  const role = useMemo(() => {
    return get(user, 'data.role', 1);
  }, [user]);

  return (
    <Suspense fallback={<LoadingLayout />}>
      <Route
        {...rest}
        render={(props) =>
          loginUser ? (
            <>
              {role === USERS.SUPER_ADMIN ? (
                <Component {...props} />
              ) : (
                <>
                  <Redirect to={adminRoute('/projects')} />
                </>
              )}
            </>
          ) : (
            <Redirect to={adminRoute('/login')} />
          )
        }
      />
    </Suspense>
  );
};

export default RoutePermission;
