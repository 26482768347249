import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme:any) => ({
    header: {
        marginBottom:30,
        fontFamily:'Open Sans',
        boxSizing:'border-box',
    },
    title : {
        fontSize:14,
        color:theme.custom.colors.blueDark,
        fontWeight:400
    },
    warning: {
        color:'blue',
        marginBottom:5,
        display:'block',
        fontSize:14,
        fontStyle:'italic'
    },
    gird : {
        flexWrap:'inherit',
    },
    fromControl: {
        border: `1px solid ${theme.custom.colors.blueDark}`,
        borderRadius:10,
        marginBottom: 40,
        padding: '20px !important',
    },
    input: {
        width:'100%',
        height: 50,
        border: `1px solid ${theme.custom.colors.blueDark}`,
        color:theme.custom.colors.blueDark,
        background: 'none',
        borderRadius:10,
        padding: '10px',
        fontSize:16,
        fontWeight:400,
        fontStyle:'nomal',
        '&:focus-visible': {
           outline:'none'
        }
    },
    review : {
        marginTop:25
    },
    img : {
        width:160,
        height: 160,
        borderRadius:'50%',
        marginTop:25

    },
    buttonUpdate : {
        boxShadow:'none !important',
        '&:hover':{
            opacity: '0.8'
        }       
    },
    buttonCancel : {
        boxShadow:'none !important',
        background: `${theme.custom.colors.bgButtonCancel} !important `,
        color: theme.custom.colors.blueDark,
        '&:hover':{
            background: `${theme.custom.colors.white} !important `,
        }
    },
    flexButton : {
        margin: '20px 0px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    formDialog: {
        width: 'auto',
    },

    titleDialog : {
        textAlign:'center',
        color: theme.custom.colors.blueDark,
        fontSize:20,
        fontWeight:600,
    }
}));

export default useStyles;