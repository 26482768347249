import { FormControl } from "@material-ui/core";
import { DatePicker, DatePickerProps } from "antd";
import { ReactNode, useRef } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { HelpText } from "../RepresentData/HelpText";
import { FormLabel } from "../RepresentData/FormLabel";
import useStyles from "./styles";

type DateTimePickerProps = DatePickerProps & {
  control?: Control<any>;
  label?: string | React.ReactNode;
  rules?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  name: string;
  errorMessage?: string | ReactNode;
};

export function DateTimePicker({
  control,
  label,
  rules,
  name,
  className,
  onSelect,
  errorMessage,
  ...datePickerProps
}: DateTimePickerProps) {
  const inputRef = useRef<any>();

  const styles = useStyles();
  const classes = [styles["time-control"], className].filter(Boolean).join(" ");

  return (
    <Controller
      control={control}
      name={name}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      defaultValue=""
      render={(field, { invalid }) => {
        const handleSelecFn =
          onSelect ??
          function (selectDate: any) {
            field.onChange(selectDate);
          };

        return (
          <FormControl fullWidth error={invalid} className={classes}>
            {label && <FormLabel>{label}</FormLabel>}
            <DatePicker
              {...datePickerProps}
              {...field}
              onSelect={handleSelecFn}
              ref={inputRef}
            />
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
    />
  );
}
