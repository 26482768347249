import { useDispatch } from 'react-redux';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { MESSAGES } from '../../constants/alert';
import { BaseRequest } from '../../request/Request';
import { alertFailure, alertSuccess } from '../../store/actions/alert';
import { SlideManageForm } from './components/Form/SlideManageForm';
import { defaultMedia } from './constants';
import { FileType, Media } from './constants/type';
import { uploadFile } from './utils';

function CreateSlider() {
  const dispatch = useDispatch();

  async function uploadFiles(data: Media) {
    const { firstSlide, secondSlide, thumbnail } = data;
    secondSlide.type = FileType.IMAGE;
    thumbnail.type = FileType.IMAGE;

    const uploadFirstSlideRequest = uploadFile(firstSlide);
    const uploadThumbnails = uploadFile(thumbnail);
    const uploadSecondSlideRequest = uploadFile(secondSlide);

    const results = await Promise.all([uploadFirstSlideRequest, uploadSecondSlideRequest, uploadThumbnails]);
    if (results[0].status !== 200 || results[1].status !== 200 || results[2].status !== 200) {
      throw new Error('Failed to upload file');
    }

    const firstSlideData = await results[0].json();
    const secondSlideData = await results[1].json();
    const thumbnailsData = await results[2].json();

    if (firstSlideData.status !== 200 || secondSlideData.status !== 200 || thumbnailsData.status !== 200) {
      throw new Error('Failed to upload file');
    }

    return {
      slide1_url: firstSlideData.data,
      slide1_type: firstSlide.type,
      slide1_option_upload: firstSlide.uploadType,
      slide2_url: secondSlideData.data,
      slide2_type: secondSlide.type,
      slide2_option_upload: secondSlide.uploadType,
      thumbnail_url: thumbnailsData.data,
      thumbnail_option_upload: thumbnail.uploadType,
    };
  }

  async function createMedia(data: Media) {
    try {
      const uploadFileResults = await uploadFiles(data);
      const createData: Record<string, any> = {
        ...uploadFileResults,
        project_type: data.projectType,
        project_name: data.projectName,
        project_network: data.networkAvailable,
        description: data.description,
        whitepaper: data.whitepaper,
        website: data.website,
        trailer: data.trailer,
        telegram: data.telegram,
        twitter: data.twitter,
        discord: data.discord,
        priority: data.priority
      };

      const request = new BaseRequest();
      const response = await request.post('/admin/media', createData);
      if (response.status !== 200) {
        throw new Error('Failed to create media');
      }

      const resObj = await response.json();

      if (resObj.status !== 200) {
        throw new Error(resObj.message);
      }

      dispatch(alertSuccess(MESSAGES.CREATE_SUCCESS));
      return true;
    } catch (e: any) {
      dispatch(alertFailure(e.message));
      return false;
    }
  }

  return (
    <DefaultLayout>
      <SlideManageForm data={defaultMedia} onSubmit={createMedia} />
    </DefaultLayout>
  );
}

export default CreateSlider;
