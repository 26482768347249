import { useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import useStyles from './style';
import { ACCEPT_CURRENCY } from '../../../../../constants';
import { getIconCurrencyUsdt } from '../../../../../utils/usdt';
import { PaperTitle } from '../../../../../components/Base/Paper';
import { HelpText, NumberInput } from '../../../../../components/Base/Form';
import RequireSymbol from '../../../../../components/Base/RequireSymbol';
import useFormStyles from '../useFormStyles';
import { Checkbox } from '../../../../../components/Base/Form/Checkbox/Checkbox';

function ExchangeRateETH(props: any) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { control, setValue, errors, watch, poolDetail, token, hasTBA } = props;

  useEffect(() => {
    if (poolDetail) {
      if (poolDetail.accept_currency === ACCEPT_CURRENCY.ETH) {
        setValue('tokenRate', poolDetail.ether_conversion_rate);
      } else {
        // poolDetail.ether_conversion_rate
        setValue('tokenRate', poolDetail.tokenRate || 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolDetail]);

  const isDeployed = poolDetail?.isDeploy;

  const acceptCurrency = watch('acceptCurrency');
  const networkAvailable = watch('networkAvailable');
  let { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: acceptCurrency,
    networkAvailable: networkAvailable,
  });

  const isTBA: boolean = hasTBA && (props.control?.watchInternal('tokenRateTBA') as any);

  return (
    <>
      <div className={classes.formControlTitle}>
        <PaperTitle>Exchange Rates</PaperTitle>
        <RequireSymbol />
      </div>
      {hasTBA && (
        <Checkbox
          control={props.control}
          disabled={!!poolDetail?.isDeploy}
          errorMessage={errors?.tokenRateTBA?.message}
          name="tokenRateTBA"
          label="TBA"
        />
      )}
      <div className={classes.formControlFlex}>
        <div className={`${classes.formControlFlexBlock} ${classes.exchangeRateInput}`}>
          <label className={`${classes.formControlLabel}`}>You have</label>

          <div className={classes.formControlRate}>
            <input
              type="number"
              name="ethFor"
              disabled={true}
              value={1}
              className={`${classes.formInputBox} ${classes.formInputBoxEther}`}
            />
            {/* <button className={classes.box}>{token?.symbol || ''}</button> */}

            <Tooltip title={token?.symbol || ''}>
              <button className={classes.box}>{token?.symbol || ''}</button>
            </Tooltip>
          </div>
        </div>

        <img className={classes.formControlIcon} src="/images/icon-exchange.svg" alt="" />
        <div className={`${classes.formControlFlexBlock} ${classes.exchangeRateInput}`}>
          <label className={`${classes.formControlLabel}`}>You get</label>
          <div className={classes.formControlRate}>
            <NumberInput
              control={control}
              decimalsLimit={8}
              maxLength={25}
              className={classes.rateInput}
              currencyInputClass={`${classes.formInputBox} ${classes.formInputBoxBS}`}
              disabled={isDeployed || isTBA}
              name="tokenRate"
            />
            <Tooltip title={currencyName}>
              <button className={`${classes.box} ${classes.boxEther}`}>{currencyName}</button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.errorFlex}>
        <div></div>
        <div className={classes.errorGet}>
          <HelpText>
            <span className={classes.error}>{errors?.tokenRate?.message}</span>
          </HelpText>
        </div>
      </div>
    </>
  );
}

export default ExchangeRateETH;
