import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import FormInvestmentPoolConfig from './InvestmentPoolConfigProject';

const InvestmentPoolConfigDetail = (props: any) => {
  const { completed, isCreate } = props;

  const showCompletedCreate = () => {
    return <FormInvestmentPoolConfig completed={completed} isCreate={isCreate} />;
  };

  const showCompletedDetail = () => {
    if (!_.isEmpty(completed)) {
      return <FormInvestmentPoolConfig completed={completed} isCreate={isCreate} />;
    }
    return <p>Loading...</p>;
  };

  return <div>{isCreate ? showCompletedCreate() : showCompletedDetail()}</div>;
};

export default withRouter(InvestmentPoolConfigDetail);
