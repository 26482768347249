/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@material-ui/core';
import * as anchor from '@project-serum/anchor';
import { web3 } from '@project-serum/anchor';
import { TokenInstructions } from '@project-serum/serum';
import * as spl from '@solana/spl-token';
import { PublicKey, SystemProgram } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { cloneDeep, get, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PoolType } from '../..';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button, Textarea } from '../../../../components/Base/Form';
import { Paper, PaperTitle } from '../../../../components/Base/Paper';
import {
  MAPPING_CURRENCY_ADDRESS,
  NATIVE_TOKEN_ADDRESS,
  NETWORK_AVAILABLE,
  NETWORK_AVAILABLE_CHAINID,
  USERS,
} from '../../../../constants';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { initWorkspace, useWorkspace } from '../../../../hooks/useWorkspace';
import { updateDeploySuccess } from '../../../../request/pool';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { deployPool } from '../../../../store/actions/campaign';
import { alertActions } from '../../../../store/constants/alert';
import { campaignActions } from '../../../../store/constants/campaign';
import { adminRoute } from '../../../../utils';
import { unixTime } from '../../../../utils/convertDate';
import { uploadFile } from '../../../../utils/file';
import {
  OFFERED_CURRENCY_SEED,
  PRESALE_POOL_SEED,
  PRESALE_POOL_TOKEN_ACCOUNT_SEED,
} from '../../../../utils/solana/sollet/deployPool';
import { getTokenInfo, TokenType } from '../../../../utils/token';
import { UploadType } from '../../../SliderManage/constants/type';
import { BuyTime } from '../components/BuyTime';
import { ClaimConfigTable } from '../components/ClaimConfig';
import { ExchangeRate } from '../components/ExchangeRate';
import { GeneralDetail } from '../components/GeneralDetail';
import { LockSchedule } from '../components/LockSchedule';
import { Priority } from '../components/Priority';
import { ProgressBarSetting } from '../components/ProgressBarSetting';
import { SocialSetting } from '../components/SocialSetting';
import { ExtendSocialSetting } from '../components/SocialSetting/ExtendSocialSetting';
import { TokenInfo } from '../components/TokenInfo';
import { ProjectDetails } from './components/Detail';
import PublicSellSetting from './components/PublicSellSetting';
import { RegisterTime } from './components/RegisterTime/RegisterTime';
import UserJoinPool from './components/UserJoinPool/UserJoinPool';
import { DEFAULT_POOL, VALIDATION_SCHEMA_FOR_XBORG_POOL } from './constants';
import { ClaimConfig, WhitelistUsers, XBorgPool } from './constants/pool';
import useStyles from './style';

interface PoolFormProps {
  isEdit: boolean;
  poolDetail?: XBorgPool;
  onSubmit: (data: any) => Promise<any>;
}

export function PoolForm({ isEdit, poolDetail, onSubmit }: PoolFormProps) {
  initWorkspace();
  const { program, wallet, connection, provider } = useWorkspace();
  const deployer = cloneDeep(wallet);
  const [error, setError] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: loginUser } = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingDeploy, setLoadingDeploy] = useState(false);
  const [deployed, setDeployed] = useState(false);
  const [token, setToken] = useState<TokenType | null>(null);
  const [decimalsToken, setDecimalsToken] = useState<number>(9);
  const [decimalsCurrentcy, setDecimalsCurrentcy] = useState<number>(9);

  const { user } = useSelector((state: any) => state);
  const role = useMemo(() => {
    return get(user, 'data.role', 1);
  }, [user]);
  const { appChainID } = useTypedSelector((state) => state.appNetwork).data;
  const [listQualified, setListQualified] = useState<WhitelistUsers[]>([]);

  const [isOpenConfirmDeploy, setOpenConfirmDeploy] = useState<boolean>(false);

  const isSuperAdmin = role === USERS.SUPER_ADMIN;

  const isDeploy = typeof poolDetail?.isDeploy === 'boolean' ? poolDetail?.isDeploy : new BigNumber(poolDetail?.isDeploy || 0).gt(0);

  const form = useForm<XBorgPool>({
    mode: 'onChange',
    defaultValues: { ...DEFAULT_POOL, needValidate: false },
    reValidateMode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA_FOR_XBORG_POOL),
  });

  const {
    register,
    setValue,
    reset,
    errors,
    handleSubmit,
    control,
    watch,
    getValues,
    setError: setFormError,
    clearErrors,
  } = form;

  const tokenInfo = watch('tokenInfo.token');
  const networkAvailable = watch('networkAvailable');
  const currentcy = watch('acceptCurrency');

  useEffect(() => {
    const resetValue = poolDetail ?? DEFAULT_POOL;

    reset({
      ...resetValue,
    });
  }, [poolDetail, reset]);

  useEffect(() => {
    if (!errors.tokenInfo?.token && networkAvailable === NETWORK_AVAILABLE.SOL && !isEmpty(tokenInfo)) {
      Promise.all([getDecimalTokenOnSolana(false), getDecimalTokenOnSolana(true)]);
    }
  }, [wallet, provider, program, tokenInfo, networkAvailable, currentcy]);

  const getDecimalTokenOnSolana = async (isToken: boolean) => {
    try {
      const TOKEN_PROGRAM_ID = new anchor.web3.PublicKey(TokenInstructions.TOKEN_PROGRAM_ID.toString());
      const mint = new spl.Token(
        provider?.connection,
        isToken
          ? new PublicKey(tokenInfo as any)
          : new PublicKey(
              (currentcy === 'usdt'
                ? process.env.REACT_APP_SMART_CONTRACT_SOLANA_USDT_ADDRESS
                : process.env.REACT_APP_SMART_CONTRACT_SOLANA_USDC_ADDRESS) as any
            ),
        TOKEN_PROGRAM_ID,
        provider?.wallet
      );
      const info = await mint.getMintInfo();
      const decimals = info?.decimals;
      if (isToken) {
        setDecimalsToken(decimals);
      } else {
        setDecimalsCurrentcy(decimals);
      }
      console.log({ info, isToken });
    } catch (error) {
      setFormError('tokenInfo.token', { type: 'custom', message: 'The address is not valid' });
    }
  };

  const submitPool = async (data: XBorgPool, handleSubmit?: (data: any) => Promise<any>) => {
    // Format Claim Config
    const campaignClaimConfigJson = data.campaignClaimConfig?.toString() || '[]';
    const campaignClaimConfig: ClaimConfig[] = JSON.parse(campaignClaimConfigJson);
    const claimConfigData = campaignClaimConfig.map((item: ClaimConfig) => {
      item.startTime = item.startTime ? unixTime(item.startTime) : null;
      item.endTime = item.endTime ? unixTime(item.endTime) : null;

      const claimModel: Record<string, string | number | null | undefined> = {
        start_time: item.startTime,
        end_time: item.endTime,
        min_percent_claim: item.minPercentClaim ?? 0,
        max_percent_claim: item.maxPercentClaim,
      };

      if (item.id && item.id !== -1) {
        claimModel.id = item.id;
      }

      return claimModel;
    });

    let tokenInfo: any = {
      symbol: data?.tokenInfo.symbol,
    };

    if (data?.tokenInfo.token && networkAvailable !== NETWORK_AVAILABLE.SOL && !data.tokenInfoTBA) {
      tokenInfo = await getTokenDetail(data.tokenInfo.token);
      // check token when pool not yet deploy
      if (!poolDetail?.isDeploy) {
        if (!tokenInfo?.symbol) {
          setFormError('tokenInfo.token', {
            message: 'Could not find the specified address.',
            shouldFocus: true,
            type: 'validate',
          });
          return;
          // throw Error('Could not find the specified address.');
        }
      }

      tokenInfo.symbol = data?.tokenInfo.symbol;
    }

    let banner = data.banner.uploadedUrl;

    if (data.banner.uploadType === UploadType.FILE && !!data.banner.file) {
      banner = await uploadFile(data.banner.file);
    }

    if (data.banner.uploadType === UploadType.LINK) {
      banner = data.banner.link ?? '';
    }

    let tokenImage = data.tokenInfo.tokenImages.uploadedUrl;
    if (
      data.tokenInfo.tokenImages.uploadType === UploadType.FILE &&
      !!data.tokenInfo.tokenImages.file &&
      !data.tokenInfoTBA
    ) {
      tokenImage = await uploadFile(data.tokenInfo.tokenImages.file);
    }

    if (data.tokenInfo.tokenImages.uploadType === UploadType.LINK) {
      tokenImage = data.tokenInfo.tokenImages.link ?? '';
    }

    const requestData = {
      name: data.name,
      banner: banner,
      website: data.website,
      project_label: data.label,
      medium_link: data.mediumLink,
      twitter_link: data.twitterLink,
      telegram_link: data.telegramLink,
      network_available: data.networkAvailable,
      accept_currency: data.networkAvailable === NETWORK_AVAILABLE.TBA ? 'TBA' : data.acceptCurrency,
      kyc_bypass: !!data.kycBypass,
      facebook_link: data.facebookLink,
      linkedin_link: data.linkedinLink,
      reddit_link: data.redditLink,
      discord_link: data.discordLink,
      token: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.token,
      symbol: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.symbol,
      address_receiver: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.addressReceiver,
      total_sold_coin: data.tokenInfoTBA ? 'TBA' : data.tokenInfo.totalSoldCoin,
      token_images: data.tokenInfoTBA ? 'TBA' : tokenImage,
      token_conversion_rate: data.tokenRateTBA ? 'TBA' : data.tokenRate,
      start_join_pool_time: data.registerTimeTBA
        ? 'TBA'
        : data.startRegisterTime
        ? unixTime(data.startRegisterTime)
        : null,
      end_join_pool_time: data.registerTimeTBA
        ? 'TBA'
        : data.finishRegisterTime
        ? unixTime(data.finishRegisterTime)
        : null,
      start_time: data.buyTimeTBA ? 'TBA' : data.startTime ? unixTime(data.startTime) : null,
      finish_time: data.buyTimeTBA ? 'TBA' : data.finishTime ? unixTime(data.finishTime) : null,
      description: data.description,
      lock_schedule: data.lockSchedule,
      claim_policy: data.claimTBA ? 'TBA' : data.claimPolicy,
      registed_by: loginUser?.wallet_address,
      claim_configuration: data.claimTBA ? [] : claimConfigData,
      freeBuyTimeSetting: {
        start_time_free_buy: data.startPublicSellTime ? unixTime(data.startPublicSellTime) : null,
        end_time_free_buy: data.endPublicSellTime ? unixTime(data.endPublicSellTime) : null,
        max_bonus_free_buy: data.maxBonus ? data.maxBonus : poolDetail?.maxBonus,
      },
      is_private: PoolType.PRIVATE,
      is_display: data.isDisplay,
      is_display_live: data.isDisplayLive,
      is_qualified_user_display: data?.isQualifiedUserDisplay || 0,
      is_tba:
        data.tokenRateTBA || data.buyTimeTBA || data.tokenInfoTBA || data.networkAvailable === NETWORK_AVAILABLE.TBA,
      priority: data?.priority,
      progress_display: data?.progressDisplay,
      is_progress_display: data?.isProgressDisplay,
    };

    return handleSubmit ? await handleSubmit(requestData) : await onSubmit(requestData);
  };

  const handleFormSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response: any = await submitPool(data);
      if (response) {
        if (response?.status === 200) {
          if (isEdit) {
            dispatch(alertSuccess('Updated successfully!'));
          } else {
            dispatch(alertSuccess('Created successfully!'));
          }
          history.push(adminRoute('/projects'));
        } else {
          if (isEdit) {
            dispatch(alertFailure('Update failed'));
          } else {
            dispatch(alertFailure('Create failed'));
          }
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message));
      //  console.log("ERROR: ", e);
    } finally {
      setLoading(false);
    }
  };

  async function handleUpdateAfterDeploy(data: XBorgPool) {
    submitPool(data, async (data: any) => {
      setLoading(true);
      try {
        const updateData = {
          ...data,
          is_private: poolDetail?.isPrivate,
          network_available: poolDetail?.networkAvailable,
          accept_currency: poolDetail?.acceptCurrency,
          token: poolDetail?.tokenInfo.token,
          token_conversion_rate: poolDetail?.tokenRate,
          start_join_pool_time: unixTime(poolDetail?.startRegisterTime),
          end_join_pool_time: unixTime(poolDetail?.finishRegisterTime),
          start_time: unixTime(poolDetail?.startTime),
          finish_time: unixTime(poolDetail?.finishTime),
          address_receiver: poolDetail?.tokenInfo.addressReceiver,
        };

        const res = await onSubmit(updateData);

        if (res.status !== 200) {
          dispatch(alertFailure('Fail!'));
          return;
        }

        history.push(adminRoute('/projects'));
        dispatch(alertSuccess('Updated successfully!'));
      } catch (e: any) {
        dispatch(alertFailure(e.message));
      } finally {
        setLoading(false);
      }
    });
  }

  // Create / Update Pool (Before Pool Deployed to Smart Contract)
  const handleCreateOrUpdatePool = () => {
    // needValidate is check create , update or deploy
    setValue('needValidate', false);
    if (poolDetail?.isDeploy) {
      handleSubmit(handleUpdateAfterDeploy)();
    } else {
      handleSubmit(handleFormSubmit, (err: any) => console.log(err))();
    }
  };

  const getTokenDetail = async (token: string) => {
    const choosenNetwork = watch('networkAvailable');
    const chainId = NETWORK_AVAILABLE_CHAINID[choosenNetwork];

    if (!chainId) {
      return;
    }

    const erc20Token = await getTokenInfo(token, chainId);
    let tokenInfo: any = {};
    if (erc20Token) {
      const { name, symbol, decimals, address } = erc20Token;
      tokenInfo = { name, symbol, decimals, address };
    }
    return tokenInfo;
  };

  const onCancelDeploy = () => {
    setOpenConfirmDeploy(false);
    setValue('needValidate', false);
    return false;
  };

  const openPopupConfirmDeploy = () => {
    if (poolDetail?.isDeploy || deployed) {
      dispatch(alertFailure('Pool is deployed!'));
      return false;
    }
    setOpenConfirmDeploy(true);
  };

  // Deploy Pool And Update
  const deployPoolWithWalletMetamask = async (data: any) => {
    setLoadingDeploy(true);
    const tokenInfo = await getTokenDetail(data?.tokenInfo?.token);
    const dataDeploy = {
      token: data?.tokenInfo?.token,
      start_time: data.startTime ? unixTime(data.startTime) : null,
      finish_time: data.finishTime ? unixTime(data.finishTime) : null,
      accept_currency: data.acceptCurrency,
      tokenInfo,
      exchange_rate: data.tokenRate,
      network_available: data.networkAvailable,
      address_receiver: data?.tokenInfo?.addressReceiver,
      wallet: poolDetail?.wallet,
      id: poolDetail?.id,
    };
    try {
      // Save data before deploy
      const response = await submitPool(data);
      if (!response) {
        const error = new Error('Token Address Error');
        (error as any).code = 1001;
        throw error;
      }
      await dispatch(deployPool(dataDeploy, history));
      setLoadingDeploy(false);
      setDeployed(true);
    } catch (err: any) {
      setDeployed(false);
      setLoadingDeploy(false);
      if (err?.code !== 1001) {
        dispatch({
          type: campaignActions.MY_CAMPAIGN_CREATE_FAIL,
          payload: err.message,
        });

        dispatch({
          type: alertActions.ERROR_MESSAGE,
          payload: err.message,
        });
      }
    }
  };

  const deployPoolOnSolana = async (data: XBorgPool) => {
    setLoadingDeploy(true);
    // data deploy
    const dataDeploy = {
      token: data.tokenInfo.token,
      accept_currency: data.acceptCurrency,
      exchange_rate: data.tokenRate,
      address_receiver: data.tokenInfo.addressReceiver,
      wallet: poolDetail?.wallet,
      campaign_hash: poolDetail?.campaignHash,
      network_available: data.networkAvailable,
    };

    const { accept_currency, network_available } = dataDeploy;
    const response = await submitPool(data);
    if (!response) {
      const error = new Error('Token Address Error');
      (error as any).code = 1001;
      throw error;
    }

    // data argument pass
    const randomCampaignHash = web3.Keypair.generate().publicKey;
    const campaign_hash = dataDeploy.campaign_hash ? new PublicKey(dataDeploy.campaign_hash) : randomCampaignHash;
    const poolId = campaign_hash;
    const token = new PublicKey(dataDeploy.token);

    let paidTokenAddress = MAPPING_CURRENCY_ADDRESS[network_available]?.[accept_currency];
    if (!paidTokenAddress) {
      paidTokenAddress = NATIVE_TOKEN_ADDRESS;
    }

    const offeredCurrency = new PublicKey(paidTokenAddress);
    const DECIMAL_SOLANA = Math.pow(10, 9);
    // rate token/currentcy = x
    // example 1 IDO token = 5 x 1 currentcy => x = 5
    const tokenRate = getValues().tokenRate;

    // ( 10^ decimalsToken / 10^ decimalsCurrentcy)
    const ratioTokenWithCurrentcy = new BigNumber(Math.pow(10, decimalsToken)).dividedBy(
      Math.pow(10, decimalsCurrentcy)
    );

    // rate/10^decimal = tokenRate * ( 10^ decimalsToken / 10^ decimalsCurrentcy)
    // => rate between token currentcy by each decimals
    const actualRate = new BigNumber(DECIMAL_SOLANA)
      .multipliedBy(new BigNumber(1).div(tokenRate))
      .multipliedBy(ratioTokenWithCurrentcy);
    // upgrade 10^9
    const offeredRate = new anchor.BN(actualRate.toNumber());

    const signature = dataDeploy?.wallet?.wallet_address;
    const signer = new PublicKey(signature);
    const args = {
      poolId,
      token,
      offeredCurrency,
      offeredRate,
      decimalsCurrentcy,
      wallet: new PublicKey(dataDeploy.address_receiver!),
      signer,
    };

    const [presalePoolAccount] = await anchor.web3.PublicKey.findProgramAddress(
      [PRESALE_POOL_SEED, poolId.toBuffer()],
      program.programId
    );

    const [presalePoolTokenAccount] = await anchor.web3.PublicKey.findProgramAddress(
      [PRESALE_POOL_TOKEN_ACCOUNT_SEED, presalePoolAccount.toBuffer()],
      program.programId
    );

    const [offeredCurrencyAccount] = await anchor.web3.PublicKey.findProgramAddress(
      [OFFERED_CURRENCY_SEED, presalePoolAccount.toBuffer(), offeredCurrency.toBuffer()],
      program.programId
    );
    console.log('DATA DEPLOY ON SOLANA', {
      args,
      accounts: {
        sender: deployer.publicKey,
        presalePoolAccount,
        presalePoolTokenAccount,
        offeredCurrencyAccount,
        mint: token,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
      },
    });
    console.log('=======================================================================');

    const transaction = await program.rpc.initPresalePool(...Object.values(args), {
      accounts: {
        sender: deployer.publicKey,
        presalePoolAccount,
        presalePoolTokenAccount,
        offeredCurrencyAccount,
        mint: token,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
        tokenProgram: spl.TOKEN_PROGRAM_ID,
      },
    });
    console.log('=======================================================================');
    dispatch({
      type: alertActions.SUCCESS_MESSAGE,
      payload: 'Deployed Successfully!',
    });
    return transaction;
  };

  const handlerDeploy = async () => {
    setOpenConfirmDeploy(false);
    if (poolDetail?.networkAvailable === NETWORK_AVAILABLE.SOL) {
      try {
        const transaction = await deployPoolOnSolana(getValues());
        const randomCampaignHash = web3.Keypair.generate().publicKey;
        const existCampaignHash = poolDetail?.campaignHash ? poolDetail?.campaignHash : randomCampaignHash;
        const updateData = {
          token_decimals: 9,
          campaign_hash: existCampaignHash,
          transaction_hash: transaction,
        };
        await updateDeploySuccess(updateData, poolDetail.id);
        setLoadingDeploy(false);
        setDeployed(true);
        window.location.reload();
      } catch (err: any) {
        setLoadingDeploy(false);
        console.log('ERROR: ', err);
        if (err?.code !== 1001) {
          dispatch({
            type: campaignActions.MY_CAMPAIGN_CREATE_FAIL,
            payload: err.message,
          });
          dispatch({
            type: alertActions.ERROR_MESSAGE,
            payload: err.message,
          });
        }
      }
    } else {
      setTimeout(() => {
        handleSubmit(deployPoolWithWalletMetamask)();
      }, 100);
    }
  };

  const checkHandlerDeploy = () => {
    setValue('needValidate', true);
    handleSubmit(
      () => {
        if (listQualified?.length <= 0) {
          setError('* Button is required');
          setOpenConfirmDeploy(false);
          return;
        }

        openPopupConfirmDeploy();
        setError('');
      },
      () => {
        setValue('needValidate', false);
      }
    )();
  };

  const checkListQualified = (list: WhitelistUsers[]) => {
    setListQualified(list);
  };

  return (
    <>
      <input type="checkbox" name="needValidate" ref={register} hidden />
      <div className="contentPage">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GeneralDetail
              detail={poolDetail}
              control={control}
              errors={errors}
              setValue={setValue}
              register={register}
            />
            <SocialSetting errors={errors} control={control}>
              <ExtendSocialSetting errors={errors} control={control} />
            </SocialSetting>
            <ClaimConfigTable
              hasTBA={true}
              poolDetail={poolDetail}
              setValue={setValue}
              register={register}
              errors={errors}
              control={control}
            />
            <LockSchedule errors={errors} control={control} />
            <PublicSellSetting
              serverBonus={Number(watch('serverMaxBonus'))}
              control={control}
              errors={errors}
              poolDetail={poolDetail}
              isEdit={isEdit}
            />
            {isEdit && isDeploy && (
              <ProgressBarSetting
                detail={poolDetail}
                control={control}
                errors={errors}
                setValue={setValue}
                register={register}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <ProjectDetails control={control} detail={poolDetail} setValue={setValue} watch={watch} errors={errors} />
            <TokenInfo poolDetail={poolDetail} token={token} setToken={setToken} formMethods={form} />
            <ExchangeRate
              poolDetail={poolDetail}
              register={register}
              token={token}
              setValue={setValue}
              errors={errors}
              control={control}
              watch={watch}
              hasTBA
            />
            <RegisterTime control={control} errors={errors} poolDetail={poolDetail} />
            <BuyTime hasTBA={true} control={control} errors={errors} poolDetail={poolDetail} />
            <Priority control={control} errors={errors} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <PaperTitle>About The Project</PaperTitle>
            <Textarea
              name="description"
              control={control}
              errorMessage={errors?.description?.message}
              className={classes['description']}
              minRows={10}
            />
          </Paper>
        </Grid>

        <Grid container spacing={2}>
          {isEdit && (
            <Grid item xs={12}>
              <UserJoinPool
                error={error}
                registeredList={poolDetail?.whitelistUsers}
                campaign_id={poolDetail?.id}
                poolDetail={poolDetail}
                checkListQualified={checkListQualified}
                control={control}
              />
            </Grid>
          )}
        </Grid>
        <Grid container justifyContent={isEdit && isSuperAdmin ? 'space-between' : 'center'}>
          {isEdit && isSuperAdmin && (
            <Button
              disabled={
                !poolDetail?.id || !!poolDetail?.isDeploy || loading || loadingDeploy || deployed || !isSuperAdmin
              }
              className={
                !isEdit || poolDetail?.isDeploy || deployed ? classes.formButtonDeployed : classes.formButtonDeploy
              }
              onClick={checkHandlerDeploy}
              hidden={!isSuperAdmin}
            >
              {loadingDeploy && <CircularProgress size={25} />}
              {!loadingDeploy && 'Deploy The Project'}
            </Button>
          )}

          <Button
            disabled={loading || loadingDeploy}
            className={classes.formButtonUpdatePool}
            onClick={handleCreateOrUpdatePool}
            // style={!isSuperAdmin ? { width: '100%' } : {}}
          >
            {loading || loadingDeploy ? (
              <CircularProgress size={25} />
            ) : isEdit ? (
              'Update The Project'
            ) : (
              'Create The Project'
            )}
          </Button>
        </Grid>
        <ConfirmDialog
          open={isOpenConfirmDeploy}
          confirmLoading={false}
          onConfirm={handlerDeploy}
          onCancel={onCancelDeploy}
          buttonContent="Yes"
          className={classes.modalConfirm}
        >
          <div className={classes.contentDeploy}>
            <p>The system will store the latest pool information.</p>
            <p>Are you sure you want to deploy?</p>
          </div>
        </ConfirmDialog>
      </div>
    </>
  );
}
