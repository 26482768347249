import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { MESSAGES } from '../../constants/alert';
import { BaseRequest } from '../../request/Request';
import { alertFailure, alertSuccess } from '../../store/actions/alert';
import { SlideManageForm } from './components/Form/SlideManageForm';
import { defaultMedia } from './constants';
import { FileType, Media, MediaInfo, UploadType } from './constants/type';
import { convertMedia, fakeRequest, uploadFile } from './utils';

function EditSlider() {
  const params = useParams<{ id: string }>();
  const id = params.id;
  const dispatch = useDispatch();
  const [media, setMedia] = useState<Media>({ ...defaultMedia });

  function detectUploadFile(media: MediaInfo, rawMedia: MediaInfo) {
    const hasUploadFile = media.uploadType === UploadType.FILE && !!media.file;
    const hasUpdateLink = media.uploadType === UploadType.LINK && rawMedia.uploadedUrl !== media.link;
    const hasChangeSlideType = media.type !== rawMedia.type;

    if (hasUpdateLink || hasUploadFile || hasChangeSlideType) {
      return uploadFile(media);
    }

    return fakeRequest(rawMedia.uploadedUrl);
  }

  async function uploadFiles(data: Media) {
    const { firstSlide, secondSlide, thumbnail } = data;
    secondSlide.type = FileType.IMAGE;
    thumbnail.type = FileType.IMAGE;

    const uploadFirstSlideRequest = detectUploadFile(firstSlide, media.firstSlide);
    const uploadSecondSlideRequest = detectUploadFile(secondSlide, media.secondSlide);
    const uploadThumbnails = detectUploadFile(thumbnail, media.thumbnail);

    const results = await Promise.all([uploadFirstSlideRequest, uploadSecondSlideRequest, uploadThumbnails]);
    if (results[0].status !== 200 || results[1].status !== 200 || results[2].status !== 200) {
      throw new Error('Failed to upload file');
    }

    const firstSlideData = await results[0].json();
    const secondSlideData = await results[1].json();
    const thumbnailsData = await results[2].json();

    if (firstSlideData.status !== 200 || secondSlideData.status !== 200 || thumbnailsData.status !== 200) {
      throw new Error('Failed to upload file');
    }

    return {
      slide1_url: firstSlideData.data,
      slide1_type: firstSlide.type,
      slide1_option_upload: firstSlide.uploadType,
      slide2_url: secondSlideData.data,
      slide2_type: secondSlide.type,
      slide2_option_upload: secondSlide.uploadType,
      thumbnail_url: thumbnailsData.data,
      thumbnail_option_upload: thumbnail.uploadType,
    };
  }

  async function editMedia(data: Media) {
    try {
      const uploadFileResults = await uploadFiles(data);
      const createData: Record<string, any> = {
        id: media.id,
        ...uploadFileResults,
        project_type: data.projectType,
        project_name: data.projectName,
        project_network: data.networkAvailable,
        description: data.description,
        whitepaper: data.whitepaper,
        website: data.website,
        trailer: data.trailer,
        telegram: data.telegram,
        twitter: data.twitter,
        discord: data.discord,
        priority: data.priority
      };

      const request = new BaseRequest();
      const response = await request.put(`/admin/media/${id}`, createData);
      if (response.status !== 200) {
        throw new Error('Failed to update slider media');
      }

      const resObj = await response.json();

      if (resObj.status !== 200) {
        throw new Error(resObj.message);
      }

      dispatch(alertSuccess(MESSAGES.UPDATE_SUCCESS));
      return true;
    } catch (e: any) {
      dispatch(alertFailure(e.message));
      return false;
    }
  }

  async function getDetail(id: number | string) {
    try {
      const request = new BaseRequest();
      const response = await request.get(`/admin/media/${id}`);

      if (response.status !== 200) {
        throw new Error('Failed to get media list');
      }

      const result = await response.json();

      if (result.status !== 200) {
        throw new Error(result.message);
      }

      setMedia(convertMedia(result.data));
    } catch (e: any) {
      dispatch(alertFailure(e.message));
    }
  }

  useEffect(() => {
    getDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <DefaultLayout>
      <SlideManageForm data={media} onSubmit={editMedia} />
    </DefaultLayout>
  );
}

export default EditSlider;