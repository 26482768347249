import { mixed, number, object } from 'yup';

export const CLAIM_VALIDATION_SCHEMA = object().shape({
  startTime: mixed().required('This field is required'),
  maxPercentClaim: number()
    .typeError('This field must be number')
    .required('This field is required')
    .moreThan(0, 'This field must be greater than 0')
    .max(100, 'This field must be smaller than 100'),
});
