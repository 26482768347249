import { Control, DeepMap, FieldError } from 'react-hook-form';
import { TextInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';

interface GeneralDetailProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
}

export function GeneralDetail({ control, errors }: GeneralDetailProps) {
  return (
    <Paper>
      <TextInput
        name="projectName"
        control={control}
        label="Project Name"
        errorMessage={errors?.projectName?.message}
        isRequired={true}
      />
      <TextInput
        isRequired={true}
        name="description"
        control={control}
        label="Description"
        errorMessage={errors?.description?.message}
      />
    </Paper>
  );
}
