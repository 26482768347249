import { Control, DeepMap, FieldError } from 'react-hook-form';
import useRegisterTimeStyles from './style';
import { Checkbox, DateTimePicker } from '../../../../../../components/Base/Form';
import moment from 'moment';
import { XBorgPool } from '../../constants/pool';
import { PaperTitle } from '../../../../../../components/Base/Paper';

interface RegisterTimeProps {
  control?: Control<XBorgPool>;
  errors?: DeepMap<XBorgPool, FieldError>;
  poolDetail?: XBorgPool;
}

export function RegisterTime({ control, poolDetail, errors }: RegisterTimeProps) {
  const registerTimeClasses = useRegisterTimeStyles();
  const sectionIsTBA = control?.watchInternal('registerTimeTBA') as boolean;
  const disabledDateTime = sectionIsTBA || !!poolDetail?.isDeploy;

  return (
    <div className={registerTimeClasses.container}>
      <PaperTitle>Register Time</PaperTitle>
      <Checkbox control={control} disabled={sectionIsTBA} name="registerTimeTBA" errorMessage={errors?.registerTimeTBA?.message} label="TBA" />
      <div className={registerTimeClasses.cardContainer}>
        <DateTimePicker
          name="startRegisterTime"
          label="Start Register Time"
          control={control}
          errorMessage={errors?.startRegisterTime?.message}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={1}
          className={registerTimeClasses['time-control']}
          disabled={disabledDateTime}
        />
        <DateTimePicker
          name="finishRegisterTime"
          label="End Register Time"
          control={control}
          errorMessage={errors?.finishRegisterTime?.message}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={1}
          disabled={disabledDateTime}
        />
      </div>
    </div>
  );
}
