import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import LandingProjectDetail from './Detail/LandingPageDetail';

const LandingProjectCreate = () => {
  return (
    <DefaultLayout>
      <LandingProjectDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(LandingProjectCreate);
