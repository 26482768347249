/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../store/actions/user';
import { useDispatch } from 'react-redux';
import { useSelectedNetwork } from '../../../hooks/useSelectedNetwork';
import { useSolana } from '../../../hooks/useSolana';
import NestedList from './NestMenus';
import ConfirmDialog from '../ConfirmDialog';
import useStyles from './styles';

const NavLeft = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { isSelectedSolana } = useSelectedNetwork();
  const { logout: solanaLogout } = useSolana();
  const [isOpenLogout, setIsOpenLogout] = useState(false);

  const logoutUser = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isSelectedSolana) {
      return solanaLogout().then(() => {
        dispatch(logout());
      });
    }

    dispatch(logout());
  };

  return (
    <>
      <ConfirmDialog
        open={isOpenLogout}
        confirmLoading={false}
        onConfirm={logoutUser}
        onCancel={() => {
          setIsOpenLogout(false);
        }}
        buttonContent="Yes"
        className={styles.modalLogout}
      >
        <p className={styles.titleLogout}>Do you want to logout?</p>
      </ConfirmDialog>
      <NestedList onLogOut={() => setIsOpenLogout(true)} />
    </>
  );
};

export default withRouter(NavLeft);
