import { TableCell, TableRow } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { adminRoute } from '../../../utils';
import useStyles from './style';

export interface StepParticipateType {
  id:             number;
  title:          string;
  description:    string;
  participate_id: number | string;
  position:       null;
  created_at:     null;
  updated_at:     null;
}


const StepParticipateRow = (props: any) => {
  const {
    record,
    currentOpen,
    setCurrentOpen,
    categories,
  }: { record: StepParticipateType; currentOpen: any; setCurrentOpen: any; onReload: any; categories : any } = props;
  const classes = useStyles();
  const history = useHistory();
  const { ref, setIsVisible } = useComponentVisible();

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    setIsVisible(record?.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const goToUpdate = () => {
    history.push(adminRoute(`/step-participate-config/update/${record.id}`));
  };

  const cateName = useMemo(() => {
    const cate = categories?.find((item) => Number(item.id) === Number(record.participate_id));
    if(cate) {
      return cate.title || ''
    }
  }, [categories, record]);

  return (
    <>
      {record?.id && (
        <>
          <TableRow ref={ref} className={classes.tableRow} key={record.id}>
            <TableCell onClick={goToUpdate} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{record.title}</span>
            </TableCell>
            <TableCell onClick={goToUpdate} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{record.description}</span>
            </TableCell>
            <TableCell align="center" onClick={goToUpdate} className={classes.tableCell} component="td" scope="row">
              <span className={classes.tableCellTitle}>{cateName}</span>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default StepParticipateRow;
