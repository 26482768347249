import { BscConnector } from '@binance-chain/bsc-connector';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { APP_NETWORKS_NAME } from './network';

const METAMASK_DEEPLINK = process.env.REACT_APP_METAMASK_DEEPLINK;

export const bscConnector = new BscConnector({}) as any;
export const injected = new InjectedConnector({});

const originalChainIdChangeHandler = bscConnector.handleChainChanged;

//@ts-ignore
bscConnector.handleChainChanged = (chainId: string) => {
  const chainIdNum = Number(chainId);
  console.debug("Handling 'chainChanged' event with payload", chainId, isNaN(chainIdNum));
  if (isNaN(chainIdNum)) {
    bscConnector.emitError('NaN ChainId');
    return;
  }
  //@ts-ignore
  originalChainIdChangeHandler(chainId);
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  // iconName: string
  description: string;
  href: string | null;
  // color: string
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
  disableIcon: string;
  icon: string;
  deepLink?: string;
}

export enum ConnectorNames {
  MetaMask = 'MetaMask',
  BSC = 'BSC Wallet',
  Sollet = 'Sollet',
  Phantom = 'Phantom',
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  METAMASK: {
    connector: injected,
    name: ConnectorNames.MetaMask,
    icon: '/images/metamask-logo.png',
    disableIcon: '/images/metamask-disabled.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    mobile: true,
    deepLink: METAMASK_DEEPLINK,
  },
};

export const SUPPORTED_WALLETS_POLYGON: { [key: string]: WalletInfo } = {
  METAMASK: SUPPORTED_WALLETS.METAMASK,
};

export const SUPPORTED_WALLETS_SOLANA: { [key: string]: WalletInfo } = {
  PHANTOM: {
    connector: {} as any,
    name: ConnectorNames.Phantom,
    icon: '/images/phantom-logo.svg',
    description: 'Connect to Phantom Wallet...',
    disableIcon: '/images/phantom-logo.svg',
    href: null,
  },
  SOLLET: {
    connector: {} as any,
    name: ConnectorNames.Sollet,
    icon: '/images/sollet-logo.svg',
    description: 'Connect to Sollet Wallet...',
    disableIcon: '/images/sollet-logo.svg',
    href: null,
  },
};

export const connectorsByName: { [key: string]: AbstractConnector } = {
  [ConnectorNames.MetaMask]: injected,
};

export const connectorsSupportByNetwork: { [key: string]: { [key: string]: WalletInfo } } = {
  [APP_NETWORKS_NAME.METAMASK]: SUPPORTED_WALLETS,
  [APP_NETWORKS_NAME.POLYGON]: SUPPORTED_WALLETS_POLYGON,
  [APP_NETWORKS_NAME.SOLANA]: SUPPORTED_WALLETS_SOLANA,
  [APP_NETWORKS_NAME.BSC]: SUPPORTED_WALLETS,
};
