import React, { useEffect, useMemo, useState } from 'react';
import { useStyles, useTableStyles } from './style';

import { Tabs, Switch, Empty } from 'antd';

import Button from '../../../../../../components/Base/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { WhitelistUsers } from '../../constants/pool';
import { snapshotUser, getSnapshotList } from '../../../../../../request/pool';
import { alertFailure, alertSuccess } from '../../../../../../store/actions/alert';
import { useDispatch, useSelector } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { Controller } from 'react-hook-form';
import { formatCMR8Rule } from '../../../../../../utils/formatNumber';

const { TabPane } = Tabs;

// const convertTiers = (tier: number) => {
//   switch (tier) {
//     case 1:
//       return "Autobots";
//     case 2:
//       return "Decepticons";
//     case 4:
//       return "Maximals";
//     default:
//       return "-";
//   }
// };

const UserJoinPool = (props: any) => {
  const { registeredList, campaign_id, poolDetail, error, checkListQualified, control } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { role } = useSelector((state: any) => state.user.data);
  const [qualifiedList, setQualifiedList] = useState<WhitelistUsers[] | []>([]);
  const [currentKey, setCurrentKey] = useState<string>('1');

  const getListQualified = async () => {
    const data = await getSnapshotList(campaign_id).catch((e) => {});
    setQualifiedList(data.data);
    checkListQualified(data.data);
  };

  const snapShotUser = async () => {
    try {
      const res = await snapshotUser(campaign_id);
      if (res.status !== 200) {
        dispatch(alertFailure(res.message));
        return;
      }

      setTimeout(() => {
        getListQualified();
      }, 2000);

      dispatch(alertSuccess('Snapshotted!'));
    } catch (e: any) {
      console.log(e.any);
    }
  };

  useEffect(() => {
    if (campaign_id) {
      getListQualified();
    }
    // eslint-disable-next-line
  }, [campaign_id]);

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <label>List Users Joining The Project</label>
      </div>
      {role === 3 && (
        <Button label="Snapshot" className={classes.button} onClick={snapShotUser} disabled={!!poolDetail?.isDeploy} />
      )}
      {error && (
        <FormHelperText className={classes.error} error={true}>
          {error}
        </FormHelperText>
      )}
      <div className={classes.switchButtonField}>
        <p>Display Qualified Users</p>
        {/* <Switch size="small" /> */}
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="isQualifiedUserDisplay"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  checked={value}
                  defaultChecked={value}
                  checkedChildren="Display"
                  unCheckedChildren="Hidden"
                  onChange={onChange}
                />
              );
            }}
          />
        </FormControl>
      </div>
      <Tabs className={classes.tabs} defaultActiveKey="1" onChange={(key) => setCurrentKey(key)}>
        <TabPane tab="Registered Users" key="1">
          <UsersTable list={registeredList} currentKey={currentKey} />
        </TabPane>
        <TabPane tab="Qualified Users" key="2">
          <UsersTable list={qualifiedList} currentKey={currentKey} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserJoinPool;

const UsersTable = (props: { list: WhitelistUsers[]; currentKey: string }) => {
  const { list, currentKey } = props;

  const TABLE_HEADERS = useMemo(
    () => ['No.', 'Email', 'Wallet Address', 'Point', currentKey === '1' ? 'Register time' : 'Max Buy'],
    [currentKey]
  );

  const classes = useTableStyles();

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.root} aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {TABLE_HEADERS.map((element, index) => (
              <TableCell key={index}>{element}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.length ? (
            list.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{user?.wallet_address}</TableCell>
                <TableCell>{currentKey === '1' ? user?.tier : user?.level}</TableCell>
                <TableCell>
                  {currentKey === '1' ? user?.created_at || '-' : formatCMR8Rule(user?.pkf_balance ?? 0)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Empty />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
