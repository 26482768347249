import { UploadType, MediaInfo } from '../constants/type';
import { FileType, Media } from '../constants/type';
import { BaseRequest } from '../../../request/Request';

export function convertMedia(data: any): Media {
  return {
    id: data.id,
    projectName: data.project_name,
    networkAvailable: data.project_network,
    projectType: data.project_type,
    description: data.description,
    firstSlide: {
      type: data.slide1_type,
      link: data.slide1_option_upload === UploadType.LINK ? data.slide1_url : '',
      uploadType: data.slide1_option_upload,
      uploadedUrl: data.slide1_url,
    },
    secondSlide: {
      type: data.slide2_type,
      link: data.slide2_option_upload === UploadType.LINK ? data.slide2_url : '',
      uploadType: data.slide2_option_upload,
      uploadedUrl: data.slide2_url,
    },
    thumbnail: {
      type: FileType.IMAGE,
      uploadType: data.thumbnail_option_upload,
      link: data.thumbnail_option_upload === UploadType.LINK ? data.thumbnail_url : '',
      uploadedUrl: data.thumbnail_url,
    },
    priority: data.priority || '',
    whitepaper: data.whitepaper,
    website: data.website,
    trailer: data.trailer,
    telegram: data.telegram,
    twitter: data.twitter,
    discord: data.discord,
  };
}

export function fakeRequest(responseData: any) {
  const response: any = {
    status: 200,
    json: () =>
      Promise.resolve({
        data: responseData,
        message: '',
        status: 200,
      }),
  };
  return Promise.resolve(response);
}

export function uploadFile(media: MediaInfo): Promise<Response> {
  if (media.uploadType === UploadType.LINK || !media.file) {
    return fakeRequest(media.link);
  }

  let url = '';
  const form = new FormData();
  if (media.type === FileType.IMAGE) {
    form.append('media', media.file);
    url = '/admin/upload-image';
  } else {
    form.append('video', media.file);
    url = '/admin/upload-video';
  }

  return new BaseRequest().postImage(url, form);
}
