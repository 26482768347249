import { ETH_CHAIN_ID, BSC_CHAIN_ID, POLYGON_CHAIN_ID, SOLANA_CHAIN_ID } from './constants/network';

export const DEFAULT_LIMIT = 10;
export const API_URL_PREFIX = 'admin';
export const ADMIN_URL_PREFIX = 'dashboard';
export const IMAGE_URL_PREFIX = 'image';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const ACCEPT_CURRENCY = {
  ETH: 'eth',
  USDT: 'usdt',
  USDC: 'usdc',
};
export const BUY_TYPE = {
  WHITELIST_LOTTERY: 'whitelist',
  FCFS: 'fcfs',
};
export const NETWORK_AVAILABLE = {
  ETH: 'eth',
  BSC: 'bsc',
  SOL: 'sol',
  POLYGON: 'polygon',
  TBA: 'tba',
};
export const CARD_TYPE = {
  XBORG: 'xborg',
  OPEN: 'open',
  PARTNER: 'partner',
  ACTIVE: 'active',
};
export const STATUS_LANDING_PROJECT_AVAILABLE = {
  COMPLETED: 'Completed',
  COMING_SOON: 'Coming Soon',
  IN_PROGRESS: 'In progress',
};
export const NETWORK_AVAILABLE_CHAINID = {
  [NETWORK_AVAILABLE.BSC]: BSC_CHAIN_ID,
  [NETWORK_AVAILABLE.ETH]: ETH_CHAIN_ID,
  [NETWORK_AVAILABLE.SOL]: SOLANA_CHAIN_ID,
  [NETWORK_AVAILABLE.POLYGON]: POLYGON_CHAIN_ID,
  [NETWORK_AVAILABLE.TBA]: '',
};
export const PUBLIC_WINNER_STATUS = {
  PUBLIC: 1,
  PRIVATE: 0,
};
export const POOL_IS_PRIVATE = {
  PUBLIC: 0,
  PRIVATE: 1,
  SEED: 2,
  COMMUNITY: 3,
};

export const USDT_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_USDT_ADDRESS;
export const USDC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_USDC_ADDRESS;
export const USDT_BSC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BSC_USDT_ADDRESS;
export const USDC_BSC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BSC_USDC_ADDRESS;
export const BUSD_BSC_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_BSC_BUSD_ADDRESS;
export const USDT_POLYGON_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_POLYGON_USDT_ADDRESS;
export const USDC_POLYGON_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_POLYGON_USDC_ADDRESS;

export const ETHERSCAN_URL = process.env.REACT_APP_ETHERSCAN_BASE_URL || '';
export const BCSSCAN_URL = process.env.REACT_APP_BSCSCAN_BASE_URL || '';

export const NETWORK_ETH_NAME = process.env.REACT_APP_NETWORK_NAME;
export const NETWORK_POLYGON_NAME = process.env.REACT_APP_POLYGON_NETWORK_NAME;
export const NETWORK_SOLANA_NAME = process.env.REACT_APP_SOLANA_NETWORK_NAME;
export const NATIVE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000';

export const MAPPING_CURRENCY_ADDRESS: any = {
  eth: {
    eth: NATIVE_TOKEN_ADDRESS,
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_ADDRESS,
    usdc: USDC_ADDRESS,
  },
  bsc: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_BSC_ADDRESS,
    usdc: USDC_BSC_ADDRESS,
  },
  polygon: {
    eth: NATIVE_TOKEN_ADDRESS, // eth for native token
    native: NATIVE_TOKEN_ADDRESS,
    usdt: USDT_POLYGON_ADDRESS,
    usdc: USDC_POLYGON_ADDRESS,
  },
  sol: {
    usdt: process.env.REACT_APP_SMART_CONTRACT_SOLANA_USDT_ADDRESS,
    usdc: process.env.REACT_APP_SMART_CONTRACT_SOLANA_USDC_ADDRESS,
  },
};

export const APP_NETWORK_NAMES = {
  [ETH_CHAIN_ID]: NETWORK_ETH_NAME,
};
export const ACCEPT_NETWORKS = {
  ETH_CHAIN_ID,
  BSC_CHAIN_ID,
  POLYGON_CHAIN_ID,
};

export const CHAIN_IDS = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
  BSC_TESTNET: 97,
  BSC_MAINNET: 56,
  POLYGON_TESTNET: 80001,
  POLYGON: 137,
};
export const CHAIN_ID_NAME_MAPPING: any = {
  '1': 'Mainnet',
  '3': 'Ropsten',
  '4': 'Rinkeby',
  '5': 'Goerli',
  '42': 'Kovan',
  '97': 'BSC Testnet',
  '56': 'BSC Mainnet',
  '137': 'Polygon Mainnet',
  '80001': 'Polygon Testnet',
  '1666600000': 'Harmony Mainnet',
};
export const ETH_NETWORK_ACCEPT_CHAINS: any = {
  '1': 'Mainnet',
  '3': 'Ropsten',
  '4': 'Rinkeby',
  '5': 'Goerli',
  '42': 'Kovan',
};
export const BSC_NETWORK_ACCEPT_CHAINS: any = {
  '97': 'BSC Testnet',
  '56': 'BSC Mainnet',
};

export const POLYGON_NETWORK_ACCEPT_CHAINS: any = {
  '80001': 'Polygon Testnet',
  '137': 'Polygon Mainnet',
};

export const ETHERSCAN_BASE_URL: any = {
  '1': 'https://etherscan.io/address',
  '4': 'https://rinkeby.etherscan.io/address',
  '5': 'https://goerli.etherscan.io/address',
  '56': 'https://bscscan.com/address',
  '97': 'https://testnet.bscscan.com/address',
  '137': 'https://polygonscan.com/address',
  '80001': 'https://mumbai.polygonscan.com/address',
};

export const SOLANASCAN_BASE_URL = 'https://solscan.io/account';

export const USERS = {
  SUPER_ADMIN: 3,
  ADMIN: 1,
  USER: 2,
};

export const POOL_STATUS = {
  UPCOMING: 'upcoming',
  IN_PROGRESS: 'inprogress',
};
