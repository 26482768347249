import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme:any) => ({
    header: {
        marginBottom:30,
        fontFamily:'Open Sans',
        boxSizing:'border-box',
    },
    title : {
        fontSize:14,
        color:theme.custom.colors.blueDark,
        fontWeight:400
    },
    warning: {
        color:'blue',
        marginBottom:5,
        display:'block',
        fontSize:14,
        fontStyle:'italic'
    },
    gird : {
        flexWrap:'inherit',
    },
    fromControl: {
        border: `1px solid ${theme.custom.colors.blueDark}`,
        borderRadius:10,
        marginBottom: 40,
        padding: '20px !important',
    },
    formTextarea: {
      marginTop: 30,
    },
    textarea: {
      marginTop: 0,
    },
    input: {
        width:'100%',
        height: 50,
        border: `1px solid ${theme.custom.colors.blueDark}`,
        color:theme.custom.colors.blueDark,
        background: 'none',
        borderRadius:10,
        padding: '10px',
        fontSize:16,
        fontWeight:400,
        fontStyle:'nomal',
        '&:focus-visible': {
           outline:'none'
        }
    },
    review : {
        marginTop:25
    },
    img : {
        width:160,
        height: 160,
        borderRadius:'50%',
        marginTop:25

    },
    buttonUpdate : {
      width: '50%',
        boxShadow:'none !important',
        '&:hover':{
            opacity: '0.8'
        }       
    },
    buttonCancel : {
        boxShadow:'none !important',
        background: `${theme.custom.colors.bgButtonCancel} !important `,
        color: theme.custom.colors.blueDark,
        '&:hover':{
            background: `${theme.custom.colors.white} !important `,
        }
    },
    flexButton : {
        margin: '20px 0px',
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    formDialog: {
        width: 'auto',
    },

    titleDialog : {
        textAlign:'center',
        color: theme.custom.colors.blueDark,
        fontSize:20,
        fontWeight:600,
    },
    //formControlRate
    label: {
      marginRight:10,
    },
      formControlFlex: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      formControlLabel: {
        color: '#9A9A9A !important',
      },
      formControlIcon: {
        display: 'inline-block',
        margin: '25px 10px 0px 10px',
      },
      formControlFlexBlock: {
        width:'50%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      },
      exchangeRateTitle: {
        fontSize: 16,
        fontWeight: 600,
        color: '#363636',
      },
      formControlRate: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: 5,
        '&>input:disabled': {
          width:'100%',
          minWidth:200,
          background: '#f5f5f5',
          borderColor: '#d9d9d9 !important',
        },
      },
      exchangeRateInput: {
        width: '45%',
      },
      formInputBoxEther: {
        border: '1px solid #000000 !important',
        width: '100%',
      },
      rateInput: {
        marginTop: 0,
        marginBottom:-3,
      },
      formInputBox: {
        backgroundColor: '#DFDFFF',
        height: 50,
        borderRadius:5,
        padding:10,
        color: '#3A39BB',
        width: '100%',
      },
      formInputBoxBS: {
        backgroundColor: '#DFDFFF',
        color: '#3A39BB',
        width: '100%',
      },
      box: {
        right: 0,
        top: 0,
        width: 70,
        height: 50,
        backgroundColor: '#000000',
        fontSize: 14,
        color: 'white',
        border: 'none',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        'border-top-right-radius': 5,
        'border-bottom-right-radius': 5,
        padding: 5,
      },
      boxEther: {
        backgroundColor: '#3A39BB',
      },
}));

export default useStyles;