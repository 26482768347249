import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import ButtonLink from '../../../components/Base/ButtonLink';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import { editContract, getNftContract } from '../../../request/ntf-contract';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { useCommonStyle } from '../../../styles';
import { adminRoute } from '../../../utils';
import { NFTContract } from '../types';
import { NFTContractForm } from './components/NFTContractForm';
import { initData } from './contants';
import useStyles from './styles';

const CreateNFTContractPage = (props: any) => {
  const commonStyle = useCommonStyle();
  const styles = useStyles();
  const dispatch = useDispatch();
  const params = useParams<any>();
  const [data, setData] = useState<NFTContract>({
    ...initData,
  });

  async function retrieveContract() {
    try {
      const data = await getNftContract(params.id);

      setData({
        id: data.id,
        address: data.collection_nft,
        name: data.project_name,
        network: data.network_available,
      });
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
      setData({
        ...initData,
      });
    }
  }

  const handleSubmit = async (contract: NFTContract) => {
    try {
      await editContract(data.id, {
        id: data.id,
        name: contract.name,
        address: data.address,
        network: data.network
      });

      dispatch(alertSuccess('Updated successfully!'));
      retrieveContract();
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    }
  };

  useEffect(() => {
    retrieveContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <DefaultLayout>
      <div className={commonStyle.headPage}>
        <div className={commonStyle.headPageLeft}>
          <ButtonLink to={adminRoute('/nft-contracts')} text="Back" icon="icon-arrow-left.svg">
            <img className="icon-back" src="/images/icon-arrow-left.svg" alt="" />
            Back
          </ButtonLink>
        </div>
      </div>
      <div className="contentPage">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.boxCampaignDetail}>
              <div className={styles.headBoxCampaignDetail}>
                <h2 className={styles.titleBoxCampaignDetail}>Edit a new nft contract</h2>
              </div>
              <div className="clearfix"></div>
              <div className={styles.formShow}>
                <NFTContractForm data={data} onSubmit={handleSubmit} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </DefaultLayout>
  );
};

export default withRouter(CreateNFTContractPage);
