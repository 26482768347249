import { CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { RadioGroup, TextInput } from '../../../../components/Base/Form';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { FileType, UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { createNewSlider, updateNewSlider } from '../../../../request/completed';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { NewSliderType } from '../../NewSliderRow/NewSliderRow';

interface NewSlider extends Omit<NewSliderType, 'thumbnail_url'> {
  thumbnail_url: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

const LandingProjectForm = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: NewSlider; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, errors, watch, getValues } = useForm<NewSlider>({
    mode: 'onChange',
    defaultValues: {
      thumbnail_url: {
        uploadType: UploadType.LINK,
        link: '',
      },
      media_link_upload: '',
      project_name: '',
      description: '',
      total_raise: '',
      ave_eth: '',
      total_project: '',
      user: '',
      priority: '',
      media_link_type: FileType.IMAGE
    },
    reValidateMode: 'onChange',
    // resolver: yupResolver(),
  });

  useEffect(() => {
    if (completed) {
      reset({
        thumbnail_url: {
          link: completed.thumbnail_url,
          uploadedUrl: completed.thumbnail_url,
          uploadType: UploadType.LINK,
        },
        media_link_upload: completed.media_link_upload,
        project_name: completed.project_name,
        description: completed.description,
        total_raise: completed.total_raise,
        ave_eth: completed.ave_eth,
        total_project: completed.total_project,
        user: completed.user,
        priority: completed.priority,
        media_link_type: completed.media_link_type
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: NewSlider) => {
    setIsOpenModal(false);
    let thumbnail_url = values.thumbnail_url.link;
    setLoading(true);
    try {
      if (values.thumbnail_url.uploadType === UploadType.FILE && !!values.thumbnail_url.file) {
        thumbnail_url = await uploadFile(values.thumbnail_url.file);
      }

      if (values.thumbnail_url.uploadType === UploadType.LINK) {
        thumbnail_url = values.thumbnail_url.link ?? '';
      }

      const trimmedValue = {
        thumbnail_url,
        media_link_upload: values.media_link_upload,
        project_name: values.project_name,
        description: values.description,
        total_raise: values.total_raise,
        ave_eth: values.ave_eth,
        total_project: values.total_project,
        user: values.user,
        priority: values.priority,
        media_link_type: values.media_link_type
      };

      if (isCreate) {
        const res = await createNewSlider(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/new-sliders'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateNewSlider(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/new-sliders'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/completed')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <TextInput name="project_name" control={control} label="Project Name" isRequired={true} />
            <TextInput name="description" control={control} label="Description" isRequired={true} />
            <RadioGroup
              name={`media_link_type`}
              label={`Media Link Type`}
              control={control}
              radioData={[
                {
                  value: FileType.IMAGE,
                  label: 'Image',
                },
                {
                  value: FileType.VIDEO,
                  label: 'Video',
                },
              ]}
              onRenderRadio={(data) => (
                <FormControlLabel
                  key={data.value}
                  value={data.value}
                  label={data.label}
                  control={<Radio size="small" />}
                />
              )}
            />
            <TextInput name="media_link_upload" control={control} label="Media Url" isRequired={false} />
            <TextInput name="total_raise" control={control} label="Total raise" isRequired={false} />
            <TextInput name="ave_eth" control={control} label="Ave eth" isRequired={false} />
            <TextInput name="total_project" control={control} label="Total project" isRequired={false} />
            <TextInput name="user" control={control} label="User" isRequired={false} />
            <TextInput name="priority" control={control} label="Priority" isRequired={false} />
          </Paper>
        </Grid>

        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <label>Thumbnail</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={
                  {
                    link: completed?.thumbnail_url || '',
                    uploadedUrl: completed?.thumbnail_url || '',
                    uploadType: UploadType.LINK,
                  } as any
                }
                formName="thumbnail_url"
                errors={errors?.thumbnail_url}
                selectTooltip={
                  <>
                    Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                  </>
                }
              />
            </>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the new slide' : 'Update the new slide'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>
          Are you sure to {isCreate ? 'create this project?' : 'update the project?'}
        </p>
      </ConfirmDialog>
    </>
  );
};

export default LandingProjectForm;
