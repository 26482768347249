import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { TextInput } from '../../../../../components/Base/Form';
import useStyles from '../GeneralDetail/style';

interface ScocialSettingProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  children?: any;
}

export function NFTMultiplierScore({ control, errors, children }: ScocialSettingProps) {
  const classes = { ...useStyles() };
  const postFix = (
    <button className={`${classes.box}`}>
      <img src="/images/pen.svg" alt="pen" />
    </button>
  );

  return (
    <Paper>
      <PaperTitle>NFT Multiplier Score</PaperTitle>
      <TextInput
        name="common_score"
        control={control}
        errorMessage={errors?.common_score?.message}
        label="Common"
        postFix={postFix}
      />
      <TextInput name="rare_score" control={control} errorMessage={errors?.rare_score?.message} label="Rare" postFix={postFix} />
      <TextInput name="elite_score" control={control} errorMessage={errors?.elite_score?.message} label="Elite" postFix={postFix} />
      <TextInput
        name="special_score"
        control={control}
        errorMessage={errors?.special_score?.message}
        label="1:1"
        postFix={postFix}
      />
    </Paper>
  );
}
