import { CircularProgress, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { TextInput } from '../../../../components/Base/Form';
import { createButtonExplorer, updateButtonExplorerConfig } from '../../../../request/completed';

interface Completed {
  id: number
  button_name: string;
}

interface FormOurServiceConfigProps extends Completed {}

const FormConfigButtonLauchExplorer = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: Completed; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, getValues } = useForm<FormOurServiceConfigProps>({
    mode: 'onChange',
    defaultValues: {
      button_name: '',
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (completed) {
      reset({
        button_name: completed.button_name,
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: FormOurServiceConfigProps) => {
    setIsOpenModal(false);

    setLoading(true);

    try {
      const trimmedValue = {
        button_name: values.button_name,
      };

      if (isCreate) {
        const res = await createButtonExplorer(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/projects'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateButtonExplorerConfig(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/projects'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/projects')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={12}>
          <Paper>
            <TextInput name="button_name" control={control} label="Button Name" isRequired={true} />
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the config' : 'Update the config'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to {isCreate ? 'create this config?' : 'update the config?'}</p>
      </ConfirmDialog>
    </>
  );
};

export default FormConfigButtonLauchExplorer;
