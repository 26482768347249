import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { convertDateTimeToUnix } from '../../utils/convertDate';
import useStyles from './style';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import Button from '../../components/Base/ButtonLink';
import AdminRow from './AdminRow/AdminRow';
import { adminRoute } from '../../utils';
import SearchForm from './SearchForm';
import { getAdminList } from '../../request/admin';
import TableCommon from '../../components/TableCommon';

const tableHeaders = ['USERNAME', 'FULL NAME', 'EMAIL', 'WALLET ADDRESS', 'ROLE', 'STATUS', 'ACTION'];

const AdminList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [filter] = useState(false);
  const [currentOpen, setCurrentOpen] = useState('');
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [finishTime, setFinishTime] = useState<Date | null>(null);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(page);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const getAdminListInfo = async (currentPage: any, query: any, startTime: any, finishTime: any, filter: any) => {
    const queryParams = {
      page: currentPage,
      searchQuery: query,
    };

    try {
      setLoading(true);
      const resObject = await getAdminList(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setPage(page);
        setLastPage(lastPage);
        setAdmins(data);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  useEffect(() => {
    handleCampaignQuery(currentPage, query, startTime, finishTime, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage, query, startTime, finishTime, filter]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCampaignSearch = (event: any) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };

  const handleCampaignQuery = (
    currentPage: number,
    query?: string,
    startTime?: Date | null,
    finishTime?: Date | null,
    filter?: boolean
  ) => {
    getAdminListInfo(currentPage, query, convertDateTimeToUnix(startTime), convertDateTimeToUnix(finishTime), filter);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderAdmin = (admin: any) => {
    return (
      <AdminRow
        key={admin.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        admin={admin}
        onReload={() => {
          if (currentPage === 1) {
            handleCampaignQuery(1, query, null, null, filter);
          } else {
            setCurrentPage(1);
          }
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      admins &&
      lastPage > 1 && (
        <Pagination
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };
  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/create-admin')} text={'Create a new admin'} />
        </div>
        <SearchForm
          startTime={startTime}
          setStartTime={setStartTime}
          finishTime={finishTime}
          setFinishTime={setFinishTime}
          setCurrentPage={setCurrentPage}
          handleCampaignSearch={handleCampaignSearch}
        />
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={admins}
        onRenderContent={onRenderAdmin}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default AdminList;
