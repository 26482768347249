import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  'time-control': {
    marginRight: '40px',
  },
  exchangeRate: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '20px 25px 30px 25px',
    marginTop: 20,
  },
}));

export default useStyles;
