import FormControl from '@material-ui/core/FormControl';
import { Switch } from 'antd';
import { Controller } from 'react-hook-form';
import { withRouter } from 'react-router';
import useFormStyles from '../useFormStyles';
import useStyles from './style';

function DisplayPoolSwitch(props: any) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { control } = props;

  return (
    <div className={classes.boxToggle}>
      <label className={classes.formControlLabel}>Display</label>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="is_display_nft_stake_anarkey"
          render={(field) => {
            const { value, onChange } = field;

            return (
              <Switch
                onChange={async (switchValue) => {
                  onChange(switchValue);
                }}
                checked={value}
                checkedChildren="Display"
                unCheckedChildren="Hidden"
              />
            );
          }}
        />
      </FormControl>
      <br />
    </div>
  );
}

export default withRouter(DisplayPoolSwitch);
