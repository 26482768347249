import React, { useState, useEffect, useMemo } from 'react';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { getFeatureConfig } from '../../request/completed';
import FeatureConfigDetail from './FeatureConfigDetail/Detail/FeatureConfigDetail';
import { first } from 'lodash';

const FeatureConfig: React.FC<any> = (props: any) => {
  const [list, setList] = useState<any[]>([]);

  const completed = useMemo(() => {
    return first(list);
  }, [list]);

  const isCreate = useMemo(() => {
    return list.length === 0;
  }, [list]);

  const getList = async () => {
    try {
      const resObject = await getFeatureConfig();
      if (resObject.status === 200 && resObject.data) {
        setList(resObject.data)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <DefaultLayout>
      <FeatureConfigDetail completed={completed} isCreate={isCreate} />
    </DefaultLayout>
  );
};

export default FeatureConfig;
