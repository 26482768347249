import { TableCell, TableRow } from '@material-ui/core';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { adminRoute } from '../../../utils';
import useStyles from './style';

export interface CategoryType {
  id: number;
  title: string;
  card_type: string;
}

const CategoryRow = (props: any) => {
  const {
    record,
    currentOpen,
    setCurrentOpen,
  }: { record: CategoryType; currentOpen: any; setCurrentOpen: any; onReload: any; categories: any } = props;
  const classes = useStyles();
  const history = useHistory();
  const { ref, setIsVisible } = useComponentVisible();

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    setIsVisible(record?.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const goToUpdate = () => {
    history.push(adminRoute(`/step-participate-config/${record.id}`));
  };


  return (
    <>
      {record?.id && (
        <>
          <TableRow ref={ref} className={classes.tableRow} key={record.id}>
            <TableCell onClick={goToUpdate} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{record.title}</span>
            </TableCell>
            <TableCell onClick={goToUpdate} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle} style={{textAlign: 'center'}}>{record.card_type}</span>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default CategoryRow;
