export const retry = async (callback: Function, retryNumber: number = 3, delay = 0, onRetryFail?: Function) => {
  const errors: Error[] = [];

  async function _retry(callback, retryNumber) {
    if (retryNumber <= 0) {
      onRetryFail?.(errors);
      return;
    }

    try {
      await callback();
    } catch (e: any) {
      errors.push(e);

      setTimeout(() => {
        _retry(callback, retryNumber - 1);
      }, delay);
    }
  }

  await _retry(callback, retryNumber);
};
