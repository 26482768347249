import Skeleton from '@material-ui/lab/Skeleton';
import { withRouter } from 'react-router-dom';
import useStyles from '../styles';
import FormOurService from './OurServiceAdmin';

const OurServiceDetailPage = (props: any) => {
  const styles = useStyles();
  const { ourService, failure, isCreate } = props;

  const showOurServiceCreate = () => {
    return (
      <>
        <FormOurService ourService={ourService || {}} isCreate={isCreate} />
      </>
    );
  };

  const showOurServiceDetail = () => {
    if (ourService) {
      return (
        <>
          <FormOurService ourService={ourService} isCreate={isCreate} />
        </>
      );
    } else if (failure) {
      return <p style={{ padding: '20px', textAlign: 'center', color: 'red' }}>There is no item that does exists</p>;
    }

    return (
      <div className={styles.skeletonLoading}>
        {[...Array(10)].map((num, index) => (
          <div key={index}>
            <Skeleton className={styles.skeleton} width="100%" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.boxCampaignDetail}>
      <div className={styles.headBoxCampaignDetail}>
        <h2 className={styles.titleBoxCampaignDetail}>{isCreate ? 'Create a new OurService' : 'OurService Detail'}</h2>
      </div>
      <div className="clearfix"></div>
      <div className={styles.formShow}>{isCreate ? showOurServiceCreate() : showOurServiceDetail()}</div>
    </div>
  );
};

export default withRouter(OurServiceDetailPage);
