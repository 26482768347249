import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../../components/Base/ConfirmDialog';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { deletePartner } from '../../../request/partners';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { adminRoute } from '../../../utils';
import useStyles from './style';

const PartnerRecord = (props: any) => {
  const { partner, currentOpen, setCurrentOpen, onReload } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { ref, setIsVisible } = useComponentVisible();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  useEffect(() => {
    setIsVisible(partner.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const handleDelete = () => {
    setIsOpenModal(false);
    deletePartner(partner.id, partner).then((res) => {
      if (res.status === 200) {
        dispatch(alertSuccess('Delete successfully!'));
        onReload();
      } else {
        dispatch(alertFailure(res.message || 'Something went wrong'));
      }
    });
  };
  const PartnerDetail = () => {
    history.push(adminRoute(`/partners/update/${partner.id}`));
  };

  return (
    <>
      <TableRow ref={ref} className={classes.tableRow} key={partner.id}>
        <TableCell onClick={PartnerDetail} className={classes.tableCell} component="td">
          <span className={classes.tableCellTitle}>{partner.project_name}</span>
        </TableCell>
        <TableCell onClick={PartnerDetail} className={classes.tableCell} component="td">
          <span className={classes.tableCellTitle}>{partner.image_link}</span>
        </TableCell>
        <TableCell align="center" onClick={PartnerDetail} className={classes.tableCell} component="td" scope="row">
          <span className={classes.tableCellTitle}>{partner.order}</span>
        </TableCell>
        <TableCell align="center" className={classes.tableCell} component="td" scope="row">
          <IconButton
            aria-controls={open ? 'fade-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{
              padding: '6px 12px',
            }}
          >
            <img src="/images/icon_dot.svg" alt="delete" />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setIsOpenModal(true);
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={handleDelete}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        // className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to delete this partner?</p>
      </ConfirmDialog>
    </>
  );
};

export default PartnerRecord;
