import { ACCEPT_CURRENCY } from '../../../../../constants';
import ExchangeRateUSDTDisplay from './ExchangeRateUSDTDisplay';
import ExchangeRateETH from './ExchangeRateETH';
import ExchangeRateDisplayPriceSwitch from './ExchangeRateDisplayPriceSwitch';
import { Paper } from '../../../../../components/Base/Paper';

export function ExchangeRate(props: any) {
  const { register, setValue, errors, control, watch, poolDetail, token, hasTBA } = props;

  const acceptCurrency = watch('acceptCurrency');

  return (
    <Paper>
      {acceptCurrency === ACCEPT_CURRENCY.ETH && (
        <ExchangeRateDisplayPriceSwitch
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
        />
      )}

      <ExchangeRateETH
        poolDetail={poolDetail}
        register={register}
        token={token}
        setValue={setValue}
        errors={errors}
        control={control}
        watch={watch}
        hasTBA={hasTBA}
      />

      {acceptCurrency === ACCEPT_CURRENCY.ETH && (
        <ExchangeRateUSDTDisplay
          poolDetail={poolDetail}
          register={register}
          token={token}
          setValue={setValue}
          errors={errors}
          control={control}
          watch={watch}
        />
      )}
    </Paper>
  );
}
