import { CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { RadioGroup, TextInput } from '../../../../components/Base/Form';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { CARD_TYPE } from '../../../../constants';
import {
  createCardType, updateCardType
} from '../../../../request/completed';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { CardType } from '../../CardParticipateRow/CardParticipateRow';

interface Completed extends Omit<CardType, 'link_image'> {
  link_image: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

interface PostFixPrice {
  post_ath_price: string;
}

interface FormMatrix extends Completed, PostFixPrice {}

const CardParticipateForm = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate , id }: { completed: Completed; isCreate: boolean , id : string} = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, errors, watch, getValues } = useForm<FormMatrix>({
    mode: 'onChange',
    defaultValues: {
      link_image: {
        uploadType: UploadType.LINK,
        link: '',
      },
      title: '',
      card_type: CARD_TYPE.ACTIVE,
    },
    reValidateMode: 'onChange',
    // resolver: yupResolver(),
  });

  useEffect(() => {
    if (completed) {
      reset({
        link_image: {
          link: completed.link_image,
          uploadedUrl: completed.link_image,
          uploadType: UploadType.LINK,
        },
        title: completed.title,
        card_type: completed.card_type,
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: FormMatrix) => {
    setIsOpenModal(false);
    let link_image = values.link_image.link;
    setLoading(true);
    try {
      if (values.link_image.uploadType === UploadType.FILE && !!values.link_image.file) {
        link_image = await uploadFile(values.link_image.file);
      }

      if (values.link_image.uploadType === UploadType.LINK) {
        link_image = values.link_image.link ?? '';
      }

      const trimmedValue = {
        link_image,
        title: values.title,
        card_type: values.card_type,
      };

      if (isCreate) {
        const res = await createCardType(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/card-participate-config'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateCardType(completed.id || id, trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/card-participate-config'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/card-participate-config')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <label>Card Information</label>
            <TextInput name="title" control={control} label="Title" isRequired={true} />
          </Paper>
        </Grid>
        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Card Type"
                  control={control}
                  name="card_type"
                  radioData={[
                    {
                      value: CARD_TYPE.ACTIVE,
                      label: 'Active',
                    },
                    {
                      value: CARD_TYPE.OPEN,
                      label: 'Open',
                    },
                    {
                      value: CARD_TYPE.PARTNER,
                      label: 'Partner',
                    },
                    {
                      value: CARD_TYPE.XBORG,
                      label: 'Xborg',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel
                      key={data.value}
                      value={data.value}
                      label={data.label}
                      control={<Radio size="small" />}
                    />
                  )}
                />
              </div>
            </>
          </Paper>

          <Paper>
            <>
              <label>Link Image</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={
                  {
                    link: completed?.link_image || '',
                    uploadedUrl: completed?.link_image || '',
                    uploadType: UploadType.LINK,
                  } as any
                }
                formName="link_image"
                errors={errors?.link_image}
                selectTooltip={
                  <>
                    Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                  </>
                }
              />
            </>
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the project' : 'Update the project'}
        </Button>
      </div>
      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>
          Are you sure to {isCreate ? 'create this card?' : 'update the card?'}
        </p>
      </ConfirmDialog>
    </>
  );
};

export default CardParticipateForm;
