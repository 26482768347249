import React, { useState, useEffect, useMemo } from 'react';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { getConfigButtonExplore } from '../../request/completed';
import ConfigButtonLauchExplorerDetail from './ConfigButtonLauchExplorerDetail/Detail/ConfigButtonLauchExplorerDetail';
import { first } from 'lodash';

const ConfigButtonLauchExplorer: React.FC<any> = (props: any) => {
  const [list, setList] = useState<any[]>([]);

  const completed = useMemo(() => {
    return first(list);
  }, [list]);

  const isCreate = useMemo(() => {
    return list.length === 0;
  }, [list]);

  const getList = async () => {
    try {
      const resObject = await getConfigButtonExplore();
      if (resObject.status === 200 && resObject.data) {
        setList(resObject.data)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <DefaultLayout>
      <ConfigButtonLauchExplorerDetail completed={completed} isCreate={isCreate} />
    </DefaultLayout>
  );
};

export default ConfigButtonLauchExplorer;
