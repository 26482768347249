import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Empty } from 'antd';
import { useHistory } from 'react-router';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { adminRoute } from '../../../../../utils';
import { convertUnixTimeToDateTime } from '../../../../../utils/convertDate';
import { useTableStyles } from './style';
import { FORMAT_UTC } from '../../../../../utils/constant';

const ListRewardTable = (props: { list: any[] }) => {
  const { list } = props;
  const history = useHistory();

  const TABLE_HEADERS = [
    'No.',
    'Token Reward Name',
    'Network',
    'Currency',
    'Token Address',
    'Total Reward Amount',
    'START TIME',
    'END TIME',
    'STATUS',
  ];

  const classes = useTableStyles();

  const handleDetail = (id: number) => {
    history.push(adminRoute(`/reward/${id}`));
  };

  return (
    <Paper>
      <PaperTitle>Boost NFT collection</PaperTitle>
      <TableContainer className={classes.container}>
        <Table className={classes.root} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {TABLE_HEADERS.map((element, index) => (
                <TableCell key={index}>{element}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length ? (
              list.map((user, index) => (
                <TableRow key={user.id} className={classes.pointer} onClick={() => handleDetail(user?.id)}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{user?.token_name}</TableCell>
                  <TableCell>{user?.network}</TableCell>
                  <TableCell>{user?.currency}</TableCell>
                  <TableCell>{user?.token_address}</TableCell>
                  <TableCell>{user?.total_reward}</TableCell>
                  <TableCell>{convertUnixTimeToDateTime(user?.start_time, FORMAT_UTC)}</TableCell>
                  <TableCell>{convertUnixTimeToDateTime(user?.end_time, FORMAT_UTC)}</TableCell>
                  <TableCell>{user?.status}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <Empty />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ListRewardTable;
