import { Link } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';
import { NumberInput, TextInput } from '../../../../../components/Base/Form';
import { Checkbox } from '../../../../../components/Base/Form/Checkbox/Checkbox';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { useCommonStyle } from '../../../../../styles';
import { scanRoute } from '../../../../../utils';
import { Pool } from '../../types';
import { UploadFile } from '../../../../../components/Base/UploadFile';
import useFormStyles from '../useFormStyles';
import useStyles from './style';
import { TokenAddress } from './TokenAddress';

interface TokenInfoProps<T extends Pool> {
  poolDetail?: T;
  token: any;
  setToken: any;
  formMethods: UseFormMethods<T>;
}

export function TokenInfo<T extends Pool = Pool>({ poolDetail, token, setToken, formMethods }: TokenInfoProps<T>) {
  const { register, watch, control, errors } = formMethods;
  const classes = { ...useStyles(), ...useFormStyles() };
  const commonStyle = useCommonStyle();
  const isTBA: boolean = watch('tokenInfoTBA');
  const isDeployed = Number(poolDetail?.isDeploy) > 0;

  return (
    <Paper>
      <PaperTitle>Token Info</PaperTitle>
      <Checkbox
        control={control}
        disabled={!!poolDetail?.isDeploy}
        errorMessage={(errors?.tokenInfoTBA as any)?.message}
        name="tokenInfoTBA"
        label="TBA"
      />
      <TokenAddress
        poolDetail={poolDetail}
        token={token}
        setToken={setToken}
        disabled={isTBA}
        formMethods={formMethods}
      />
      <TextInput
        name="tokenInfo.symbol"
        control={control}
        label="Token Symbol"
        errorMessage={(errors?.tokenInfo as any)?.symbol?.message}
        isRequired={true}
        disabled={isTBA}
      />
      {poolDetail?.isDeploy ? (
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Address (Receive money)</label>
          <div className={commonStyle.boldText}>
            <Link href={scanRoute(poolDetail?.tokenInfo?.addressReceiver, poolDetail)} target={'_blank'}>
              {poolDetail?.tokenInfo.addressReceiver}
            </Link>
          </div>
          <input name="tokenInfo.addressReceiver" hidden ref={register} readOnly />
        </div>
      ) : (
        <TextInput
          name="tokenInfo.addressReceiver"
          control={control}
          label="Address (Receive money)"
          errorMessage={(errors?.tokenInfo as any)?.addressReceiver?.message}
          disabled={!!poolDetail?.isDeploy || isTBA}
          inputProps={{
            maxLength: 255,
          }}
        />
      )}
      <NumberInput
        name="tokenInfo.totalSoldCoin"
        control={control}
        label="Total Sold Coin"
        errorMessage={(errors?.tokenInfo as any)?.totalSoldCoin?.message}
        isRequired={true}
        disabled={isTBA || isDeployed}
      />
      <UploadFile
        control={control}
        watch={control?.watchInternal}
        data={poolDetail?.tokenInfo.tokenImages}
        formName="tokenInfo.tokenImages"
        errors={(errors.tokenInfo as any)?.tokenImages}
        label="Token Icon"
        disabled={isTBA || isDeployed}
        tooltip={
          <>
            Recommended ratio: 1:1 <br /> Minimum resolution: 30x30 pixel
          </>
        }
      />
    </Paper>
  );
}
