import { Button, TableCell, TableRow, Tooltip, withStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
//@ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useComponentVisible from '../../../hooks/useComponentVisible';
import DetailForm from './DeailForm';
import useStyles from './style';

type UserProps = {
  id: string;
  email: string;
  user_telegram?: string;
  wallet_address: string;
  is_kyc: number;
  address_country: string;

  tier?: number;
  staked_point?: string;
  bonus_point?: string;
  reputation_point?: string;
  total_point?: string;
  updated_at?: number | null;
};

type UserRowProps = {
  user: UserProps;
  getUserListInfo: any;
  callBackCopy: () => void;
  address: string;
};

const UserRecord: React.FC<UserRowProps> = (props: UserRowProps) => {
  const { user, getUserListInfo, callBackCopy, address } = props;
  const classes = useStyles();
  const { ref } = useComponentVisible();
  const [isOpenDetailPopup, setIsOpenDetailPopup] = useState(false);

  return (
    <>
      <DetailForm
        getUserListInfo={getUserListInfo}
        userData={user}
        isOpenDetailPopup={isOpenDetailPopup}
        setIsOpenDetailPopup={setIsOpenDetailPopup}
      />
      <TableRow ref={ref} className={classes.tableRow} key={user.id}>
        <TableCell className={classes.tableCell} align="left" style={{ maxWidth: 204 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={<p style={{ fontSize: 15 }}>{user.wallet_address}</p>}>
              <span className={`${classes.wordBreak} ${classes.walletAddress}`}>{user.wallet_address}</span>
            </Tooltip>
            {/* <span className={classes.walletAddress}>{user.wallet_address}</span> */}
            <CustomTooltip
              title={
                <p style={{ fontSize: 14, fontFamily: 'OpenSans-Regular' }}>
                  {user.wallet_address === address ? 'Copied' : 'Copy address'}
                </p>
              }
              placement="top"
              arrow
            >
              <CopyToClipboard
                onCopy={() => {
                  callBackCopy();
                }}
                text={user.wallet_address}
              >
                <Button style={{ minWidth: '20px' }}>
                  <img src="/images/icon-copy.svg" alt="" style={{ maxHeight: '18px' }} />
                </Button>
              </CopyToClipboard>
            </CustomTooltip>
          </div>
        </TableCell>
        <TableCell className={classes.tableCell} align="center">
          <>
            {user.total_point && (
              <Tooltip title={<p style={{ fontSize: 15 }}>{user.total_point}</p>}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{user.total_point}</span>
                  {/* <CopyToClipboard
                    onCopy={() => NotificationManager.success("Copied")}
                    text={user.total_point}
                  >
                    <Button style={{ minWidth: "20px" }}>
                      <img
                        src="/images/icon-copy.svg"
                        alt=""
                        style={{ maxHeight: "18px" }}
                      />
                    </Button>
                  </CopyToClipboard> */}
                </div>
              </Tooltip>
            )}
          </>
        </TableCell>

        <TableCell className={classes.tableCell} align="center">
          {user.updated_at ? moment(user.updated_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : 'null'}
        </TableCell>
      </TableRow>
    </>
  );
};

const CustomTooltip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: '#C4C4C4',
    color: 'black',
    fontSize: 14,
    borderRadius: 4,
  },
  arrow: {
    color: '#C4C4C4',
  },
}))(Tooltip);

export default UserRecord;
