/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import DefaultLayout from '../../../../components/Layout/DefaultLayout';
import { getPoolStakeDetail } from '../../../../request/admin';
import { alertFailure } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import { UploadType } from '../../../SliderManage/constants/type';
import { DataPoolAPI } from '../contants';
import { RewardStakingForm } from './RewardStakingForm';

interface RewardStakingEditProps extends RouteComponentProps {
  id: any;
}

const RewardStakingEdit: React.FC<RewardStakingEditProps> = (props: RewardStakingEditProps) => {
  const isEdit = true;
  const { id } = props;
  const dispatch = useDispatch();
  const [poolDetail, setPoolDetail] = useState<any>({});
  const [originPool, setOriginPool] = useState<any>();

  useEffect(() => {
    if (id) {
      getPool();
    }
  }, [id]);

  const convertData = (data: DataPoolAPI) => {
    let destinationData: any;

    const {
      pool_name,
      lock_period,
      common_score,
      rare_score,
      elite_score,
      special_score,
      apr,
      is_display,
      id,
      is_display_nft_stake,
      total_nft_stake,
      total_nft_stake_anarkey,
      is_display_nft_stake_anarkey,
      anarkey_lock_period
    } = data;

    destinationData = {
      ...destinationData,
      id,
      pool_name,
      banner: {
        uploadType: UploadType.LINK,
        uploadedUrl: data.banner,
        link: data.banner,
      },
      lock_period,
      anarkey_lock_period,
      common_score,
      rare_score,
      elite_score,
      special_score,
      apr,
      is_display,
      is_display_nft_stake: Number(is_display_nft_stake),
      total_nft_stake,
      is_display_nft_stake_anarkey: Number(is_display_nft_stake_anarkey),
      total_nft_stake_anarkey,
      collection_address: data.boostCollections[0].address,
      percent_booster: data.boostCollections[0].percent,
      tokenRewards: data.tokenRewards,
    };
    return destinationData;
  };

  const getPool = async () => {
    try {
      const res = await getPoolStakeDetail(id);
      if (res?.status === 200) {
        if (res?.data?.pool_detail) {
          const newData = convertData(res?.data?.pool_detail);

          setPoolDetail(newData);
          setOriginPool(res?.data?.pool_detail);
        }
      } else {
        dispatch(alertFailure('Fetch pool detail failed'));
      }
    } catch (error) {}
  };

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/projects')} />
      <RewardStakingForm isEdit={isEdit} poolDetail={poolDetail} originPool={originPool} />
    </DefaultLayout>
  );
};

export default withRouter(RewardStakingEdit);
