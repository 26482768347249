import { useCommonStyle } from '../../../../../styles';
import { scanRoute } from '../../../../../utils';
import Link from '@material-ui/core/Link';
import { useEffect, useState } from 'react';
import { getPoolContractOfSolana } from '../../../../../request/pool';
import { NETWORK_AVAILABLE } from '../../../../../constants';
import { get } from 'lodash';
import { retry } from '../../../../../utils/retry';
import useFormStyles from '../useFormStyles';

function PoolHash(props: any) {
  const classes = useFormStyles();
  const commonStyle = useCommonStyle();
  const { poolDetail } = props;

  const [tokenAddressSolana, setTokenAddressSolana] = useState<string>('');

  useEffect(() => {
    retry(getPoolContractSolana, 5, 4000);
    // eslint-disable-next-line
  }, [poolDetail]);

  const getPoolContractSolana = async () => {
    if (poolDetail && poolDetail?.id && poolDetail?.networkAvailable === NETWORK_AVAILABLE.SOL) {
      const data = await getPoolContractOfSolana(poolDetail?.id);
      const status = get(data, 'status');
      const message = get(data, 'message');
      const tokenAddress = get(data, 'data.tokenAddress');

      if (status !== 200) {
        throw new Error(message);
      }

      setTokenAddressSolana(tokenAddress);
    }
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Project Contract Address</label>
        <div className={commonStyle.boldText}>
          {!!poolDetail?.isDeploy && (
            <Link href={scanRoute(poolDetail?.campaignHash, poolDetail)} target={'_blank'}>
              {poolDetail?.networkAvailable === NETWORK_AVAILABLE.SOL ? tokenAddressSolana : poolDetail?.campaignHash}
            </Link>
          )}
          {!poolDetail?.isDeploy && '--'}
        </div>
      </div>
    </>
  );
}

export default PoolHash;
