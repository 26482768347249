import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { createPartner, updatePartner } from '../../../../request/partners';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { NumberInput, TextInput } from '../../../../components/Base/Form';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';

export const BASE_VALIDATION_SCHEMA = object({
  order: number()
    .typeError('This field must be a number')
    .required('This field must be a number')
    .integer('This field must be integer')
    .min(0, 'This field must be greater than 0'),
});

interface Partner {
  name: string;
  order: number | string;
  imgLink: {
    uploadType: UploadType;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

const FormPartner = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { partner, isCreate } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { handleSubmit, reset, control, errors, watch, getValues } = useForm<Partner>({
    mode: 'onChange',
    defaultValues: {
      order: '',
      name: '',
      imgLink: {
        uploadType: UploadType.LINK,
        link: '',
      },
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(BASE_VALIDATION_SCHEMA),
  });

  useEffect(() => {
    if (partner) {
      reset({
        name: partner.project_name,
        order: partner.order,
        imgLink: {
          link: partner.image_link,
          uploadedUrl: partner.image_link,
          uploadType: UploadType.LINK,
        },
      });
    }
  }, [partner, reset]);

  const handlerUpdate = async (values: Partner) => {
    setIsOpenModal(false);

    let banner = values.imgLink.uploadedUrl;
    setLoading(true);
    try {
      if (values.imgLink.uploadType === UploadType.FILE && !!values.imgLink.file) {
        banner = await uploadFile(values.imgLink.file);
      }

      if (values.imgLink.uploadType === UploadType.LINK) {
        banner = values.imgLink.link ?? '';
      }

      const trimmedValue = {
        project_name: values.name.toUpperCase(),
        image_link: banner,
        order: values.order,
      };

      if (isCreate) {
        const res = await createPartner(trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/partners'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updatePartner(partner.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/partners'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/partners')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <TextInput
              name="name"
              control={control}
              placeholder="Enter the project name"
              label="Project Name"
              errorMessage={errors?.name?.message}
            />
          </Paper>
          <Paper>
            <UploadFile
              control={control}
              watch={watch}
              data={{
                link: partner?.image_link || '',
                uploadedUrl: partner?.image_link || '',
                uploadType: UploadType.LINK,
              }}
              formName="imgLink"
              errors={errors?.imgLink}
              selectTooltip={
                <>
                  Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                </>
              }
            />
          </Paper>
        </Grid>

        <Grid item={true} xs={6}>
          <Paper>
            <NumberInput
              name="order"
              control={control}
              placeholder="Enter the order number"
              label="Order"
              errorMessage={errors?.order?.message}
            />
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={handleSubmit(() => setIsOpenModal(true))}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create' : 'Update'}
        </Button>
        <Button disabled={loading} className={classes.buttonCancel} href={adminRoute('/partners')}>
          Cancel
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => handlerUpdate(getValues())}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to {isCreate ? 'create this project?' : 'update the logo?'}</p>
      </ConfirmDialog>
    </>
  );
};

export default FormPartner;
