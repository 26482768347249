import { makeStyles } from '@material-ui/core';

const useFormStyles = makeStyles((theme) => ({
  formControlTitle: {
    display: 'flex',
    gap: 3,
  },
  formInputBox: {
    border: '1px solid #DFDFDF',
    padding: '13px',
    maxWidth: '100%',
    width: '100%',
    fontSize: 14,
    borderRadius: 5,
    'border-top-right-radius': 0,
    'border-bottom-right-radius': 0,

    '&:focus': {
      outline: 'none',
    },
  },
  formControl: {
    marginTop: 30,
    position: 'relative',
  },
  formControlLabel: {
    fontSize: 14,
    letterSpacing: '0.25px',
    color: '#001F4D',
  },
  formDatePicker: {
    maxWidth: 300,
    width: 300,
    marginTop: 5,
    border: '1px solid #DFDFDF',
    borderRadius: 5,
    position: 'relative',

    '& .react-date-picker__wrapper': {
      backgroundColor: 'white !important',
    },
  },
  formControlInput: {
    display: 'block',
    border: '1px solid #DFDFDF',
    width: '100%',
    padding: '13px',
    borderRadius: 5,
    marginTop: 5,
    backgroundColor: 'white',
    transition: '.1s all ease-in',

    '&:focus': {
      borderColor: '#FFCC00',
      outline: 'none',
    },
  },
  formControlRate: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: 5,
    '&>input:disabled': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9 !important',
    },
  },
  formErrorMessage: {
    marginTop: 7,
    color: 'red',
  },
  formErrorMessageAbsolute: {
    position: 'absolute',
    bottom: '-20px',
  },
  error: {
    color: 'red',
  },
  errorGet: {
    width: '45%',
    marginLeft: 20,
  },
  errorFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useFormStyles;
