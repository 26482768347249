import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import ConfigButtonLauchAppDetail from './Detail/ConfigButtonLauchAppDetail';

const ConfigButtonLauchAppCreate = () => {
  return (
    <DefaultLayout>
      <ConfigButtonLauchAppDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(ConfigButtonLauchAppCreate);
