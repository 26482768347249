import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import ButtonLink from '../../../components/Base/ButtonLink';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import { createContract } from '../../../request/ntf-contract';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { useCommonStyle } from '../../../styles';
import { adminRoute } from '../../../utils';
import { NFTContract } from '../types';
import { NFTContractForm } from './components/NFTContractForm';
import { initData } from './contants';
import useStyles from './styles';

const CreateNFTContractPage = (props: any) => {
  const commonStyle = useCommonStyle();
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (data: NFTContract) => {
    try {
      await createContract(data);

      dispatch(alertSuccess('Created successfully!'));
      history.push(adminRoute('/nft-contracts'));
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    }
  };

  return (
    <DefaultLayout>
      <div className={commonStyle.headPage}>
        <div className={commonStyle.headPageLeft}>
          <ButtonLink to={adminRoute('/nft-contracts')} text="Back" icon="icon-arrow-left.svg">
            <img className="icon-back" src="/images/icon-arrow-left.svg" alt="" />
            Back
          </ButtonLink>
        </div>
      </div>
      <div className="contentPage">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.boxCampaignDetail}>
              <div className={styles.headBoxCampaignDetail}>
                <h2 className={styles.titleBoxCampaignDetail}>{'Create a new nft contract'}</h2>
              </div>
              <div className="clearfix"></div>
              <div className={styles.formShow}>
                <NFTContractForm isCreate data={initData} onSubmit={handleSubmit} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </DefaultLayout>
  );
};

export default withRouter(CreateNFTContractPage);
