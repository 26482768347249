import { FormControl, Grid, Tooltip } from '@material-ui/core';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { HelpText } from '../RepresentData/HelpText';
import { FormLabel } from '../RepresentData/FormLabel';
import useStyles from '../styles';
import RequireSymbol from '../../RequireSymbol';
import { TextInputProps } from '../TextInput/TextInput';

interface TooltipProps extends TextInputProps {
  tooltip?: any;
}

export function FileInput({
  control,
  label,
  errorMessage,
  rules,
  className,
  onChange: userOnChange,
  value: inputValue,
  isRequired,
  tooltip,
  ...textFieldProps
}: TooltipProps) {
  const inputRef = useRef<any>();
  const styles = useStyles();
  const classes = [styles['form-control'], styles['no-border'], className].filter(Boolean).join(' ');

  return (
    <Controller
      render={({ onChange, onBlur, value }, { invalid }) => {
        const onChangeFn = userOnChange ? userOnChange : (event) => onChange(event?.target?.files[0]);

        return (
          <FormControl error={invalid} fullWidth className={classes}>
            {label && (
              <div style={{ display: 'flex', gap: 3 }}>
                <FormLabel htmlFor={textFieldProps.id}>{label}</FormLabel>
                {isRequired && <RequireSymbol />}
              </div>
            )}
            <Tooltip
              classes={{
                tooltip: styles.tooltip,
                arrow: styles.arrow,
              }}
              title={tooltip}
              placement="bottom-start"
              arrow
            >
              <Grid component="label" className={styles.fileInput} htmlFor={textFieldProps.name} container>
                <button onClick={() => inputRef?.current?.click?.()}>Choose File</button>
                <span>{value ? value.name : 'No file chosen'}</span>
              </Grid>
            </Tooltip>
            <input
              name={textFieldProps.name}
              ref={inputRef}
              id={textFieldProps.name}
              type="file"
              onChange={onChangeFn}
              onBlur={onBlur}
            />
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      rules={rules}
      name={textFieldProps.name ?? 'text-field'}
      control={control}
    />
  );
}
