/* eslint-disable react-hooks/exhaustive-deps */

import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Pagination from '@material-ui/lab/Pagination';
import { Select } from 'antd';
//@ts-ignore
import { cloneDeep, debounce, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLink from '../../components/Base/ButtonLink';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import TableCommon, { ColumnOrder } from '../../components/TableCommon';
import { POOL_STATUS, USERS } from '../../constants';
import { getCampaigns } from '../../store/actions/campaign';
import { useCommonStyle } from '../../styles';
import { adminRoute } from '../../utils';
import CampaignsRecord from './PoolsRecord';
import useStyles from './style';

interface PoolRow {
  id: number;
  name: string;
  start_time: string;
  finish_time: string;
  symbol: string;
  status: string;
}

export enum PoolType {
  PRIVATE = 1,
  COMMUNITY = 2,
  EXECUTIVE = 3,
}
const { Option } = Select;

const tableHeaders = [
  'PROJECT NAME',
  'START',
  'FINISH',
  'PROJECT TYPE',
  'TOKEN SYMBOL',
  'STATUS',
  'PRIORITY',
  'ACTION',
];

const Pools: React.FC<any> = (props: any) => {
  const commonStyle = useCommonStyle();

  const dispatch = useDispatch();

  const { page = 1, lastPage, data: campaigns } = useSelector((state: any) => state.campaigns.data);
  const { loading, failure } = useSelector((state: any) => state.campaigns);
  const { role } = useSelector((state: any) => state.user.data);

  const [filter, setFilter] = useState(role && role === USERS.ADMIN);
  const [currentOpen, setCurrentOpen] = useState('');
  const [startTime] = useState<Date | null>(null);
  const [finishTime] = useState<Date | null>(null);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(page);
  const [status, setStatus] = useState<string>('');

  const [columnsOrder, setColumnsOrder] = useState<ColumnOrder[]>([{ label: 'PRIORITY', direction: '' }]);
  const classes = useStyles({
    role,
  });

  useEffect(() => {
    const isFilter = role !== USERS.SUPER_ADMIN ? true : filter;
    const existSort = columnsOrder.filter((item) => !isEmpty(item.direction));
    if (existSort && existSort.length > 0) {
      handleCampaignQuery(currentPage, query, isFilter, status, columnsOrder);
    } else {
      handleCampaignQuery(currentPage, query, isFilter, status);
    }
  }, [dispatch, currentPage, query, startTime, finishTime, filter, status, role, columnsOrder]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCampaignSearch = (event: any) => {
    setCurrentPage(1);
    setQuery(event.target.value);
  };

  const handleCampaignQuery = (
    currentPage: number,
    query: string,
    filter: boolean,
    status?: string,
    columnsOrder?: ColumnOrder[]
  ) => {
    dispatch(getCampaigns(currentPage, query, filter, status, columnsOrder));
  };

  const delayCampaignSearch = debounce(handleCampaignSearch, 500);

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderCampaign = (data: any) => {
    return (
      <CampaignsRecord
        key={data.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        campaign={data}
        onReload={() => {
          if (currentPage === 1) {
            handleCampaignQuery(1, query, filter, status);
          } else {
            setCurrentPage(1);
          }
        }}
      />
    );
  };

  const handleChangeStatus = (value: string, option: any) => {
    setStatus(value);
    setCurrentPage(1);
  };

  const onRenderPagination = () => {
    return (
      campaigns &&
      lastPage > 1 && (
        <Pagination
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  const handlerSort = (label: string, direction: 'asc' | 'desc') => {
    const cloneColumsOrder = cloneDeep(columnsOrder);
    const filter = cloneColumsOrder.filter((item) => item.label !== label);
    filter.push({ label, direction: direction ? direction : 'desc' });
    setColumnsOrder(filter);
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left" style={{ display: 'flex' }}>
          <ButtonLink
            to={adminRoute('/projects/add')}
            text="Create XBorg project"
            className={classes.buttonCreateNew}
          />
          <ButtonLink
            to={adminRoute('/projects/community/add')}
            text="Create Community project"
            className={classes.buttonCreateNew}
          />
          <ButtonLink
            to={adminRoute('/projects/executive-lounge/add')}
            text="Create Executive project"
            className={classes.buttonCreateNew}
          />
        </div>
      </div>
      <div className={classes.refreshCampaigns}>
        <div className={classes.flexForm}>
          {role === 3 && (
            <span className={classes.refreshCampaignsContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    name="checkedIcon"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFilter(e.target.checked);
                      setCurrentPage(1);
                    }}
                  />
                }
                label={<p className={classes.refreshCampaignsText}>Filter by your own projects</p>}
              />
            </span>
          )}
          <Select className={classes.select} defaultValue="All" onChange={handleChangeStatus}>
            <Option value="">All</Option>
            <Option value={POOL_STATUS.UPCOMING}>Upcoming</Option>
            <Option value={POOL_STATUS.IN_PROGRESS}>In Progress</Option>
          </Select>
        </div>
        <div className={commonStyle.boxSearch}>
          <input className={commonStyle.inputSearch} onChange={delayCampaignSearch} placeholder="Search" />
          <img className={commonStyle.iconSearch} src="/images/icon-search.svg" alt="" />
        </div>
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={campaigns as PoolRow[]}
        onRenderContent={onRenderCampaign}
        onRenderPagination={onRenderPagination}
        columnsOrder={columnsOrder}
        onSort={handlerSort}
      />
    </DefaultLayout>
  );
};

export default Pools;
