import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => {
  return {
    boxCampaignDetail: {
      borderRadius: 10,
      marginBottom: 20,
    },
    headBoxCampaignDetail: {
      background: 'linear-gradient(90deg, #61009D 0%, rgba(90, 231, 240, 0.64) 100%)',
      boxShadow: '0px 2px 4px rgba(138, 146, 166, 0.3)',
      borderRadius: '10px 10px 0px 0px',
      height: 54,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',

      '& .campaign-active': {
        color: '#56b022',
      },
      '& .campaign-active::after': {
        backgroundColor: '#56b022',
      },
      '& .campaign-suspend': {
        color: 'red',
      },
      '& .campaign-suspend::after': {
        backgroundColor: 'red',
      },
    },
    titleBoxCampaignDetail: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '26px',
      color: '#FFFFFF',
      fontFamily: 'OpenSans-Regular',
    },
    formShow: {
      padding: '24px 50px',
      backgroundColor: 'white',
      borderRadius: 10,
      'border-top-left-radius': 0,
      'border-top-right-radius': 0,
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
    },
    buttonUpdate: {
      boxShadow: 'none !important',
      '&:hover': {
        opacity: '0.8',
      },
    },
    buttonCancel: {
      boxShadow: 'none !important',
      background: `${theme.custom.colors.bgButtonCancel} !important `,
      color: theme.custom.colors.blueDark,
      // '&:hover': {
      //   background: `${theme.custom.colors.white} !important `,
      // },
    },
    flexButton: {
      margin: '20px 0px',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    formDialog: {
      width: 'auto',
      '& .MuiPaper-root': {
        width: 400,
      },
    },
    titleDialog: {
      textAlign: 'center',
      color: theme.custom.colors.blueDark,
      fontSize: 20,
      fontWeight: 600,
    },
    iconSelect: {
      width: 400,
      '& .MuiSelect-root': {
        display: 'flex',
        padding: '10px 14px',
        minHeight: '30px',
      },
    },
  };
});

export default useStyles;
