import { number, object } from 'yup';
import { NETWORK_AVAILABLE } from '../../../../../constants';
import { UploadType } from '../../../../SliderManage/constants/type';
import { ExcutivePool } from '../types/pool';
import { BASE_VALIDATION_SCHEMA, TBA_VALIDATION } from './../../contants';

export const DEFAULT_POOL: ExcutivePool = {
  name: '',
  banner: {
    uploadType: UploadType.LINK,
    uploadedUrl: '',
  },
  website: '',
  networkAvailable: NETWORK_AVAILABLE.POLYGON,
  acceptCurrency: 'usdt',
  kycBypass: false,
  tokenInfoTBA: false,
  buyTimeTBA: false,
  claimTBA: false,
  tokenRateTBA: false,
  tokenInfo: {
    token: '',
    totalSoldCoin: '',
    tokenImages: {
      uploadType: UploadType.LINK,
      uploadedUrl: '',
    },
    symbol: '',
    addressReceiver: '',
  },
  tokenRate: 0,
  startTime: '',
  finishTime: '',
  mediumLink: '',
  twitterLink: '',
  telegramLink: '',
  facebookLink: '',
  linkedinLink: '',
  redditLink: '',
  description: '',
  lockSchedule: '',
  claimPolicy: '',
  campaignClaimConfig: [],
  campaignHash: '',
  isDisplay: 0,
  priority: '',
  nft: {
    maxAllocation: '',
    tax: '',
  },
};

export const VALIDATION_SCHEMA = object({
  ...BASE_VALIDATION_SCHEMA,
  ...TBA_VALIDATION,
  nft: object().when(['nftTBA'], {
    is: (nftTBA) => !nftTBA,
    then: object().shape({
      maxAllocation: number()
        .typeError('This field must be number')
        .required('This field is required')
        .moreThan(0, 'This field must greater than 0'),
      tax: number()
        .required('This field is required')
        .typeError('This field must be number')
        .min(0, 'This field must greater than 0')
        .max(100, 'This field must less than 100'),
    }),
    otherwise: object().shape({
      maxAllocation: number()
        .typeError('This field must be number')
        .required('This field is required')
        .moreThan(0, 'This field must greater than 0'),
      tax: number()
        .required('This field is required')
        .typeError('This field must be number')
        .min(0, 'This field must greater than 0')
        .max(100, 'This field must less than 100'),
    }),
  }),
});
