import { InputLabelProps } from "@material-ui/core";
import useStyles from "../styles";

export function FormLabel({ className, htmlFor, children }: InputLabelProps) {
  const styles = useStyles();
  const classes = [styles["form-control-label"], className]
    .filter(Boolean)
    .join(" ");

  return (
    <label className={classes} htmlFor={htmlFor}>
      {children}
    </label>
  );
}
