import { AbstractConnector } from '@web3-react/abstract-connector';
import { useWeb3React } from '@web3-react/core';
import { useWalletSignatureAsync } from '../../hooks/useWalletSignatureAsync';
import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { APP_NETWORKS_ID, ETH_CHAIN_ID, SOLANA_CHAIN_ID } from '../../constants/network';
import { ConnectorNames, connectorsByName } from '../../constants/connectors';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { NetworkUpdateType, settingAppNetwork, settingCurrentConnector } from '../../store/actions/appNetwork';
import { alertFailure } from '../../store/actions/alert';
import { logout as logoutAction, setConnectUser } from '../../store/actions/user';
import { BaseRequest } from '../../request/Request';
import { switchNetwork } from '../../utils/setupNetwork';
import { userActions } from '../../store/constants/user';
import BigNumber from 'bignumber.js';
import getAccountBalance from '../../connectors/getAccountBalance';
import { apiRoute } from '../../utils';
import { ACCESS_TOKEN_KEY } from '../../constants/authentication';
import { isValidAddress } from '../../services/web3';

interface Web3ReactLocalContextValues {
  logout: () => Promise<void>;
  balance: string | number;
  connector?: AbstractConnector;
  library: any;
  login: (account?: string) => Promise<any>;
  connectWallet: (connector: AbstractConnector, walletName: string) => Promise<any>;
  account: string;
  walletName: string;
  walletChainId?: string;
  connected: boolean;
  connecting: boolean;
  getAccountBalance: () => Promise<any>;
}

export const Web3ReactLocalContext = createContext<Web3ReactLocalContextValues>({
  balance: 0,
  library: undefined,
  login: () => Promise.resolve(false),
  logout: () => Promise.resolve(),
  connectWallet: () => Promise.resolve(false),
  account: '',
  walletName: '',
  walletChainId: ETH_CHAIN_ID,
  connected: false,
  connecting: false,
  getAccountBalance: () => Promise.resolve(true),
});

export const Web3ReactLocalProvider: FC = ({ children }) => {
  const { appChainID, walletChainID } = useTypedSelector((state) => state.appNetwork as any).data;
  const { data: user } = useTypedSelector((state) => state.user as any);
  const { account: connectedAccount, activate, active, error, deactivate, library, chainId } = useWeb3React();
  const { web3Sign } = useWalletSignatureAsync();
  const dispatch = useDispatch();

  const [currentConnector, setCurrentConnector] = useState<AbstractConnector | undefined>();
  const [balance, setBalance] = useState('0');
  const [walletName, setWalletName] = useState<string>('');
  const [connecting, setConnecting] = useState(false);
  // Flag to describe WalletConnect is changing account
  const [wcChangingAcc, setWCChangingAccount] = useState(false);

  const connectWallet = useCallback(
    async (connector: AbstractConnector, wallet: string) => {
      setConnecting(true);
      setCurrentConnector(connector);
      setWalletName(wallet);

      if (wallet === ConnectorNames.MetaMask && appChainID !== SOLANA_CHAIN_ID) {
        await switchNetwork(appChainID, wallet);
      }

      if (!connector || !wallet) {
        return false;
      }

      try {
        return await activate(connector, undefined, true).then(() => {
          dispatch(settingCurrentConnector(wallet));
        });
      } catch (error: any) {
        setWalletName('');

        throw error;
      } finally {
        setConnecting(false);
      }
    },
    [activate, dispatch, appChainID]
  );

  const clearWalletState = useCallback(() => {
    dispatch(settingCurrentConnector(undefined));
    dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));

    setWalletName('');
    setCurrentConnector(undefined);
    setWCChangingAccount(false);
  }, [dispatch]);

  const logout = useCallback(() => {
    return new Promise<void>((resolve) => {
      deactivate();
      dispatch(logoutAction());
      dispatch(setConnectUser(undefined));
      clearWalletState();
      resolve();
    });
  }, [deactivate, dispatch, clearWalletState]);

  const login = useCallback(
    async (account?: string) => {
      const baseRequest = new BaseRequest();
      const signature = await web3Sign(walletName, account);
      if (!signature) {
        return false;
      }

      const response = await baseRequest.post(apiRoute('login'), {
        signature,
        wallet_address: account ?? connectedAccount,
      });

      const resultObj = await response.json();

      if (!resultObj) {
        throw new Error('Login fail');
      }

      if (resultObj.status !== 200) {
        const error = new Error(resultObj.message);
        (error as any).status = resultObj.status;

        throw error;
      }

      const { token, user } = resultObj.data;

      localStorage.setItem(ACCESS_TOKEN_KEY, token.token);

      dispatch({
        type: userActions.USER_LOGIN_SUCCESS,
        payload: user,
      });

      dispatch(setConnectUser(account ?? connectedAccount));

      return true;
    },
    [connectedAccount, dispatch, web3Sign, walletName]
  );

  // Setup event for web3react
  useEffect(() => {
    if (!currentConnector || active || error) {
      return;
    }

    const handleWeb3ReactUpdate = (updated: any) => {
      if (!updated?.chainId) {
        return;
      }

      const chainId = Number(updated.chainId).toString();

      // Incase user select network that not support by dapp
      if (!APP_NETWORKS_ID.has(chainId.toString())) {
        return;
      }

      dispatch(settingAppNetwork(NetworkUpdateType.App, chainId.toString()));
    };

    const handleWeb3ReactError = (err: any) => {
      if (err === 'NaN ChainId') {
        dispatch(settingAppNetwork(NetworkUpdateType.Wallet, undefined));
      }
    };

    const handleWeb3ReactDisconnect = () => {
      clearWalletState();
    };

    currentConnector.on('Web3ReactUpdate', handleWeb3ReactUpdate);
    currentConnector.on('Web3ReactError', handleWeb3ReactError);
    currentConnector.on('Web3ReactDeactivate', handleWeb3ReactDisconnect);

    return () => {
      if (currentConnector && currentConnector.removeListener && active) {
        currentConnector.removeListener('Web3ReactUpdate', handleWeb3ReactUpdate);
        currentConnector.removeListener('Web3ReactError', handleWeb3ReactError);
        currentConnector.removeListener('Web3ReactDeactivate', handleWeb3ReactDisconnect);
      }
    };
  }, [
    currentConnector,
    connectedAccount,
    active,
    appChainID,
    dispatch,
    error,
    clearWalletState,
    walletName,
    connectWallet,
    login,
    logout,
  ]);

  // Handle user change account on WalletConnect
  useEffect(() => {
    if (!active || !connectedAccount || !library || !wcChangingAcc) {
      return;
    }

    // Relogin user
    login(connectedAccount).finally(() => {
      setWCChangingAccount(false);
    });
  }, [connectedAccount, active, library, wcChangingAcc, login, walletName]);

  const getAccountDetails = useCallback(async () => {
    if (!active || !appChainID || !connectedAccount) {
      return;
    }

    // User choosen network not match with current network in wallet
    if (appChainID !== walletChainID) {
      setBalance(new BigNumber(0).toFixed(5));
      return;
    }

    const accountBalance = await getAccountBalance(
      appChainID,
      walletChainID,
      connectedAccount as string,
      walletName
    ).catch((error) => {
      dispatch(alertFailure(error.message));
      if (error.code === 'NETWORK_ERROR') {
        logout();
      }
    });

    if (!accountBalance) {
      setBalance(new BigNumber(0).toFixed(5));
      return;
    }

    const userBalance = new BigNumber(accountBalance._hex).div(new BigNumber(10).pow(18)).toFixed(5);

    setBalance(userBalance);
  }, [connectedAccount, appChainID, active, walletChainID, dispatch, walletName, logout]);

  // Get account balance when change network
  useEffect(() => {
    getAccountDetails();
  }, [getAccountDetails, chainId]);

  // Check and init wallet connect
  useEffect(() => {
    if (!user) {
      logout();
      return;
    }

    if (!isValidAddress(user.wallet_address)) {
      return;
    }

    connectWallet(connectorsByName[ConnectorNames.MetaMask], ConnectorNames.MetaMask).catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Switch network when appChainId change
  useEffect(() => {
    if (appChainID === SOLANA_CHAIN_ID || !active || !walletName) {
      return;
    }

    switchNetwork(appChainID, walletName).catch((e) => {
      const isSupportedChain = APP_NETWORKS_ID.has(chainId?.toString() ?? '');
      const updateChainId = isSupportedChain ? chainId?.toString() : ETH_CHAIN_ID;

      dispatch(settingAppNetwork(NetworkUpdateType.App, updateChainId));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appChainID]);

  // Update network chainId of wallet
  useEffect(() => {
    chainId && dispatch(settingAppNetwork(NetworkUpdateType.Wallet, chainId.toString()));
  }, [chainId, dispatch]);

  return (
    <Web3ReactLocalContext.Provider
      value={{
        balance,
        login,
        logout,
        connectWallet,
        connector: currentConnector,
        library,
        account: connectedAccount ?? '',
        walletName,
        connected: active,
        connecting,
        walletChainId: chainId?.toString(),
        getAccountBalance: getAccountDetails,
      }}
    >
      {children}
    </Web3ReactLocalContext.Provider>
  );
};
