import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginTop: '20px',
    '& .MuiFormControl-root': {
      flexDirection: 'row',
    },
  },
  box: {
    position: 'absolute',
    right: 0,
    top: 27,
    width: 55,
    height: 50,
    backgroundColor: '#001F4C',
    fontSize: 14,
    color: 'white',
    border: 'none',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    'border-top-right-radius': 5,
    'border-bottom-right-radius': 5,
    padding: 5,
  },
  box1: {
    position: 'absolute',
    right: 0,
    top: 22,
    width: 55,
    height: 50,
    backgroundColor: '#001F4C',
    fontSize: 14,
    color: 'white',
    border: 'none',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    'border-top-right-radius': 5,
    'border-bottom-right-radius': 5,
    padding: 5,
  },
}));

export default useStyles;
