import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import FormConfigButtonLauchApp from './FormConfigButtonLauchApp';

const ConfigButtonLauchAppDetail = (props: any) => {
  const { completed, isCreate } = props;

  const showCompletedCreate = () => {
    return <FormConfigButtonLauchApp completed={completed} isCreate={isCreate} />;
  };

  const showCompletedDetail = () => {
    if (!_.isEmpty(completed)) {
      return <FormConfigButtonLauchApp completed={completed} isCreate={isCreate} />;
    }
    return <p>Loading...</p>;
  };

  return <div>{isCreate ? showCompletedCreate() : showCompletedDetail()}</div>;
};

export default withRouter(ConfigButtonLauchAppDetail);
