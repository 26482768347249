import { IconButton, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../../components/Base/ConfirmDialog';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { deleteLanding } from '../../../request/completed';
import { alertFailure, alertSuccess } from '../../../store/actions/alert';
import { adminRoute } from '../../../utils';
import useStyles from './style';


export interface LandingProject {
  id: number;
  project_name: string;
  project_network: string;
  project_status: string;
  banner: string;
  trailer_link: string;
  description: string;
  pool_id: string;
  priority: string;
  is_display: number;
  created_at: string;
  updated_at: string;
}

const LandingProjectRow = (props: any) => {
  const {
    landingProject,
    currentOpen,
    setCurrentOpen,
    onReload,
  }: { landingProject: LandingProject; currentOpen: any; setCurrentOpen: any; onReload: any } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { ref, setIsVisible } = useComponentVisible();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    currentOpen && setCurrentOpen('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingProject]);

  useEffect(() => {
    setIsVisible(landingProject?.id === currentOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpen]);

  const handleDelete = () => {
    setIsOpenModal(false);
    deleteLanding(landingProject.id, landingProject).then((res) => {
      if (res.status === 200) {
        dispatch(alertSuccess('Delete successfully!'));
        onReload();
      } else {
        dispatch(alertFailure(res.message || 'Something went wrong'));
      }
    });
  };
  const PartnerDetail = () => {
    history.push(adminRoute(`/landing-projects/update/${landingProject.id}`));
  };

  return (
    <>
      {landingProject?.id && (
        <>
          <TableRow ref={ref} className={classes.tableRow} key={landingProject.id}>
            <TableCell onClick={PartnerDetail} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{landingProject.project_name}</span>
            </TableCell>
            <TableCell onClick={PartnerDetail} className={classes.tableCell} component="td">
              <span className={classes.tableCellTitle}>{landingProject.description}</span>
            </TableCell>
            <TableCell align="left" onClick={PartnerDetail} className={classes.tableCell} component="td" scope="row">
              <span className={classes.tableCellTitle}>{landingProject.project_network}</span>
            </TableCell>
            <TableCell align="left" onClick={PartnerDetail} className={classes.tableCell} component="td" scope="row">
              <span className={classes.tableCellTitle}>{landingProject.project_status}</span>
            </TableCell>
            <TableCell align="center" className={classes.tableCell} component="td" scope="row">
              <IconButton
                aria-controls={open ? 'fade-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{
                  padding: '6px 12px',
                }}
              >
                <img src="/images/icon_dot.svg" alt="delete" />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setIsOpenModal(true);
                    handleClose();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </TableCell>
          </TableRow>
          <ConfirmDialog
            open={isOpenModal}
            confirmLoading={false}
            onConfirm={handleDelete}
            onCancel={() => {
              setIsOpenModal(false);
            }}
            buttonContent="Yes"
          >
            <p className={classes.titleDialog}>Are you sure to delete this project?</p>
          </ConfirmDialog>
        </>
      )}
    </>
  );
};

export default LandingProjectRow;
