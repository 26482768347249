import { Tooltip } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { HelpText, NumberInput } from '../../../../components/Base/Form';
import { PaperTitle } from '../../../../components/Base/Paper';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { ACCEPT_CURRENCY } from '../../../../constants';
import { getIconCurrencyUsdt } from '../../../../utils/usdt';
import useStyles from '../../../Pools/Forms/components/ExchangeRate/style';
import useFormStyles from '../../../Pools/Forms/components/useFormStyles';

function NewExchangeRate(props: any) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { control, setValue, errors, watch, poolDetail, token, nameCurrency, exchangeRate, isDisable } = props;

  const path = `exchange_rate`;

  useEffect(() => {
    if (poolDetail) {
      if (poolDetail.accept_currency === ACCEPT_CURRENCY.ETH) {
        setValue('tokenRate', poolDetail.ether_conversion_rate);
      } else {
        // poolDetail.ether_conversion_rate
        setValue('tokenRate', poolDetail.tokenRate || 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolDetail]);

  const acceptCurrency = watch(`${nameCurrency}`);
  const networkAvailable = watch('networkAvailable');
  const nameTokenLoaded = isEmpty(token) ? '' : token?.symbol;

  let { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: acceptCurrency,
    networkAvailable: networkAvailable,
  });

  return (
    <>
      <div className={classes.formControlTitle}>
        <PaperTitle>Exchange Rates</PaperTitle>
        <RequireSymbol />
      </div>
      <div className={classes.formControlFlex}>
        <div className={`${classes.formControlFlexBlock} ${classes.exchangeRateInput}`}>
          <label className={`${classes.formControlLabel}`}>You have</label>

          <div className={classes.formControlRate}>
            <input
              type="number"
              name="ethFor"
              disabled={true}
              value={1}
              className={`${classes.formInputBox} ${classes.formInputBoxEther}`}
            />
            <Tooltip title={nameTokenLoaded || ''}>
              <button className={classes.box}>{nameTokenLoaded || ''}</button>
            </Tooltip>
          </div>
        </div>
        <img className={classes.formControlIcon} src="/images/icon-exchange.svg" alt="" />
        <div className={`${classes.formControlFlexBlock} ${classes.exchangeRateInput}`}>
          <label className={`${classes.formControlLabel}`}>You get</label>
          <div className={classes.formControlRate}>
            <NumberInput
              control={control}
              decimalsLimit={8}
              maxLength={25}
              className={classes.rateInput}
              currencyInputClass={`${classes.formInputBox} ${classes.formInputBoxBS}`}
              disabled={isDisable}
              name={exchangeRate}
            />
            <Tooltip title={currencyName}>
              <button className={`${classes.box} ${classes.boxEther}`}>{currencyName}</button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={classes.errorFlex}>
        <div></div>
        <div className={classes.errorGet}>
          <HelpText>
            <span className={classes.error}>{get(errors, `${path}.message`)}</span>
          </HelpText>
        </div>
      </div>
    </>
  );
}

export default NewExchangeRate;
