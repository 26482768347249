export const ETH_CHAIN_ID = process.env.REACT_APP_NETWORK_ID as string;
export const BSC_CHAIN_ID = process.env.REACT_APP_BSC_NETWORK_ID as string;
export const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_NETWORK_ID as string;
export const SOLANA_CHAIN_ID = process.env.REACT_APP_SOLANA_CHAIN_ID as string;

export const ETH_RPC_URL = process.env.REACT_APP_NETWORK_URL || '';
export const BSC_RPC_URL = process.env.REACT_APP_BSC_RPC_URL || '';
export const POLYGON_RPC_URL = process.env.REACT_APP_POLYGON_RPC_URL || '';

export const USDT_ADDRESS = process.env.REACT_APP_USDT_SMART_CONTRACT;
export const USDC_ADDRESS = process.env.REACT_APP_USDC_SMART_CONTRACT;

export const USDC_POLYGON_ADDRESS = process.env.REACT_APP_USDC_POLYGON_SMART_CONTRACT;
export const USDT_POLYGON_ADDRESS = process.env.REACT_APP_USDT_POLYGON_SMART_CONTRACT;

export const USDC_BSC_ADDRESS = process.env.REACT_APP_USDC_BSC_SMART_CONTRACT;
export const USDT_BSC_ADDRESS = process.env.REACT_APP_USDT_BSC_SMART_CONTRACT;
export const BUSD_BSC_ADDRESS = process.env.REACT_APP_BUSD_BSC_SMART_CONTRACT;

export const ETHERSCAN_URL = process.env.REACT_APP_ETHER_SCAN_BASE_URL || '';
export const BCSSCAN_URL = process.env.REACT_APP_BSC_SCAN_BASE_URL || '';
export const POLYGONSCAN_URL = process.env.REACT_APP_POL_SCAN_BASE_URL || '';

export const REWARD_DISTRIBUTOR_BSC = process.env.REACT_APP_REWARD_DISTRIBUTOR_BSC || '';
export const REWARD_DISTRIBUTOR_ETH = process.env.REACT_APP_REWARD_DISTRIBUTOR_ETH || '';
export const REWARD_DISTRIBUTOR_POLYGON = process.env.REACT_APP_REWARD_DISTRIBUTOR_POLYGON || '';

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  BSC_TESTNET = 97,
  BSC_MAINNET = 56,
  POLYGON_MAINNET = 137,
  MUMBAI_POLYGON_TESTNET = 80001,
}

export type chainId = Extract<
  ChainId,
  | ChainId.MAINNET
  | ChainId.ROPSTEN
  | ChainId.RINKEBY
  | ChainId.GOERLI
  | ChainId.KOVAN
  | ChainId.BSC_MAINNET
  | ChainId.BSC_TESTNET
  | ChainId.POLYGON_MAINNET
  | ChainId.MUMBAI_POLYGON_TESTNET
>;

export const ChainIdNameMapping: { [key in ChainId]: string } = {
  [ChainId.MAINNET]: 'Mainnet',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GOERLI]: 'Goerli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.BSC_MAINNET]: 'BSC Mainnet',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.POLYGON_MAINNET]: 'Polygon Mainnet',
  [ChainId.MUMBAI_POLYGON_TESTNET]: 'Mumbai Testnet',
};

export const NETWORK_NAME_MAPPINGS: any = {
  '1': 'Mainnet',
  '3': 'Ropsten',
  '5': 'Goerli',
  '42': 'Kovan',
  '4': 'Rinkeby',
  '56': 'BSC Mainnet',
  '97': 'BSC Testnet',
  '137': 'Polygon Mainnet',
  '80001': 'Mumbai Testnet',
};

export interface NetworkInfo {
  name: string;
  id?: string | undefined;
  icon: string;
  disableIcon: string;
  currency?: string;
  [k: string]: any;
}

export enum APP_NETWORKS_NAME {
  METAMASK = 'METAMASK',
  BSC = 'BSC',
  POLYGON = 'POLYGON',
  SOLANA = 'SOLANA',
}

export type appNetworkType = Extract<APP_NETWORKS_NAME, APP_NETWORKS_NAME.METAMASK | APP_NETWORKS_NAME.POLYGON>;

export const APP_NETWORKS: { [key in APP_NETWORKS_NAME]: NetworkInfo } = {
  [APP_NETWORKS_NAME.METAMASK]: {
    name: 'Ethereum',
    id: ETH_CHAIN_ID,
    icon: '/images/ethereum.svg',
    disableIcon: '/images/ethereum-disabled.png',
  },
  [APP_NETWORKS_NAME.BSC]: {
    name: 'BSC',
    id: BSC_CHAIN_ID,
    icon: '/images/bsc.svg',
    disableIcon: '/images/binance-disabled.png',
  },
  [APP_NETWORKS_NAME.POLYGON]: {
    name: 'Polygon',
    id: POLYGON_CHAIN_ID,
    icon: '/images/polygon.svg',
    disableIcon: '/images/polygon-matic-disabled.svg',
  },
  [APP_NETWORKS_NAME.SOLANA]: {
    name: 'Solana',
    id: SOLANA_CHAIN_ID,
    icon: '/images/solana-sol-logo.svg',
    disableIcon: '/images/solana-disabled.svg',
  },
};

export const APP_NETWORKS_ID = new Set([ETH_CHAIN_ID, BSC_CHAIN_ID, POLYGON_CHAIN_ID, BSC_CHAIN_ID]);

export const NETWORK_ETH_NAME = process.env.REACT_APP_NETWORK_ETH_NAME;
export const NETWORK_BSC_NAME = process.env.REACT_APP_NETWORK_BSC_NAME;
export const NETWORK_POLYGON_NAME = process.env.REACT_APP_NETWORK_POLYGON_NAME;

export const appNetwork: { [key: string]: string } = {
  [ETH_CHAIN_ID]: NETWORK_ETH_NAME as string,
  [BSC_CHAIN_ID]: NETWORK_BSC_NAME as string,
  [POLYGON_CHAIN_ID]: NETWORK_POLYGON_NAME as string,
};

export const APP_NETWORKS_SUPPORT: { [key: number]: NetworkInfo } = {
  [ETH_CHAIN_ID]: {
    name: 'Ethereum',
    id: ETH_CHAIN_ID,
    icon: '/images/ethereum.svg',
    disableIcon: '/images/ethereum-disabled.png',
    currency: 'ETH',
    networkName: NETWORK_NAME_MAPPINGS[ETH_CHAIN_ID],
    details: {
      chainId: `0x${(+ETH_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[ETH_CHAIN_ID],
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [],
      blockExplorerUrls: [],
    },
  },
  [BSC_CHAIN_ID]: {
    name: 'BSC Mainnet',
    id: BSC_CHAIN_ID,
    icon: '/images/bsc.svg',
    disableIcon: '/images/binance-disabled.png',
    currency: 'BNB',
    networkName: NETWORK_NAME_MAPPINGS[BSC_CHAIN_ID],
    details: {
      chainId: `0x${(+BSC_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[BSC_CHAIN_ID],
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: [BSC_RPC_URL],
      blockExplorerUrls: BCSSCAN_URL ? [BCSSCAN_URL] : null,
    },
  },
  [POLYGON_CHAIN_ID]: {
    name: 'Polygon',
    id: POLYGON_CHAIN_ID,
    icon: '/images/polygon-matic.svg',
    disableIcon: '/images/polygon-matic-disabled.svg',
    currency: 'MATIC',
    networkName: NETWORK_NAME_MAPPINGS[POLYGON_CHAIN_ID],
    details: {
      chainId: `0x${(+POLYGON_CHAIN_ID).toString(16)}`,
      chainName: NETWORK_NAME_MAPPINGS[POLYGON_CHAIN_ID],
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [POLYGON_RPC_URL],
      blockExplorerUrls: POLYGONSCAN_URL ? [POLYGONSCAN_URL] : null,
    },
  },
  [SOLANA_CHAIN_ID]: {
    name: 'Solana',
    id: SOLANA_CHAIN_ID,
    icon: '/images/solana-sol-logo.svg',
    disableIcon: '/images/solana-disabled.svg',
    currency: 'SOL',
    networkName: '',
    details: {
      chainId: ``,
      chainName: '',
      nativeCurrency: {
        name: 'SOL',
        symbol: 'SOL',
        decimals: 18,
      },
      rpcUrls: [''],
      blockExplorerUrls: [''],
    },
  },
};

export const RPC_URLS = {
  [POLYGON_CHAIN_ID]: POLYGON_RPC_URL,
  [BSC_CHAIN_ID]: BSC_RPC_URL,
  [ETH_CHAIN_ID]: ETH_RPC_URL,
};

export const JSON_RPC_CONNECTION = process.env.REACT_APP_SONALA_NETWORK || '';
export const WS_RPC_CONNECTION = process.env.REACT_APP_SOLANA_WS_URL || '';
export const SOLANA_PRESALE_FACTORY_ADDRESS = process.env.REACT_APP_SMART_CONTRACT_SONALA_PRESALE_FACTORY_ADDRESS || '';
export const SOLANA_PRESALE_FACTORY_ADDRESS_TAX =
  process.env.REACT_APP_SMART_CONTRACT_SOLANA_TAX_PRESALE_FACTORY_ADDRESS || '';
