/* eslint-disable react-hooks/exhaustive-deps */

import Pagination from '@material-ui/lab/Pagination';
import React, { useState, useEffect } from 'react';
import Button from '../../components/Base/ButtonLink';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import TableCommon from '../../components/TableCommon';
import { getListCard, getListStepParticipate } from '../../request/completed';
import { adminRoute } from '../../utils';
import useStyles from './style';
import StepParticipateRow, { StepParticipateType } from './StepParticipateRow/StepParticipateRow';

const tableHeaders = ['TITLE', 'DESCRIPTION', 'CARD TYPE'];

const StepParticipate: React.FC<any> = (props: any) => {
  const { match } = props;
  const id = match.params.id;
  const limit = 8;
  const classes = useStyles();
  const [list, setList] = useState<StepParticipateType[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [failure, setFailure] = useState<boolean>(false);
  const [currentOpen, setCurrentOpen] = useState('');
  const [lastPage, setLastPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(page);
  const [query] = useState('');
  const [categories, setCategories] = useState<any[]>([]);

  const getList = async (currentPage: any, query: any) => {
    const queryParams = {
      page: currentPage,
      limit: limit,
      name: query,
    };

    try {
      setLoading(true);
      const resObject = await getListStepParticipate(queryParams, id);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setList(data);
        setPage(page);
        setLastPage(lastPage);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getList(currentPage, query);
  }, [currentPage, query]);

  useEffect(() => {
    fetchListCard();
  }, []);

  const fetchListCard = async () => {
    try {
      const res = await getListCard();
      if (res.data) {
        setCategories(res.data);
      }
    } catch (error) {}
  };

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderLandingProject = (record: StepParticipateType) => {
    return (
      <StepParticipateRow
        key={record.id}
        currentOpen={currentOpen}
        categories={categories}
        setCurrentOpen={handleCurrentOpenSet}
        record={record}
        onReload={() => {
          if (currentPage === 1) {
            getList(1, query);
          } else {
            setCurrentPage(1);
          }
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      list &&
      lastPage > 1 && (
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/step-participate-config/add')} text={'Create new step'} />
        </div>
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={list}
        onRenderContent={onRenderLandingProject}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default StepParticipate;
