import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import ProjectMatrixConfigDetail from './Detail/ProjectMatrixConfigDetail';

const ProjectMatrixConfigCreate = () => {
  return (
    <DefaultLayout>
      <ProjectMatrixConfigDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(ProjectMatrixConfigCreate);
