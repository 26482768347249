import { CircularProgress, Tooltip } from '@material-ui/core';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldName, UseFormMethods } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NETWORK_AVAILABLE_CHAINID } from '../../../../../constants';
import { APP_NETWORKS_ID, SOLANA_CHAIN_ID } from '../../../../../constants/network';
import { isValidAddress } from '../../../../../services/web3';
import { getTokenInfo } from '../../../../../utils/token';
import { Pool } from '../../types';
import useFormStyles from '../useFormStyles';
import useStyles from './style';

interface TokenAddressProps<T extends Pool> {
  poolDetail?: T;
  token: any;
  setToken: any;
  disabled?: boolean;
  formMethods: UseFormMethods<T>;
}

export function TokenAddress<T extends Pool = Pool>(props: TokenAddressProps<T>) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { appChainID: appChainId } = useSelector((state: any) => state.appNetwork).data;
  const [loadingToken, setLoadingToken] = useState(false);
  const { poolDetail, token, setToken, disabled, formMethods } = props;
  const { errors, watch, register, setError, clearErrors, getValues } = formMethods;
  const isDeployed = !!poolDetail?.isDeploy;
  const tokenInfoError = errors?.tokenInfo ?? {};
  const network = watch<string, string>('networkAvailable') as string;
  const choosenNetwork = NETWORK_AVAILABLE_CHAINID[network];

  useEffect(() => {
    if (poolDetail?.tokenInfoTBA || !choosenNetwork) {
      setToken(null);
      return;
    }

    const timer = setTimeout(() => {
      const inputToken = getValues<string, string>('tokenInfo.token') as string;
      loadingTokenData(inputToken);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appChainId, choosenNetwork, poolDetail]);

  const loadingTokenData = async (tokenValue: string) => {
    if (
      !isValidAddress(tokenValue) ||
      choosenNetwork === SOLANA_CHAIN_ID ||
      tokenValue === 'TBA' ||
      !tokenValue?.trim?.() ||
      !choosenNetwork
    ) {
      setLoadingToken(false);
      setToken(null);
      return;
    }

    try {
      setToken(null);
      setLoadingToken(true);
      const erc20Token = await getTokenInfo(tokenValue, choosenNetwork);
      if (!erc20Token) {
        throw new Error('Could not find the specified address.');
      }

      const { name, symbol, decimals, address } = erc20Token;
      setLoadingToken(false);
      setToken({
        name,
        symbol,
        decimals,
        address,
      });
      clearErrors('tokenInfo.token' as FieldName<T>);
    } catch (err: any) {
      setError('tokenInfo.token' as FieldName<T>, {
        message: err.message,
        shouldFocus: true,
        type: 'validate',
      });
    } finally {
      setLoadingToken(false);
    }
  };

  const handleTokenGetInfo = debounce(async (e: any) => {
    if (APP_NETWORKS_ID.has(appChainId)) {
      await loadingTokenData(e.target.value);
    }
  }, 500);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Token Address&nbsp;</label>
        <div className={classes.formControlInputLoading}>
          <input
            type="text"
            maxLength={255}
            ref={register}
            onChange={handleTokenGetInfo}
            name="tokenInfo.token"
            className={classes.formControlInput}
            readOnly={isDeployed || disabled}
          />
          {loadingToken ? (
            <div className={classes.circularProgress}>
              <CircularProgress size={25} />
            </div>
          ) : tokenInfoError?.['token'] &&
            (tokenInfoError?.['token'].type === 'tokenAlreadyUsed' ||
              tokenInfoError?.['token'].type === 'invalidToken') ? (
            <img src="/images/icon_close.svg" className={classes.loadingTokenIcon} alt="close-icon" />
          ) : (
            token && <img src="/images/icon_check.svg" className={classes.loadingTokenIcon} alt="close-icon" />
          )}
        </div>
        <p className={`${classes.formErrorMessage}`}>{(tokenInfoError as any)?.token?.message}</p>
      </div>
      {token && (
        <div className={classes.tokenInfo}>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token</span>
            <div className="tokenInfoContent">
              <img src="/images/eth.svg" alt="erc20" />
              <Tooltip title={<p style={{ fontSize: 15 }}>{token.name}</p>}>
                <p className="tokenInfoText wordBreak">{`${token.name}`}</p>
              </Tooltip>
            </div>
          </div>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token Symbol</span>
            <div className="tokenInfoContent">
              <Tooltip title={<p style={{ fontSize: 15 }}>{token.symbol}</p>}>
                <p className="wordBreak">{`${token.symbol}`}</p>
              </Tooltip>
            </div>
          </div>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token Decimals</span>
            <div className="tokenInfoContent">{`${token.decimals}`}</div>
          </div>
        </div>
      )}
    </>
  );
}
