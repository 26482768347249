/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import AppContainer from './AppContainer';
import ErrorBoundary from './components/Base/ErrorBoundary';
import PrivateRoute from './components/Base/PrivateRoute';
import RoutePermission from './components/Base/RoutePermission';
import { SolanaProvider } from './contexts/solana';
import { Web3ReactLocalProvider } from './contexts/web3react';
import AdminCreate from './pages/AdminList/AdminDetail/AdminCreate';
import AdminEdit from './pages/AdminList/AdminDetail/AdminEdit';
import AdminList from './pages/AdminList/AdminList';
import Completed from './pages/Completed';
import CreateCompleted from './pages/Completed/DetailCompleted/CompletedCreate';
import CompletedEdit from './pages/Completed/DetailCompleted/CompletedEdit';
import ErrorPage from './pages/ErrorPage';
import ExecutivePartners from './pages/ExecutivePartners';
import CreatePartners from './pages/ExecutivePartners/DetailPartners/PartnerCreate';
import PartnerEdit from './pages/ExecutivePartners/DetailPartners/PartnerEdit';
import Login from './pages/Login';
import NFTContracts from './pages/NFTContract';
import CreateNFTContract from './pages/NFTContract/Detail/CreateNFTContract';
import EditNFTContract from './pages/NFTContract/Detail/EditNFTContract';
import NotFoundPage from './pages/NotFoundPage';
import ParticipantsCreate from './pages/Participants/ParticipantDetail/ParticipantsCreate';
import ParticipantList from './pages/Participants/ParticipantList';
import Pools from './pages/Pools';
import CommunityPoolCreate from './pages/Pools/Forms/Community/PoolCreate';
import CommunityPoolEdit from './pages/Pools/Forms/Community/PoolEdit';
import ExcutivePoolCreate from './pages/Pools/Forms/ExcutiveLounge/PoolCreate';
import ExcutivePoolEdit from './pages/Pools/Forms/ExcutiveLounge/PoolEdit';
import XborgPoolCreate from './pages/Pools/Forms/Xborg/PoolCreate';
import XborgPoolEdit from './pages/Pools/Forms/Xborg/PoolEdit';
import Profile from './pages/Profile';
import RewardStaking from './pages/RewardStaking';
import CreateReward from './pages/RewardStaking/Forms/Reward/CreateReward';
import EditReward from './pages/RewardStaking/Forms/Reward/EditReward';
import SliderManage from './pages/SliderManage';
import CreateSlider from './pages/SliderManage/CreateSlider';
import EditSlider from './pages/SliderManage/EditSlider';
import UserList from './pages/UserList';
import { clearAlert } from './store/actions/alert';
import { adminRoute } from './utils';
import OurServiceList from './pages/OurService/OurServiceList';
import OurServiceEdit from './pages/OurService/OurServiceDetail/OurServiceEdit';
import OurServiceCreate from './pages/OurService/OurServiceDetail/OurServiceCreate';
import LandingProject from './pages/LandingProject';
import ProjectMatrix from './pages/ProjectMatrix';
import ProjectMatrixCreate from './pages/ProjectMatrix/ProjectMatrixDetail/ProjectMatrixCreate';
import ProjectMatrixEdit from './pages/ProjectMatrix/ProjectMatrixDetail/ProjectMatrixEdit';
import NewSlider from './pages/NewSlider';
import NewSliderCreate from './pages/NewSlider/NewSliderDetail/NewSliderCreate';
import NewSliderEdit from './pages/NewSlider/NewSliderDetail/NewSliderEdit';
import LandingProjectCreate from './pages/LandingProject/DetailLandingPage/LandingProjectCreate';
import LandingProjectEdit from './pages/LandingProject/DetailLandingPage/LandingProjectEdit';
import AboutInvestmentPoolList from './pages/AboutInvestmentPool/AboutInvestmentPoolList';
import AboutInvestmentPoolEdit from './pages/AboutInvestmentPool/AboutInvestmentPoolDetail/AboutInvestmentPoolEdit';
import AboutInvestmentPoolCreate from './pages/AboutInvestmentPool/AboutInvestmentPoolDetail/AboutInvestmentPoolCreate';
import YielBoxProject from './pages/YielBoxProject';
import YielBoxProjectCreate from './pages/YielBoxProject/YielBoxProjectDetail/YielBoxProjectCreate';
import YielBoxProjectEdit from './pages/YielBoxProject/YielBoxProjectDetail/YielBoxProjectEdit';
import FeatureProject from './pages/FeatureProject';
import FeatureProjectCreate from './pages/FeatureProject/FeatureProjectDetail/FeatureProjectCreate';
import FeatureProjectEdit from './pages/FeatureProject/FeatureProjectDetail/FeatureProjectEdit';
import InvestmentPoolConfig from './pages/InvestmentPoolConfig';
import InvestmentPoolConfigCreate from './pages/InvestmentPoolConfig/InvestmentPoolConfigDetail/InvestmentPoolConfigCreate';
import InvestmentPoolConfigEdit from './pages/InvestmentPoolConfig/InvestmentPoolConfigDetail/InvestmentPoolConfigEdit';
import OurServiceConfig from './pages/OurServiceConfig';
import OurServiceConfigCreate from './pages/OurServiceConfig/OurServiceConfigDetail/OurServiceConfigCreate';
import OurServiceConfigEdit from './pages/OurServiceConfig/OurServiceConfigDetail/OurServiceConfigEdit';
import FeatureConfig from './pages/FeatureConfig';
import FeatureConfigCreate from './pages/FeatureConfig/FeatureConfigDetail/FeatureConfigCreate';
import FeatureConfigEdit from './pages/FeatureConfig/FeatureConfigDetail/FeatureConfigEdit';
import ProjectMatrixConfig from './pages/ProjectMatrixConfig';
import ProjectMatrixConfigCreate from './pages/ProjectMatrixConfig/ProjectMatrixConfigDetail/ProjectMatrixConfigCreate';
import ProjectMatrixConfigEdit from './pages/ProjectMatrixConfig/ProjectMatrixConfigDetail/ProjectMatrixConfigEdit';
import ProjectYieldBoxConfig from './pages/ProjectYieldBoxConfig';
import ProjectYieldBoxConfigCreate from './pages/ProjectYieldBoxConfig/ProjectYieldBoxConfigDetail/ProjectYieldBoxConfigCreate';
import ProjectYieldBoxConfigEdit from './pages/ProjectYieldBoxConfig/ProjectYieldBoxConfigDetail/ProjectYieldBoxConfigEdit';
import ConfigButtonLaunchApp from './pages/ConfigButtonLauchApp';
import ConfigButtonLauchAppCreate from './pages/ConfigButtonLauchApp/ConfigButtonLauchAppDetail/ConfigButtonLauchAppCreate';
import ConfigButtonLauchAppEdit from './pages/ConfigButtonLauchApp/ConfigButtonLauchAppDetail/ConfigButtonLauchAppEdit';
import ConfigButtonLauchExplorer from './pages/ConfigButtonLauchExplorer';
import ConfigButtonLauchExplorerCreate from './pages/ConfigButtonLauchExplorer/ConfigButtonLauchExplorerDetail/ConfigButtonLauchExplorerCreate';
import ConfigButtonLauchExplorerEdit from './pages/ConfigButtonLauchExplorer/ConfigButtonLauchExplorerDetail/ConfigButtonLauchExplorerEdit';
import FAndQPage from './pages/FAQ';
import FAndQCreate from './pages/FAQ/FAQDetail/FQCreate';
import FAndQEdit from './pages/FAQ/FAQDetail/FQEdit';
import CardParticipate from './pages/CardParticipate';
import CardParticipateCreate from './pages/CardParticipate/CardParticipateDetail/CardParticipateCreate';
import CardParticipateEdit from './pages/CardParticipate/CardParticipateDetail/CardParticipateEdit';
import StepParticipate from './pages/StepParticipate';
import StepParticipateCreate from './pages/StepParticipate/StepParticipateDetail/StepParticipateCreate';
import StepParticipateEdit from './pages/StepParticipate/StepParticipateDetail/StepParticipateEdit';
import FAQConfig from './pages/FAQConfig';
import ParticipateConfig from './pages/ParticipateConfig';
import ConfigButtonInSectionFeature from './pages/ConfigButtonInSectionFeature';
import ListCategoryPage from './pages/FAQ/ListCategory';
import ListCategoryParticipate from './pages/StepParticipate/ListCategoryParticipate';

/**
 * Main App routes.
 */
const Routes: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const alert = useSelector((state: any) => state.alert);
  const { history } = props;

  useEffect(() => {
    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
      }
    };
  }, []);
  useEffect(() => {
    const { type, message } = alert;
    if (type && message) {
      NotificationManager[type](message);
    }
  }, [alert]);

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(clearAlert());
    });
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={`${adminRoute('/projects')}`} />} />
        <Route exact path={`${adminRoute()}`} render={() => <Redirect to={`${adminRoute('/projects')}`} />} />
        <PrivateRoute path={adminRoute('/projects')} exact component={Pools} />
        <PrivateRoute path={adminRoute('/reward')} exact component={RewardStaking} />
        <PrivateRoute path={adminRoute('/create-reward/:poolId')} exact component={CreateReward} />
        <PrivateRoute path={adminRoute('/reward/:id')} exact component={EditReward} />
        <PrivateRoute path={adminRoute('/projects/add')} exact component={XborgPoolCreate} />
        <PrivateRoute exact path={adminRoute('projects/:id')} component={XborgPoolEdit} />
        <PrivateRoute path={adminRoute('/projects/community/add')} exact component={CommunityPoolCreate} />
        <PrivateRoute exact path={adminRoute('projects/community/:id')} component={CommunityPoolEdit} />
        <PrivateRoute path={adminRoute('/projects/executive-lounge/add')} exact component={ExcutivePoolCreate} />
        <PrivateRoute exact path={adminRoute('projects/executive-lounge/:id')} component={ExcutivePoolEdit} />
        <PrivateRoute path={adminRoute('/profile')} component={Profile} />
        <PrivateRoute exact path={adminRoute('/nft-contracts')} component={NFTContracts} />
        <PrivateRoute path={adminRoute('/nft-contracts/add')} component={CreateNFTContract} />
        <PrivateRoute path={adminRoute('/nft-contracts/:id')} component={EditNFTContract} />
        <RoutePermission exact path={adminRoute('/partners')} component={ExecutivePartners} />
        <RoutePermission exact path={adminRoute('/partners/create')} component={CreatePartners} />
        <RoutePermission exact path={adminRoute('/partners/update/:id')} component={PartnerEdit} />

        <RoutePermission exact path={adminRoute('/completed')} component={Completed} />
        <RoutePermission exact path={adminRoute('/completed/create')} component={CreateCompleted} />
        <RoutePermission exact path={adminRoute('/completed/update/:id')} component={CompletedEdit} />

        <RoutePermission exact path={adminRoute('/landing-projects')} component={LandingProject} />
        <RoutePermission exact path={adminRoute('/landing-projects/create')} component={LandingProjectCreate} />
        <RoutePermission exact path={adminRoute('/landing-projects/update/:id')} component={LandingProjectEdit} />

        <RoutePermission exact path={adminRoute('/project-matrix')} component={ProjectMatrix} />
        <RoutePermission exact path={adminRoute('/project-matrix/create')} component={ProjectMatrixCreate} />
        <RoutePermission exact path={adminRoute('/project-matrix/update/:id')} component={ProjectMatrixEdit} />

        <RoutePermission exact path={adminRoute('/fa-config/create')} component={FAndQCreate} />
        <RoutePermission exact path={adminRoute('/fa-config/:id')} component={FAndQPage} />
        <RoutePermission exact path={adminRoute('/fa-category')} component={ListCategoryPage} />
        <RoutePermission exact path={adminRoute('/fa-config/update/:id')} component={FAndQEdit} />

        <RoutePermission exact path={adminRoute('/project-yieldbox')} component={YielBoxProject} />
        <RoutePermission exact path={adminRoute('/project-yieldbox/create')} component={YielBoxProjectCreate} />
        <RoutePermission exact path={adminRoute('/project-yieldbox/update/:id')} component={YielBoxProjectEdit} />

        <RoutePermission exact path={adminRoute('/project-feature')} component={FeatureProject} />
        <RoutePermission exact path={adminRoute('/project-feature/create')} component={FeatureProjectCreate} />
        <RoutePermission exact path={adminRoute('/project-feature/update/:id')} component={FeatureProjectEdit} />

        <RoutePermission path={adminRoute('/list-user')} component={UserList} />
        <RoutePermission path={adminRoute('/participants')} component={ParticipantList} />
        <RoutePermission exact path={adminRoute('/create-participant-ido')} component={ParticipantsCreate} />
        <RoutePermission exact path={adminRoute('/list-admin')} component={AdminList} />
        <RoutePermission path={adminRoute('/list-admin/:id')} component={AdminEdit} />
        <RoutePermission exact path={adminRoute('/create-admin')} component={AdminCreate} />
        <RoutePermission exact path={adminRoute('/list-our-service')} component={OurServiceList} />
        <RoutePermission path={adminRoute('/list-our-service/:id')} component={OurServiceEdit} />
        <RoutePermission exact path={adminRoute('/create-our-service')} component={OurServiceCreate} />

        <RoutePermission exact path={adminRoute('/new-sliders')} component={NewSlider} />
        <RoutePermission exact path={adminRoute('/new-sliders/add')} component={NewSliderCreate} />
        <RoutePermission exact path={adminRoute('/new-sliders/:id/update')} component={NewSliderEdit} />

        <RoutePermission exact path={adminRoute('/list-about-investment')} component={AboutInvestmentPoolList} />
        <RoutePermission path={adminRoute('/list-bout-investment/:id')} component={AboutInvestmentPoolEdit} />
        <RoutePermission exact path={adminRoute('/create-bout-investment')} component={AboutInvestmentPoolCreate} />

        <RoutePermission exact path={adminRoute('/sliders')} component={SliderManage} />
        <RoutePermission exact path={adminRoute('/sliders/add')} component={CreateSlider} />
        <RoutePermission exact path={adminRoute('/sliders/:id/update')} component={EditSlider} />
        <RoutePermission exact path={adminRoute('/step-participate-config/add')} component={StepParticipateCreate} />
        <RoutePermission exact path={adminRoute('/card-participate-config')} component={CardParticipate} />
        <RoutePermission exact path={adminRoute('/card-participate-config/add')} component={CardParticipateCreate} />
        <RoutePermission
          exact
          path={adminRoute('/card-participate-config/update/:id')}
          component={CardParticipateEdit}
        />
        <RoutePermission
          exact
          path={adminRoute('/step-participate-config-category')}
          component={ListCategoryParticipate}
        />
        <RoutePermission exact path={adminRoute('/step-participate-config/:id')} component={StepParticipate} />
       
        <RoutePermission
          exact
          path={adminRoute('/step-participate-config/update/:id')}
          component={StepParticipateEdit}
        />

        <RoutePermission exact path={adminRoute('/investment-pool-config')} component={InvestmentPoolConfig} />
        <RoutePermission
          exact
          path={adminRoute('/investment-pool-config/create')}
          component={InvestmentPoolConfigCreate}
        />
        <RoutePermission
          exact
          path={adminRoute('/investment-pool-config/update/:id')}
          component={InvestmentPoolConfigEdit}
        />

        <RoutePermission exact path={adminRoute('/our-service-config')} component={OurServiceConfig} />
        <RoutePermission exact path={adminRoute('/our-service-config/create')} component={OurServiceConfigCreate} />
        <RoutePermission exact path={adminRoute('/our-service-config/update/:id')} component={OurServiceConfigEdit} />

        <RoutePermission exact path={adminRoute('/feature-config')} component={FeatureConfig} />
        <RoutePermission exact path={adminRoute('/feature-config/create')} component={FeatureConfigCreate} />
        <RoutePermission exact path={adminRoute('/feature-config/update/:id')} component={FeatureConfigEdit} />

        <RoutePermission exact path={adminRoute('/project-matrix-config')} component={ProjectMatrixConfig} />
        <RoutePermission
          exact
          path={adminRoute('/project-matrix-config/create')}
          component={ProjectMatrixConfigCreate}
        />
        <RoutePermission
          exact
          path={adminRoute('/project-matrix-config/update/:id')}
          component={ProjectMatrixConfigEdit}
        />

        <RoutePermission exact path={adminRoute('/project-yield-box-config')} component={ProjectYieldBoxConfig} />
        <RoutePermission
          exact
          path={adminRoute('/project-yield-box-config/create')}
          component={ProjectYieldBoxConfigCreate}
        />
        <RoutePermission
          exact
          path={adminRoute('/project-yield-box-config/update/:id')}
          component={ProjectYieldBoxConfigEdit}
        />

        <RoutePermission exact path={adminRoute('/button-1-config')} component={ConfigButtonLaunchApp} />
        <RoutePermission exact path={adminRoute('/button-1-config/create')} component={ConfigButtonLauchAppCreate} />
        <RoutePermission exact path={adminRoute('/button-1-config/update/:id')} component={ConfigButtonLauchAppEdit} />

        <RoutePermission exact path={adminRoute('/button-2-config')} component={ConfigButtonLauchExplorer} />
        <RoutePermission
          exact
          path={adminRoute('/button-2-config/create')}
          component={ConfigButtonLauchExplorerCreate}
        />
        <RoutePermission
          exact
          path={adminRoute('/button-2-config/update/:id')}
          component={ConfigButtonLauchExplorerEdit}
        />

        <RoutePermission exact path={adminRoute('/faq-config')} component={FAQConfig} />
        <RoutePermission exact path={adminRoute('/participate-config-title')} component={ParticipateConfig} />
        <RoutePermission
          exact
          path={adminRoute('/button-in-section-feature')}
          component={ConfigButtonInSectionFeature}
        />
        <PrivateRoute exact path={adminRoute('/error')} component={ErrorPage} />
        <Route path={adminRoute('/login')} component={Login} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <NotificationContainer />
      <Router>
        <Web3ReactLocalProvider>
          <SolanaProvider>
            <ErrorBoundary>
              <AppContainer>
                <RoutesHistory />
              </AppContainer>
            </ErrorBoundary>
          </SolanaProvider>
        </Web3ReactLocalProvider>
      </Router>
    </>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
