import { useCallback, useState } from 'react';

export function useLocalStorageState(key: string, defaultState?: string) {
    const [state, setState] = useState(() => {
        // NOTE: Not sure if this is ok
        if (typeof window !== 'undefined') {
            const storedState = localStorage.getItem(key);
            if (storedState) {
                return JSON.parse(storedState);
            }
        }

        return defaultState;
    });

    const setLocalStorageState = useCallback(
        (newState: any) => {
            const changed = state !== newState;
            if (!changed) {
                return;
            }
            setState(newState);
            if (newState === null) {
                localStorage.removeItem(key);
            } else {
                localStorage.setItem(key, JSON.stringify(newState));
            }
        },
        [state, key]
    );

    return [state, setLocalStorageState];
}
