import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nftContractInput: {
    marginTop: 0,
  },
  maxAllocationInput: {
    '& > div > label': {
      color: '#001F4D',
      margin: 0,
      padding: 0,
      fontSize: 16,
      fontFamily: 'OpenSans-SemiBold',
      fontWeight: 500
    },
    '& > div > span': {
      fontSize: 16,
      fontWeight: 500
    },
  },
}));

export default useStyles;
