import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import FormPartner from './FormPartner';

const PartnerDetail = (props: any) => {
  const { partner, isCreate } = props;

  const showPartnerCreate = () => {
    return <FormPartner partner={partner} isCreate={isCreate} />;
  };

  const showPartnerDetail = () => {
    if (!_.isEmpty(partner)) {
      return <FormPartner partner={partner} isCreate={isCreate} />;
    }
    return <p>There is no partners that does exists</p>;
  };

  return <div>{isCreate ? showPartnerCreate() : showPartnerDetail()}</div>;
};

export default withRouter(PartnerDetail);
