import { NETWORK_AVAILABLE } from './../../../../../constants';
import { CommunityPool } from '../types/pool';
import { number, object } from 'yup';
import { BASE_VALIDATION_SCHEMA, TBA_VALIDATION } from '../../contants';
import { UploadType } from '../../../../SliderManage/constants/type';

export const DEFAULT_POOL: CommunityPool = {
  name: '',
  banner: {
    uploadType: UploadType.LINK,
    uploadedUrl: '',
  },
  website: '',
  networkAvailable: NETWORK_AVAILABLE.POLYGON,
  acceptCurrency: 'usdt',
  kycBypass: false,
  tokenInfoTBA: false,
  tokenInfo: {
    token: '',
    totalSoldCoin: '',
    tokenImages: {
      uploadType: UploadType.LINK,
      uploadedUrl: '',
    },
    symbol: '',
    addressReceiver: '',
  },
  tokenRateTBA: false,
  tokenRate: 0,
  startTime: '',
  finishTime: '',
  mediumLink: '',
  twitterLink: '',
  telegramLink: '',
  facebookLink: '',
  linkedinLink: '',
  redditLink: '',
  description: '',
  lockSchedule: '',
  claimPolicy: '',
  buyTimeTBA: false,
  claimTBA: false,
  campaignClaimConfig: [],
  campaignHash: '',
  isDisplay: 0,
  maxAllocation: '',
  tax: '',
  needValidate: false,
  priority: '',
};

export const VALIDATION_SCHEMA = object({
  ...BASE_VALIDATION_SCHEMA,
  ...TBA_VALIDATION,
  maxAllocation: number()
    .required('This field is required')
    .typeError('This field must be number')
    .min(0, 'This field must greater than 0'),
  tax: number()
    .required('This field is required')
    .typeError('This field must be number')
    .min(0, 'This field must greater than 0')
    .max(100, 'This field must less than 100'),
  progressDisplay: number()
    .transform((value) => (!value ? null : value))
    .nullable()
    .typeError('This field must be a number')
    .moreThan(0, 'This field must be greater than 0')
    .max(100, 'This field must less than 100'),
});
