import { Control, DeepMap, FieldError } from 'react-hook-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioGroup } from '../../../../../../components/Base/Form';
import { Paper } from '../../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../../components/Base/Paper/PaperTitle';
import { NETWORK_AVAILABLE } from '../../../../../../constants';
import { XBorgPool } from '../../constants/pool';
import { KycRequired } from './KycRequired';

interface DetailProps {
  detail?: XBorgPool;
  control?: Control<XBorgPool>;
  watch?: any;
  setValue?: any;
  errors?: DeepMap<XBorgPool, FieldError>;
}

export function ProjectDetails({ control, detail, errors }: DetailProps) {
  const valueNetwork = control?.watchInternal('networkAvailable') as string;
  const networkIsTBA = valueNetwork === NETWORK_AVAILABLE.TBA;

  const disableAcceptCurrency = !!detail?.isDeploy || networkIsTBA;
  return (
    <Paper>
      <PaperTitle>Project Details</PaperTitle>
      <RadioGroup
        name="networkAvailable"
        label="Network Available"
        errorMessage={errors?.networkAvailable?.message}
        control={control}
        radioData={[
          {
            value: NETWORK_AVAILABLE.POLYGON,
            label: 'Polygon',
          },
          {
            value: NETWORK_AVAILABLE.SOL,
            label: 'Solana',
          },
          {
            value: NETWORK_AVAILABLE.ETH,
            label: 'Ethereum',
          },
          {
            value: NETWORK_AVAILABLE.BSC,
            label: 'BSC',
          },
          {
            value: NETWORK_AVAILABLE.TBA,
            label: 'TBA',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel
            key={data.value}
            value={data.value}
            label={data.label}
            control={<Radio size="small" />}
            disabled={!!detail?.isDeploy}
          />
        )}
      />
      <RadioGroup
        name="acceptCurrency"
        label="Accept Currency"
        errorMessage={errors?.acceptCurrency?.message}
        control={control}
        radioData={[
          {
            value: 'usdt',
            label: 'USDT',
          },
          {
            value: 'usdc',
            label: 'USDC',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel
            key={data.value}
            value={data.value}
            label={data.label}
            control={<Radio size="small" />}
            disabled={disableAcceptCurrency}
          />
        )}
      />
      <KycRequired errors={errors} control={control} />
    </Paper>
  );
}
