export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum UploadType {
  LINK = 'link',
  FILE = 'file',
}

export interface MediaInfo {
  type: FileType;
  uploadType: UploadType;
  link?: string;
  file?: File | null;
  uploadedUrl?: string;
}

export interface Media {
  id: string;
  projectName: string;
  projectType: string;
  networkAvailable: string;
  whitepaper?: string;
  website?: string;
  trailer?: string;
  telegram?: string;
  twitter?: string;
  discord?: string;
  description: string;
  priority: number | string;
  firstSlide: MediaInfo;
  secondSlide: MediaInfo;
  thumbnail: MediaInfo;
}
