import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    "button": {
        fontFamily: "inherit",
        background: "linear-gradient(90deg, #61009D 0%, rgba(90, 231, 240, 0.64) 100%)",
        color: "#fff",
        padding: "8px 34px",
        borderRadius: 10,
        fontWeight: 600,
        fontSize: 16,
        boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.05)",
        textTransform: 'initial',
        border: 'none', 
        "&:disabled": {
            color: "white",
            cursor:'not-allowed !important',
            pointerEvents:'unset',
            '&:hover': {
                transform: 'translateY(0px)',
                backgroundColor:'#C4C4C4 !important',
            },
        },
        transition: '.2s all ease-in',
        '&:hover': {
            boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
            transform: 'translateY(-7px)'
        },
        '& .MuiCircularProgress-root': {
            color: "#fff"
        }
    }
}))

export default useStyles
