import { combineReducers } from 'redux';
import { campaignsReducer } from './campaign';
import { alertReducer } from './alert';
import userReducer, { userConnectReducer, userProfileUpdateReducer, userCurrentNetwork } from './user';
import { appNetworkReducer } from './appNetwork';
import { globalReducer } from './global';
import { openReducer } from './open';

const rootReducer = combineReducers({
  user: userReducer,
  userConnect: userConnectReducer,
  userCurrentNetwork: userCurrentNetwork,
  userProfileUpdate: userProfileUpdateReducer,
  campaigns: campaignsReducer,
  alert: alertReducer,
  appNetwork: appNetworkReducer,
  global: globalReducer,
  open : openReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
