import { Control, DeepMap, FieldError } from 'react-hook-form';
import { NumberInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';

interface PriorityProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
}

export function Priority({ control, errors }: PriorityProps) {
  return (
    <Paper>
      <NumberInput name="priority" control={control} label="Priority" errorMessage={errors?.priority?.message} />
    </Paper>
  );
}
