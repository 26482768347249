import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControlInputLoading: {
    position: 'relative',
    '&>input:disabled': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: 'rgba(0, 0, 0, 0.38)'
    },
    '&>input:read-only': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: 'rgba(0, 0, 0, 0.38)'
    },
  },
  tokenInfo: {
    marginTop: 15,
    padding: '20px 15px',
    backgroundColor: '#F0F0F0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,

    '& > .tokenInfoBlock': {
      color: '#363636',
      textAlign: 'left',
    },

    '& .tokenInfoLabel': {
      fontSize: 14,
      color: '#636363',
    },

    '& .wordBreak': {
      width: 150,
      maxWidth: 150,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    '& .tokenInfoContent': {
      marginTop: 7,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
    },

    '& .tokenInfoText': {
      marginLeft: 15,
    },
  },
  loadingTokenIcon: {
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  circularProgress: {
    width: 25,
    height: 25,
    position: 'absolute',
    right: 10,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export default useStyles;
