import { makeStyles } from "@material-ui/core";

const useRegisterTimeStyles = makeStyles((theme: any) => {
    return {
        container: {
            backgroundColor: 'white',
            boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
            borderRadius: 10,
            padding: '20px 25px 30px 25px',
            marginTop: 20,
        },
        cardContainer: {
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
        },
        "time-control": {
            marginRight: "40px",
        }
    }
})

export default useRegisterTimeStyles;