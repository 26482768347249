import { Control, DeepMap, FieldError } from 'react-hook-form';
import { TextInput } from '../../../../../components/Base/Form';

interface ExtendScocialSettingProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  children?: any;
}

export function ExtendSocialSetting({ control, errors }: ExtendScocialSettingProps) {
  return (
    <>
      <TextInput
        name="facebookLink"
        control={control}
        errorMessage={errors?.mediumLink?.message}
        label="Facebook Link"
      />
      <TextInput
        name="linkedinLink"
        control={control}
        errorMessage={errors?.mediumLink?.message}
        label="Linkedin Link"
      />
      <TextInput name="redditLink" control={control} errorMessage={errors?.mediumLink?.message} label="Reddit Link" />
      <TextInput name="discordLink" control={control} errorMessage={errors?.mediumLink?.message} label="Discord Link" />
    </>
  );
}
