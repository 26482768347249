import { useTypedSelector } from '../hooks/useTypedSelector';
import { useWeb3React } from '@web3-react/core';

type ReturnType = {
  isAuth: boolean;
  connectedAccount: string | null | undefined;
  wrongChain: boolean;
};

const useAuth = (): ReturnType => {
  const { account, chainId } = useWeb3React();

  const { appChainID } = useTypedSelector((state: any) => state.appNetwork).data;
  const { data: loginUser } = useTypedSelector((state: any) => state.user);

  return {
    isAuth: !!loginUser,
    connectedAccount: account,
    wrongChain: appChainID !== chainId,
  };
};

export default useAuth;
