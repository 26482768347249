const USER_PURGE = 'USER_PURGE';
const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
const USER_LOGOUT = 'USER_LOGOUT';

const USER_CONNECT_WALLET_LOADING = 'USER_CONNECT_WALLET_LOADING';
const USER_CONNECT_WALLET_SUCCESS = 'USER_CONNECT_WALLET_SUCCESS';
const USER_CONNECT_WALLET_FAILURE = 'USER_CONNECT_WALLET_FAILURE';
const USER_CONNECT_WALLET_LOCK = 'USER_CONNECT_WALLET_LOCK';

const USER_WALLET_CHANGED = 'USER_WALLET_CHANGED';

const USER_PROFILE_UPDATE_LOADING = 'USER_PROFILE_UPDATE_LOADING';
const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';
const USER_PROFILE_UPDATE_CLEAR_ERROR = 'USER_PROFILE_UPDATE_CLEAR_ERROR';

const USER_PROFILE_LOADING = 'USER_PROFILE_LOADING';
const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const userActions = {
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_CONNECT_WALLET_LOADING,
  USER_CONNECT_WALLET_FAILURE,
  USER_CONNECT_WALLET_SUCCESS,
  USER_CONNECT_WALLET_LOCK,
  USER_WALLET_CHANGED,
  USER_PURGE,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_LOADING,
  USER_PROFILE_UPDATE_FAILURE,
  USER_PROFILE_LOADING,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
  USER_PROFILE_UPDATE_CLEAR_ERROR
};
