import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxToggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginTop: '20px',
    '& .MuiFormControl-root': {
      flexDirection: 'row',
    },
  },
}));

export default useStyles;
