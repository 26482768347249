import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import DefaultLayout from '../../../../components/Layout/DefaultLayout';
import { adminRoute } from '../../../../utils';
import { CreateRewardForm } from './CreateRewardForm';

const CreateReward: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = false;

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/reward')} />
      <CreateRewardForm isEdit={isEdit} />
    </DefaultLayout>
  );
};

export default withRouter(CreateReward);
