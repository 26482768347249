import React, { useState, useEffect } from 'react';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { getListFeature } from '../../request/completed';
import FeatureRow, { FeatureType } from './FeatureProjectRow/FeatureProjectRow';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from './style';
import TableCommon from '../../components/TableCommon';
import Button from '../../components/Base/ButtonLink';
import { adminRoute } from '../../utils';

const tableHeaders = ['NAME', 'NETWORK', 'STATUS', 'PRIORITY', 'ACTION'];

const FeatureProject: React.FC<any> = (props: any) => {
  const limit = 8;

  const [list, setList] = useState<FeatureType[]>([]);
  const [page, setPage] = useState(1);
  const [query] = useState('');
  const [currentOpen, setCurrentOpen] = useState('');
  const [lastPage, setLastPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(page);
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [failure, setFailure] = useState<boolean>(false);

  const getList = async (currentPage: any, query: any) => {
    const queryParams = {
      page: currentPage,
      limit: limit,
    };

    try {
      setLoading(true);
      const resObject = await getListFeature(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setList(data);
        setPage(page);
        setLastPage(lastPage);
      }
    } catch (err) {
      console.log(err);
      setFailure(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList(currentPage, query);
  }, [currentPage, query]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderLandingProject = (landingProject: FeatureType) => {
    return (
      <FeatureRow
        key={landingProject.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        landingProject={landingProject}
        onReload={() => {
          if (currentPage === 1) {
            getList(1, query);
          } else {
            setCurrentPage(1);
          }
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      list &&
      lastPage > 1 && (
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/project-feature/create')} text={'Create new feature project'} />
        </div>
        {/* <div className={classes.boxSearch}>
          <img className={classes.iconSearch} src="/images/icon-search.svg" alt="" />
          <input className={classes.inputSearch} onChange={delayCompletedSearch} placeholder="Search" />
        </div> */}
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={list}
        onRenderContent={onRenderLandingProject}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default FeatureProject;
