import Link from '@material-ui/core/Link';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { NumberInput, TextInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import {
  BCSSCAN_URL, ETHERSCAN_URL, POLYGONSCAN_URL,
  REWARD_DISTRIBUTOR_BSC,
  REWARD_DISTRIBUTOR_ETH,
  REWARD_DISTRIBUTOR_POLYGON
} from '../../../../../constants/network';
import useCommonStyle from '../../../../../styles/CommonStyle';
import useFormStyles from '../useFormStyles';
import DisplayPoolSwitch from './DisplayPoolSwitch';

interface ProjectGeneralInfoProps {
  detail?: any;
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  register: any;
  setValue: any;
  isEdit: boolean;
}

export function ProjectGeneralInfo({ detail, control, errors, register, setValue, isEdit }: ProjectGeneralInfoProps) {
  const classes = useFormStyles();
  const commonStyle = useCommonStyle();

  return (
    <Paper>
      <PaperTitle>Project General Info</PaperTitle>

      <DisplayPoolSwitch
        poolDetail={detail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
      />
      <TextInput
        name="pool_name"
        control={control}
        label="Project Name"
        errorMessage={errors?.pool_name?.message}
        isRequired={true}
        disabled={isEdit}
      />
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Reward Pool Address</label>
        <div className={commonStyle.boldText}>
          <span>BSC :</span>
          <Link href={`${BCSSCAN_URL}/address/${REWARD_DISTRIBUTOR_BSC}`} target={'_blank'}>
            {REWARD_DISTRIBUTOR_BSC}
          </Link>
        </div>
        <div className={commonStyle.boldText}>
          <span>ETH :</span>
          <Link href={`${ETHERSCAN_URL}/address/${REWARD_DISTRIBUTOR_ETH}`} target={'_blank'}>
            {REWARD_DISTRIBUTOR_ETH}
          </Link>
        </div>
        <div className={commonStyle.boldText}>
          <span>POLYGON :</span>
          <Link href={`${POLYGONSCAN_URL}/address/${REWARD_DISTRIBUTOR_POLYGON}`} target={'_blank'}>
            {REWARD_DISTRIBUTOR_POLYGON}
          </Link>
        </div>
      </div>
      <NumberInput
        name="lock_period"
        control={control}
        label="XBorg - Set Lock Period"
        errorMessage={errors?.lock_period?.message}
        isRequired={true}
      />
      <NumberInput
        name="anarkey_lock_period"
        control={control}
        label="AnarKey - Set Lock Period"
        errorMessage={errors?.anarkey_lock_period?.message}
        isRequired={true}
      />
    </Paper>
  );
}
