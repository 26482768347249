import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  exchangeRate: {
    backgroundColor: 'white',
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.1)`,
    borderRadius: 10,
    padding: '20px 25px 30px 25px',
    marginTop: 20,
  },
  containerTime: {
    display: 'flex',
    '&>div:first-child': {
      marginRight: 20,
    },
    '&>div:last-child': {
      marginLeft: 20,
    },
  },
}));

export default useStyles;
