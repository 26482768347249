import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import styles from './styles.module.scss';

interface SlideConfirmModalProps {
  content: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function SlideConfirmModal({ open, content, onCancel, onConfirm }: SlideConfirmModalProps) {
  return (
    <ConfirmDialog
      open={open}
      confirmLoading={false}
      onConfirm={onConfirm}
      onCancel={onCancel}
      buttonContent="Yes"
      className={styles.modalConfirm}
    >
      <div className={styles.contentDeploy}>
        <p>{content}</p>
      </div>
    </ConfirmDialog>
  );
}
