import { GLOBAL_ACTIONS } from './../constants/global';
import { AnyAction } from 'redux';

type StateType = {
  collapsed: boolean;
};

const initialState = {
  collapsed: false,
};

export const globalReducer = (state: StateType = initialState, action: AnyAction) => {
  switch (action.type) {
    case GLOBAL_ACTIONS.UPDATE_COLLAPSE: {
      return {
        ...state,
        collapsed: action.payload.collapsed,
      };
    }

    default: {
      return state;
    }
  }
};
