import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { NETWORK_AVAILABLE } from '../../../constants';
import { NETWORK_ICON } from '../Detail/contants';
import { NFTContract } from '../types';
import useStyles from './styles';

type NFTContractRowProps = {
  data: NFTContract;
  onClick: (id: number | string) => void;
};

export const NFTContractRow: React.FC<NFTContractRowProps> = ({ data, onClick }: NFTContractRowProps) => {
  const classes = useStyles();

  const handleClick = () => onClick(data.id);

  const { name, network, address } = data;

  const renderNetWork = () => {
    let nameNetwork = '';
    if (network === NETWORK_AVAILABLE.BSC) {
      nameNetwork = 'BSC';
    } else if (network === NETWORK_AVAILABLE.POLYGON) {
      nameNetwork = 'Polygon';
    } else if(network === NETWORK_AVAILABLE.SOL) {
      nameNetwork = 'Solana';
    } else {
      nameNetwork = 'Ethereum'
    }
    return (
      <>
        <img alt="icon" src={NETWORK_ICON[network as string]} className={classes.image}/> <span>{nameNetwork}</span>
      </>
    );
  };

  return (
    <TableRow className={classes.tableRow} key={data.id}>
      <TableCell className={classes.tableCell} component="td" scope="row" onClick={handleClick}>
        <span className={`${classes.wordBreak} ${classes.userName}`}>{name}</span>
      </TableCell>
      <TableCell className={classes.tableCell} component="td" scope="row" onClick={handleClick}>
        <span className={`${classes.wordBreak} ${classes.lastname}`}>{renderNetWork()}</span>
      </TableCell>
      <TableCell className={classes.tableCell} align="center" component="td" scope="row" onClick={handleClick}>
        <span className={`${classes.wordBreak} ${classes.email}`}>{address}</span>
      </TableCell>
    </TableRow>
  );
};
