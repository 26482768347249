import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '../../components/Base/ButtonLink';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import TableCommon from '../../components/TableCommon';
import { getNftContracts } from '../../request/ntf-contract';
import { adminRoute } from '../../utils';
import { NFTContractRow } from './components/TableRow';
import useStyles from './styles';
import { NFTContract } from './types';

const tableHeaders = ['PROJECT NAME', 'NETWORK', 'NFT CONTRACT ADDRESS'];

const NFTContracts: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const [nftContracts, setNFTContracts] = useState<NFTContract[]>([]);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  async function retrieveData() {
    const query = {
      page: currentPage,
      limit: 10,
    };

    try {
      const res = await getNftContracts(query);

      if (res.status !== 200) {
        throw new Error(res.message);
      }

      const { lastPage, data } = res.data;
      setLastPage(lastPage);
      setNFTContracts(
        data.map(
          (item: any): NFTContract => ({
            id: item.id,
            name: item.project_name,
            network: item.network_available,
            address: item.collection_nft,
          })
        )
      );
      setFailure(false);
    } catch (e) {
      setFailure(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    retrieveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function handleRedirectPage(id: number | string) {
    history.push(adminRoute(`/nft-contracts/${id}`));
  }

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const onRenderPagination = () => {
    return (
      nftContracts &&
      lastPage > 1 && (
        <Pagination
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/nft-contracts/add')} text="Add a new NFT Contract" />
        </div>
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={nftContracts}
        onRenderContent={(item) => {
          return <NFTContractRow key={item.id} data={item} onClick={handleRedirectPage} />;
        }}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default NFTContracts;
