import { CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { RadioGroup, TextInput } from '../../../../components/Base/Form';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { NETWORK_AVAILABLE } from '../../../../constants';
import { createProjectMatrix, updateProjectMatrix } from '../../../../request/completed';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { ProjectMatrixType } from '../../ProjectMatrixRow/ProjectMatrixRow';

interface Completed extends Omit<ProjectMatrixType, 'project_icon'> {
  project_icon: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

interface PostFixPrice {
  post_ath_price: string;
}

interface FormMatrix extends Completed, PostFixPrice {}

const LandingProjectForm = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: Completed; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, errors, watch, getValues } = useForm<FormMatrix>({
    mode: 'onChange',
    defaultValues: {
      project_icon: {
        uploadType: UploadType.LINK,
        link: '',
      },
      project_name: '',
      ath_roi: '',
      description: '',
      project_network: '',
      total_raise: '',
      category1: '',
      category2: '',
      total_supply: '',
      ath_price: '',
      priority: '',
      post_ath_price: '',
    },
    reValidateMode: 'onChange',
    // resolver: yupResolver(),
  });

  useEffect(() => {
    if (completed) {
      const allPart = completed?.ath_price?.split('_') || [];
      let price = '';
      let postFix = '';
      if (allPart.length > 1) {
        price = allPart[0];
        postFix = allPart[1];
      }
      reset({
        project_icon: {
          link: completed.project_icon,
          uploadedUrl: completed.project_icon,
          uploadType: UploadType.LINK,
        },
        project_name: completed.project_name,
        ath_roi: completed.ath_roi,
        description: completed.description,
        project_network: completed.project_network,
        total_raise: completed.total_raise,
        category1: completed.category1,
        category2: completed.category2,
        total_supply: completed.total_supply,
        ath_price: price,
        priority: completed.priority,
        post_ath_price: postFix,
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: FormMatrix) => {
    setIsOpenModal(false);
    let project_icon = values.project_icon.link;
    setLoading(true);
    let finalPrice = '';
    if (values.ath_price && values.post_ath_price) {
      finalPrice = values.ath_price + '_' + values.post_ath_price;
    } else {
      if (values.ath_price) {
        finalPrice = values.ath_price;
      } else {
        finalPrice = values.post_ath_price;
      }
    }
    try {
      if (values.project_icon.uploadType === UploadType.FILE && !!values.project_icon.file) {
        project_icon = await uploadFile(values.project_icon.file);
      }

      if (values.project_icon.uploadType === UploadType.LINK) {
        project_icon = values.project_icon.link ?? '';
      }

      const trimmedValue = {
        project_icon,
        project_name: values.project_name,
        ath_roi: values.ath_roi,
        description: values.description,
        project_network: values.project_network,
        total_raise: values.total_raise,
        category1: values.category1,
        category2: values.category2,
        total_supply: values.total_supply,
        ath_price: finalPrice,
        priority: values.priority,
      };

      if (isCreate) {
        const res = await createProjectMatrix(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/project-matrix'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateProjectMatrix(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/project-matrix'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/completed')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <label>Project Matrix Information</label>
            <TextInput name="project_name" control={control} label="Project Name" isRequired={true} />
            <TextInput name="description" control={control} label="Description" isRequired={true} />
            <TextInput name="ath_roi" control={control} label="ATH Roi" isRequired={false} />
            <TextInput name="total_raise" control={control} label="Total raise" isRequired={false} />
            <TextInput name="category1" control={control} label="Category 1" isRequired={false} />
            <TextInput name="category2" control={control} label="Category 2" isRequired={false} />
            <TextInput name="total_supply" control={control} label="Total Supply" isRequired={false} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextInput
                style={{ width: '160%' }}
                name="ath_price"
                control={control}
                label="ATH price"
                isRequired={false}
              />
              <TextInput
                style={{ width: '40%', marginTop: -17, position: 'absolute', right: 0 }}
                name="post_ath_price"
                control={control}
                isRequired={false}
                placeholder="ETH"
              />
            </div>
            <TextInput name="priority" control={control} label="Priority" isRequired={false} />
          </Paper>
        </Grid>

        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Network Available"
                  control={control}
                  name="project_network"
                  radioData={[
                    {
                      value: NETWORK_AVAILABLE.POLYGON,
                      label: 'Polygon',
                    },
                    {
                      value: NETWORK_AVAILABLE.SOL,
                      label: 'Solana',
                    },
                    {
                      value: NETWORK_AVAILABLE.ETH,
                      label: 'Ethereum',
                    },
                    {
                      value: NETWORK_AVAILABLE.BSC,
                      label: 'BSC',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel
                      key={data.value}
                      value={data.value}
                      label={data.label}
                      control={<Radio size="small" />}
                    />
                  )}
                />
              </div>
            </>
          </Paper>

          <Paper>
            <>
              <label>Project Icon</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={
                  {
                    link: completed?.project_icon || '',
                    uploadedUrl: completed?.project_icon || '',
                    uploadType: UploadType.LINK,
                  } as any
                }
                formName="project_icon"
                errors={errors?.project_icon}
                selectTooltip={
                  <>
                    Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                  </>
                }
              />
            </>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the project' : 'Update the project'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>
          Are you sure to {isCreate ? 'create this project?' : 'update the project?'}
        </p>
      </ConfirmDialog>
    </>
  );
};

export default LandingProjectForm;
