import Pagination from '@material-ui/lab/Pagination';
import { debounce } from 'lodash';
import React, { useState, useEffect } from 'react';
import Button from '../../components/Base/ButtonLink';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import TableCommon from '../../components/TableCommon';
import { getCompletedList } from '../../request/completed';
import { adminRoute } from '../../utils';
import PartnerRecord from './CompletedRow/CompletedRow';
import useStyles from './style';

const tableHeaders = ['PROJECT NAME', 'TOKEN SYMBOL', 'STATUS', 'ACTION'];

const Completed: React.FC<any> = (props: any) => {
  const limit = 8;
  const classes = useStyles();
  const [partner, setPartner] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [failure, setFailure] = useState<boolean>(false);
  const [currentOpen, setCurrentOpen] = useState('');
  const [lastPage, setLastPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(page);
  const [query, setQuery] = useState('');

  const getCompletedInfo = async (currentPage: any, query:any) => {
    const queryParams = {
      page: currentPage,
      limit:limit,
      name:query
    };

    try {
      setLoading(true);
      const resObject = await getCompletedList(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setPartner(data);
        setPage(page);
        setLastPage(lastPage);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getCompletedInfo(currentPage, query);
  }, [currentPage, query]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderAdmin = (completed: any) => {
    return (
      <PartnerRecord
        key={completed.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        completed={completed}
        onReload={() => {
          if (currentPage === 1) {
            getCompletedInfo(1, query);
          } else {
            setCurrentPage(1);
          }
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      partner &&
      lastPage > 1 && (
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };

  const handleCompletedSearch = (event:any) => {
    setPage(1);
    setQuery(event.target.value)
  }
  const delayCompletedSearch = debounce(handleCompletedSearch, 500);

  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/completed/create')} text={'Create new completed project'} />
        </div>
        <div className={classes.boxSearch}>
          <img className={classes.iconSearch} src="/images/icon-search.svg" alt="" />
          <input className={classes.inputSearch} onChange={delayCompletedSearch} placeholder="Search" />
        </div>
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={partner}
        onRenderContent={onRenderAdmin}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default Completed;
