import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import StepParticipateDetail from './Detail/StepParticipateDetail';

const StepParticipateCreate = () => {
  return (
    <DefaultLayout>
      <StepParticipateDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(StepParticipateCreate);
