/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import idl from '../../../../abi/Reward.json';
import { Button } from '../../../../components/Base/Form';
import { initWorkspace } from '../../../../hooks/useWorkspace';
import { updateRewardPool } from '../../../../request/admin';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import { unixTime } from '../../../../utils/convertDate';
import { uploadFile } from '../../../../utils/file';
import { UploadType } from '../../../SliderManage/constants/type';
import { NewTokenReward } from '../components/NewTokenReward';
import { DEFAULT_REWARD, VALIDATION_SCHEMA_REWARD } from '../contants';
import useStyles from './style';

interface RewardStakingProps {
  isEdit: boolean;
  poolDetail?: any;
  originPool?: any;
}

// const PROGRAM = process.env.REACT_APP_SOLANA_PROGRAM_REWARD || '';

export function EditRewardForm({ isEdit, poolDetail, originPool }: RewardStakingProps) {
  // const { connection } = initWorkspace({
  //   idl: idl,
  //   publicKey: PROGRAM,
  // });
  const { id } = useParams<any>();
  const classes = { ...useStyles() };
  const [token, setToken] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_REWARD,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA_REWARD),
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { register, setValue, reset, errors, handleSubmit, control, getValues } = form;

  useEffect(() => {
    const resetValue = poolDetail ? poolDetail : DEFAULT_REWARD;

    reset({
      ..._.cloneDeep(resetValue),
    });
  }, [poolDetail, reset]);

  const createPoolStakeNft = async (data: any) => {
    setLoading(true);
    let stake_pool_id = Number(id);
    const { decimals, symbol } = token;
    let token_image;
    if (data.banner.uploadType === UploadType.FILE && !!data.banner.file) {
      token_image = await uploadFile(data.banner.file);
    } else {
      token_image = data.banner.link;
    }
    let dataSave = {
      token_name: data.token_name,
      token_symbol: symbol,
      token_decimal: decimals,
      network: data.network,
      token_image,
      token_address: data.token_address,
      total_reward: data.total_reward,
      reward_duration: data.reward_duration,
      start_time: unixTime(data.start_time),
      currency: data.currency,
      exchange_rate: data.exchange_rate,
      end_time: unixTime(data.end_time),
      is_claim: Number(data.is_claim || 0),
      is_direct_claim : Number(data.is_direct_claim || 0),
      claim_policy: data.claim_policy,
      reward_percent_normal: data.reward_percent_normal,
      reward_percent_ido: data.reward_percent_ido,
      ido_pool_id: data.ido_pool_id,
      is_display: 1,
      rw_token_account: null,
      min_spent : Number(data.min_spent || 0)
    };

    try {
      const response = await updateRewardPool(dataSave, stake_pool_id);
      if (response) {
        if (response?.status === 200) {
          dispatch(alertSuccess('Update successfully!'));
          history.push(adminRoute('/reward'));
        } else {
          dispatch(alertFailure('Update failed'));
        }
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    handleSubmit(createPoolStakeNft)();
  };

  return (
    <>
      <input type="checkbox" name="needValidate" ref={register} hidden />
      <div className="contentPage">
        <Grid item xs={12}>
          <NewTokenReward
            poolDetail={poolDetail}
            token={token}
            setToken={setToken}
            formMethods={form}
            register={register}
            isEdit={isEdit}
            originPool={originPool}
            // connection={connection}
            getValues={getValues}
          />
        </Grid>

        <Grid container spacing={2} justifyContent={'space-between'}>
          <Button
            disabled={loading}
            onClick={validateForm}
            className={classes.formButtonUpdatePool}
            style={{ width: '47%' }}
          >
            {loading && <CircularProgress size={25} />}
            {!loading && 'Update Token Reward'}
          </Button>
        </Grid>
      </div>
    </>
  );
}
