import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import ProjectYieldBoxConfigDetail from './Detail/ProjectYieldBoxConfigDetail';

const ProjectYieldBoxConfigCreate = () => {
  return (
    <DefaultLayout>
      <ProjectYieldBoxConfigDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(ProjectYieldBoxConfigCreate);
