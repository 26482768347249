import FormControl from '@material-ui/core/FormControl';
import { Switch } from 'antd';
import { Controller } from 'react-hook-form';
import { withRouter } from 'react-router';
import useFormStyles from '../useFormStyles';
import useStyles from '../StakedXborgSetting/style';

function NewEnableButtonClaim(props: any) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { control , name , label} = props;

  return (
    <div className={classes.boxToggle}>
      <label className={classes.formControlLabel}>{label || 'Enable Claim'}</label>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name={name}
          render={(field) => {
            const { value, onChange } = field;

            return (
              <Switch
                onChange={async (switchValue) => {
                  onChange(switchValue);
                }}
                checked={value}
                checkedChildren="Display"
                unCheckedChildren="Hidden"
              />
            );
          }}
        />
      </FormControl>
      <br />
    </div>
  );
}

export default withRouter(NewEnableButtonClaim);
