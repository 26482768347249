import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import LandingProjectForm from './FormLandingProject';

const LandingProjectDetail = (props: any) => {
  const { completed, isCreate } = props;

  const showCompletedCreate = () => {
    return <LandingProjectForm completed={completed} isCreate={isCreate} />;
  };

  const showCompletedDetail = () => {
    if (!_.isEmpty(completed)) {
      return <LandingProjectForm completed={completed} isCreate={isCreate} />;
    }
    return <p>Loading...</p>;
  };

  return <div>{isCreate ? showCompletedCreate() : showCompletedDetail()}</div>;
};

export default withRouter(LandingProjectDetail);
