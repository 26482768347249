import { useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { getPhantomWallet, getSolletExtensionWallet, getSolletWallet } from '@solana/wallet-adapter-wallets';
import { PublicKey } from '@solana/web3.js';
import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACCESS_TOKEN_KEY } from '../../constants/authentication';
import useAuth from '../../hooks/useAuth';
import { useConnection } from '../../hooks/useConnection';
import { useSelectedNetwork } from '../../hooks/useSelectedNetwork';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useWalletSignatureAsync } from '../../hooks/useWalletSignatureAsync';
import { BaseRequest } from '../../request/Request';
import { formatNumber, transformLamportsToSOL } from '../../shared/help';
import { logout as logoutAction, setConnectUser } from '../../store/actions/user';
import { userActions } from '../../store/constants/user';
import { validateSolAddress } from '../../utils/solana';
import { ConnectionProvider } from './connection';
interface SolanaLocalContextValues {
  logout: () => Promise<any>;
  balanceSol: {
    value: number;
    formatted: string;
  };
  updateCurrentBalance: () => void;
  login: () => Promise<any>;
}

export const SolanaLocalContext = createContext<SolanaLocalContextValues>({
  logout: () => Promise.resolve(),
  balanceSol: {
    value: 0,
    formatted: '0',
  },
  updateCurrentBalance: () => {},
  login: () => Promise.resolve(false),
});

const SolanaLocalProvider: FC = ({ children }) => {
  const { disconnect, publicKey } = useWallet();
  const connectedUser = useTypedSelector((state) => state.userConnect).data;
  const dispatch = useDispatch();
  const { isSelectedSolana } = useSelectedNetwork();
  const { solanaSign } = useWalletSignatureAsync();
  const [balanceSol, setBalanceSol] = useState({
    value: 0,
    formatted: '0',
  });
  const { connection } = useConnection();
  const isSolAddress = useMemo(() => validateSolAddress(connectedUser), [connectedUser]);

  const logout = useCallback(() => {
    return new Promise<any>((resolve) => {
      disconnect().then(() => {
        dispatch(logoutAction());
        dispatch(setConnectUser(undefined));
        resolve(null);
      });
    });
  }, [disconnect, dispatch]);

  // Logout user if login publickey storage not match with current public key
  useEffect(() => {
    if (isSolAddress && publicKey && connectedUser && publicKey.toString() !== connectedUser && isSelectedSolana) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey, connectedUser, isSolAddress]);

  useEffect(() => {
    updateCurrentBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedUser]);

  function login() {
    return new Promise<any>((resolve, reject) => {
      dispatch({
        type: userActions.USER_LOGIN_LOADING,
      });

      return solanaSign()
        .then((signature) => {
          const baseRequest = new BaseRequest();

          return baseRequest.post(`/admin/sol-login`, {
            signature: Buffer.from(signature),
            wallet_address: publicKey?.toString(),
          });
        })
        .then((res) => res.json())
        .then((resultObj) => {
          if (resultObj && resultObj.status && resultObj.status !== 200) {
            dispatch({ type: userActions.USER_LOGIN_FAILURE, payload: '' });
            reject?.(resultObj);
            return;
          }

          const { token, user } = resultObj.data;
          localStorage.setItem(ACCESS_TOKEN_KEY, token.token);

          dispatch({
            type: userActions.USER_LOGIN_SUCCESS,
            payload: user,
          });

          dispatch(setConnectUser(publicKey?.toString()));

          resolve?.(resultObj);
        })
        .catch((e) => {
          reject?.(e);
        });
    });
  }

  const updateCurrentBalance = () => {
    if (!isSolAddress) {
      return;
    }

    const currentPublicKey = connectedUser || publicKey;

    if (!currentPublicKey) {
      setBalanceSol({
        value: 0,
        formatted: '0',
      });

      return;
    }

    connection
      .getAccountInfo(new PublicKey(currentPublicKey))
      .then((response) => {
        const balanceResult = transformLamportsToSOL(response?.lamports ?? 0);
        setBalanceSol({
          value: balanceResult ?? 0,
          formatted: formatNumber.format(balanceResult) as string,
        });
      })
      .catch((err) => {});
  };

  return (
    <SolanaLocalContext.Provider
      value={{
        logout,
        balanceSol,
        updateCurrentBalance,
        login,
      }}
    >
      {children}
    </SolanaLocalContext.Provider>
  );
};

export const SolanaProvider: FC = ({ children }) => {
  const wallets = useMemo(() => [getPhantomWallet(), getSolletWallet(), getSolletExtensionWallet()], []);
  const { isAuth } = useAuth();
  const { isSelectedSolana } = useSelectedNetwork();

  return (
    <ConnectionProvider>
      <WalletProvider autoConnect={isAuth && isSelectedSolana} wallets={wallets}>
        <SolanaLocalProvider>{children}</SolanaLocalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
