import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
        color: 'red'
    }
  };
});

export default useStyles;
