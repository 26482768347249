import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { BaseRequest } from '../../request/Request';
import { Slide } from './components/Slide';
import { Media } from './constants/type';
import { convertMedia } from './utils';
import Button from '../../components/Base/ButtonLink';
import styles from './styles.module.scss';
import { adminRoute } from '../../utils';
import { SlideConfirmModal } from './components/ConfirmModal';
import { alertFailure, alertSuccess } from '../../store/actions/alert';
import { MESSAGES } from '../../constants/alert';

const SliderManage = () => {
  const [media, setMedia] = useState<Media[]>([]);
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState<string | number>(-1);

  async function getLast3Media() {
    try {
      const request = new BaseRequest();
      const response = await request.get('/media-list');

      if (response.status !== 200) {
        throw new Error('Failed to get media list');
      }

      const result = await response.json();

      if (result.status !== 200) {
        throw new Error(result.message);
      }

      setMedia(result.data.map((item: any) => convertMedia(item)));
    } catch (e: any) {
      dispatch(alertFailure(e.message));
    }
  }

  async function deleteSlide() {
    try {
      const request = new BaseRequest();
      const response = await request.delete(`/admin/media/${deleteId}`);

      if (response.status !== 200) {
        throw new Error('Failed to delete media!');
      }

      const result = await response.json();

      if (result.status !== 200) {
        throw new Error(result.message);
      }

      setDeleteId(-1);
      dispatch(alertSuccess(MESSAGES.DELETE_SUCCESS));
      await getLast3Media();
    } catch (e: any) {
      dispatch(alertFailure(e.message));
    }
  }

  useEffect(() => {
    getLast3Media();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <h2 className={styles.title}>Slider Media Upload</h2>
      <Button to={adminRoute('/sliders/add')} text="Create New Slide" className={styles['create-slide-btn']} />
      <div>
        {media.map((item) => (
          <Slide onDelete={(id: number | string) => setDeleteId(id)} key={item.id} data={item} />
        ))}
      </div>
      <SlideConfirmModal
        open={deleteId !== -1}
        content="Are you sure to delete this project?"
        onCancel={() => setDeleteId(-1)}
        onConfirm={() => deleteSlide()}
      />
    </DefaultLayout>
  );
};

export default SliderManage;
