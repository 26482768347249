import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createTheme'

interface Props {
  spacing: number;
}

const useStyles = makeStyles<Theme, Props>(props => ({
  button: {
    background: 'linear-gradient(90deg, #61009D 0%, rgba(90, 231, 240, 0.64) 100%)',
    boxShadow: '0px 2px 4px rgba(138, 146, 166, 0.3)',
    border: 'none',
    borderRadius: 10,
    display: 'inline-block',
    fontFamily: 'Roboto-Medium',
    cursor: 'pointer',
    transition: '.2s all ease-in',
    padding:'8px 24px',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      boxShadow: '0px 15px 20px rgba(0, 0, 0, .1)',
      transform: 'translateY(-7px)'
    }
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    // fontSize: '1rem',
    color: 'white',
    fontWeight: 500,
    marginBottom:0
  },
  buttonText: {
  marginLeft: props => props.spacing
}
}));

export default useStyles;
