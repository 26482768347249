import { Dialog, FormHelperText } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletName } from '@solana/wallet-adapter-wallets';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Base/Form';
import { ConnectorNames, connectorsSupportByNetwork } from '../../../constants/connectors';
import { appNetworkType, APP_NETWORKS, APP_NETWORKS_NAME, BSC_CHAIN_ID, ETH_CHAIN_ID, POLYGON_CHAIN_ID } from '../../../constants/network';
import { useWeb3ReactLocal } from '../../../hooks/useWeb3ReactLocal';
import { isMetaMaskInstalled } from '../../../services/web3';
import { SupportSolletWallet } from '../../../utils/solana/sollet/interface';
import { ComponentProps, ContentSection, DialogContent, DialogTitle } from '../Modal';
import { ConnectNetworkBox } from './ConnectWalletBox/ConnectNetworkBox';
import ConnectWalletBox from './ConnectWalletBox/ConnectWalletBox';
import useStyles from './style';

const WALLET_INSTALL: Record<string, string> = {
  [ConnectorNames.MetaMask]: 'https://metamask.io/download/',
  [WalletName.Phantom]: 'https://phantom.app/download',
  [WalletName.Sollet]: 'https://chrome.google.com/webstore/detail/sollet/fhmfendgdocmcbmfikdcogofphimnkno',
};

type SelectedWallet = {
  name: string;
  connector: AbstractConnector;
  appChainId: string;
};

const SolanaWallet = {
  [WalletName.Phantom]: WalletName.Phantom,
  [WalletName.Sollet]: WalletName.SolletExtension,
};

function checkExtensionAvailable(walletName: string) {
  if (walletName === ConnectorNames.MetaMask && !isMetaMaskInstalled()) {
    return false;
  }

  if (walletName === ConnectorNames.Phantom && !(window as any).solana) {
    return false;
  }

  if (walletName === ConnectorNames.Sollet && !(window as any).sollet) {
    return false;
  }

  return true;
}

const ConnectWalletModal: React.FC<ComponentProps> = ({ opened, handleClose, width }: ComponentProps) => {
  const connectingWallet = useRef(false);
  const [walletError, setWalletError] = useState<string>('');
  const [selectedWallet, setSelectedWallet] = useState<SelectedWallet>({
    name: '',
    connector: {} as any,
    appChainId: '',
  });
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;
  const { select, wallet, connect } = useWallet();
  const { connectWallet: connectWeb3Wallet } = useWeb3ReactLocal();
  const solanaCurrentWallet = useRef(wallet?.name ?? '');
  const styles = useStyles();

  const connectorsByNetwork = (() => {
    switch (appChainID) {
      case ETH_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.METAMASK];
      case POLYGON_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.POLYGON];
      case BSC_CHAIN_ID:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.BSC];
      // case ETH_CHAIN_ID:
      //   return SUPPORTED_WALLETS;
      default:
        return connectorsSupportByNetwork[APP_NETWORKS_NAME.SOLANA];
    }
  })();

  function handleWalletChosen(name: string, connector: AbstractConnector, appChainId: string) {
    setSelectedWallet({
      name: name,
      connector: connector,
      appChainId: appChainId,
    });
  }

  function connectSolanaWallet() {
    connect()
      .then(() => {
        handleClose();
      })
      .catch(() => {
        //handleClose();
      });
  }

  // Handle solana wallet connect
  function handleConnectSolanaWallet(name: SupportSolletWallet) {
    if (name !== wallet?.name || !wallet) {
      select(name);
      solanaCurrentWallet.current = name;
      return;
    }

    connectSolanaWallet();
  }

  // Handle another wallet connect
  function connectMetamaskWallet(wallet: string, connector: AbstractConnector) {
    connectingWallet.current = true;

    connectWeb3Wallet(connector, wallet)
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function connectWallet() {
    if (!selectedWallet.name) {
      return;
    }

    const isAvailable = checkExtensionAvailable(selectedWallet.name);

    if (!isAvailable) {
      setWalletError(selectedWallet.name);
      return;
    }

    const solanaWalletName = SolanaWallet[selectedWallet.name];
    if (solanaWalletName) {
      handleConnectSolanaWallet(solanaWalletName);
      return;
    }

    connectMetamaskWallet(selectedWallet.name, selectedWallet.connector);
  }

  useEffect(() => {
    if (!selectedWallet.name) {
      return;
    }

    connectSolanaWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    setWalletError('');
  }, [appChainID, selectedWallet]);

  useEffect(() => {
    const defaultWallet = Object.values(connectorsByNetwork)[0];
    if (!defaultWallet) {
      return;
    }

    setSelectedWallet({
      name: defaultWallet.name,
      appChainId: appChainID,
      connector: defaultWallet.connector as any,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appChainID]);

  return (
    <Dialog open={opened} onClose={handleClose} className={styles.dialog}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} customClass={styles.dialogTitle}>
        Connect Wallet
      </DialogTitle>
      <DialogContent dividers>
        <ContentSection title="1. Choose network" className={styles.dialogNetworks}>
          {Object.keys(APP_NETWORKS).map((key: string) => {
            const network = APP_NETWORKS[key as appNetworkType];
            return <ConnectNetworkBox key={key} appNetwork={network} />;
          })}
        </ContentSection>
        <ContentSection title="2. Choose wallet" className={styles.dialogNetworks}>
          {Object.keys(connectorsByNetwork).map((key: string) => {
            const network = connectorsByNetwork[key];
            return (
              <ConnectWalletBox
                key={key}
                wallet={network}
                selected={selectedWallet.name === network?.name}
                handleWalletChosen={handleWalletChosen}
              />
            );
          })}
        </ContentSection>
        <ContentSection title="3. Accept" className={styles.dialogPrivacy}>
          <span className={styles.dialogPrivacyText}>
            By clicking sign in you indicate that you have read and agree to our&nbsp;
            <Link className={styles.dialogPrivacyHighlight} to="/terms" target="_blank">
              Terms of Service&nbsp;
            </Link>
            and&nbsp;
            <Link className={styles.dialogPrivacyHighlight} to="/privacy" target="_blank">
              Privacy Policy&nbsp;
            </Link>
          </span>
        </ContentSection>
        <Button className="dialog__connect" onClick={connectWallet}>
          Connect
        </Button>
        {walletError && (
          <FormHelperText className={styles.error} error={true}>
            Oops! {walletError} is unavailable
            <br />
            <a href={WALLET_INSTALL[walletError ?? '']} target="_blank" rel="noreferrer">
              Click here
            </a>
            &nbsp; to install the extension
          </FormHelperText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withWidth()(ConnectWalletModal);
