import { alertSuccess } from '../../store/actions/alert';
import { userActions } from '../constants/user';
import { BaseRequest } from '../../request/Request';
import { getWeb3Instance } from '../../services/web3';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { apiRoute } from '../../utils';

type UserProfileProps = {
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  avatar: string;
};

const MESSAGE_SIGNATURE = process.env.REACT_APP_MESSAGE_SIGNATURE || '';

const getMessageParams = () => {
  const msgSignature = MESSAGE_SIGNATURE;
  return msgSignature;
};

const dispatchErrorWithMsg = (dispatch: Dispatch, action: string, msg: string) => {
  dispatch({
    type: action,
    payload: msg,
  });
};

const getCurrentAccount = async () => {
  const web3Instance = getWeb3Instance();
  const accounts = await web3Instance?.eth.getAccounts();

  if (accounts && accounts.length !== 0) {
    return accounts[0];
  }

  return undefined;
};

export const logout = () => {
  localStorage.removeItem('access_token');

  return {
    type: userActions.USER_LOGOUT,
  };
};

export const setConnectUser = (data?: string | null) => {
  return {
    type: userActions.USER_CONNECT_WALLET_SUCCESS,
    payload: data ?? '',
  };
};

export const clearUserProfileUpdate = () => {
  return {
    type: userActions.USER_PROFILE_UPDATE_CLEAR_ERROR,
  };
};

export const connectWallet = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({ type: userActions.USER_CONNECT_WALLET_LOADING });
    try {
      const windowObj = window as any;
      const { ethereum } = windowObj;
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const loginUser = accounts.length ? accounts[0] : '';

      if (loginUser) {
        dispatch({
          type: userActions.USER_CONNECT_WALLET_SUCCESS,
          payload: loginUser,
        });
      } else {
        dispatch(logout());
      }
    } catch (error) {
      dispatch({
        type: userActions.USER_CONNECT_WALLET_FAILURE,
        payload: error,
      });
    }
  };
};

export const getUserDetail = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({ type: userActions.USER_PROFILE_LOADING });
    try {
      const baseRequest = new BaseRequest();

      const response = (await baseRequest.get(apiRoute('profile'))) as any;
      const resObj = await response.json();

      if (resObj.status && resObj.status === 200) {
        dispatch({
          type: userActions.USER_PROFILE_SUCCESS,
          payload: resObj.data.user,
        });
      } else {
        dispatch({
          type: userActions.USER_PROFILE_FAILURE,
          payload: resObj.message,
        });
      }
    } catch (error) {
      dispatch({
        type: userActions.USER_PROFILE_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateUserProfile = (updatedUser: UserProfileProps) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch({
        type: userActions.USER_PROFILE_UPDATE_LOADING,
      });

      const baseRequest = new BaseRequest();
      const ethAddress = await getCurrentAccount();

      if (ethAddress) {
        const windowObj = window as any;
        const { ethereum } = windowObj;
        const { firstName, lastName, avatar } = updatedUser;
        await ethereum.sendAsync(
          {
            method: 'eth_signTypedData',
            params: [getMessageParams(), ethAddress],
            from: ethAddress,
          },
          async function (err: Error, result: any) {
            if (err || result.error) {
              const errMsg = err.message || result.error.message;
              dispatchErrorWithMsg(dispatch, userActions.USER_PROFILE_UPDATE_FAILURE, errMsg);
            } else {
              const response = (await baseRequest.post(apiRoute('update-profile'), {
                firstname: firstName,
                lastname: lastName,
                avatar,
                signature: result.result,
              })) as any;

              const resObj = await response.json();

              if (resObj.status && resObj.status === 200 && resObj.data) {
                const { user } = resObj.data;

                dispatch(alertSuccess('Update profile successful!'));

                dispatch({
                  type: userActions.USER_LOGIN_SUCCESS,
                  payload: user,
                });

                dispatch({
                  type: userActions.USER_PROFILE_UPDATE_SUCCESS,
                  payload: user,
                });
              }

              if (resObj.status && resObj.status !== 200) {
                dispatchErrorWithMsg(dispatch, userActions.USER_PROFILE_UPDATE_FAILURE, resObj.message);
              }
            }
          }
        );
      }
    } catch (error: any) {
      dispatchErrorWithMsg(dispatch, userActions.USER_PROFILE_UPDATE_FAILURE, error.message);
    }
  };
};
