import { ReactNode, useRef } from 'react';
import { FormControl } from '@material-ui/core';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { HelpText } from '../RepresentData/HelpText';
import useStyles from '../styles';
import { FormLabel } from '../RepresentData/FormLabel';
import RequireSymbol from '../../RequireSymbol';

type TextInputProps = {
  control?: Control<any>;
  label?: string;
  errorMessage?: string | ReactNode;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  isRequired?: boolean;
  currencyInputClass?: string;
  postFix?: any;
} & CurrencyInputProps;

export function NumberInput({
  control,
  label,
  errorMessage,
  rules,
  className,
  isRequired,
  disabled,
  postFix,
  currencyInputClass,
  ...currentcyInput
}: TextInputProps) {
  const inputRef = useRef<any>();
  const styles = useStyles();
  const classes = [styles['form-control'], className].filter(Boolean).join(' ');

  return (
    <Controller
      render={({ value, onChange }, { invalid }) => {
        return (
          <FormControl error={invalid} fullWidth className={classes}>
            {label && (
              <div style={{ display: 'flex', gap: 3 }}>
                <FormLabel htmlFor={currentcyInput.id}>{label}</FormLabel>
                {isRequired && <RequireSymbol />}
              </div>
            )}
            <CurrencyInput
              {...currentcyInput}
              value={value}
              onValueChange={(value) => {
                onChange(value);
              }}
              ref={inputRef}
              disabled={disabled}
              className={`${styles['number-input']}`}
            />
            {postFix && <>{postFix}</>}
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      rules={rules}
      name={currentcyInput.name ?? 'text-field'}
      control={control}
    />
  );
}
