import { ReactNode, useRef } from 'react';
import { CheckboxProps, FormControl, Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { HelpText } from '../RepresentData/HelpText';
import useStyles from '../styles';

type TextInputProps = {
  control?: Control<any>;
  label?: string;
  errorMessage?: string | ReactNode;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  isRequired?: boolean;
} & CheckboxProps;

export function Checkbox({
  control,
  label,
  errorMessage,
  rules,
  className,
  isRequired,
  ...currentcyInput
}: TextInputProps) {
  const inputRef = useRef<any>();
  const styles = useStyles();
  const classes = [styles['form-control'], className].filter(Boolean).join(' ');

  return (
    <Controller
      render={({ value, onChange }, { invalid }) => {
        return (
          <FormControl error={invalid} className={classes}>
            <FormControlLabel
              control={
                <MUICheckbox
                  inputRef={inputRef}
                  color="secondary"
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
              label={label}
            />
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      rules={rules}
      name={currentcyInput.name ?? 'text-field'}
      control={control}
    />
  );
}
