import React from 'react';
import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import PartnerDetail from './Detail/PartnerDetail';

const CreatePartners = () => {
  return (
    <DefaultLayout>
      <PartnerDetail partner={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(CreatePartners);
