import { useDispatch } from 'react-redux';
import { MAPPING_CURRENCY_ADDRESS, NATIVE_TOKEN_ADDRESS, NETWORK_AVAILABLE } from '../../../../../constants';
import BigNumber from 'bignumber.js';
import ContractABI from '../../../../../abi/FactoryCommunityPoolABI.json';
import { getContractInstance } from '../../../../../services/web3';
import { updateDeploySuccess } from '../../../../../request/pool';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { alertActions } from '../../../../../store/constants/alert';
import { trim } from 'lodash';
import { getTokenInfo } from '../../../../../utils/token';

interface DeployData {
  token: string;
  startTime: number | null;
  finishTime: number | null;
  acceptCurrency: string;
  tokenInfo: any;
  tokenRate: string | number;
  networkAvailable: string;
  addressReceiver: string;
  wallet: any;
  id?: string | number;
  tax: string | number;
}

function getContractAddress(network: string) {
  switch (network) {
    case NETWORK_AVAILABLE.BSC:
      return process.env.REACT_APP_SMART_CONTRACT_BSC_FACTORY_ADDRESS_COMUNITY;
    case NETWORK_AVAILABLE.POLYGON:
      return process.env.REACT_APP_SMART_CONTRACT_POLYGON_FACTORY_ADDRESS_COMUNITY;
    case NETWORK_AVAILABLE.ETH:
      return process.env.REACT_APP_SMART_CONTRACT_ETH_FACTORY_ADDRESS_COMUNITY;
    default:
      throw new Error('Network are not supported');
  }
}

function getChainId(network: string) {
  switch (network) {
    case NETWORK_AVAILABLE.BSC:
      return process.env.REACT_APP_BSC_NETWORK_ID;
    case NETWORK_AVAILABLE.POLYGON:
      return process.env.REACT_APP_POLYGON_NETWORK_ID;
    case NETWORK_AVAILABLE.ETH:
      return process.env.REACT_APP_NETWORK_ID;
    default:
      throw new Error('Network are not supported');
  }
}

export function useDeploy() {
  const user = useTypedSelector((state) => state.user).data;
  const dispatch = useDispatch();

  async function deploy(pool: DeployData) {
    const { tokenRate, tokenInfo, acceptCurrency, networkAvailable, token, tax, addressReceiver } = pool;

    // native token
    let paidTokenAddress = MAPPING_CURRENCY_ADDRESS[networkAvailable]?.[acceptCurrency];
    if (!paidTokenAddress) {
      paidTokenAddress = NATIVE_TOKEN_ADDRESS;
    }

    const maxTax = new BigNumber(10).pow(18);
    // let reversedRate = new BigNumber(1).dividedBy(tokenRate).toFixed();
    const taxRate = maxTax.multipliedBy(tax).div(100).toString();

    const contractAddress = getContractAddress(networkAvailable);
    const factorySmartContract = getContractInstance(ContractABI, contractAddress || '', false);

    if (!factorySmartContract) {
      throw new Error('Failed to get contract');
    }

    const userWalletAddress = user.wallet_address;
    const signerWallet = pool.wallet.wallet_address;
    const info = await getTokenInfo(paidTokenAddress, getChainId(networkAvailable) as string);
    const ratioTokenWithCurrentcy = new BigNumber(Math.pow(10, tokenInfo?.decimals)).dividedBy(
      Math.pow(10, info?.decimals as any)
    );
    console.log({
      tokenRate,
      ratioTokenWithCurrentcy: ratioTokenWithCurrentcy.toString(),
      info,
      tokenDecimals: tokenInfo?.decimals,
      networkAvailable,
    });
    const DECIMAL_EVM = Math.pow(10, 18);
    const offeredRate = new BigNumber(DECIMAL_EVM)
      .multipliedBy(new BigNumber(1).div(tokenRate))
      .multipliedBy(ratioTokenWithCurrentcy)
      .toFixed(0,0);
    // const offeredRate = new BigNumber(reversedRate).multipliedBy(Math.pow(10, tokenInfo?.decimals || 18)).toFixed();

    console.log('Data deploy :', {
      token,
      paidTokenAddress,
      decimals: '18',
      offeredRate,
      taxRate,
      addressReceiver,
      signerWallet,
    });

    const createdCampaign = await factorySmartContract.methods
      .registerPool(
        token,
        trim(paidTokenAddress),
        '18',
        offeredRate,
        taxRate,
        trim(addressReceiver),
        trim(signerWallet)
      )
      .send({
        from: userWalletAddress,
      });

    console.log('Deploy Response: ', createdCampaign);
    if (!createdCampaign) {
      return;
    }

    dispatch({
      type: alertActions.SUCCESS_MESSAGE,
      payload: 'Deployed Successfully!',
    });

    let campaignHash = '';
    if (createdCampaign?.events && createdCampaign?.events && createdCampaign?.events[0]) {
      campaignHash = createdCampaign?.events[0].address;
    }
    const updateData = {
      campaign_hash: campaignHash,
      token_symbol: tokenInfo?.symbol,
      token_name: tokenInfo?.name,
      token_decimals: tokenInfo?.decimals,
      token_address: tokenInfo?.address,
      transaction_hash: createdCampaign.transactionHash,
    };

    await updateDeploySuccess(updateData, pool.id);
    window.location.reload();
  }

  return {
    deploy,
  };
}
