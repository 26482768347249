import { FormControl, MenuItem, Select, SelectProps } from '@material-ui/core';
import { ReactNode, useRef } from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { HelpText } from '../RepresentData/HelpText';
import { FormLabel } from '../RepresentData/FormLabel';
import useStyles from '../styles';
import RequireSymbol from '../../RequireSymbol';

type KeyValue<K, V> = {
  key: K;
  value: V;
};

type DropdownProps<TKey, TValue> = {
  control?: Control<any>;
  options: KeyValue<TKey, TValue>[];
  onRenderOptions?: (item: KeyValue<TKey, TValue>) => ReactNode;
  errorMessage?: string | ReactNode;
  isRequired?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & SelectProps;

export function Dropdown({
  control,
  options,
  onRenderOptions,
  label,
  className,
  errorMessage,
  rules,
  isRequired,
  ...dropdownProps
}: DropdownProps<string | number | readonly string[] | undefined, string>) {
  const inputRef = useRef<any>();
  const styles = useStyles();
  const classes = [styles['form-control'], className].filter(Boolean).join(' ');

  return (
    <Controller
      render={({ value, onChange }, { invalid }) => {
        return (
          <FormControl error={invalid} fullWidth className={classes}>
            {label && (
              <FormLabel htmlFor={dropdownProps.id}>
                {label} {isRequired && <RequireSymbol />}
              </FormLabel>
            )}
            <Select {...dropdownProps} value={value} onChange={onChange} inputRef={inputRef} variant="outlined">
              {options.map(
                (item) =>
                  onRenderOptions?.(item) ?? (
                    <MenuItem key={item.key + ''} value={item.key}>
                      {item.value}
                    </MenuItem>
                  )
              )}
            </Select>
            <HelpText>{errorMessage}</HelpText>
          </FormControl>
        );
      }}
      onFocus={() => {
        inputRef?.current?.focus();
      }}
      rules={rules}
      name={dropdownProps.name ?? 'text-field'}
      control={control}
    />
  );
}
