import { CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { RadioGroup, TextInput } from '../../../../components/Base/Form';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { NETWORK_AVAILABLE } from '../../../../constants';
import { createYieldboxInjection, updateYieldBox } from '../../../../request/completed';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { YieldBoxProjectType } from '../../YielBoxProjectRow/YielBoxProjectRow';

interface Completed extends Omit<YieldBoxProjectType, 'project_icon'> {
  project_icon: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

interface FormMatrix extends Completed {}

const FormYielBoxProject = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: Completed; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, errors, watch, getValues } = useForm<FormMatrix>({
    mode: 'onChange',
    defaultValues: {
      project_icon: {
        uploadType: UploadType.LINK,
        link: '',
      },
      project_name: '',
      project_network: NETWORK_AVAILABLE.POLYGON,
      token: '',
      pre_token: '',
      ath_value: '',
      pre_ath_value: '',
      priority: '',
      ath_price: '',
      pre_ath_price: '',
      amount_inject: ''
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (completed) {
      reset({
        project_icon: {
          link: completed.project_icon,
          uploadedUrl: completed.project_icon,
          uploadType: UploadType.LINK,
        },
        project_name: completed.project_name,
        project_network: completed.project_network,
        ath_price: completed.ath_price,
        priority: completed.priority,
        pre_ath_price: completed.pre_ath_price,
        token: completed.token,
        pre_token: completed.pre_token,
        ath_value: completed.ath_value,
        pre_ath_value: completed.pre_ath_value,
        amount_inject: completed.amount_inject,
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: FormMatrix) => {
    setIsOpenModal(false);
    let project_icon = values.project_icon.link;
    setLoading(true);

    try {
      if (values.project_icon.uploadType === UploadType.FILE && !!values.project_icon.file) {
        project_icon = await uploadFile(values.project_icon.file);
      }

      if (values.project_icon.uploadType === UploadType.LINK) {
        project_icon = values.project_icon.link ?? '';
      }

      const trimmedValue = {
        project_icon,
        project_name: values.project_name,
        project_network: values.project_network,
        ath_price: values.ath_price,
        priority: values.priority,
        pre_ath_price: values.pre_ath_price,
        token: values.token,
        pre_token: values.pre_token,
        ath_value: values.ath_value,
        pre_ath_value: values.pre_ath_value,
        amount_inject: values.amount_inject,
      };

      if (isCreate) {
        const res = await createYieldboxInjection(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/project-yieldbox'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateYieldBox(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/project-yieldbox'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/project-yieldbox')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <label>Yieldbox Injection Information</label>
            <TextInput name="project_name" control={control} label="Project Name" isRequired={true} />
            <TextInput name="amount_inject" control={control} label="Amount Inject" />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100px' }}>
                <TextInput name="pre_token" control={control} isRequired={false} placeholder="$" label="Token" />
              </div>
              <TextInput style={{
                marginTop: 23
              }} name="token" control={control} isRequired={false} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100px' }}>
                <TextInput name="pre_ath_value" control={control} isRequired={false} placeholder="$" label="ATH Value" />
              </div>
              <TextInput style={{
                marginTop: 23
              }} name="ath_value" control={control} isRequired={false} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100px' }}>
                <TextInput name="pre_ath_price" control={control} isRequired={false} placeholder="$" label="ATH price" />
              </div>
              <TextInput style={{
                marginTop: 23
              }} name="ath_price" control={control} isRequired={false} />
            </div>
            <TextInput name="priority" control={control} label="Priority" isRequired={false} />
          </Paper>
        </Grid>

        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Network Available"
                  control={control}
                  name="project_network"
                  radioData={[
                    {
                      value: NETWORK_AVAILABLE.POLYGON,
                      label: 'Polygon',
                    },
                    {
                      value: NETWORK_AVAILABLE.SOL,
                      label: 'Solana',
                    },
                    {
                      value: NETWORK_AVAILABLE.ETH,
                      label: 'Ethereum',
                    },
                    {
                      value: NETWORK_AVAILABLE.BSC,
                      label: 'BSC',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel
                      key={data.value}
                      value={data.value}
                      label={data.label}
                      control={<Radio size="small" />}
                    />
                  )}
                />
              </div>
            </>
          </Paper>

          <Paper>
            <>
              <label>Project Icon</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={
                  {
                    link: completed?.project_icon || '',
                    uploadedUrl: completed?.project_icon || '',
                    uploadType: UploadType.LINK,
                  } as any
                }
                formName="project_icon"
                errors={errors?.project_icon}
                selectTooltip={
                  <>
                    Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                  </>
                }
              />
            </>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the project' : 'Update the project'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>
          Are you sure to {isCreate ? 'create this project?' : 'update the project?'}
        </p>
      </ConfirmDialog>
    </>
  );
};

export default FormYielBoxProject;
