import { Control, DeepMap, FieldError } from 'react-hook-form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { TextInput } from '../../../../../components/Base/Form';

interface ScocialSettingProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  children?: any;
}

export function SocialSetting({ control, errors, children }: ScocialSettingProps) {
  return (
    <Paper>
      <PaperTitle>Social Setting</PaperTitle>
      <TextInput
        name="twitterLink"
        control={control}
        errorMessage={errors?.twitterLink?.message}
        label="Twitter Link"
      />
      <TextInput
        name="telegramLink"
        control={control}
        errorMessage={errors?.telegramLink?.message}
        label="Telegram Link"
      />
      <TextInput name="mediumLink" control={control} errorMessage={errors?.mediumLink?.message} label="Medium Link" />
      {children}
    </Paper>
  );
}
