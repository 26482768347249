import { CircularProgress, Tooltip } from '@material-ui/core';
// import { PublicKey } from '@solana/web3.js';
import { debounce, get } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldName, UseFormMethods } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HelpText } from '../../../../../components/Base/Form';
import RequireSymbol from '../../../../../components/Base/RequireSymbol';
import { NETWORK_AVAILABLE_CHAINID } from '../../../../../constants';
import { SOLANA_CHAIN_ID } from '../../../../../constants/network';
import { isValidAddress } from '../../../../../services/web3';
import { getTokenInfo } from '../../../../../utils/token';
import useFormStyles from '../useFormStyles';
import useStyles from './style';

interface TokenAddressProps {
  poolDetail?: any;
  token: any;
  setToken: any;
  disabled?: boolean;
  formMethods: UseFormMethods<any>;
  forName: string;
  register: any;
  nameNetwork: string;
  originPool: any;
  // connection: any;
}

export function TokenAddress(props: TokenAddressProps) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { appChainID: appChainId } = useSelector((state: any) => state.appNetwork).data;
  const [loadingToken, setLoadingToken] = useState(false);
  const { poolDetail, token, setToken, formMethods, register, forName, nameNetwork, disabled } = props;

  const { errors, watch, setError, clearErrors, getValues } = formMethods;
  const network = watch<string, string>(`${nameNetwork}`) as string;
  const choosenNetwork = NETWORK_AVAILABLE_CHAINID[network];
  // const [solanaVerifed, setSolanaVerifed] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const inputToken = getValues<string, string>(forName) as string;
      if (inputToken) {
        if (choosenNetwork !== SOLANA_CHAIN_ID) {
          loadingTokenData(inputToken);
        } else {
          // getInfoAccount(inputToken);
        }
      }
    }, 300);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appChainId, choosenNetwork, poolDetail]);

  const loadingTokenData = async (tokenValue: string) => {
    console.log('load')
    if (
      !isValidAddress(tokenValue) ||
      choosenNetwork === SOLANA_CHAIN_ID ||
      tokenValue === 'TBA' ||
      !tokenValue?.trim?.() ||
      !choosenNetwork
    ) {
      if (tokenValue.length > 0) {
        setError(forName as FieldName<any>, {
          message: 'Invalid Address',
          shouldFocus: true,
          type: 'validate',
        });
      }
      setLoadingToken(false);
      return;
    }

    try {
      setLoadingToken(true);
      const erc20Token = await getTokenInfo(tokenValue, choosenNetwork);
      if (!erc20Token) {
        throw new Error('Could not find the specified address.');
      }

      const { name, symbol, decimals, address } = erc20Token;
      setLoadingToken(false);
      setToken({
        name,
        symbol,
        decimals,
        address,
      });
      clearErrors(forName as FieldName<any>);
    } catch (err: any) {
      setError(forName as FieldName<any>, {
        message: err.message,
        shouldFocus: true,
        type: 'validate',
      });
    } finally {
      setLoadingToken(false);
    }
  };

  // const getInfoAccount = async (tokenValue: string) => {
  //   try {
  //     let mint = (await connection.getParsedAccountInfo(new PublicKey(tokenValue))) as any;
  //     const decimals = mint?.value?.data?.parsed?.info?.decimals;
  //     // all the token data is here
  //     if (decimals && Number(decimals) > -1) {
  //       setSolanaVerifed(true);
  //       const newToken = {
  //         name: '',
  //         symbol: '',
  //         decimals,
  //         address: tokenValue,
  //       };
  //       setToken(newToken);
  //       clearErrors(forName as FieldName<any>);
  //     } else {
  //       setSolanaVerifed(false);

  //       setError(forName as FieldName<any>, {
  //         message: 'Invalid address of token',
  //         shouldFocus: true,
  //         type: 'validate',
  //       });
  //     }
  //   } catch (error) {
  //     console.log({ error }, 'info');
  //     setSolanaVerifed(false);

  //     setError(forName as FieldName<any>, {
  //       message: 'Invalid address of token',
  //       shouldFocus: true,
  //       type: 'validate',
  //     });
  //   }
  // };

  const handleTokenGetInfo = debounce(async (e: any) => {
    console.log('choosenNetwork',choosenNetwork)
    if (choosenNetwork === SOLANA_CHAIN_ID) {
      // await getInfoAccount(e.target.value);
    } else {
      await loadingTokenData(e.target.value);
    }
  }, 500);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>
          Token Address&nbsp;
          <RequireSymbol />
        </label>
        <div className={classes.formControlInputLoading}>
          <input
            type="text"
            maxLength={255}
            ref={register}
            onChange={handleTokenGetInfo}
            name={forName}
            className={classes.formControlInput}
            readOnly={disabled}
          />
          <HelpText>
            <span
              style={{
                color: '#f44336',
              }}
            >
              {get(errors, forName)?.message}
            </span>
          </HelpText>
          {loadingToken && (
            <div className={classes.circularProgress}>
              <CircularProgress size={25} />
            </div>
          )}
          {token && choosenNetwork !== SOLANA_CHAIN_ID && token?.name && (
            <img src="/images/icon_check.svg" className={classes.loadingTokenIcon} alt="close-icon" />
          )}
          {/* {token && choosenNetwork === SOLANA_CHAIN_ID && solanaVerifed && (
            <img src="/images/icon_check.svg" className={classes.loadingTokenIcon} alt="close-icon" />
          )} */}
        </div>
      </div>
      {token && !get(errors, forName) && choosenNetwork !== SOLANA_CHAIN_ID && (
        <div className={classes.tokenInfo}>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token</span>
            <div className="tokenInfoContent">
              <img src="/images/eth.svg" alt="erc20" />
              <Tooltip title={<p style={{ fontSize: 15 }}>{token?.name}</p>}>
                <p className="tokenInfoText wordBreak">{`${token?.name}`}</p>
              </Tooltip>
            </div>
          </div>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token Symbol</span>
            <div className="tokenInfoContent">
              <Tooltip title={<p style={{ fontSize: 15 }}>{token?.symbol}</p>}>
                <p className="wordBreak">{`${token?.symbol}`}</p>
              </Tooltip>
            </div>
          </div>
          <div className="tokenInfoBlock">
            <span className="tokenInfoLabel">Token Decimals</span>
            <div className="tokenInfoContent">{`${token?.decimals}`}</div>
          </div>
        </div>
      )}
    </>
  );
}
