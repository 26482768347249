import {BaseRequest} from "./Request";
import {apiRoute} from "../utils";
const queryString = require('query-string');

export const getPartnerList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/partner`);
  url += '?' + queryString.stringify(queryParams);

  const response = await baseRequest.get(url) as any;
  const resObject = await response.json();

  return resObject;
};

export const getPartnerDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/partner/${id}`);

  const response = await baseRequest.get(url) as any;
  const resObject = await response.json();

  return resObject;
};

export const updatePartner = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/partner/${id}/update`);
  const response = await baseRequest.post(url, data) as any;
  const resObject = await response.json();

  return resObject;
};

export const createPartner = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/partner/create`);

  const response = await baseRequest.post(url, data) as any;
  const resObject = await response.json();

  return resObject;
};

export const deletePartner = async (id:number | string , data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/partner/${id}/delete`);

  const response = await baseRequest.delete(url, data) as any;
  const resObject = await response.json();

  return resObject;
};
