import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import OurServiceConfigDetail from './Detail/OurServiceConfigDetail';

const OurServiceConfigCreate = () => {
  return (
    <DefaultLayout>
      <OurServiceConfigDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(OurServiceConfigCreate);
