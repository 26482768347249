import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createAboutInvestmentPool, updateAboutInvestmentPool } from '../../../../request/admin';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { useCommonStyle } from '../../../../styles';
import { adminRoute } from '../../../../utils';
import useStyles from '../styles';
import { AboutInvestmentProps } from '../../AboutInvestmentPoolRow/AboutInvestmentPoolRow';

const FormInvestmentPool = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();
  const { history, isCreate } = props;

  const { ourService } = props;
  let { id, title, description, priority } = ourService as AboutInvestmentProps;
  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      title,
      description,
      priority,
    },
  });

  const onSubmit = (values: any) => {
    const trimmedValues = {
      ...values,
      title: values.title.trim(),
      description: values.description.trim(),
      priority: values.priority,
    };
    if (isCreate) {
      createAboutInvestmentPool(trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/list-about-investment'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    } else {
      updateAboutInvestmentPool(id, trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/list-about-investment'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Title&nbsp;<span className={commonStyle.required}>(*)</span>
          </label>
          <input
            className={styles.inputG}
            name="title"
            placeholder="Title"
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.title && <span className={commonStyle.error}>This field is required</span>}
        </div>
        <div className="clearfix"></div>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Description</label>
          <input className={styles.inputG} name="description" placeholder="Description" ref={register({})} />
          {errors.description && <span className={commonStyle.error}>This field is required</span>}
        </div>
        <div className="clearfix"></div>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Priority</label>
          <input ref={register({})} className={styles.inputG} name="priority" placeholder="" />
        </div>
        <div className="clearfix"></div>

        <div className={styles.listBtn}>
          <Button type="submit" className={styles.btnSubmit}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(FormInvestmentPool);
