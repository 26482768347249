import { makeStyles } from '@material-ui/core';
import { USERS } from '../../constants';

interface Props {
  role: number;
}

const useStyles = makeStyles<Props>((props) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > .header-left > a:not(:first-child)': {
      marginLeft: 100,
    },
  },
  headerRight: {
    display: 'flex',
  },
  pagination: {
    marginTop: 30,
    marginBottom: 20,
    fontSize: 12,
    fontWeight: 400,
    '& .MuiPagination-ul': {
      justifyContent: 'center',
      '& li': {
        color: '#001F4D',
        '& .MuiPaginationItem-page': {
          border: '1px solid #001F4D',
          borderRadius: 6,
        },
      },
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#001F4D',
      color: '#fff',
    },
  },
  refreshCampaigns: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    justifyContent: 'space-between',
  },
  refreshCampaignsContainer: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: '#001F4D',
    '&:hover .refreshCampaignsIcon': {
      transform: 'rotateZ(180deg)',
      fill: '#FFCC00',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#61009D !important',
    },
  },
  refreshCampaignsText: {
    fontWeight: 500,
    fontSize: '1rem',
    transition: '.2s all ease-in',
    fontFamily: 'OpenSans-SemiBold',
    marginBottom: 0,
  },
  refreshCampaignsIcon: {
    transition: '.2s all ease-in',
  },
  buttonCreateNew: {
    fontFamily: 'OpenSans-SemiBold',
  },
  flexForm: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: 180,
    marginLeft: props.role === USERS.SUPER_ADMIN ? 100 : 0,
    '& .ant-select-selector': {
      border: '1px solid #E9ECEF !important',
      borderRadius: '8px !important',
      boxShadow: 'none !important',
    },
  },
}));

export default useStyles;
