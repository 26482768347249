import React, { useState, useEffect, useMemo } from 'react';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { getInvestmentPoolConfig } from '../../request/completed';
import InvestmentPoolConfigDetail from './InvestmentPoolConfigDetail/Detail/InvestmentPoolConfigDetail';
import { first } from 'lodash';

const InvestmentPoolConfig: React.FC<any> = (props: any) => {
  const [list, setList] = useState<any[]>([]);

  const completed = useMemo(() => {
    return first(list);
  }, [list]);

  const isCreate = useMemo(() => {
    return list.length === 0;
  }, [list]);

  const getList = async () => {
    try {
      const resObject = await getInvestmentPoolConfig();
      if (resObject.status === 200 && resObject.data) {
        setList(resObject.data)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <DefaultLayout>
      <InvestmentPoolConfigDetail completed={completed} isCreate={isCreate} />
    </DefaultLayout>
  );
};

export default InvestmentPoolConfig;
