import FormControl from '@material-ui/core/FormControl';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { Switch } from 'antd';
import { HelpText } from '../../../../../components/Base/Form';
import useFormStyles from '../useFormStyles';

interface KycRequiredProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
}

export function KycRequired({ errors, control }: KycRequiredProps) {
  const classes = useFormStyles();

  return (
    <>
      <div className={classes.formControl}>
        <Controller
          control={control}
          defaultValue={false}
          name="kycBypass"
          render={({ value, onChange }, { invalid }) => {
            return (
              <FormControl error={invalid} component="fieldset">
                <label className={classes.formControlLabel}>KYC Bypass</label>
                <Switch
                  onChange={async (switchValue) => {
                    await onChange(switchValue);
                  }}
                  checked={value ?? false}
                  checkedChildren="Bypass"
                  unCheckedChildren="Required"
                />
                <HelpText>{errors?.kycBypass?.message}</HelpText>
              </FormControl>
            );
          }}
        />
      </div>
    </>
  );
}
