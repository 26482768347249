import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme:any) => ({
    header: {
        marginBottom:20,
    },
    pagination : {
       '& .MuiPagination-ul': {
        justifyContent:'center',
        '& .Mui-selected':{
            background: theme.custom.colors.blueDark,
            color:theme.custom.colors.white,
        },
        '& .MuiPaginationItem-outlined':{
            border:`1px solid ${theme.custom.colors.blueDark} !important`
        }
       }
    }
}))

export default useStyles;