/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import idl from '../../../../abi/Reward.json';
import { Button } from '../../../../components/Base/Form';
// import { initWorkspace } from '../../../../hooks/useWorkspace';
import { createStakePool, StakePool, updateStakePool } from '../../../../request/admin';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import { BoosterCollection } from '../components/BoosterCollection';
import { ProjectGeneralInfo } from '../components/GeneralDetail';
import { NFTMultiplierScore } from '../components/NFTMultiplierScore';
import ListRewardTable from '../components/RewardPoolList';
import { StakedAnarkeySetting } from '../components/StakedAnarkeySetting';
import { StakedXborgSetting } from '../components/StakedXborgSetting';
import { DEFAULT_POOL_STAKE, TokenRewardAPI, VALIDATION_SCHEMA } from '../contants';
import useStyles from './style';

interface RewardStakingProps {
  isEdit: boolean;
  poolDetail?: any;
  originPool?: any;
}

const PROGRAM = process.env.REACT_APP_SOLANA_PROGRAM_REWARD || '';

export function RewardStakingForm({ isEdit, poolDetail, originPool }: RewardStakingProps) {
  // const { connection } = initWorkspace({
  //   idl: idl,
  //   publicKey: PROGRAM,
  // });

  const classes = { ...useStyles() };
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      ...DEFAULT_POOL_STAKE,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, setValue, reset, errors, handleSubmit, control, getValues } = form;

  const dataRewardList = useMemo(() => {
    let data = (poolDetail?.tokenRewards || []) as TokenRewardAPI[];
    let result = data.map((item) => {
      let status;
      const startTime = item.start_time;
      const endTime = item.end_time;
      const closeTimeDate = moment.unix(parseInt(endTime)).toDate();
      const startTimeDate = moment.unix(parseInt(startTime)).toDate();
      const currentDate = new Date();
      if (currentDate >= closeTimeDate) {
        status = 'Close';
      } else if (startTimeDate > currentDate) {
        status = 'Created';
      } else {
        status = 'Running';
      }
      return {
        ...item,
        status: status,
      };
    });
    return result;
  }, [poolDetail?.tokenRewards]);

  useEffect(() => {
    const resetValue = poolDetail ? poolDetail : DEFAULT_POOL_STAKE;

    reset({
      ..._.cloneDeep(resetValue),
    });
  }, [poolDetail, reset]);

  const validateForm = () => {
    handleSubmit(createPoolStakeNft)();
  };

  const add = () => {
    history.push(adminRoute(`create-reward/${poolDetail.id}`));
  };

  const createPoolStakeNft = async (data: any) => {
    // setLoading(true);

    try {
      const {
        pool_name,
        is_display,
        lock_period,
        total_nft_stake,
        is_display_nft_stake,
        total_nft_stake_anarkey,
        is_display_nft_stake_anarkey,
        collection_address,
        percent_booster,
        anarkey_lock_period,
        min_spent
      } = data;

      const boost_collections = [
        {
          address: collection_address,
          percent: Number(percent_booster),
        },
      ];

      let dataSave: StakePool = {
        pool_name,
        is_display: is_display ? 1 : 0,
        lock_period,
        anarkey_lock_period,
        common_score: data?.common_score || '',
        rare_score: data?.rare_score || '',
        elite_score: data?.elite_score || '',
        special_score: data?.special_score || '',
        apr: data?.apr || '',
        total_nft_stake : Number(total_nft_stake),
        is_display_nft_stake : Number(is_display_nft_stake),
        total_nft_stake_anarkey : Number(total_nft_stake_anarkey),
        is_display_nft_stake_anarkey : Number(is_display_nft_stake_anarkey),
        boost_collections: isEdit ? boost_collections : [],
        min_spent : Number(min_spent || 0)
      };
      let response: any;
      if (isEdit) {
        response = await updateStakePool(poolDetail.id, dataSave);
      } else {
        response = await createStakePool(dataSave);
      }
      if (response) {
        if (response?.status === 200) {
          if (isEdit) {
            dispatch(alertSuccess('Updated successfully!'));
          } else {
            dispatch(alertSuccess('Created successfully!'));
          }
          history.push(adminRoute('/projects'));
        } else {
          if (isEdit) {
            dispatch(alertFailure('Update failed'));
          } else {
            dispatch(alertFailure('Create failed'));
          }
        }
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <input type="checkbox" name="needValidate" ref={register} hidden />
      <div className="contentPage">
        <Grid item xs={12}>
          <ProjectGeneralInfo
            detail={poolDetail}
            control={control}
            errors={errors}
            setValue={setValue}
            register={register}
            isEdit={isEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <BoosterCollection errors={errors} control={control} />
        </Grid>
        <Grid item xs={12}>
          <NFTMultiplierScore errors={errors} control={control} />
        </Grid>
        {/* <Grid item xs={12}>
          <AprSetting control={control} errors={errors} />
        </Grid> */}
        {isEdit && (
          <Grid item xs={12}>
            <StakedXborgSetting register={register} setValue={setValue} control={control} />
          </Grid>
        )}
        {isEdit && (
          <Grid item xs={12}>
            <StakedAnarkeySetting register={register} setValue={setValue} control={control} />
          </Grid>
        )}
        <ListRewardTable list={dataRewardList} />
        <Grid container spacing={2} justifyContent={'space-between'}>
          <Button disabled={loading} onClick={add} className={classes.formButtonUpdatePool} style={{ width: '47%' }}>
            {loading && <CircularProgress size={25} />}
            {!loading && 'Add New Token Reward'}
          </Button>
          {isEdit ? (
            <Button
              disabled={loading}
              onClick={validateForm}
              className={classes.formButtonUpdatePool}
              style={{ width: '47%' }}
            >
              {loading && <CircularProgress size={25} />}
              {!loading && 'Update the project'}
            </Button>
          ) : (
            <Button
              disabled={loading}
              onClick={validateForm}
              className={classes.formButtonUpdatePool}
              style={{ width: '47%' }}
            >
              {loading && <CircularProgress size={25} />}
              {!loading && 'Create the project'}
            </Button>
          )}
        </Grid>
      </div>
    </>
  );
}
