import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import { getStepDetail } from '../../../request/completed';
import StepParticipateDetail from './Detail/StepParticipateDetail';

interface Params {
  id: string;
}

const StepParticipateEdit: React.FC<RouteComponentProps<Params>> = (props: RouteComponentProps<Params>) => {
  const { match } = props;
  const id = match.params.id;
  const [detail, setDetail] = useState({});

  const getDetail = async () => {
    try {
      const resObject = await getStepDetail(id);
      setDetail(resObject.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <DefaultLayout>
      <StepParticipateDetail completed={detail} isCreate={false} />
    </DefaultLayout>
  );
};

export default withRouter(StepParticipateEdit);
