import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import CardParticipateForm from './FormCardParticipate';

const CardParticipateDetail = (props: any) => {
  const { completed, isCreate , id } = props;

  const showCompletedCreate = () => {
    return <CardParticipateForm completed={completed} isCreate={isCreate} />;
  };

  const showCompletedDetail = () => {
    if (!_.isEmpty(completed)) {
      return <CardParticipateForm id={id} completed={completed} isCreate={isCreate} />;
    }
    return <p>Loading...</p>;
  };

  return <div>{isCreate ? showCompletedCreate() : showCompletedDetail()}</div>;
};

export default withRouter(CardParticipateDetail);
