import Link from '@material-ui/core/Link';
import * as anchor from '@project-serum/anchor';
import { TokenInstructions } from '@project-serum/serum';
import { PublicKey } from '@solana/web3.js';
import { Tooltip } from 'antd';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import idl from '../../../../../abi/Reward.json';
import { Button } from '../../../../../components/Base/Form';
import { initWorkspace } from '../../../../../hooks/useWorkspace';
import { alertFailure, alertSuccess } from '../../../../../store/actions/alert';
import { useCommonStyle } from '../../../../../styles';
import useFormStyles from '../useFormStyles';

const TOKEN_PROGRAM_ID = new anchor.web3.PublicKey(TokenInstructions.TOKEN_PROGRAM_ID.toString());
const AIRDROP_TOKEN_ACCOUNT_SEED = Buffer.from('airdrop-token-account');
const PROGRAM = process.env.REACT_APP_SOLANA_PROGRAM_REWARD;

function TokenAccount(props: any) {
  const dispatch = useDispatch();
  const { wallet, program } = initWorkspace({
    idl: idl,
    publicKey: PROGRAM as string,
  });
  const classes = useFormStyles();
  const commonStyle = useCommonStyle();
  const { poolDetail, rw_token_account, tokenAddress, watch } = props;
  const [tokenAccountAddress, setTokenAccountAddress] = useState<string>('');

  const addressRewardAccount = useMemo(() => {
    return get(poolDetail, rw_token_account, '');
  }, [rw_token_account, poolDetail]);

  const tokenAddressWatch = useMemo(() => {
    if (tokenAddress) {
      return watch(tokenAddress);
    }
    return null;
  }, [tokenAddress, watch]);

  useEffect(() => {
    localStorage.setItem(rw_token_account, addressRewardAccount);
  }, [addressRewardAccount, rw_token_account]);

  const initAccount = async () => {
    const airdropAccount = new PublicKey(process.env.REACT_APP_SOLANA_AIRDROP_ACCOUNT as string);
    const [airdropTokenAccount] = await getAirdropTokenAccount(airdropAccount, new PublicKey(tokenAddressWatch));

    console.log({
      airdropAccount: airdropAccount.toString(),
      airdropTokenAccount: airdropTokenAccount.toString(),
      sender: wallet?.publicKey.toString(),
      systemProgram: anchor.web3.SystemProgram.programId.toString(),
      mint: tokenAddressWatch.toString(),
      rent: anchor.web3.SYSVAR_RENT_PUBKEY.toString(),
      tokenProgram: TOKEN_PROGRAM_ID.toString(),
    });

    try {
      await program.methods
        .initAirdropTokenAccount(new PublicKey(tokenAddressWatch))
        .accounts({
          airdropAccount,
          airdropTokenAccount,
          sender: wallet?.publicKey,
          systemProgram: anchor.web3.SystemProgram.programId,
          mint: new PublicKey(tokenAddressWatch),
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
          tokenProgram: TOKEN_PROGRAM_ID,
        })
        .rpc();
      setTokenAccountAddress(airdropTokenAccount.toString());
      localStorage.setItem(rw_token_account, airdropTokenAccount.toString());
      dispatch(alertSuccess('Gen token account successfully!'));
    } catch (error: any) {
      console.log({ error });
      dispatch(alertFailure(`Gen token account failed `));
    }
  };

  const getAirdropTokenAccount = (airdropAccount: PublicKey, mintAccount: PublicKey) => {
    return anchor.web3.PublicKey.findProgramAddress(
      [AIRDROP_TOKEN_ACCOUNT_SEED, airdropAccount.toBuffer(), mintAccount.toBuffer()],
      new PublicKey(PROGRAM as string)
    );
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Token Account</label>

        <Tooltip title="Admin has to deposit the amount to the token account before the official reward distribution date.">
          <img src="/images/icon-tool-tip.svg" alt="icon-tool-tip" style={{ marginBottom: 2, marginLeft: 5 }} />
        </Tooltip>
        <Button className={classes.formButtonUpdatePool} disabled={addressRewardAccount} onClick={initAccount}>
          Gen token account
        </Button>
        <div className={commonStyle.boldText}>
          {addressRewardAccount || tokenAccountAddress ? (
            <Link
              href={`${process.env.REACT_APP_SOLANA_SCAN}/${addressRewardAccount || tokenAccountAddress}/?cluster=${
                process.env.REACT_APP_SOLANA_NETWORK_CLUSTER
              }`}
              target={'_blank'}
            >
              {addressRewardAccount || tokenAccountAddress}
            </Link>
          ) : (
            '--'
          )}
          {/* {!addressRewardAccount && !tokenAccountAddress && '--'} */}
        </div>
      </div>
    </>
  );
}

export default TokenAccount;
