import {
  FormControl,
  FormControlLabelProps,
  RadioGroup as MUIRadioGroup,
  RadioProps,
  Tooltip,
} from '@material-ui/core';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { HelpText } from '../RepresentData/HelpText';
import { FormLabel } from '../RepresentData/FormLabel';
import useFormControlStyle from '../styles';
import { ReactNode, useRef } from 'react';
import RequireSymbol from '../../RequireSymbol';

interface RadioGroupProps<T extends RadioProps | Omit<FormControlLabelProps, 'control'>> {
  control?: Control<any>;
  label?: string;
  errorMessage?: string | ReactNode;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  defaultValue?: any;
  name: string;
  className?: string;
  radioData: T[];
  onRenderRadio: (radioData: T) => React.ReactNode;
  row?: boolean;
  required?: boolean;
  tooltip?: string;
}

export function RadioGroup<T extends RadioProps | Omit<FormControlLabelProps, 'control'>>({
  control,
  label,
  radioData,
  onRenderRadio,
  errorMessage,
  rules,
  defaultValue,
  name,
  className,
  row = true,
  required,
  tooltip,
}: RadioGroupProps<T>) {
  const ref = useRef<any>();
  const formControlStyles = useFormControlStyle();
  const classes = [formControlStyles['form-control'], className].filter(Boolean).join(' ');

  return (
    <Controller
      control={control}
      value={defaultValue}
      name={name}
      rules={rules}
      onFocus={() => {
        ref?.current?.focus();
      }}
      render={({ value, onChange, onBlur }, { invalid }) => {
        return (
          <Tooltip
            classes={{
              tooltip: formControlStyles.tooltip,
              arrow: formControlStyles.arrow,
            }}
            title={tooltip ?? ''}
            placement="bottom-start"
            arrow
          >
            <FormControl fullWidth error={invalid} className={classes}>
              {label && (
                <FormLabel>
                  {label} {required && <RequireSymbol />}
                </FormLabel>
              )}
              <MUIRadioGroup tabIndex={5} ref={ref} value={value} onChange={onChange} onBlur={onBlur} row={row}>
                {radioData.map((data) => onRenderRadio(data))}
              </MUIRadioGroup>
              <HelpText>{errorMessage}</HelpText>
            </FormControl>
          </Tooltip>
        );
      }}
    />
  );
}
