import useStyles from "./styles";

interface PaperProps {
  elevation?: number;
  children: React.ReactNode;
  className?: string;
}

export function Paper({ elevation = 2, children, className }: PaperProps) {
  const styles = useStyles();
  const classes = [styles.paper, className].filter(Boolean).join(" ");

  return <div className={classes}>{children}</div>;
}
