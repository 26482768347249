import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import FormCompleted from './FormCompleted';

const CompletedDetail = (props: any) => {
  const { completed, isCreate } = props;

  const showCompletedCreate = () => {
    return <FormCompleted completed={completed} isCreate={isCreate} />;
  };

  const showCompletedDetail = () => {
    if (!_.isEmpty(completed)) {
      return <FormCompleted completed={completed} isCreate={isCreate} />;
    }
    return <p>There is no partners that does exists</p>;
  };

  return <div>{isCreate ? showCompletedCreate() : showCompletedDetail()}</div>;
};

export default withRouter(CompletedDetail);
