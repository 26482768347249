import { Control, DeepMap, FieldError } from 'react-hook-form';
import { NumberInput } from '../../../../../../components/Base/Form';
import { Paper } from '../../../../../../components/Base/Paper';
import { ExcutivePool } from '../../types/pool';
import useStyles from './styles';

interface LockScheduleProps {
  poolDetail?: ExcutivePool;
  control?: Control<ExcutivePool>;
  errors?: DeepMap<ExcutivePool, FieldError>;
}

export function Tax({ control, errors, poolDetail }: LockScheduleProps) {
  const classes = useStyles();

  return (
    <Paper>
      <NumberInput
        label="Max Allocation"
        disabled={!!poolDetail?.isDeploy}
        name="nft.maxAllocation"
        control={control}
        className={classes.maxAllocationInput}
        errorMessage={errors?.nft?.maxAllocation?.message}
        isRequired
      />

      <NumberInput
        label="Tax Rate(%)"
        disabled={!!poolDetail?.isDeploy}
        name="nft.tax"
        control={control}
        className={classes.maxAllocationInput}
        errorMessage={errors?.nft?.tax?.message}
        isRequired
      />
    </Paper>
  );
}
