import Web3 from 'web3';
import erc20ABI from '../abi/Erc20.json';
import { RPC_URLS } from '../constants/network';

export type TokenType = {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
};

type ReturnType = TokenType | undefined;

export const getTokenInfo = async (tokenAddress: string, choosenNetwork: string): Promise<ReturnType> => {
  try {
    const rpc = RPC_URLS[choosenNetwork];
    if (!rpc) {
      return undefined;
    }

    const web3Instance = new Web3(rpc);

    const contract = new web3Instance.eth.Contract(erc20ABI as any, tokenAddress);

    if (contract) {
      const tokenName = contract.methods.name().call();
      const tokenSymbol = contract.methods.symbol().call();
      const tokenDecimals = contract.methods.decimals().call();

      const res = await Promise.all([tokenName, tokenSymbol, tokenDecimals]);

      return {
        name: res[0],
        symbol: res[1],
        decimals: res[2],
        address: tokenAddress,
      };
    }
  } catch (err: any) {
    //throw new Error("Token address is invalid.");
    console.log(err.message);
  }
};

export const getShortTokenSymbol = (tokenSymbol: string, yourLength = 10) => {
  if (!tokenSymbol) tokenSymbol += '';
  if (tokenSymbol.length <= yourLength) {
    return tokenSymbol;
  }

  return `${tokenSymbol.substring(0, 10)}...`;
};
