import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from './style';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import Button from '../../components/Base/ButtonLink';
import OurServiceRow from './OurServiceRow/OurServiceRow';
import { adminRoute } from '../../utils';
import { getOurServiceList } from '../../request/admin';
import TableCommon from '../../components/TableCommon';

const tableHeaders = ['SERVICE NAME', 'DESCRIPTION', 'PRIORITY', 'ACTION'];

const OurServiceList: React.FC<any> = (props: any) => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const [ourServices, setOurServices] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  // const [filter] = useState(false);
  const [currentOpen, setCurrentOpen] = useState('');
  // const [startTime, setStartTime] = useState<Date | null>(null);
  // const [finishTime, setFinishTime] = useState<Date | null>(null);
  // const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(page);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const getAdminListInfo = async (currentPage: any) => {
    const queryParams = {
      page: currentPage,
    };

    try {
      setLoading(true);
      const resObject = await getOurServiceList(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setPage(page);
        setLastPage(lastPage);
        setOurServices(data);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  useEffect(() => {
    getAdminListInfo(currentPage);
  }, [currentPage]);

  // useEffect(() => {
  //   handleCampaignQuery(currentPage, query, startTime, finishTime, filter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, currentPage, query, startTime, finishTime, filter]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  // const handleCampaignSearch = (event: any) => {
  //   setCurrentPage(1);
  //   setQuery(event.target.value);
  // };

  // const handleCampaignQuery = (
  //   currentPage: number,
  //   query?: string,
  //   startTime?: Date | null,
  //   finishTime?: Date | null,
  //   filter?: boolean
  // ) => {
  //   getAdminListInfo(currentPage, query, convertDateTimeToUnix(startTime), convertDateTimeToUnix(finishTime), filter);
  // };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderOurService = (ourService: any) => {
    return (
      <OurServiceRow
        key={ourService.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        ourService={ourService}
        onReload={() => {
          console.log('reload');
          setCurrentPage(1);
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      ourServices &&
      lastPage > 1 && (
        <Pagination
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };
  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/create-our-service')} text={'Create a new our service'} />
        </div>
        {/* <SearchForm
          startTime={startTime}
          setStartTime={setStartTime}
          finishTime={finishTime}
          setFinishTime={setFinishTime}
          setCurrentPage={setCurrentPage}
          handleCampaignSearch={handleCampaignSearch}
        /> */}
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={ourServices}
        onRenderContent={onRenderOurService}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default OurServiceList;
