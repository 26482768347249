import ConfirmDialog from '../../../../../components/Base/ConfirmDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { DateTimePicker, NumberInput } from '../../../../../components/Base/Form';
import { CLAIM_VALIDATION_SCHEMA } from './constants';
import { ClaimConfig } from '../../types';

interface CreateEditClaimConfigFormProps {
  isOpenEditPopup: boolean;
  setIsOpenEditPopup: (value: boolean) => void;
  data: ClaimConfig;
  isEdit: boolean;
  handleCreateUpdateData: (editData: any) => void;
}

function CreateEditClaimConfigForm({
  isOpenEditPopup,
  setIsOpenEditPopup,
  data,
  isEdit,
  handleCreateUpdateData,
}: CreateEditClaimConfigFormProps) {
  // const classes = useStyles();
  const { clearErrors, errors, handleSubmit, control } = useForm<ClaimConfig>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...data,
    },
    resolver: yupResolver(CLAIM_VALIDATION_SCHEMA),
  });

  const handleSubmitPopup = (values: ClaimConfig) => {
    const responseData: ClaimConfig = {
      ...values,
      id: data.id,
    };

    handleCreateUpdateData(responseData);
  };

  function submit() {
    handleSubmit(handleSubmitPopup)();
  }

  return (
    <ConfirmDialog
      title={isEdit ? 'Edit a claim' : 'Create a claim'}
      open={isOpenEditPopup}
      confirmLoading={false}
      onConfirm={submit}
      onCancel={() => {
        setIsOpenEditPopup(false);
        clearErrors();
      }}
      buttonContent={isEdit ? 'Save' : 'Create'}
    >
      <DateTimePicker
        label="Start Time"
        name="startTime"
        control={control}
        errorMessage={errors?.startTime?.message}
        format="YYYY-MM-DD HH:mm:ss"
        showTime={{
          defaultValue: moment('00:00:00', 'HH:mm:ss'),
          format: 'HH:mm',
        }}
        minuteStep={1}
      />

      <NumberInput
        label="Max Claim (%)"
        name="maxPercentClaim"
        control={control}
        errorMessage={errors?.maxPercentClaim?.message}
      />
    </ConfirmDialog>
  );
}

export default CreateEditClaimConfigForm;
