import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NetworkUpdateType,
  settingAppNetwork,
} from "../../../../store/actions/appNetwork";
import { NetworkInfo } from "../../../../constants/network";
import useStyles from "./style";

interface ConnectWalletBoxPropsType {
  appNetwork: NetworkInfo;
  forceEnable?: boolean;
}

export const ConnectNetworkBox: FC<ConnectWalletBoxPropsType> = (
  props: ConnectWalletBoxPropsType
) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { appNetwork } = props;
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;

  const handleNetworkChange = (networkId: string) => {
    dispatch(settingAppNetwork(NetworkUpdateType.App, networkId));
  };

  const { name, icon, id } = appNetwork;
  const temporaryDisable = false; //name === APP_NETWORKS_NAME.BSC;
  
  return (
    <div
      className={styles.walletBox}
      onClick={() => {
        !temporaryDisable && handleNetworkChange(id as string);
      }}
    >
      <div className={styles.walletBoxIconWrap}>
        {
          <img
            src={icon}
            style={{ width: 50 }}
            alt={name}
            className={styles.walletBoxIcon}
          />
        }
        {appChainID === id && (
          <img
            alt=""
            src={`/images/circle_done.svg`}
            style={{ color: "#212a3b" }}
            className={styles.walletBoxCheck}
          />
        )}
      </div>
      <p className={styles.walletBoxText}>{name}</p>
    </div>
  );
};
