import { AnyAction } from 'redux';
import { openAction } from '../constants/open';

const initialState = {
  openFirst: false,
  openSecond: false,
  openThird: false,
  openFour: false,
  openFive: false
};

export const openReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case openAction.OPEN_FIRST: {
      return {
        ...state,
        openFirst: action.payload,
      };
    }

    case openAction.OPEN_SECOND: {
      return {
        ...state,
        openSecond: action.payload,
      };
    }

    case openAction.OPEN_THIRD: {
      return {
        ...state,
        openThird: action.payload,
      };
    }

    case openAction.OPEN_FOUR: {
      return {
        ...state,
        openFour: action.payload,
      };
    }

    case openAction.OPEN_FIVE: {
      return {
        ...state,
        openFive: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
