import { BaseRequest } from './Request';
import { apiRoute } from '../utils';
const queryString = require('query-string');

export const getCompletedList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = 'completed-project';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getLandingProjectList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-list-project';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getProjectMatrixList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-list-matrix-project';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getListCard = async (queryParams?: any) => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-list-participate';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getFAndQList = async (queryParams: any, id: string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/get-list-faq-of-category/${id}`;
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getListStepParticipate = async (queryParams: any, id: string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/get-list-step-participate/${id}`;
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getYieldBoxProjects = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-list-yieldbox-inject';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getListFeature = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = '/vispx-feature-project-list';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getInvestmentPoolConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-description-invest-pool';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getOurServiceConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-description-our-service';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getProjectMatrixConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-section-project-matrix';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getProjectYieldBoxConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-section-project-yieldbox';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getFAQConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-frequently-ask-questions';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getParticipateConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-participate';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getConfigButtonLaunchApp = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-launchapp-button';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getConfigButtonExplore = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-exploreapp-button';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getConfigButtonInSectionFeature = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-button-card';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getFeatureConfig = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-custom-section-project';
  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getConfigInvestment = async () => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-description-invest-pool';

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getNewSliderList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-list-slider';
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getCompletedDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/completed-project/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getLandingDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/landing-project/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getMatrixProject = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/landing-matrix-project/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getCardType = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/detail-category-participate/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getFAndQDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/detail-faq/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getStepDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/detail-step-category-participate/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getYieldBoxProject = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/yieldbox-inject/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const getNewSlider = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/landing-slider/${id}`;

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();
  return resObject;
};

export const updateCompleted = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/completed-project/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateLandingPage = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-project/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateProjectMatrix = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-matrix-project/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateCardType = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-participate/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateFAndQ = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-faq/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateStep = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-participate-step/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateYieldBox = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/yieldbox-inject/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateFeature = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-feature-project/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateInvestmentPoolConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/invest-pool-description/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateOurServiceConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/our-service-description/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateProjectMatrixConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-section-project-matrix/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateFAQConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-custom-frequently-ask-questions/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateParticipateConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-custom-participate/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateButtonLaunchAppConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-section-launchapp-button/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateButtonExplorerConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-section-explore-button/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateButtonInSectionFeature = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-custom-button-card/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateProjectYieldConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`update-section-project-yieldbox/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateFeatureConfig = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/update-section-project/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateNewSlider = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-slider/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createCompleted = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/completed-project`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createLandingPage = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-project`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createProjectMatrix = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-matrix-project`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createCardType = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-participate`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createFAndQ = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-frequently-ask-question`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createStep = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-step-participate`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getListFAndQ = async (queryParams?: any) => {
  const baseRequest = new BaseRequest();
  let url = '/admin/get-category-faq-list';
  // url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createYieldboxInjection = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-yieldbox-inject`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createFeature = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-feature-project`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createInvestmentPoolConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-description-invest-pool`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createOurServiceConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-description-our-service`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createProjectMatrixConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-section-project-matrix`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createFAQConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-frequently-ask-question`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createParticipateConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-how-to-participate`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createButtonLaunchApp = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-section-lanchapp-button`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createButtonExplorer = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-section-explore-button`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createButtonInSectionFeature = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-section-card-button`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createProjectYieldBoxConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-section-project-yieldbox`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createFeatureConfig = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/custom-section-project`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createNewSlider = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/create-slider`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteCompleted = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/completed-project/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteLanding = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-project/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteMatrix = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-matrix-project/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteDeleteCategory = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/category-participate/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteYieldBox = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/yieldbox-inject/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteNewSlider = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/landing-slider/${id}`);

  const response = (await baseRequest.delete(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};
