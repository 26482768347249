import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactNode } from 'react';
import styles from './styles.module.scss';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { isEmpty } from 'lodash';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export interface ColumnOrder {
  label: string;
  direction: 'asc' | 'desc' | '';
}

interface TableCommonProps<T> {
  tableHeaders: string[];
  loading: boolean;
  list: T[];
  onRenderContent: (data: T) => void;
  onRenderPagination: () => ReactNode;
  failure: boolean;
  columnsOrder?: ColumnOrder[];
  onSort?: (column: string, direction: 'asc' | 'desc') => void;
}

const TableCommon = <T extends object = any>(props: TableCommonProps<T>) => {
  const { tableHeaders, loading, list, onRenderContent, failure, onRenderPagination, columnsOrder, onSort } = props;

  const getOrderOfColumn = (columnsOrder: ColumnOrder[] | undefined, currentHeader: string): JSX.Element | string => {
    if (isEmpty(columnsOrder) || columnsOrder?.length === 0) {
      return currentHeader;
    }
    const existColumn = columnsOrder?.find((item) => item.label === currentHeader);
    if (isEmpty(existColumn)) {
      return currentHeader;
    }
    return (
      <>
        {existColumn?.direction === 'desc' && (
          <>
            {currentHeader}
            <ArrowDownwardIcon className={styles.icon} />
          </>
        )}
        {(existColumn?.direction === 'asc' || !existColumn?.direction) && (
          <>
            {currentHeader}
            <ArrowUpwardIcon className={styles.icon} />
          </>
        )}
      </>
    );
  };

  const getColumnByLable = (label: string) => {
    const column = columnsOrder?.find((item) => item.label === label);
    return column;
  };

  return (
    <TableContainer component={Paper} className={`${styles.tableContainer} commonTable`}>
      {loading ? (
        [...Array(10)].map((num, index) => (
          <div key={index}>
            <Skeleton className={styles.skeleton} width={'100%'} />
          </div>
        ))
      ) : (
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((tableHeader: string, index: number) => {
                return (
                  <TableCell
                    align={index + 1 === tableHeaders.length ? `center` : `left`}
                    key={index}
                    className={styles.tableHeader}
                    onClick={() => {
                      const column = getColumnByLable(tableHeader);
                      if (onSort && !isEmpty(column)) {
                        const direction = column?.direction === 'asc' || !column?.direction ? 'desc' : 'asc';
                        onSort(tableHeader, direction);
                      }
                    }}
                  >
                    <TableSortLabel>{getOrderOfColumn(columnsOrder, tableHeader)}</TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {list && list.map((data: any, index: number) => onRenderContent(data))}
          </TableBody>
        </Table>
      )}
      {failure ? (
        <p className={styles.errorMessage}>{failure}</p>
      ) : (!list || list.length === 0) && !loading ? (
        <p className={styles.noDataMessage}>No record found</p>
      ) : (
        <div className={styles.paginationField}>{onRenderPagination()}</div>
      )}
    </TableContainer>
  );
};

export default TableCommon;
