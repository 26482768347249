import { CircularProgress, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { RadioGroup, TextInput } from '../../../../components/Base/Form';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { NETWORK_AVAILABLE } from '../../../../constants';
import { createFeature, updateFeature } from '../../../../request/completed';
import RequireSymbol from '../../../../components/Base/RequireSymbol';
import { FeatureType } from '../../FeatureProjectRow/FeatureProjectRow';

interface Completed extends Omit<FeatureType, 'banner'> {
  banner: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
}

interface FormMatrix extends Completed {}

const FormFeatureProject = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: Completed; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, errors, watch, getValues } = useForm<FormMatrix>({
    mode: 'onChange',
    defaultValues: {
      banner: {
        uploadType: UploadType.LINK,
        link: '',
      },
      project_name: '',
      project_network: NETWORK_AVAILABLE.POLYGON,
      project_status: '',
      pool_id: '',
      twitter: '',
      website: '',
      discord: '',
      total_raise: '',
      pre_total_raise: '',
      max_allocation: '',
      pre_max_allocation: '',
      priority: '',
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (completed) {
      reset({
        banner: {
          link: completed.banner,
          uploadedUrl: completed.banner,
          uploadType: UploadType.LINK,
        },
        project_name: completed.project_name,
        project_network: completed.project_network,
        priority: completed.priority,
        project_status: completed.project_status,
        pool_id: completed.pool_id,
        twitter: completed.twitter,
        website: completed.website,
        discord: completed.discord,
        total_raise: completed.total_raise,
        pre_total_raise: completed.pre_total_raise,
        max_allocation: completed.max_allocation,
        pre_max_allocation: completed.pre_max_allocation,
      } as any);
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: FormMatrix) => {
    setIsOpenModal(false);
    let banner = values.banner.link;
    setLoading(true);

    try {
      if (values.banner.uploadType === UploadType.FILE && !!values.banner.file) {
        banner = await uploadFile(values.banner.file);
      }

      if (values.banner.uploadType === UploadType.LINK) {
        banner = values.banner.link ?? '';
      }

      const trimmedValue = {
        banner,
        project_name: values.project_name,
        project_network: values.project_network,
        priority: values.priority,
        project_status: values.project_status,
        pool_id: values.pool_id,
        twitter: values.twitter,
        website: values.website,
        discord: values.discord,
        total_raise: values.total_raise,
        pre_total_raise: values.pre_total_raise,
        max_allocation: values.max_allocation,
        pre_max_allocation: values.pre_max_allocation,
      };

      if (isCreate) {
        const res = await createFeature(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/project-feature'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateFeature(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/project-feature'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/project-feature')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <label>Feature Information</label>
            <TextInput name="project_name" control={control} label="Project Name" isRequired={true} />
            <TextInput name="project_status" control={control} label="Project Status" />
            <TextInput name="pool_id" control={control} label="Pool Link" />
            <TextInput name="twitter" control={control} label="Twitter" />
            <TextInput name="website" control={control} label="Website" />
            <TextInput name="discord" control={control} label="Discord" />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100px' }}>
                <TextInput
                  name="pre_total_raise"
                  control={control}
                  isRequired={false}
                  placeholder="$"
                  label="Total Raise"
                />
              </div>
              <TextInput
                style={{
                  marginTop: 23,
                }}
                name="total_raise"
                control={control}
                isRequired={false}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100px' }}>
                <TextInput
                  name="pre_max_allocation"
                  control={control}
                  isRequired={false}
                  placeholder="$"
                  label="Allocation"
                />
              </div>
              <TextInput
                style={{
                  marginTop: 23,
                }}
                name="max_allocation"
                control={control}
                isRequired={false}
              />
            </div>
            <TextInput name="priority" control={control} label="Priority" isRequired={false} />
          </Paper>
        </Grid>

        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Network Available"
                  control={control}
                  name="project_network"
                  radioData={[
                    {
                      value: NETWORK_AVAILABLE.POLYGON,
                      label: 'Polygon',
                    },
                    {
                      value: NETWORK_AVAILABLE.SOL,
                      label: 'Solana',
                    },
                    {
                      value: NETWORK_AVAILABLE.ETH,
                      label: 'Ethereum',
                    },
                    {
                      value: NETWORK_AVAILABLE.BSC,
                      label: 'BSC',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel
                      key={data.value}
                      value={data.value}
                      label={data.label}
                      control={<Radio size="small" />}
                    />
                  )}
                />
              </div>
            </>
          </Paper>

          <Paper>
            <>
              <label>Banner</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={
                  {
                    link: completed?.banner || '',
                    uploadedUrl: completed?.banner || '',
                    uploadType: UploadType.LINK,
                  } as any
                }
                formName="banner"
                errors={errors?.banner}
                selectTooltip={
                  <>
                    Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel
                  </>
                }
              />
            </>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the Feature' : 'Update the Feature'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>
          Are you sure to {isCreate ? 'create this Feature?' : 'update the Feature?'}
        </p>
      </ConfirmDialog>
    </>
  );
};

export default FormFeatureProject;
