import { FC } from "react";
import { AbstractConnector } from "@web3-react/abstract-connector";
import mobile from "is-mobile";
import useStyles from "./style";
import { WalletInfo } from "../../../../constants/connectors";
import { useSelector } from "react-redux";

interface ConnectWalletBoxPropsType {
  wallet: WalletInfo;
  handleWalletChosen: (
    name: string,
    connector: AbstractConnector,
    appChainID: string
  ) => void;
  selected: boolean;
}

export const ConnectWalletBox: FC<ConnectWalletBoxPropsType> = ({
  handleWalletChosen,
  wallet,
  selected,
}: ConnectWalletBoxPropsType) => {
  const styles = useStyles();
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;

  const connectWallet = () => {
    wallet &&
      handleWalletChosen?.(
        wallet.name,
        wallet.connector as AbstractConnector,
        appChainID
      );
  };

  const { name, icon } = wallet;

  return (
    <div
      className={styles.walletBox}
      onClick={() => {
        if (mobile() && wallet?.deepLink) {
          window.open(wallet.deepLink);
          return;
        }

        connectWallet();
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <div className={styles.walletBoxIconWrap}>
        {selected ? (
          <>
            <img
              src={icon}
              style={{ width: 50 }}
              alt={name}
              className={styles.walletBoxIcon}
            />
            <img
              alt=""
              src={`/images/circle_done.svg`}
              style={{ color: "#212a3b" }}
              className={styles.walletBoxCheck}
            />
          </>
        ) : (
          <img
            src={icon}
            style={{
              width: 50,
            }}
            alt={name}
            className={styles.walletBoxIcon}
          />
        )}
      </div>
      <p className={styles.walletBoxText}>{name}</p>
    </div>
  );
};

export default ConnectWalletBox;
