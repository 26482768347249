import { Control, DeepMap, FieldError } from 'react-hook-form';
import { TextInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { Pool } from '../../types';
import DisplayPoolSwitch from './DisplayPoolSwitch';
import PoolHash from './PoolHash';
import { UploadFile } from '../../../../../components/Base/UploadFile';

interface GeneralDetailProps {
  detail?: Pool;
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  register: any;
  setValue: any;
}

export function GeneralDetail({ detail, control, errors, register, setValue }: GeneralDetailProps) {
  return (
    <Paper>
      <PaperTitle>Project General Info</PaperTitle>
      <DisplayPoolSwitch
        poolDetail={detail}
        register={register}
        setValue={setValue}
        errors={errors}
        control={control}
      />
      <TextInput
        name="name"
        control={control}
        label="Project Name"
        errorMessage={errors?.name?.message}
        isRequired={true}
      />
      <PoolHash poolDetail={detail} />
      <UploadFile
        control={control}
        watch={control?.watchInternal}
        data={detail?.banner}
        formName="banner"
        errors={errors?.banner}
        label="Project Banner"
        tooltip={
          <>
            Recommended ratio: 16:6 <br /> Minimum resolution: 800x300 pixel
          </>
        }
      />
      <TextInput name="label" control={control} label="Project Label" errorMessage={errors?.label?.message} />
      <TextInput
        name="website"
        control={control}
        label="Project Website"
        errorMessage={errors?.website?.message}
        isRequired={true}
      />
    </Paper>
  );
}
