import { Control, DeepMap, FieldError } from 'react-hook-form';
import { TextInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';

interface ScocialSettingProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  children?: any;
}

export function BoosterCollection({ control, errors, children }: ScocialSettingProps) {
  return (
    <Paper>
      <PaperTitle>Boost NFT collection</PaperTitle>
      <TextInput
        name="collection_address"
        control={control}
        disabled={true}
        errorMessage={errors?.collection_address?.message}
        label="Collection Address"
      />
      <TextInput
        name="percent_booster"
        control={control}
        disabled={true}
        errorMessage={errors?.percent_booster?.message}
        label="% Boost"
      />
    </Paper>
  );
}
