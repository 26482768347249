import { ERROR_CODE } from '../constants/solana';
import { useWallet } from '@solana/wallet-adapter-react';
import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { ConnectorNames } from '../constants/connectors';
import { WalletSignMessageError } from '@solana/wallet-adapter-base';

const SIGNATURE = process.env.REACT_APP_MESSAGE_SIGNATURE || '';

export const getParamsWithConnector = (connectedAccount: string) => ({
  [ConnectorNames.BSC]: {
    method: 'eth_sign',
    params: [connectedAccount, SIGNATURE],
  },
  [ConnectorNames.MetaMask]: {
    method: 'personal_sign',
    params: [SIGNATURE, connectedAccount],
  },
});

export const useWalletSignatureAsync = () => {
  const { library, account: connectedAccount } = useWeb3React();
  const { signMessage } = useWallet();

  const web3Sign = useCallback(
    async (walletName?: string, account?: string) => {
      if (!connectedAccount || !library) {
        throw new Error('Wallet has not connected');
      }

      const wallet = walletName;
      const signAccount = account ?? connectedAccount;
      const paramsWithConnector = getParamsWithConnector(signAccount)[wallet as ConnectorNames];
      const provider = library.provider;

      return new Promise<string | undefined>(async (resolve, reject) => {
        await (provider as any).sendAsync(
          {
            method: paramsWithConnector?.method,
            params: paramsWithConnector?.params,
          },
          async function (err: Error, result: any) {
            if (err || result.error) {
              const error = err || (err as any).error || result.error;
              reject(error);
            } else {
              resolve(result?.result);
            }
          }
        );
      });
    },
    [library, connectedAccount]
  );

  const solanaSign = useCallback(async () => {
    const encodedMessage = new TextEncoder().encode(SIGNATURE);

    return await signMessage!(encodedMessage).catch(() => {
      throw new WalletSignMessageError('User denied message signature.', {
        code: ERROR_CODE.USER_REJECT,
      });
    });
  }, [signMessage]);

  return {
    web3Sign,
    solanaSign,
  };
};
