import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { USERS } from '../../../../constants';
import { createAdmin, updateAdmin } from '../../../../request/admin';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { useCommonStyle } from '../../../../styles';
import { adminRoute } from '../../../../utils';
import useStyles from '../styles';

const FormAdmin = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();
  const { history, isCreate } = props;
  const [roles, setRoles] = useState(USERS.SUPER_ADMIN.toString());

  let { id, username, email, firstname, wallet_address, is_active, role } = props.admin;
  const { register, errors, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      username,
      email,
      firstname,
      wallet_address,
      is_active,
      role,
    },
  });

  const onSubmit = (values: any) => {
    const trimmedValues = {
      ...values,
      username: values.username.trim(),
      wallet_address: values.wallet_address.trim(),
      role: roles,
    };
    if (isCreate) {
      createAdmin(trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/list-admin'));
        } else if (res.status === 400) {
          setError('wallet_address', { type: 'custom', message: 'This wallet address already exists' });
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    } else {
      updateAdmin(id, trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/list-admin'));
        } else if (res.status === 500) {
          setError('wallet_address', { type: 'custom', message: 'This wallet address already exists' });
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    }
  };

  const handleChangeRadio = (e: any) => {
    setRoles(e.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Username&nbsp;<span className={commonStyle.required}>(*)</span>
          </label>
          <input
            className={styles.inputG}
            name="username"
            placeholder=""
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.username && <span className={commonStyle.error}>This field is required</span>}
        </div>
        <div className="clearfix"></div>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Email</label>
          <input
            className={styles.inputG}
            name="email"
            placeholder="Email"
            ref={register({
              maxLength: 255,
            })}
          />
          {errors.email && <span className={commonStyle.error}>This field is required</span>}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Wallet address&nbsp;<span className={commonStyle.required}>(*)</span>
          </label>
          <input
            className={styles.inputG}
            name="wallet_address"
            placeholder=""
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.wallet_address?.type === 'required' && (
            <span className={commonStyle.error}>This field is required</span>
          )}
          {<span className={commonStyle.error}>{errors.wallet_address?.message}</span>}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>Full Name</label>
          <input className={styles.inputG} name="firstname" placeholder="" ref={register({ maxLength: 255 })} />
          {errors.firstname && <span className={commonStyle.error}>Max length is 255</span>}
        </div>
        <div className="clearfix"></div>
        {isCreate ? (
          <div className={styles.roles}>
            <FormControl className={styles.formControl}>
              <label className={styles.label}>
                Role&nbsp;<span className={commonStyle.required}>(*)</span>
              </label>
              <RadioGroup onChange={handleChangeRadio} className={styles.radioGroup} value={roles}>
                <FormControlLabel
                  className={styles.formControlLabel}
                  control={<Radio />}
                  value={USERS.SUPER_ADMIN.toString()}
                  label="Super Admin"
                />
                <FormControlLabel
                  className={styles.formControlLabel}
                  control={<Radio />}
                  value={USERS.ADMIN.toString()}
                  label="Admin"
                />
              </RadioGroup>
            </FormControl>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.listBtn}>
          <Button type="submit" className={styles.btnSubmit}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(FormAdmin);
