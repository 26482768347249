import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: any) => {
  return {
    pageHome: {
      background: `url('/images/admin-home-bg.svg')`,
      maxWidth: "100%",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },
    error: {
      marginTop: 27,
      fontFamily: "inherit",
      fontSize: "16px",
      color: "#FF0000 !important",
    },
    pageAdmin: {
      marginTop: "10px",
    },
    btnConnectText: {
      marginLeft: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      font: "normal normal normal 12px/18px Helvetica",
    },
    btnAccount: {
      display: "inline-block",
      backgroundColor: "#0C1018",
      padding: "8px 10px",
      borderRadius: 20,
      marginRight: "-12px",
    },
    boxImage: {
      width: "40%",
    },
    image: {
      float: 'right',
      marginRight: 156
    },
    "form__wallet-address": {
      fontFamily: "inherit",
      "& > label": {
        fontSize: "20px",
        fontWeight: 400,
        color: "#FFFFFF",
        marginTop: 18
      },
      "& > p": {
        padding: "10px 49px",
        background: "#FFFFFF",
        color: "#76479F",
        fontSize: "25px",
        fontFamily: "inherit",
        fontWeight: 400,
        height: 64,
        margin: "27px 0 0",
        width: "fit-content"
      },
    },
    login: {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .login__wrap": {
        width: "60%",
        maxWidth: "100%",
        textAlign: "left",
        margin: "0 auto",
      },
      "& .login__logo": {
        marginBottom: "15px",
        display: "flex",
        alignItems: "center",
      },
      "& .login-logo": {
        width: 20,
      },
      "& .login__logo-ether": {
        display: "flex",
        alignItems: "flex-start",
        margin: "50px 0px",
      },
      "& .login__logo img": {
        height: "40px",
      },

      "& .login__title": {
        fontWeight: 900,
        color: theme.custom.colors.primary,
        fontSize: "20px",
        marginLeft: 10,
      },
      "& .login__description": {
        fontSize: "16px",
        marginBottom: "30px",
      },
      "& .login__button": {
        marginBottom: "30px",
      },
      "& .login__button--bold": {
        textTransform: "uppercase",
        fontWeight: 700,
      },
      "& .login__logo-metamask img": {
        width: "40px",
      },

      "& .login__logo-ether-desc": {
        marginLeft: "30px",
        textAlign: "left",
      },

      "& .login__logo-ether img": {
        width: "40px",
      },

      "& .login__logo-ether-title": {
        fontSize: 60,
        fontWeight: 400,
        color: "#FFFFFF",
        margin: "0px 0 40px",
        fontFamily: 'OpenSans-Regular'
      },

      "& .logo__desc--bold": {
        fontWeight: 700,
        marginBottom: 5,
      },

      "& .MuiFormControl-root": {
        width: "100%",
      },

      "& .login__form": {
        marginTop: 20,
      },

      "& .login__form-field": {
        marginTop: 20,
      },

      "& .login__form-desc": {
        marginBottom: 0,
        fontSize: 40,
        fontWeight: 700,
        color: "#FFFFFF",
        fontFamily: 'OpenSans-Bold'
      },

      "& .login__form-error-message": {
        marginTop: 7,
        color: "red",
      },

      "& .login__form-privacy a": {
        color: "#337ab7",
      },

      "& .login__form-forgot-password": {
        cursor: "pointer",
        fontWeight: "700",
        fontSize: 15,
        display: "inline-block",
        color: "blue",
      },

      "& .login__form-button": {
        fontFamily: "inherit !important",
        marginTop: 48,
        "& >  .MuiButton-label": {
          fontSize: 30,
          fontWeight: 800,
          fontFamily: 'OpenSans-ExtraBold'
        },
      },
      "& .login__user-loading": {
        textAlign: "center",
      },

      "& .login__user-loading-text": {
        fontWeight: 700,
        fontSize: 20,
        marginTop: 25,
      },

      [theme.breakpoints.up("md")]: {
        "& .login__title": {
          lineHeight: "70px",
        },
        "& .login__logo": {
          marginBottom: "30px",
          "& img": {},
        },
        "& .login__logo img": {},
        "& .login__description": {
          marginBottom: "60px",
        },
        "& .login__button button": {
          height: "60px",
        },
        "& .login__logo-metamask img": {
          width: "auto",
        },
      },
    },
  };
});

export default useStyles;
