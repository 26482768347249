import { Skeleton } from 'antd';
import DefaultLayout from './DefaultLayout';


export const LoadingLayout = () => {
  return (
    <DefaultLayout>
      <Skeleton active paragraph={{ rows: 25 }} />
    </DefaultLayout>
  );
};
