import { Control, DeepMap, FieldError } from 'react-hook-form';
import useStyles from './style';
import useBuyTimeStyles from './style';
import { DateTimePicker } from '../../../../../components/Base/Form';
import moment from 'moment';
import { Pool } from '../../types';

import { PaperTitle } from '../../../../../components/Base/Paper';
import { Checkbox } from '../../../../../components/Base/Form/Checkbox/Checkbox';

interface BuyTimeProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  poolDetail?: Pool;
  hasTBA?: boolean;
}

export function BuyTime({ control, poolDetail, errors, hasTBA }: BuyTimeProps) {
  const classes = useStyles();
  const buyTimeClasses = useBuyTimeStyles();
  const isTBA: boolean = !!hasTBA && (control?.watchInternal('buyTimeTBA') as any);

  return (
    <div className={classes.exchangeRate}>
      <PaperTitle>Start/End Buy Time</PaperTitle>
      {hasTBA && (
        <Checkbox
          control={control}
          errorMessage={errors?.buyTimeTBA?.message}
          disabled={!!poolDetail?.isDeploy}
          name="buyTimeTBA"
          label="TBA"
        />
      )}
      <div className={buyTimeClasses.cardContainer}>
        <DateTimePicker
          name="startTime"
          label="Start Buy Time"
          control={control}
          errorMessage={errors?.startTime?.message}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={1}
          className={buyTimeClasses['time-control']}
          disabled={!!poolDetail?.isDeploy || isTBA}
        />
        <DateTimePicker
          name="finishTime"
          label="End Buy Time"
          control={control}
          errorMessage={errors?.finishTime?.message}
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            format: 'HH:mm',
          }}
          minuteStep={1}
          disabled={!!poolDetail?.isDeploy || isTBA}
        />
      </div>
    </div>
  );
}
