import configureStore from '../store/configureStore';
import { ETH_CHAIN_ID, POLYGON_CHAIN_ID, SOLANA_CHAIN_ID } from './../constants/network';

export enum SelectedNetWork {
  NONE = '',
  SOLANA = 'Solana',
  ETH = 'Metamask',
  POLYGON = 'Polygon',
}

export function useSelectedNetwork() {
  let selectedNetwork = configureStore().store.getState().appNetwork.data.appChainID;

  switch (selectedNetwork) {
    case ETH_CHAIN_ID:
      selectedNetwork = SelectedNetWork.ETH;
      break;
    case POLYGON_CHAIN_ID:
      selectedNetwork = SelectedNetWork.POLYGON;
      break;
    case SOLANA_CHAIN_ID:
      selectedNetwork = SelectedNetWork.SOLANA;
      break;
    default:
      selectedNetwork = SelectedNetWork.NONE;
      break;
  }

  return {
    isSelectedSolana: selectedNetwork === SelectedNetWork.SOLANA,
    selectedNetwork,
    selectedNetworkId: selectedNetwork,
  };
}
