import { CircularProgress, FormControl, FormControlLabel, Grid, Radio, Tooltip, } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { NumberInput, RadioGroup, TextInput } from '../../../../components/Base/Form';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadFile } from '../../../../components/Base/UploadFile';
import { UploadType } from '../../../SliderManage/constants/type';
import { uploadFile } from '../../../../utils/file';
import { NETWORK_AVAILABLE } from '../../../../constants';
import { getIconCurrencyUsdt } from '../../../../utils/usdt';
import { createCompleted, updateCompleted } from '../../../../request/completed';
import { Switch } from 'antd';
import { changeDisplayStatus } from '../../../../request/pool';
import RequireSymbol from '../../../../components/Base/RequireSymbol';

export const BASE_VALIDATION_SCHEMA = object({
  order: number()
    .typeError('This field must be a number')
    .required('This field must be a number')
    .integer('This field must be integer')
    .min(0, 'This field must be greater than 0'),
});

interface Completed {
  name: string;
  token_images: string;
  banner: {
    uploadType: UploadType;
    imgLink: string;
    link?: string;
    file?: File | null;
    uploadedUrl: string;
  };
  TGE_description: string;
  token: string;
  total_sold_coin: number | string;
  accept_currency: number | string;
  network_available: number | string;
  token_conversion_rate: number | string;
  ether_conversion_rate: number | string;
  tokenInfo: string;
  symbol: string;
  decimals: number | string;
  percentage: number | string;
  is_display: number | string;
}

const FormCompleted = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate } = props;
  const [loading, setLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);


  const {reset, control, errors, watch, getValues } = useForm<Completed>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      token_images: '',
      banner: {
        uploadType: UploadType.LINK,
        link: '',
      },
      TGE_description: '',
      token: '',
      total_sold_coin: '',
      accept_currency: 'usdt',
      network_available: NETWORK_AVAILABLE.POLYGON,
      token_conversion_rate: '',
      ether_conversion_rate: '1',
      tokenInfo: '',
      symbol: '',
      decimals: '',
      percentage: '',
      is_display: 0,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(BASE_VALIDATION_SCHEMA),
  });

  const changeDisplay = async (value: any) => {
    await changeDisplayStatus({
      poolId: completed.id,
      isDisplay: value,
    });
    return value;
  };

  useEffect(() => {
    if (completed) {
      reset({
        name: completed.name,
        token_images: completed.token_images,
        banner: {
          link: completed.banner,
          uploadedUrl: completed.banner,
          uploadType: UploadType.LINK,
        },
        TGE_description: completed.TGE_description,
        token: completed.token,
        total_sold_coin: completed.total_sold_coin,
        accept_currency: completed.accept_currency,
        network_available: completed.network_available,
        token_conversion_rate: completed.token_conversion_rate,
        ether_conversion_rate: completed.ether_conversion_rate,
        tokenInfo: completed.tokenInfo,
        symbol: completed.symbol,
        decimals: completed.decimals,
        percentage: completed.percentage,
        is_display: completed.is_display,
      });
    }
  }, [completed, reset]);

  const handlerUpdate = async (values: Completed) => {
    setIsOpenModal(false);
    let banner = values.banner.link;
    setLoading(true);
    try {
      if (values.banner.uploadType === UploadType.FILE && !!values.banner.file) {
        banner = await uploadFile(values.banner.file);
      }

      if (values.banner.uploadType === UploadType.LINK) {
        banner = values.banner.link ?? '';
      }

      const trimmedValue = {
        name: values.name.toUpperCase(),
        token_images: values.token_images,
        banner,
        TGE_description: values.TGE_description,
        token: values.token,
        total_sold_coin: values.total_sold_coin,
        accept_currency: values.accept_currency,
        network_available: values.network_available,
        token_conversion_rate: values.token_conversion_rate,
        ether_conversion_rate: 1,
        tokenInfo: values.tokenInfo,
        symbol: values.symbol,
        decimals: 18,
        percentage: values.percentage,
        is_display: values.is_display,
      };

      if (isCreate) {
        const res = await createCompleted(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/completed'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateCompleted(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/completed'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  const acceptCurrency = watch('acceptCurrency');
  const networkAvailable = watch('networkAvailable');
  let { currencyName } = getIconCurrencyUsdt({
    purchasableCurrency: acceptCurrency,
    networkAvailable: networkAvailable,
  });
  
  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/completed')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={6}>
          <Paper>
            <div>
              <label className={classes.label}>Display project</label>
              <FormControl component="fieldset">
                <Controller
                  control={control}
                  name="is_display"
                  render={(field) => {
                    const { value, onChange } = field;
                    return (
                      <Switch
                      onChange={async (switchValue) => {
                        try {
                          if (completed?.id) {
                            await changeDisplay(switchValue);
                          }
                          await onChange(switchValue);
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      checked={value}
                      checkedChildren="Display"
                      unCheckedChildren="Hidden"
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
            <TextInput 
              name="name" 
              control={control} 
              label="Project Name"
              isRequired={true}
            />
          </Paper>

          <Paper>
            <>
              <label>Project Banner</label>
              <RequireSymbol />
              <UploadFile
                control={control}
                watch={watch}
                data={{
                  link: completed?.banner  || '',
                  uploadedUrl: completed?.banner || '',
                  uploadType: UploadType.LINK,
                }}
                formName="banner"
                errors={errors?.banner}
                selectTooltip={<>Recommended ratio: 1:1 <br /> Minimum resolution: 160x160 pixel</>}
              />
            </>
          </Paper>

          <Paper>
            <>
              <label>Progress Bar Setting</label>
              <NumberInput
                name="percentage"
                control={control}
                label="Progress Bar Percentage (%)"
                errorMessage={errors?.percentage?.message}
              />
              <TextInput
                name="TGE_description"
                control={control}
                label="TGE unlock"
                errorMessage={errors?.TGE_description?.message}
              />
            </>
          </Paper>
        </Grid>

        {/* Network and token */}
        <Grid item={true} xs={6}>
          <Paper>
            <>
              <div>
                <RadioGroup
                  label="Network Available"
                  control={control}
                  name="network_available"
                  radioData={[
                    {
                      value: NETWORK_AVAILABLE.POLYGON,
                      label: 'Polygon',
                    },
                    {
                      value: NETWORK_AVAILABLE.SOL,
                      label: 'Solana',
                    },
                    {
                      value: NETWORK_AVAILABLE.ETH,
                      label: 'Ethereum',
                    },
                    {
                      value: NETWORK_AVAILABLE.BSC,
                      label: 'BSC',
                    },
                  ]}
                  onRenderRadio={(data) => (
                    <FormControlLabel key={data.value} value={data.value} label={data.label} control={<Radio size="small" />}/>
                  )}
                />
                <FormControl >
                  <RadioGroup
                    name="accept_currency"
                    label="Accept Currency"
                    control={control}
                    defaultValue={'usdt'}
                    radioData={[
                      {
                        value: 'usdt',
                        label: 'USDT',
                      },
                      {
                        value: 'usdc',
                        label: 'USDC',
                      },
                    ]}
                    onRenderRadio={(data) => (
                      <FormControlLabel
                        key={data.value}
                        value={data.value}
                        label={data.label}
                        control={<Radio size="small" />}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </>
          </Paper>

          <Paper>
            <TextInput
              name="symbol"
              control={control}
              label="Token Symbol"
              isRequired={true}
              errorMessage={errors?.name?.message}
            />
            <NumberInput
              name="total_sold_coin"
              control={control}
              label="Token Sold Coin"
              isRequired={true}
              errorMessage={errors?.total_sold_coin?.message}
            />
          </Paper>

          {/* Exchange Rate */}
          <Paper>
            <>
              <label>Exchange Rate</label>
              <RequireSymbol/>
              <div className={classes.formControlFlex}>
                <div className={`${classes.formControlFlexBlock} `}>
                  <label className={`${classes.formControlLabel}`}>You have</label>
                  <div className={classes.formControlRate}>
                    <input
                      name="ether_conversion_rate"
                      disabled={true}
                      value={1}
                      className={`${classes.formInputBox} ${classes.formInputBoxEther}`}
                    />
                    <Tooltip title={''}>
                      <button className={classes.box}></button>
                    </Tooltip>
                  </div>
                </div>

                <img className={classes.formControlIcon} src="/images/icon-exchange.svg" alt="" />
                <div className={`${classes.formControlFlexBlock}`}>
                  <label className={`${classes.formControlLabel}`}>You get</label>
                  <div className={classes.formControlRate}>
                    <NumberInput
                      control={control}
                      decimalsLimit={8}
                      maxLength={25}
                      className={classes.rateInput}
                      currencyInputClass={`${classes.formInputBox} ${classes.formInputBoxBS}`}
                      name="token_conversion_rate"
                    />
                    <Tooltip title={currencyName}>
                      <button className={`${classes.box} ${classes.boxEther}`}>{currencyName}</button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </>
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={(() => setIsOpenModal(true))}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the project' : 'Update the project'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues())
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to {isCreate ? 'create this project?' : 'update the project?'}</p>
      </ConfirmDialog>
    </>
  );
};

export default FormCompleted;
