import { useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { adminRoute } from '../../../utils';
import { useHistory } from 'react-router';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { setOpenFirst, setOpenFive, setOpenFour, setOpenSecond, setOpenThird } from '../../../store/actions/open';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      //   backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      cursor: 'pointer',
    },
    linkItemNavLeft: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 15,
      padding: '13px 26px',
      fontSize: 16,
      lineHeight: '20px',
      color: '#8D8D8D',
      transition: '0.3s',
      fontFamily: 'OpenSans-SemiBold',
      '&.active': {
        background: 'linear-gradient(90deg, #9B0BF3 0%, rgba(2, 211, 224, 0) 100%)',
        filter: 'drop-shadow(0px 2px 4px rgba(138, 146, 166, 0.3))',
        color: '#FFFFFF',

        '& .icon': {
          backgroundColor: '#FFFFFF',
        },
      },
      '&:hover': {
        background: 'linear-gradient(90deg, #9B0BF3 0%, rgba(2, 211, 224, 0) 100%)',
        filter: 'drop-shadow(0px 2px 4px rgba(138, 146, 166, 0.3))',
        color: '#FFFFFF',
        transition: '0.3s',
        '& .icon': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    text: {
      fontSize: ' 16px',
      transition: '0.3s',
      alignItems: 'center',
      fontFamily: 'OpenSans-SemiBold',
      lineHeight: '20px',
      borderRadius: '15px',
    },
  })
);

export default function NestedList(props: any) {
  const { onLogOut } = props;
  const history = useHistory();
  const classes = useStyles();
  const { openFirst, openSecond, openThird, openFour, openFive } = useTypedSelector((state) => state.open);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setOpenFirst(!openFirst));
  };

  const handleClick1 = () => {
    dispatch(setOpenSecond(!openSecond));
  };

  const handleClick2 = () => {
    dispatch(setOpenThird(!openThird));
  };

  const handleClick3 = () => {
    dispatch(setOpenFour(!openFour));
  };

  const handleClick4 = () => {
    dispatch(setOpenFive(!openFive));
  };

  const subMenuVispxLandingPage = [
    {
      title: 'New slide',
      part: adminRoute('/new-sliders'),
    },
    {
      title: 'Feature',
      part: adminRoute('/project-feature'),
    },
    {
      title: 'Landing Projects',
      part: adminRoute('/landing-projects'),
    },
    {
      title: 'Project Matrix',
      part: adminRoute('/project-matrix'),
    },
    {
      title: 'Yieldbox Injection',
      part: adminRoute('/project-yieldbox'),
    },
    {
      title: 'About Investment Pool',
      part: adminRoute('/list-about-investment'),
    },
    {
      title: 'Our Service',
      part: adminRoute('/list-our-service'),
    },
  ];

  const submenuButtons = [
    {
      title: 'Button Launch App Config',
      part: adminRoute('/button-1-config'),
    },
    {
      title: 'Button Explorer Config',
      part: adminRoute('/button-2-config'),
    },
    {
      title: 'Config Button Explore In Section Feature',
      part: adminRoute('/button-in-section-feature'),
    },
  ];

  const submenuTitleAndDescription = useMemo(() => {
    return [
      {
        title: 'Landing project config',
        part: adminRoute('/feature-config'),
      },
      {
        title: 'Project Matrix Config',
        part: adminRoute('/project-matrix-config'),
      },
      {
        title: 'Yieldbox Injection Config',
        part: adminRoute('/project-yield-box-config'),
      },
      {
        title: 'About Investment Pool Config',
        part: adminRoute('/investment-pool-config'),
        children: null,
      },
      {
        title: 'Participate Config',
        part: adminRoute('/participate-config-title'),
      },
      {
        title: 'Our Service Config',
        part: adminRoute('/our-service-config'),
      },
    ];
  }, []);

  const submenuFAQ = useMemo(() => {
    return [
      {
        title: 'FAQ',
        part: adminRoute('/fa-category'),
        children: null,
      },
      {
        title: 'Config FAQ Title',
        part: adminRoute('/faq-config'),
      },
    ];
  }, []);

  const subMenuParticipate = useMemo(() => {
    return [
      {
        title: 'Card Config',
        part: adminRoute('/card-participate-config'),
        children: null,
      },
      {
        title: 'Step Config',
        part: adminRoute('/step-participate-config-category'),
        children: null,
      },
    ];
  }, []);

  const listOldMenus = [
    {
      title: 'IDO Project',
      part: adminRoute('/projects'),
      children: null,
    },
    {
      title: 'Completed Projects',
      part: adminRoute('/completed'),
      children: null,
    },
    {
      title: 'Reward Staking',
      part: adminRoute('/reward'),
      children: null,
    },
    {
      title: 'List User',
      part: adminRoute('/list-user'),
      children: null,
    },
    {
      title: 'List Admin',
      part: adminRoute('/list-admin'),
      children: null,
    },
    {
      title: 'NFT Contracts',
      part: adminRoute('/nft-contracts'),
      children: null,
    },
    {
      title: 'Landing page',
      part: adminRoute('/sliders'),
      children: null,
    },
    {
      title: 'Executive Partners',
      part: adminRoute('/partners'),
      children: null,
    },
    {
      title: 'IDO Participants',
      part: adminRoute('/participants'),
      children: null,
    },
  ];

  return (
    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
      {listOldMenus.map((item) => {
        return (
          <ListItem
            className={classes.linkItemNavLeft + ' ' + (window.location?.pathname.includes(item.part) && 'active')}
            button
            onClick={() => history.push(item.part)}
          >
            <ListItemText className={classes.text} primary={item.title} />
          </ListItem>
        );
      })}
      <ListItem button onClick={handleClick}>
        <ListItemText primary="Vispx Landing Page Setting" />
        {openFirst ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openFirst} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenuVispxLandingPage.map((item) => {
            return (
              <ListItem
                button
                className={
                  classes.linkItemNavLeft + ' ' + (window.location?.pathname.includes(item.part) && 'active')
                  //   classes.nested
                }
                onClick={() => history.push(item.part)}
              >
                <ListItemText className={classes.text} primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <ListItem button onClick={handleClick1}>
        <ListItemText primary="Setting Button Of Landing Page" />
        {openSecond ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openSecond} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {submenuButtons.map((item) => {
            return (
              <ListItem
                button
                className={
                  classes.linkItemNavLeft + ' ' + (window.location?.pathname.includes(item.part) && 'active')
                  //   classes.nested
                }
                onClick={() => history.push(item.part)}
              >
                <ListItemText className={classes.text} primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <ListItem button onClick={handleClick2}>
        <ListItemText primary="Setting Title and Description Of Landing Page" />
        {openThird ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openThird} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {submenuTitleAndDescription.map((item) => {
            return (
              <ListItem
                button
                className={
                  classes.linkItemNavLeft + ' ' + (window.location?.pathname.includes(item.part) && 'active')
                  //   classes.nested
                }
                onClick={() => history.push(item.part)}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <ListItem button onClick={handleClick3}>
        <ListItemText primary=" FAQ questions" />
        {openFour ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openFour} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {submenuFAQ.map((item) => {
            return (
              <ListItem
                button
                className={
                  classes.linkItemNavLeft + ' ' + (window.location?.pathname.includes(item.part) && 'active')
                  //   classes.nested
                }
                onClick={() => history.push(item.part)}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <ListItem button onClick={handleClick4}>
        <ListItemText primary="Participate" />
        {openFive ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openFive} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenuParticipate.map((item) => {
            return (
              <ListItem
                button
                className={
                  classes.linkItemNavLeft + ' ' + (window.location?.pathname.includes(item.part) && 'active')
                  //   classes.nested
                }
                onClick={() => history.push(item.part)}
              >
                <ListItemText primary={item.title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <ListItem button onClick={() => onLogOut()}>
        <ListItemText primary={'Log Out'} />
      </ListItem>
    </List>
  );
}
