import { FormControlLabel, Radio } from '@material-ui/core';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { FileInput, FormLabel, RadioGroup, TextInput } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import RequireSymbol from '../../../../../components/Base/RequireSymbol';
import { FileTypeLabel, UploadFileTypeLabel } from '../../../constants';
import { FileType, MediaInfo, UploadType } from '../../../constants/type';
import ReactPlayer from 'react-player/lazy';
import styles from '../styles.module.scss';
import { useMemo } from 'react';

interface GeneralDetailProps {
  control?: Control<any>;
  watch: any;
  errors?: DeepMap<any, FieldError>;
  hasTypeSection?: boolean;
  title: string;
  formName: string;
  required?: boolean;
  data?: MediaInfo;
}

export function UploadFile({
  control,
  errors,
  hasTypeSection,
  title,
  formName,
  watch,
  required,
  data,
}: GeneralDetailProps) {
  const fileType = watch(`${formName}.type`);
  const fileTypeLabel = FileTypeLabel[fileType];
  const uploadType = watch(`${formName}.uploadType`);
  const uploadTypeLabel = UploadFileTypeLabel[uploadType];

  const message = useMemo(() => {
    if (fileType === FileType.VIDEO) {
      return 'Please upload only file with mp4, mov, mkv formats and the file size should not exceed 50mb.';
    }

    return 'Please upload only file with png, gif, jpg, jpeg, svg formats and the file size should not exceed 8mb.';
  }, [fileType]);

  return (
    <Paper>
      <PaperTitle>
        {title} {required && <RequireSymbol />}
      </PaperTitle>
      {hasTypeSection && (
        <RadioGroup
          name={`${formName}.type`}
          label="Slide Type"
          control={control}
          errorMessage={errors?.isPrivate?.message}
          radioData={[
            {
              value: FileType.IMAGE,
              label: 'Image',
            },
            {
              value: FileType.VIDEO,
              label: 'Video',
            },
          ]}
          onRenderRadio={(data) => (
            <FormControlLabel key={data.value} value={data.value} label={data.label} control={<Radio size="small" />} />
          )}
        />
      )}
      <RadioGroup
        name={`${formName}.uploadType`}
        label={`${fileTypeLabel} Type`}
        control={control}
        errorMessage={errors?.isPrivate?.message}
        radioData={[
          {
            value: UploadType.LINK,
            label: 'Link',
          },
          {
            value: UploadType.FILE,
            label: 'File',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel key={data.value} value={data.value} label={data.label} control={<Radio size="small" />} />
        )}
      />
      {uploadType === UploadType.LINK ? (
        <TextInput
          control={control}
          errorMessage={errors?.[formName]?.link?.message}
          label={`${fileTypeLabel} ${uploadTypeLabel}`}
          name={`${formName}.link`}
          type="text"
          defaultValue=""
        />
      ) : (
        <FileInput
          control={control}
          errorMessage={errors?.[formName]?.file?.message}
          label={`${fileTypeLabel} ${uploadTypeLabel}`}
          name={`${formName}.file`}
          type="file"
          className={styles['file-upload']}
          tooltip={message}
        />
      )}
      <MediaPreview data={data} />
    </Paper>
  );
}

function MediaPreview({ data }: { data?: MediaInfo }) {
  if (!data?.uploadedUrl) {
    return <></>;
  }

  if (data.type === FileType.IMAGE) {
    return (
      <section className={styles.previewMedia}>
        <FormLabel>Image Preview</FormLabel>
        <img src={data.uploadedUrl} alt={'preview'} />
      </section>
    );
  }

  return (
    <section className={styles.previewMedia}>
      <FormLabel>Video Preview</FormLabel>
      <ReactPlayer width="100%" height="auto" url={data.uploadedUrl} controls />
    </section>
  );
}
