import { NFTContract } from './../pages/NFTContract/types';
import { apiRoute } from '../utils';
import { BaseRequest } from './Request';

const queryString = require('query-string');

export const getNftContracts = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/collection-nfts`);
  url += '?' + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export async function getNftContract(id: string | number) {
  const request = new BaseRequest();
  const res = await request.get(`/admin/collection-nft/${id}`);

  if (res.status !== 200) {
    throw new Error('Failed to get nft contract');
  }

  const { data, status, message } = await res.json();
  if (status !== 200) {
    throw new Error(message);
  }

  return data;
}

export async function createContract(data: NFTContract) {
  const request = new BaseRequest();
  const res = await request.post('/admin/collection-nft/create', {
    project_name: data.name,
    collection_nft: data.address,
    network_available: data.network,
  });

  if (res.status !== 200) {
    throw new Error('Failed to create nft contract');
  }

  const resData = await res.json();
  if (resData.status !== 200) {
    throw new Error(resData.message);
  }
}

export async function editContract(id: string | number, data: NFTContract) {
  const request = new BaseRequest();
  const res = await request.post(`/admin/collection-nft/${id}/update`, {
    project_name: data.name,
    collection_nft: data.address,
    network_available: data.network,
  });

  if (res.status !== 200) {
    throw new Error('Failed to update nft contract');
  }

  const resData = await res.json();
  if (resData.status !== 200) {
    throw new Error(resData.message);
  }
}
