import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: any) => {
    return {
        root: {
            padding: 24,
            marginTop: 20,
            boxShadow: '0px 0px 15px rgb(0 0 0 / 10%)',
            borderRadius: 10
        },
        label: {
            fontSize: 16,
            fontWeight: 600,
            color: "#001F4D",
            marginBottom: 12
        },
        error: {
            margin: '10px 0',
            fontFamily: "Open Sans",
            fontSize: "16px",
            color: "#FF0000 !important",
        },
        button: {
            background: 'linear-gradient(90deg, #61009D 0%, rgba(90, 231, 240, 0.64) 100%)',
            // height: '40px !important',
            padding: '8px 24px',
            width: '96px !important',
            color: 'white',
            marginBottom: 12
        },
        switchButtonField: {
            display: 'flex',
            gap: 12,
            color: '#001F4D',
            '& .ant-switch': {
                marginTop: 4
            }
        },
        tabs:{
            color: '#8A92A6',
            '& .ant-tabs-tab-active':{
                '& .ant-tabs-tab-btn':{
                    color: '#001F4D',
                }
            },
            '& .ant-tabs-ink-bar': {
                background: '#001F4D'
            },
            '& .ant-tabs-tab': {
                fontSize: '16px'
            }
        },
        // buttonSnapshotField: {
        //     width: '100%',
        //     maxWidth: 96,
        //     height: 32
        // }
    }
})

export const useTableStyles = makeStyles((theme: any) => {
    return {
        root: {
            width: "100%"
        },
        container: {
            width: "100%"
        },
        tableHead: {
            '& .MuiTableCell-head':{
                fontSize: 16,
                fontWeight: 600,
                color: 'black'
            }
        }
    }
})