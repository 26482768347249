import { CircularProgress, Grid, ListItemText, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { TextInput, Textarea } from '../../../../components/Base/Form';
import { createFAndQ, getListFAndQ, updateFAndQ } from '../../../../request/completed';
import { FAndQType } from '../../FQRow/FQRow';
import './index.scss';
import { Dropdown } from '../../../../components/Base/Form/Dropdown/Dropdown';
import RequireSymbol from '../../../../components/Base/RequireSymbol';

const FAndQForm = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: FAndQType; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<any[]>([]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, getValues } = useForm<FAndQType>({
    mode: 'onChange',
    defaultValues: {
      question: '',
      answer: '',
      category_id: '',
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    fetchFAndQList();
  }, []);

  useEffect(() => {
    if (completed) {
      reset({
        question: completed.question,
        answer: completed.answer,
        category_id: completed.category_id,
      } as any);
    }
  }, [completed, reset]);

  const fetchFAndQList = async () => {
    try {
      const res = await getListFAndQ();
      if (res.data.data) {
        setCategories(res.data.data);
      }
    } catch (error) {}
  };

  const handlerUpdate = async (values: FAndQType) => {
    setIsOpenModal(false);
    setLoading(true);

    try {
      const trimmedValue = {
        question: values.question,
        answer: values.answer,
        category_id: values.category_id,
      };

      if (isCreate) {
        const res = await createFAndQ(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/fa-config'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateFAndQ(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/fa-config'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/fa-config')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={12}>
          <Paper>
            <label>Frequently Asked Questions</label>
            <TextInput name="question" control={control} label="Question" isRequired={true} />
            <div className={classes.formTextarea}>
              <label>Answer<RequireSymbol /></label>
              <Textarea
                className={classes.textarea}
                name="answer"
                control={control}
                minRows={10}
              />
            </div>
            <Dropdown
              control={control}
              name="category_id"
              label="Category"
              options={categories.map((item) => {
                return {
                  key: item.id,
                  value: item.name,
                };
              })}
              onRenderOptions={(item) => (
                <MenuItem value={item.key}>
                  <ListItemText primary={item.value} />
                </MenuItem>
              )}
              isRequired
            />
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the F&Q' : 'Update the F&Q'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to {isCreate ? 'create this F&Q?' : 'update the F&Q?'}</p>
      </ConfirmDialog>
    </>
  );
};
export default FAndQForm;
