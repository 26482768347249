import { Control, DeepMap, FieldError } from 'react-hook-form';
import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioGroup } from '../../../../../components/Base/Form';
import { Paper } from '../../../../../components/Base/Paper';
import { PaperTitle } from '../../../../../components/Base/Paper/PaperTitle';
import { NetworkType, ProjectType } from '../../../constants';

interface DetailProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
}

export function Detail({ control, errors }: DetailProps) {
  return (
    <Paper>
      <PaperTitle>Project details</PaperTitle>
      <RadioGroup
        name="projectType"
        label="Private Project Setting"
        control={control}
        errorMessage={errors?.isPrivate?.message}
        required
        radioData={[
          {
            value: ProjectType.PUBLIC + '',
            label: 'Public',
          },
          {
            value: ProjectType.PRIVATE + '',
            label: 'Private',
          },
          {
            value: ProjectType.COMMUNITY + '',
            label: 'Community',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel key={data.value} value={data.value} label={data.label} control={<Radio size="small" />} />
        )}
      />
      <RadioGroup
        name="networkAvailable"
        label="Network Available"
        errorMessage={errors?.networkAvailable?.message}
        control={control}
        radioData={[
          {
            value: NetworkType.TBD + '',
            label: 'TBD',
          },
          {
            value: NetworkType.POLYGON,
            label: 'Polygon',
          },
          {
            value: NetworkType.SOLANA,
            label: 'Solana',
          },
          {
            value: NetworkType.ETHEREUM,
            label: 'Ethereum',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel key={data.value} value={data.value} label={data.label} control={<Radio size="small" />} />
        )}
      />
    </Paper>
  );
}
