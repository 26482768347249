// import { Tooltip } from '@material-ui/core';
import { Tooltip } from 'antd';
import { get } from 'lodash';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { NumberInput } from '../../../../../components/Base/Form';
import { PaperTitle } from '../../../../../components/Base/Paper';
import useStyles from '../GeneralDetail/style';

interface TokenRewardDistributionProps {
  control?: Control<any>;
  errors?: DeepMap<any, FieldError>;
  reward_percent_ido: string;
  reward_percent_normal: string;
  isDisable: boolean
}

export function TokenRewardDistribution({
  control,
  errors,
  reward_percent_ido,
  reward_percent_normal,
  isDisable
}: TokenRewardDistributionProps) {
  const classes = { ...useStyles() };
  const postFix = (
    <button className={`${classes.box1}`}>
      <img src="/images/pen.svg" alt="pen" />
    </button>
  );
  return (
    <>
      <PaperTitle>
        Token Reward Distribution (%){' '}
        <Tooltip title="% All Staking Users + % IDO Participants= 100%">
          <img src="/images/icon-tool-tip.svg" alt="icon-tool-tip" style={{ marginBottom: 2, marginLeft: 5 }} />
        </Tooltip>
      </PaperTitle>
      <NumberInput
        label="All Staking Users"
        name={reward_percent_normal}
        control={control}
        errorMessage={get(errors, reward_percent_normal)?.message}
        postFix={postFix}
        disabled={isDisable}
      />
      <NumberInput
        label="IDO Participants"
        name={reward_percent_ido}
        control={control}
        errorMessage={get(errors, reward_percent_ido)?.message}
        postFix={postFix}
        disabled={isDisable}
      />
    </>
  );
}
