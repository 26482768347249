import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#D9D9D9',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    color: '#000000',
    fontSize: 14,
    border: '1px solid #000000',
    lineHeight: '20px',
  },
  arrow: {
    color: '#D9D9D9',
    left: '10% !important',
    position: 'absolute',
    '&:before': {
      border: '1px solid #000000',
    },
  },
}));

export default useStyles;
