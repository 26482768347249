import { FormControlLabel, Radio, Tooltip } from '@material-ui/core';
import { useMemo } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import { FileInput, FormLabel, RadioGroup, TextInput } from '../Form';
import RequireSymbol from '../RequireSymbol';
import { UploadFileTypeLabel } from '../../../pages/SliderManage/constants';
import { UploadType } from '../../../pages/SliderManage/constants/type';
import { MediaInfo } from '../../../pages/Pools/Forms/types';
import styles from './styles.module.scss';
import useStyles from './styles';

interface GeneralDetailProps {
  control?: Control<any>;
  watch: any;
  errors?: DeepMap<any, FieldError>;
  formName: string;
  data?: MediaInfo;
  label?: string;
  disabled?: boolean;
  tooltip?: any;
  selectTooltip?: any;
}

export function UploadFile({
  control,
  errors,
  formName,
  watch,
  data,
  label,
  disabled,
  tooltip,
  selectTooltip,
}: GeneralDetailProps) {
  const uploadType = watch(`${formName}.uploadType`) || 'link';
  const uploadTypeLabel = UploadFileTypeLabel[uploadType];
  const tooltipStyles = useStyles();

  const message = useMemo(() => {
    return 'Please upload only file with png, gif, jpg, jpeg, svg formats and the file size should not exceed 8mb.';
  }, []);

  return (
    <>
      {label && (
        <Tooltip
          classes={{
            tooltip: tooltipStyles.tooltip,
            arrow: tooltipStyles.arrow,
          }}
          title={tooltip ?? ''}
          placement="bottom-start"
          arrow
        >
          <div style={{ display: 'flex', gap: 3, marginTop: 30 }}>
            <FormLabel>{label}</FormLabel>
            <RequireSymbol />
          </div>
        </Tooltip>
      )}
      <RadioGroup
        tooltip={selectTooltip}
        name={`${formName}.uploadType`}
        label="Image Type"
        className={styles['form-upload']}
        control={control}
        errorMessage={errors?.isPrivate?.message}
        radioData={[
          {
            value: UploadType.LINK,
            label: 'Link',
          },
          {
            value: UploadType.FILE,
            label: 'File',
          },
        ]}
        onRenderRadio={(data) => (
          <FormControlLabel
            disabled={disabled}
            key={data.value}
            value={data.value}
            label={data.label}
            control={<Radio size="small" />}
          />
        )}
      />
      {uploadType === UploadType.LINK ? (
        <TextInput
          control={control}
          errorMessage={errors?.link?.message}
          label={`Image ${uploadTypeLabel}`}
          name={`${formName}.link`}
          type="text"
          defaultValue=""
          disabled={disabled}
        />
      ) : (
        <FileInput
          control={control}
          errorMessage={errors?.file?.message}
          label={`Image${uploadTypeLabel}`}
          name={`${formName}.file`}
          type="file"
          className={styles['file-upload']}
          tooltip={message}
          disabled={disabled}
        />
      )}
      <MediaPreview data={data} />
    </>
  );
}

function MediaPreview({ data }: { data?: MediaInfo }) {
  if (!data?.uploadedUrl) {
    return <></>;
  }

  return (
    <section className={styles.previewMedia}>
      <FormLabel>Image Preview</FormLabel>
      <img src={data.uploadedUrl} alt={'preview'} />
    </section>
  );
}
