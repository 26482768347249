import { object, string } from 'yup';
import { NETWORK_AVAILABLE } from './../../../constants';
import { NFTContract } from './../types';

export const initData: NFTContract = {
  id: -1,
  name: '',
  address: '',
  network: '-1',
};

export const NETWORK_ICON = {
  [NETWORK_AVAILABLE.BSC]: '/images/nft-contracts/bsc-icon.png',
  [NETWORK_AVAILABLE.POLYGON]: '/images/nft-contracts/polygon-icon.png',
  [NETWORK_AVAILABLE.SOL]: '/images/nft-contracts/solana-icon.png',
  [NETWORK_AVAILABLE.ETH]: '/images/ethereum.svg',
};

export const VALIDATION_SCHEMA = object({
  name: string().required('This field is required'),
  network: string().required('This field is required').not(['-1'], 'This field is required'),
  address: string().required('This field is required'),
});
