import { Button } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createIdo, updateAdmin } from '../../../../request/admin';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { useCommonStyle } from '../../../../styles';
import { adminRoute } from '../../../../utils';
import useStyles from '../styles';

const FormParticipant = (props: any) => {
  const styles = useStyles();
  const commonStyle = useCommonStyle();
  const dispatch = useDispatch();
  const { history, isCreate } = props;

  let { id, username, email, firstname, wallet_address, is_active, role } = props.admin;
  const { register, errors, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      username,
      email,
      firstname,
      wallet_address,
      is_active,
      role,
    },
  });

  const onSubmit = (values: any) => {
    const trimmedValues = {
      ido_pool_id: values.firstname.trim(),
      wallet_address: values.wallet_address.trim(),
    };
    if (isCreate) {
      createIdo(trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/participants'));
        } else if (res.status === 400) {
          setError('wallet_address', { type: 'custom', message: 'This wallet address already exists' });
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    } else {
      updateAdmin(id, trimmedValues).then((res) => {
        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/participants'));
        } else if (res.status === 500) {
          setError('wallet_address', { type: 'custom', message: 'This wallet address already exists' });
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Wallet address&nbsp;<span className={commonStyle.required}>(*)</span>
          </label>
          <input
            className={styles.inputG}
            name="wallet_address"
            placeholder=""
            ref={register({ required: true, maxLength: 255 })}
          />
          {errors.wallet_address?.type === 'required' && (
            <span className={commonStyle.error}>This field is required</span>
          )}
          {<span className={commonStyle.error}>{errors.wallet_address?.message}</span>}
        </div>
        <div className="clearfix"></div>

        <div className={styles.groupInput}>
          <label className={styles.inputLabel}>
            Pool ID&nbsp;<span className={commonStyle.required}>(*)</span>
          </label>
          <input className={styles.inputG} name="firstname" placeholder="" ref={register({ required: true, maxLength: 255 })} />
          {errors.firstname?.type === 'required' && <span className={commonStyle.error}>This field is required</span>}
          {<span className={commonStyle.error}>{errors.firstname?.message}</span>}
        </div>
        <div className="clearfix"></div>
        <div className={styles.listBtn}>
          <Button type="submit" className={styles.btnSubmit}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(FormParticipant);
