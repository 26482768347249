import { CircularProgress, Grid, ListItemText, MenuItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import { Button } from '../../../../components/Base/Form/Button/Button';
import { alertFailure, alertSuccess } from '../../../../store/actions/alert';
import { adminRoute } from '../../../../utils';
import useStyles from '../style';
import { useHistory } from 'react-router';
import { Paper } from '../../../../components/Base/Paper';
import { TextInput } from '../../../../components/Base/Form';
import { createStep, getListCard, updateStep } from '../../../../request/completed';
import { StepParticipateType } from '../../StepParticipateRow/StepParticipateRow';
import './index.scss';
import { Dropdown } from '../../../../components/Base/Form/Dropdown/Dropdown';

const FormStepParticipate = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { completed, isCreate }: { completed: StepParticipateType; isCreate: boolean } = props;
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<any[]>([]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { reset, control, getValues } = useForm<StepParticipateType>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      participate_id: '',
    },
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    fetchListCard();
  }, []);

  useEffect(() => {
    if (completed) {
      reset({
        title: completed.title,
        description: completed.description,
        participate_id: completed.participate_id,
      } as any);
    }
  }, [completed, reset]);

  const fetchListCard = async () => {
    try {
      const res = await getListCard();
      if (res.data) {
        setCategories(res.data);
      }
    } catch (error) {}
  };

  const handlerUpdate = async (values: StepParticipateType) => {
    setIsOpenModal(false);
    setLoading(true);

    try {
      const trimmedValue = {
        title: values.title,
        description: values.description,
        participate_id: values.participate_id,
      };

      if (isCreate) {
        const res = await createStep(trimmedValue);
        if (res.status === 200) {
          dispatch(alertSuccess('Created successfully!'));
          history.push(adminRoute('/step-participate-config'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      } else {
        const res = await updateStep(completed.id, trimmedValue);

        if (res.status === 200) {
          dispatch(alertSuccess('Updated successfully!'));
          history.push(adminRoute('/step-participate-config'));
        } else {
          dispatch(alertFailure(res.message || 'Something went wrong'));
        }
      }
    } catch (e: any) {
      dispatch(alertFailure(e.message || 'Something went wrong'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.header}>
        <BackButton to={adminRoute('/step-participate-config')} />
      </div>
      <Grid container spacing={6}>
        <Grid item={true} xs={12}>
          <Paper>
            <label>Step Participate</label>
            <TextInput name="title" control={control} label="Title" isRequired={true} />
            <TextInput name="description" control={control} label="Description" isRequired={true} />
            <Dropdown
              control={control}
              name="participate_id"
              label="Category"
              options={categories.map((item) => {
                return {
                  key: item.id,
                  value: item.title,
                };
              })}
              onRenderOptions={(item) => (
                <MenuItem value={item.key}>
                  <ListItemText primary={item.value} />
                </MenuItem>
              )}
              isRequired
            />
          </Paper>
        </Grid>
      </Grid>

      <div className={classes.flexButton}>
        <Button disabled={loading} className={classes.buttonUpdate} onClick={() => setIsOpenModal(true)}>
          {loading ? <CircularProgress size={25} /> : isCreate ? 'Create the step participate' : 'Update the step participate'}
        </Button>
      </div>

      <ConfirmDialog
        open={isOpenModal}
        confirmLoading={false}
        onConfirm={() => {
          handlerUpdate(getValues());
        }}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        buttonContent="Yes"
        className={classes.formDialog}
      >
        <p className={classes.titleDialog}>Are you sure to {isCreate ? 'create this step participate?' : 'update the tep participate?'}</p>
      </ConfirmDialog>
    </>
  );
};
export default FormStepParticipate;
