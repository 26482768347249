import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import useStyles from './style';
import DefaultLayout from '../../components/Layout/DefaultLayout';
import Button from '../../components/Base/ButtonLink';
import { adminRoute } from '../../utils';
import { getAboutInvestmentPool } from '../../request/admin';
import TableCommon from '../../components/TableCommon';
import AboutInvestmentPoolRow from './AboutInvestmentPoolRow/AboutInvestmentPoolRow';

const tableHeaders = ['TITLE', 'DESCRIPTION', 'PRIORITY', 'ACTION'];

const AboutInvestmentPoolList: React.FC<any> = (props: any) => {
  const classes = useStyles();

  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [currentOpen, setCurrentOpen] = useState('');

  const [currentPage, setCurrentPage] = useState(page);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const getList = async (currentPage: any) => {
    const queryParams = {
      page: currentPage,
    };

    try {
      setLoading(true);
      const resObject = await getAboutInvestmentPool(queryParams);
      if (resObject.status === 200) {
        const { page, lastPage, data } = resObject.data;
        setPage(page);
        setLastPage(lastPage);
        setList(data);
        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  useEffect(() => {
    getList(currentPage);
  }, [currentPage]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  const onRenderOurService = (ourService: any) => {
    return (
      <AboutInvestmentPoolRow
        key={ourService.id}
        currentOpen={currentOpen}
        setCurrentOpen={handleCurrentOpenSet}
        aboutInvestment={ourService}
        onReload={() => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }}
      />
    );
  };

  const onRenderPagination = () => {
    return (
      list &&
      lastPage > 1 && (
        <Pagination
          page={currentPage}
          className={classes.pagination}
          count={lastPage}
          onChange={handlePaginationChange}
        />
      )
    );
  };
  return (
    <DefaultLayout>
      <div className={classes.header}>
        <div className="header-left">
          <Button to={adminRoute('/create-bout-investment')} text={'Create a new about investment pool'} />
        </div>
      </div>
      <TableCommon
        tableHeaders={tableHeaders}
        loading={loading}
        failure={failure}
        list={list}
        onRenderContent={onRenderOurService}
        onRenderPagination={onRenderPagination}
      />
    </DefaultLayout>
  );
};

export default AboutInvestmentPoolList;
