import { web3 } from "@project-serum/anchor";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BackButton from "../../../../components/Base/ButtonLink/BackButton";
import DefaultLayout from "../../../../components/Layout/DefaultLayout";
import { NETWORK_AVAILABLE } from "../../../../constants";
import { createPool } from "../../../../request/pool";
import { adminRoute } from "../../../../utils";
import { PoolForm } from "./PoolForm";

const PoolCreate: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const isEdit = false;

  const addPool = async (data: any): Promise<any> => {
    const dataSave = {
      ...data,
      campaign_hash: data?.network_available === NETWORK_AVAILABLE.SOL ? web3.Keypair.generate().publicKey : "",
    };

    return await createPool(dataSave);
  };

  return (
    <DefaultLayout>
      <BackButton to={adminRoute("/projects")} />
      <PoolForm isEdit={isEdit} onSubmit={addPool} />
    </DefaultLayout>
  );
};

export default withRouter(PoolCreate);
