import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import { withRouter, useHistory } from 'react-router-dom';
import useStyles from './style';
import { useWallet } from '@solana/wallet-adapter-react';
import { Button } from '../../components/Base/Form';
import ConnectWalletModal from './ConnectWalletModal';
import { useSelectedNetwork } from '../../hooks/useSelectedNetwork';
import useAuth from '../../hooks/useAuth';
import { useSolana } from '../../hooks/useSolana';
import { FormHelperText } from '@material-ui/core';
import { NetworkUpdateType, settingAppNetwork } from '../../store/actions/appNetwork';
import { alertFailure } from '../../store/actions/alert';
import { useWeb3ReactLocal } from '../../hooks/useWeb3ReactLocal';
import { ETH_CHAIN_ID } from '../../constants/network';

const loginLogo = '/images/logo-home.svg';

const Login: React.FC<any> = (props: any) => {
  const [openConnectWallet, setOpenConnectWallet] = useState(false);

  const classes = useStyles();

  const [error, setError] = useState('');
  const { publicKey, connected, ready, wallet } = useWallet();
  const { account, logout, login } = useWeb3ReactLocal();
  const { isSelectedSolana } = useSelectedNetwork();
  const { login: solanaLogin } = useSolana();
  const { isAuth } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const walletAddress = useMemo(() => {
    if (isSelectedSolana && connected && ready) {
      return publicKey?.toString();
    }

    return account;
  }, [account, connected, isSelectedSolana, publicKey, ready]);

  const handleConnectWalletClose = () => {
    setOpenConnectWallet(false);
  };

  const handleConnectWalletOpen = () => {
    setOpenConnectWallet(true);
  };

  function handleLogin() {
    setError('');
    if (isSelectedSolana) {
      solanaLogin()
        .then(() => {
          history.push('/dashboard/projects');
        })
        .catch((error) => {
          if (
            error?.error?.code === 4001 ||
            error?.name === 'WalletSignMessageError' ||
            error?.name === 'WalletSignTransactionError'
          ) {
            dispatch(alertFailure(`${wallet?.name} Message Signature: User denied message signature`));
          }

          if (error && (error.status === 401 || error.status === 404)) {
            setError('Oops! There’s no admin account linked to that address. Try another account.');
          }
        });
      return;
    }

    login()
      .then(() => {
        history.push('/dashboard/projects');
      })
      .catch((error: any) => {
        if (error?.code === 4001) {
          dispatch(alertFailure(error.message));
        }

        if (error?.status === 401 || error?.status === 404) {
          setError('Oops! There’s no admin account linked to that address. Try another account.');
        }
      });
  }

  useEffect(() => {
    if (isAuth) {
      return history.push('/dashboard/projects');
    }

    dispatch(settingAppNetwork(NetworkUpdateType.App, ETH_CHAIN_ID));
    logout?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <Container className={classes.pageHome} fixed>
      <div className={classes.login}>
        <div className={classes.boxImage}>
          <img src={loginLogo} alt="login-logo" className={classes.image} />
        </div>
        <div className="login__wrap">
          <div className={classes.pageAdmin}>
            <div className="login__logo-ether-title">Admin portal</div>
            <div className="login__form">
              <p className="login__form-desc login__form-privacy">Connect wallet</p>
              {walletAddress && (
                <section className={classes['form__wallet-address']}>
                  <label>Address</label>
                  <p>{walletAddress}</p>
                </section>
              )}
              <Button className="login__form-button" onClick={!walletAddress ? handleConnectWalletOpen : handleLogin}>
                Connect
              </Button>
              {error && (
                <FormHelperText className={classes.error} error={true}>
                  {error}
                </FormHelperText>
              )}
            </div>
          </div>
        </div>
      </div>
      {openConnectWallet && <ConnectWalletModal opened={openConnectWallet} handleClose={handleConnectWalletClose} />}
    </Container>
  );
};

export default withRouter(Login);
