import FormControl from '@material-ui/core/FormControl';
import { Switch } from 'antd';
import { Controller } from 'react-hook-form';
import { withRouter } from 'react-router';
import { changeDisplayProgressBar } from '../../../../../request/pool';
import { renderErrorCreatePool } from '../../../../../utils/validate';
import useFormStyles from '../useFormStyles';
import useStyles from './style';

function DisplayPoolSwitch(props: any) {
  const classes = { ...useStyles(), ...useFormStyles() };
  const { errors, control, poolDetail } = props;
  const renderError = renderErrorCreatePool;

  const changeDisplay = async (value: any) => {
    const res = await changeDisplayProgressBar({
      poolId: poolDetail.id,
      isProgressDisplay: value ? 1 : 0,
    });
    console.log('Change display: Response: ', res);
    return value;
  };

  return (
    <div className={classes.boxToggle}>
      <label className={classes.formControlLabel}>Progress Bar Display</label>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="isProgressDisplay"
          render={(field) => {
            const { value, onChange } = field;

            return (
              <Switch
                onChange={async (switchValue) => {
                  try {
                    if (poolDetail?.id) {
                      await changeDisplay(switchValue);
                    }

                    await onChange(switchValue);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                checked={value}
                checkedChildren="Display"
                unCheckedChildren="Hidden"
              />
            );
          }}
        />

        <p className={classes.formErrorMessage}>{renderError(errors, 'isProgressDisplay')}</p>
      </FormControl>
      <br />
    </div>
  );
}

export default withRouter(DisplayPoolSwitch);
