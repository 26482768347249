import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BackButton from '../../../../components/Base/ButtonLink/BackButton';
import DefaultLayout from '../../../../components/Layout/DefaultLayout';
import { adminRoute } from '../../../../utils';
import { RewardStakingForm } from './RewardStakingForm';

const RewardStakingCreate: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const isEdit = false;

  return (
    <DefaultLayout>
      <BackButton to={adminRoute('/projects')} />
      <RewardStakingForm isEdit={isEdit} />
    </DefaultLayout>
  );
};

export default withRouter(RewardStakingCreate);
