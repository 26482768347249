import { FormHelperText } from "@material-ui/core";
import useStyles from "../styles";

interface HelpTextProps {
  children: React.ReactNode;
}

export function HelpText({ children }: HelpTextProps) {
  const styles = useStyles();

  return (
    <FormHelperText className={styles["help-text"]}>{children}</FormHelperText>
  );
}
