import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../../components/Layout/DefaultLayout';
import YielBoxProjectDetail from './Detail/YielBoxProjectDetail';

const YielBoxProjectCreate = () => {
  return (
    <DefaultLayout>
      <YielBoxProjectDetail completed={null} isCreate={true} />
    </DefaultLayout>
  );
};

export default withRouter(YielBoxProjectCreate);
